import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

interface DeleteDialogProps {
  open: boolean;
  confirmationText: string;
  onCancel?: () => void;
  onConfirm?: () => void;
}

export const DeleteDialog: React.FC<DeleteDialogProps> = ({
  open,
  confirmationText,
  onCancel,
  onConfirm,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>{t("dialog.deleteConfirmation")}</DialogTitle>
      <DialogContent>{confirmationText}</DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{t("common.cancel")}</Button>
        <Button onClick={onConfirm}>{t("common.delete")}</Button>
      </DialogActions>
    </Dialog>
  );
};
