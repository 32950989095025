import { useQuery } from "react-query";
import { getBasicOrganization, getOrganization } from "../api/api";

export function useOrganization() {
  return useQuery("organization", () => getOrganization());
}

export function useBasicOrganization() {
  return useQuery("basicOrganization", () => getBasicOrganization());
}
