import {
  ConfidenceMeasure,
  ConfidenceMeasureType,
} from "common/types/common.types";
import React from "react";
import { SimpleTableComponent } from "../../components/tables/simpleTable/SimpleTableComponent";
import { SimpleTableRow } from "../../components/tables/simpleTable/SimpleTableRow";
import { SimpleTableElement } from "../../components/tables/simpleTable/SimpleTableElement";
import { round } from "common/helpers/common.helpers";
import { Typography } from "common/components/Typography";
import { getColorInRange } from "common/helpers/colors.helpers";
import { CenteredContentWrapper } from "../../components/wrappers/CenteredContentWrapper";
import { useTranslation } from "react-i18next";

interface PredictionsConfidenceMeasureProps {
  confidence: ConfidenceMeasure;
}

const ConfidenceMeasureItem: React.FC<{
  name: string;
  description: string;
  value: number;
}> = ({ name, description, value }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "20px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "5px",
        }}
      >
        <Typography>{name}</Typography>
        <Typography
          variant="caption"
          textStyle={{
            fontSize: 15,
          }}
        >
          {description}
        </Typography>
      </div>
      <div style={{ fontSize: "18px", color: getColorInRange(value) }}>
        <b>{round(value * 100)}%</b>
      </div>
    </div>
  );
};

const borderStyle = "1px solid #e0e0e0";
const paddingConst = "10px";

export const PredictionsConfidenceMeasure: React.FC<
  PredictionsConfidenceMeasureProps
> = ({ confidence }) => {
  const { t } = useTranslation();

  return (
    <div style={{ maxWidth: "700px" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          borderBottom: borderStyle,
          padding: paddingConst,
        }}
      >
        {Object.values(ConfidenceMeasureType).map((type) => {
          const measure = confidence.measures.find((m) => m.type === type);
          if (!measure) {
            return null;
          }

          return (
            <ConfidenceMeasureItem
              key={type}
              name={t(`confidenceMeasure.measures.${type}.title`)}
              description={t(`confidenceMeasure.measures.${type}.description`)}
              value={measure.value}
            />
          );
        })}
      </div>
      <div
        style={{
          padding: paddingConst,
        }}
      >
        <ConfidenceMeasureItem
          name={t("confidenceMeasure.totalScore")}
          description={t("confidenceMeasure.totalScoreDescription")}
          value={confidence.total}
        />
      </div>
    </div>
  );
};
