import { CircularProgress } from "@mui/material";
import React from "react";

interface LoaderCenteredProps {
  wrapperStyle?: React.CSSProperties;
}

export const LoaderCentered: React.FC<LoaderCenteredProps> = ({
  wrapperStyle,
}) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...wrapperStyle,
      }}
    >
      <CircularProgress />
    </div>
  );
};
