import React, { useMemo, useState } from "react";
import { ContentCard } from "../../components/ContentCard";
import { SimpleTableComponent } from "../../components/tables/simpleTable/SimpleTableComponent";
import { SimpleTableRow } from "../../components/tables/simpleTable/SimpleTableRow";
import { SimpleTableHeader } from "../../components/tables/simpleTable/SimpleTableHeader";
import {
  Comparable,
  ComparableForReview,
  ComparableForReviewStatus,
  ComparableFull,
  ComparableTransactionFull,
} from "common/types/common.types";
import { SimpleTableElement } from "../../components/tables/simpleTable/SimpleTableElement";
import { round } from "common/helpers/common.helpers";
import {
  adjustmentFields,
  getTranslatedFieldName,
} from "common/helpers/comparables.helpers";
import colors from "common/styles/colors";
import { addAlpha } from "common/helpers/colors.helpers";
import { ComparableCard } from "../../components/map/ComparableCard";
import { Button, Chip } from "@mui/material";
import {
  extendedFilters,
  getComparableFilterTypeTranslated,
  getFilterValues,
  otherFilters,
} from "../../helpers/comparableFilters.helpers";
import { useMessagesStatus } from "../../components/messages/hooks";
import { SavedComparableFilter } from "../../types/api.types";
import { TagComponent } from "../../components/tags/TagComponent";
import { CenteredContentWrapper } from "../../components/wrappers/CenteredContentWrapper";
import {
  ExpandLess,
  ExpandMore,
  UnfoldLess,
  UnfoldMore,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";

interface TransparencySimilarityProps {
  comparablesForReview: ComparableForReview[];
  appliedFilters?: SavedComparableFilter[];
}

export const TransparencySimilarity: React.FC<TransparencySimilarityProps> = ({
  comparablesForReview,
  appliedFilters,
}) => {
  const { t } = useTranslation();
  const { data: messagesStatus } = useMessagesStatus();

  // Determine which fields we are showing based on the similarity details
  const fields = useMemo(() => {
    const fieldsSet = new Set<string>();
    comparablesForReview.forEach((c) => {
      c.similarities?.similarities_by_field?.forEach((f) => {
        fieldsSet.add(f.field);
      });
    });
    return Array.from(fieldsSet);
  }, [comparablesForReview]);

  const getFieldSimilarity = (
    comp: ComparableFull | Comparable,
    field: string
  ) => {
    return (
      comp.similarities?.similarities_by_field?.find((f) => f.field === field)
        ?.score ?? null
    );
  };

  const getStatusColor = (status: ComparableForReviewStatus) => {
    switch (status) {
      case ComparableForReviewStatus.Unchanged:
        return colors.green;
      case ComparableForReviewStatus.Removed:
        return colors.red;
      case ComparableForReviewStatus.Changed:
        return colors.orange;
      case ComparableForReviewStatus.Expanded:
        return colors.grey;
      default:
        return colors.grey;
    }
  };

  // Group comparables so that consecutive items with status Expanded are merged into a segment.
  // (If you need to group by another key, adjust the logic accordingly.)
  const rows = useMemo(() => {
    const result: {
      type: "single" | "segment";
      data: ComparableForReview | ComparableForReview[];
    }[] = [];
    let i = 0;
    while (i < comparablesForReview.length) {
      const comp = comparablesForReview[i];
      if (comp.status !== ComparableForReviewStatus.Expanded) {
        result.push({ type: "single", data: comp });
        i++;
      } else {
        const segment: ComparableForReview[] = [];
        while (
          i < comparablesForReview.length &&
          (comparablesForReview[i].status ===
            ComparableForReviewStatus.Expanded ||
            comparablesForReview[i].status ===
              ComparableForReviewStatus.Removed)
        ) {
          segment.push(comparablesForReview[i]);
          i++;
        }
        result.push({ type: "segment", data: segment });
      }
    }
    return result;
  }, [comparablesForReview]);

  const segmentsIndexes = useMemo(() => {
    return rows.flatMap((r, index) => (r.type === "segment" ? [index] : []));
  }, [rows]);

  // State to track which segments (by index) are expanded.
  const [expandedSegments, setExpandedSegments] = useState<Set<number>>(
    new Set()
  );

  const toggleSegment = (segmentIndex: number) => {
    setExpandedSegments((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(segmentIndex)) {
        newSet.delete(segmentIndex);
      } else {
        newSet.add(segmentIndex);
      }
      return newSet;
    });
  };

  // Calculate total number of columns in the table:
  // (1 for the property card, 1 for the joint similarity, one per field, and 1 for the status)
  const totalColumns = fields.length + 3;

  // Helper to render a single comparable row. Optionally indent the first cell (when inside a segment).
  const renderRow = (c: ComparableForReview, isExpanded: boolean = false) => {
    const ComparableReviewStatusTranslations: Record<
      ComparableForReviewStatus,
      string
    > = {
      [ComparableForReviewStatus.Changed]: t(
        "transparency.statusTypes.changed"
      ),
      [ComparableForReviewStatus.Removed]: t(
        "transparency.statusTypes.removed"
      ),
      [ComparableForReviewStatus.Unchanged]: t(
        "transparency.statusTypes.unchanged"
      ),
      [ComparableForReviewStatus.Expanded]: t(
        "transparency.statusTypes.expanded"
      ),
    };

    const status = messagesStatus?.[c.comparable_transaction.id];
    return (
      <SimpleTableRow
        key={c.comparable_transaction.id}
        style={{
          backgroundColor: isExpanded ? addAlpha("#A6A69E", 0.2) : undefined,
        }}
      >
        <SimpleTableElement isFirstElement>
          <ComparableCard comparable={c} isOverview noBorder status={status} />
        </SimpleTableElement>
        <SimpleTableElement style={{ textAlign: "center" }}>
          <b>{round(c.similarities.joint_similarity, 2).toFixed(2)}</b>
        </SimpleTableElement>
        {fields.map((f, index) => (
          <SimpleTableElement
            key={f}
            style={{
              paddingRight: index === fields.length - 1 ? 10 : undefined,
              textAlign: "center",
              width: "100px",
            }}
          >
            {round(getFieldSimilarity(c, f) ?? 0, 2).toFixed(2)}
          </SimpleTableElement>
        ))}
        <SimpleTableElement>
          <TagComponent color={getStatusColor(c.status)}>
            <CenteredContentWrapper>
              {ComparableReviewStatusTranslations[c.status]}
            </CenteredContentWrapper>
          </TagComponent>
        </SimpleTableElement>
      </SimpleTableRow>
    );
  };

  return (
    <ContentCard
      title={t("transparency.comparableSelection")}
      icon={
        expandedSegments.size === segmentsIndexes.length ? (
          <Button
            onClick={() => setExpandedSegments(new Set())}
            startIcon={<UnfoldLess />}
          >
            {t("transparency.collapseAll")}
          </Button>
        ) : (
          <Button
            onClick={() => setExpandedSegments(new Set(segmentsIndexes))}
            startIcon={<UnfoldMore />}
          >
            {t("transparency.expandAll")}
          </Button>
        )
      }
    >
      <div style={{ marginBottom: "20px" }}>
        <div style={{ marginBottom: "10px" }}>
          {t("transparency.candidatesSelectedByFilters")}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            flexWrap: "wrap",
          }}
        >
          {appliedFilters?.map((f) => {
            if (
              otherFilters.includes(f.type) ||
              extendedFilters.includes(f.type)
            ) {
              return null;
            }
            return (
              <Chip
                key={f.type}
                label={`${getComparableFilterTypeTranslated(
                  f.type
                )}: ${getFilterValues(f)}`}
                variant="outlined"
              />
            );
          })}
        </div>
      </div>
      <SimpleTableComponent>
        <SimpleTableRow>
          <SimpleTableHeader isFirstElement>
            {t("transparency.comparableObject")}
          </SimpleTableHeader>
          <SimpleTableHeader>{t("transparency.similarity")}</SimpleTableHeader>
          {fields.map((f) => (
            <SimpleTableHeader key={f}>
              {f === "distance"
                ? t("transparency.distance")
                : getTranslatedFieldName(
                    f as keyof ComparableTransactionFull,
                    t
                  )}
            </SimpleTableHeader>
          ))}
          <SimpleTableHeader>{t("transparency.status")}</SimpleTableHeader>
        </SimpleTableRow>

        {rows.map((row, index) => {
          if (row.type === "single") {
            return renderRow(row.data as ComparableForReview);
          } else {
            const segmentData = row.data as ComparableForReview[];
            const isExpanded = expandedSegments.has(index);
            return (
              <React.Fragment key={`segment-${index}`}>
                {/* Segment header row */}
                <SimpleTableRow
                  onClick={() => toggleSegment(index)}
                  style={{
                    cursor: "pointer",
                    background: addAlpha("#727272", 0.2),
                  }}
                >
                  <SimpleTableElement isFirstElement colSpan={totalColumns}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                      }}
                    >
                      <div>
                        {t("transparency.nonSelectedObjects", {
                          count: segmentData.length,
                        })}
                      </div>
                      <div style={{ marginTop: "5px" }}>
                        {isExpanded ? <ExpandLess /> : <ExpandMore />}
                      </div>
                    </div>
                  </SimpleTableElement>
                </SimpleTableRow>
                {/* If expanded, render each row inside the segment with an indent */}
                {isExpanded && segmentData.map((comp) => renderRow(comp, true))}
              </React.Fragment>
            );
          }
        })}
      </SimpleTableComponent>
    </ContentCard>
  );
};
