import { getColorInRange } from "common/helpers/colors.helpers";
import { ConfidenceMeasure } from "common/types/common.types";
import React, { useState } from "react";
import { SimpleDialog } from "../dialogs/SimpleDialog";
import { PredictionsConfidenceMeasure } from "../../routes/property/PredictionsConfidenceMeasure";
import { round } from "common/helpers/common.helpers";
import { useTranslation } from "react-i18next";

interface ConfidenceTooltipProps {
  confidence: ConfidenceMeasure;
  fontSize?: string;
}

export const ConfidenceTooltip: React.FC<ConfidenceTooltipProps> = ({
  confidence,
  fontSize,
}) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <div
        style={{
          color: getColorInRange(confidence.total),
          cursor: "pointer",
          fontSize: fontSize ?? "24px",
        }}
        onClick={() => setOpen(true)}
      >
        <b>{round(confidence.total * 100)}%</b>
      </div>

      <SimpleDialog
        open={open}
        onClose={() => setOpen(false)}
        title={t("confidenceTooltip.title")}
        buttonText={t("confidenceTooltip.understand")}
        onSubmit={() => setOpen(false)}
      >
        <PredictionsConfidenceMeasure confidence={confidence} />
      </SimpleDialog>
    </>
  );
};
