import { useFormContext } from "react-hook-form";
import { modifyErrorText } from "../helpers/form.helpers";

export const useFormFieldErrors = (name: string, label?: string) => {
  const {
    formState: { errors },
  } = useFormContext();

  // Handle nested field paths (e.g., "RC_EndUserInfo.vv")
  const fieldPath = name.split('.');
  let error = errors;
  for (const path of fieldPath) {
    error = error?.[path] as any;
  }

  const errorText = error?.message ? modifyErrorText(error.message.toString(), label) : undefined;
  return { errorText };
};
