import React, { useState } from "react";
import {
  AdditionalREType,
  Adjustments,
  Comparable,
  ComparableFull,
  ComparableTransactionFull,
  MyProperty,
} from "common/types/common.types";
import { Typography } from "common/components/Typography";
import {
  adjustmentFields,
  getTranslatedFieldName,
} from "common/helpers/comparables.helpers";
import {
  useAppraiserPropertyFields,
  useAppraiserPropertyFieldsMutation,
  usePricePredictions,
} from "../../hooks/pricePredictions.hooks";
import _ from "lodash";
import {
  calcSoldPrice,
  recalcAdjustments,
} from "../../helpers/comparable.helpers";
import { AdjustmentSummaryRow } from "./AdjustmentSummaryRow";
import { Button } from "common/components/buttons/Button";
import colors from "common/styles/colors";
import { useNavigate } from "react-router-dom";
import { AdditionalREField } from "./AdditonalREField";
import {
  AddAdditionalAdjustment,
  AdditionalFieldForm,
  AppraiserPropertyFieldOption,
} from "./AddAdditionalAdjustment";
import { AdjustmentRow } from "./AdjustmentRow";
import { AdjustmentTypography } from "./AdjustmentTypography";
import styles from "../../styles/adjustmentsTable.module.css";
import { Divider } from "common/components/listItems/Divider";
import { CenteredContentWrapper } from "../wrappers/CenteredContentWrapper";
import MinusIcon from "../../assets/icons/minus.svg";
import EqualIcon from "../../assets/icons/equal.svg";
import { RelatedREPrice } from "./RelatedREPrice";
import { OutlinedButton } from "common/components/buttons/OutlinedButton";
import { useQuery } from "react-query";
import { getAdjustmentsDescriptions } from "../../api/api";
import { useTranslation } from "react-i18next";

interface AdjustmentsTableProps {
  comparable: ComparableFull;
  property: MyProperty;
}

export const AdjustmentsTable: React.FC<AdjustmentsTableProps> = ({
  comparable,
  property,
}) => {
  const {
    getComparableAdjustments,
    updateComparableAdjustments,
    pricePredictions,
    isLoading,
  } = usePricePredictions(property._id);

  const [adjustments, setAdjustments] = useState<Adjustments>(
    getComparableAdjustments(comparable) as Adjustments
  );
  const [additionalPropertyFields, setAdditionalPropertyFields] = useState(
    pricePredictions?.additional_property_fields ?? []
  );

  const navigate = useNavigate();

  const updateAdjustments = (adjustments: Adjustments) => {
    setAdjustments(
      recalcAdjustments(adjustments, comparable.comparable_transaction)
    );
  };

  const realSoldPrice = calcSoldPrice(
    adjustments,
    comparable.comparable_transaction
  );
  const realSoldPriceArea =
    realSoldPrice / comparable.comparable_transaction.area;

  const { appraiserPropertyFields, getAppraiserPropertyFieldName } =
    useAppraiserPropertyFields();
  const { mutateAsync } = useAppraiserPropertyFieldsMutation();

  const { data: adjustmentsDescriptions } = useQuery(
    "adjustments_descriptions",
    getAdjustmentsDescriptions
  );

  const { t } = useTranslation();

  if (!appraiserPropertyFields) {
    return null;
  }

  const getAdditionalPropertyFieldValue = (id: string) =>
    additionalPropertyFields?.find(
      (field) => field.appraiserPropertyFieldId === id
    )?.fieldValue;

  const additionalAdjustmentsOptions: AppraiserPropertyFieldOption[] =
    appraiserPropertyFields.map((propertyField) => ({
      _id: propertyField._id,
      name: propertyField.name,
      fieldValue: additionalPropertyFields.find(
        (additional) =>
          additional.appraiserPropertyFieldId === propertyField._id
      )?.fieldValue,
      used: adjustments.additional_fields?.some(
        (field) => field.appraiserPropertyFieldId === propertyField._id
      ),
    }));

  const onAdditionalAdjustmentSubmit = async (
    additional: AdditionalFieldForm
  ) => {
    const fieldName = additional.fieldName;
    const fieldValue = additional.fieldValue;
    let appraiserPropertyFieldId = additional.appraiserPropertyFieldId;

    if (!appraiserPropertyFieldId) {
      appraiserPropertyFieldId = (await mutateAsync({ name: fieldName }))._id;
    }

    const additionalPropertyField = additionalPropertyFields.find(
      (field) => field.appraiserPropertyFieldId === appraiserPropertyFieldId
    );

    if (!!additionalPropertyField) {
      additionalPropertyField.fieldValue = fieldValue;
    } else {
      additionalPropertyFields.push({
        appraiserPropertyFieldId,
        fieldValue,
      });
    }
    setAdditionalPropertyFields([...additionalPropertyFields]);

    setAdjustments({
      ...adjustments,
      additional_fields: [
        ...(adjustments.additional_fields ?? []).filter(
          (field) => field.appraiserPropertyFieldId !== appraiserPropertyFieldId
        ),
        {
          appraiserPropertyFieldId,
          comparableDescription: additional.comparableDescription,
          percent: additional.percent,
        },
      ],
    });
  };

  return (
    <div>
      <div style={{ margin: "40px 0" }}>
        <Typography variant="h2">Sandorio kainos skaičiuoklė</Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "12px",
            marginTop: "30px",
          }}
        >
          <RelatedREPrice
            label="Turto vertė"
            price={realSoldPrice}
            isBold
            width="110px"
          />
          <div style={{ marginTop: "40px" }}>
            <EqualIcon />
          </div>
          <RelatedREPrice
            label="Sandorio vertė"
            price={comparable.comparable_transaction.sold_price}
          />
          <div style={{ marginTop: "40px" }}>
            <MinusIcon />
          </div>
          <AdditionalREField
            type={AdditionalREType.Basement}
            adjustments={adjustments}
            onChange={(adj) => updateAdjustments(adj)}
            relatedRE={comparable.comparable_transaction.related_re?.find(
              (re) => re.type === AdditionalREType.Basement
            )}
          />
          <div style={{ marginTop: "40px" }}>
            <MinusIcon />
          </div>
          <AdditionalREField
            type={AdditionalREType.Garage}
            adjustments={adjustments}
            onChange={(adj) => updateAdjustments(adj)}
            relatedRE={comparable.comparable_transaction.related_re?.find(
              (re) => re.type === AdditionalREType.Garage
            )}
          />
        </div>
      </div>

      <Typography variant="h2">Pataisų koregavimas</Typography>
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          marginTop: "20px",
        }}
        className={styles.table}
      >
        <thead>
          <tr>
            <th style={{ paddingLeft: 0 }}>
              <AdjustmentTypography color={colors.grey}>
                Vertės pataisa
              </AdjustmentTypography>
            </th>
            <th>
              <AdjustmentTypography color={colors.grey}>
                Aprašymas
              </AdjustmentTypography>
            </th>
            <th>
              <AdjustmentTypography color={colors.grey}>
                Aprašymas
              </AdjustmentTypography>
            </th>
            <th>
              <AdjustmentTypography color={colors.grey}>
                Pataisa €/m2
              </AdjustmentTypography>
            </th>
            <th>
              <AdjustmentTypography color={colors.grey}>
                Pataisos koeficientas
              </AdjustmentTypography>
            </th>
          </tr>
        </thead>
        <tbody>
          {adjustments.adjusted_fields.map((field, index) => {
            const adjustmentField = adjustmentFields[field.field];

            return (
              <AdjustmentRow
                key={field.field}
                name={getTranslatedFieldName(
                  field.field as keyof ComparableTransactionFull,
                  t
                )}
                propertyDesription={adjustmentField?.propertyFieldValue(
                  property
                )}
                comparableDescription={adjustmentField?.fieldValue(
                  comparable.comparable_transaction
                )}
                priceArea={field.price_area}
                percent={field.percent}
                onChange={(value) => {
                  const deepAdjustmentsCopy = _.cloneDeep(adjustments);
                  deepAdjustmentsCopy.adjusted_fields[index].percent = value;

                  updateAdjustments(deepAdjustmentsCopy);
                }}
                onChangeFull={(value) => {
                  const deepAdjustmentsCopy = _.cloneDeep(adjustments);
                  deepAdjustmentsCopy.adjusted_fields =
                    deepAdjustmentsCopy.adjusted_fields?.map((field) => {
                      if (field.field === value.field) {
                        return value;
                      }
                      return field;
                    });

                  updateAdjustments(deepAdjustmentsCopy);
                }}
                fieldname={field.field}
                config={{
                  fullname: field.config_fullname,
                  name: field.config_name,
                }}
                adjustmentDescription={
                  adjustmentsDescriptions?.find(
                    (description) => description.field === field.field
                  )?.description
                }
              />
            );
          })}
          {adjustments.additional_fields?.map((field, index) => (
            <AdjustmentRow
              key={field.appraiserPropertyFieldId}
              name={getAppraiserPropertyFieldName(
                field.appraiserPropertyFieldId
              )}
              propertyDesription={getAdditionalPropertyFieldValue(
                field.appraiserPropertyFieldId
              )}
              comparableDescription={field.comparableDescription}
              priceArea={field.percent * realSoldPriceArea}
              percent={field.percent}
              onChange={(value) => {
                field.percent = value;
                updateAdjustments(adjustments);
              }}
              onRemoveClick={() => {
                updateAdjustments({
                  ...adjustments,
                  additional_fields: adjustments.additional_fields?.filter(
                    (_, i) => index !== i
                  ),
                });
              }}
              adjustmentDescription={
                adjustmentsDescriptions?.find(
                  (description) =>
                    description.appraiserPropertyFieldId ===
                    field.appraiserPropertyFieldId
                )?.description
              }
            />
          ))}
        </tbody>
      </table>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingTop: "20px",
          paddingBottom: "20px",
        }}
      >
        <AddAdditionalAdjustment
          options={additionalAdjustmentsOptions}
          onSubmit={onAdditionalAdjustmentSubmit}
        />
      </div>
      <Divider />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          margin: "30px 0",
          alignItems: "flex-end",
        }}
      >
        <AdjustmentSummaryRow
          label="Bendra pataisa"
          priceArea={adjustments.price_increase_area}
          price={
            adjustments.price_increase_area *
            comparable.comparable_transaction.area
          }
        />
        <AdjustmentSummaryRow
          label="Turto vertė"
          priceArea={realSoldPriceArea}
          price={realSoldPrice}
        />
        <AdjustmentSummaryRow
          label="Kaina po pataisų"
          priceArea={adjustments.adjusted_price_area}
          price={
            adjustments.adjusted_price_area *
            comparable.comparable_transaction.area
          }
        />
      </div>
      <CenteredContentWrapper>
        <div style={{ display: "flex", gap: "20px" }}>
          <OutlinedButton
            style={{ width: "345px" }}
            onPress={() => navigate(-1)}
          >
            ATŠAUKTI
          </OutlinedButton>
          <Button
            isLoading={isLoading}
            onPress={() => {
              updateComparableAdjustments(
                comparable.comparable_transaction.id,
                adjustments,
                additionalPropertyFields
              ).then(() => {
                navigate(-1);
              });
            }}
            backgroundColor="purple2"
            textStyle={{ color: colors.white }}
            style={{ width: "345px" }}
          >
            IŠSAUGOTI IR GRĮŽTI
          </Button>
        </div>
      </CenteredContentWrapper>
    </div>
  );
};
