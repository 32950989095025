import React, { useEffect } from "react";
import * as Yup from "yup";
import { OrganizationProfile } from "../../types/api.types";
import { useOrganization } from "../../hooks/organization.hooks";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormInput } from "common/components/form/hookForms/FormInput";
import { FormImageInput } from "../../components/form/FormImageInput";
import LoadingButton from "@mui/lab/LoadingButton";
import { ContentCard } from "../../components/ContentCard";
import { useMutation, useQueryClient } from "react-query";
import { setOrganizationProfile } from "../../api/api";
import { showToastError, showToastSuccess } from "common/toast/toast";
import { FormSettingsContext } from "common/components/form/hookForms/FormSettingsContext";
import { FormLoadingButton } from "../../components/form/FormLoadingButton";
import { FormAutocomplete } from "../../components/form/FormAutocomplete";
import { useAppraisersInOrganization } from "../../hooks/auth.hooks";
import { FormInputBox } from "../../components/form/FormInputBox";

interface OrganizationProfileFormProps {}

const schema = Yup.object().shape({
  name: Yup.string().required(),
  fullName: Yup.string().nullable(),
  email: Yup.string().nullable(),
  phone: Yup.string()
    .notRequired()
    .matches(/^\+?[1-9]\d{1,14}$/, "Telefono numeris įvestas neteisingai")
    .nullable(),
  website: Yup.string().url().notRequired().nullable(),
  logo: Yup.string(),
  primaryAppraiserId: Yup.string().nullable(),
  description: Yup.string().nullable(),
}) as Yup.ObjectSchema<OrganizationProfile>;

export const OrganizationProfileForm: React.FC<
  OrganizationProfileFormProps
> = () => {
  const { data: organization } = useOrganization();

  const methods = useForm<OrganizationProfile>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: organization?.name ?? "",
      fullName: organization?.fullName,
      email: organization?.email,
      phone: organization?.phone,
      website: organization?.website,
      logo: organization?.logo,
      primaryAppraiserId: organization?.primaryAppraiserId,
      description: organization?.description,
    },
  });

  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(setOrganizationProfile, {
    onError: () => {
      showToastError();
    },
    onSuccess: () => {
      showToastSuccess("Profilio informacija sėkmingai išsaugota.");
      queryClient.invalidateQueries("organization");
    },
  });

  const { data: appraisers } = useAppraisersInOrganization();

  useEffect(() => {
    if (appraisers && organization) {
      const primaryAppraiser = appraisers.find(
        (a) => a._id === organization.primaryAppraiserId
      );
      // @ts-ignore
      methods.setValue("primaryAppraiser", primaryAppraiser?.email);
    }
  }, [appraisers]);

  return (
    <ContentCard title="Įmonės profilis">
      <FormProvider {...methods}>
        <FormSettingsContext.Provider
          value={{ desktopColumns: true, smallFont: true, maxInputWidth: 600 }}
        >
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 20,
            }}
            onSubmit={methods.handleSubmit((data) => {
              mutate(data);
            })}
          >
            <FormImageInput name="logo" label="Logotipas" />
            <FormInput
              name="name"
              label="Pavadinimas"
              variant="text"
              isRequired
            />
            <FormInput
              name="fullName"
              label="Pilnas pavadinimas"
              variant="text"
            />
            <FormAutocomplete
              name="primaryAppraiser"
              label="Pirminis vertintojas"
              options={appraisers?.map((a) => a.email) ?? []}
              onSelect={(value) => {
                const appraiser = appraisers?.find((a) => a.email === value);
                if (!value && typeof value === "string") {
                  return;
                }
                methods.setValue("primaryAppraiserId", appraiser?._id ?? null);
              }}
              enableFiltering
            />
            <FormInput name="email" label="El. paštas" variant="text" />
            <FormInput name="phone" label="Telefonas" variant="text" />
            <FormInput name="website" label="Tinklalapis" variant="text" />
            <FormInputBox name="description" label="Aprašymas" rows={3} />
            <FormLoadingButton isLoading={isLoading} />
          </form>
        </FormSettingsContext.Provider>
      </FormProvider>
    </ContentCard>
  );
};
