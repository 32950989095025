import {
  CommuteElement,
  Comparable,
  ComparableFull,
  FinalPricePredictions,
  MyPropertyAddRequest,
  PredictedPrice,
  Property,
  SimilarListings,
  WorkTimes,
} from "common/types/common.types";
import { ComparableFilterType } from "../helpers/comparableFilters.helpers";
import { Appraiser, AppraiserProperty, AppraiserRole } from "./appraiser.types";
import { PropertyPermission } from "../routes/property/PropertyShareSettings";
import { DesignationType } from "common/types/comparableFields.types";

export interface LoginRequest {
  email: string;
  password: string;
}

export interface RegisterRequest {
  token: string;
  password: string;
}

export interface RegisterResponse {
  appraiser: Appraiser;
  requiresVerification?: boolean;
}

export interface OrganizationProfile {
  name: string;
  fullName?: string | null;
  description?: string | null;
  email?: string | null;
  phone?: string | null;
  website?: string | null;
  logo?: string;
  primaryAppraiserId?: string | null;
}

export interface BasicOrganization extends OrganizationProfile {
  _id: string;
}

export interface Organization extends BasicOrganization {
  monthlyCreditLimit?: number;
  transactionRetentionDays?: number | null;
  createdAt: string;
  updatedAt: string;
  RC_EndUserInfo?: { [key: string]: string };
  requiresAppraiserVerification?: boolean;
}

export interface CreateAppraiserRequest {
  email: string;
  role: AppraiserRole;
}

export interface AppraiserPropertyField {
  _id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
}

export interface Pagination {
  page: number;
  pageSize: number;
  total: number;
}

export interface PaginatedResponse<T> {
  data: T;
  pagination: Pagination & {
    total: number;
  };
}

export enum PropertyAssessmentStatus {
  Unfinished = "unfinished",
  New = "new",
  InProgress = "inProgress",
  WaitingForReview = "waitingForReview",
  Final = "final",
}

export enum AppraiserFilter {
  All = "all",
  My = "my",
}

export enum InfraFilter {
  All = "all",
  Planned = "planned",
  Existing = "existing",
}

export enum InfraFilterByDistance {
  All = "all",
  Walking = "walking",
}

export interface DecodedToken<T> {
  data?: T;
  error?: {
    name: string;
  };
}

export type DecodedAppraiserToken = DecodedToken<{ email: string }>;

export interface SavedComparableFilter {
  type: ComparableFilterType;
  values: any;
}

export interface AnalyzedReport {
  area: number | null;
  floors: number | null;
  floors_total: number | null;
  address: string;
  year_construction: number | null;
  year_reconstruction: number | null;
  sold_price: number | null;
  location: string;
  lat: number | null;
  lng: number | null;
}

export enum MessageType {
  INFO = "info",
  FAKED = "faked",
  WRONG_DATA = "wrong_data",
}

export interface StatusMessage {
  type: MessageType;
  message?: string;
}

export interface ComparableMessageCreateRequest {
  type: MessageType;
  message: string;
  transactionId: string;
}

export interface ComparableMessage extends ComparableMessageCreateRequest {
  _id: string;
  organizationId: string;
  appraiserId: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface TransactionsSearchResponse {
  base_transactions: Comparable[];
  full_transactions: ComparableFull[];
  failed_geocoding_count: number;
  multiple_objects_count: number;
}

export interface ValuationZone {
  zone_nr: string;
}

export interface ValuationZoneFull extends ValuationZone {
  geometry: {
    coordinates: number[][][];
  };
}

export interface ValuationZoneFullCoeff extends ValuationZoneFull {
  coeffs: Record<string, number>;
}

export interface UsedCreditsRow {
  creditsUsed: number;
  actionsCount: number;
  actionType: "197" | "198";
}

export interface OrganizationUsedCredits {
  totalCreditsUsed: number;
  monthlyCreditLimit: number | null;
  currentMonth: number;
}

export interface AppraiserUsedCredits {
  appraiserId: string;
  email: string;
  totalCredits: number;
}

export interface UsedCreditsByMonth {
  month: number;
  year: number;
  appraisers: AppraiserUsedCredits[];
}

export interface AdjustmentProvider {
  name: string;
  fullname: string;
}

export interface AdjustmentField {
  field: string;
  providers: AdjustmentProvider[];
  providerSelected: string;
  description?: string;
}

export interface AdditionalField {
  field: string;
  appraiserPropertyFieldId: string;
  description?: string;
}

export interface AdminAdjustments {
  adjustmentFields: AdjustmentField[];
  additionalFields: AdditionalField[];
}

export interface AdjustmentDescription {
  description: string;
  field?: string;
  appraiserPropertyFieldId?: string;
  header?: string;
}

export interface AdjustmentDescriptionForComparable {
  field: string;
  provider: string;
  percent: number;
  comparable_index: number;
  comparable_id: string;
  description?: string | null;
}

export interface TransactionsImportResult {
  found_in_db_count: number;
  saved_in_db_count: number;
  not_supported_count: number;
}

export interface MontonioPaymentMethod {
  name: string;
  logoUrl: string;
  supportedCurrencies: string[];
  uiPosition: number;
  code: string;
}
export interface PaymentMethods {
  paymentMethods: {
    paymentInitiation: {
      setup: {
        LT: {
          supportedCurrencies: string[];
          paymentMethods: MontonioPaymentMethod[];
        };
      };
    };
    cardPayments: {
      logoUrl: string;
    };
  };
}

export enum PaymentMethodType {
  Bank = "bank",
  Card = "card",
  Free = "free",
}

export enum PropertyType {
  Apartment = "apartment",
  House = "house",
  Cottage = "cottage",
}

export const PropertyTypeTranslations: Record<PropertyType, string> = {
  [PropertyType.Apartment]: "Butas",
  [PropertyType.House]: "Namas",
  [PropertyType.Cottage]: "Kotedžas",
};

export interface PropertyLegalParagraph {
  text: string;
  listItems?: string[];
}

export interface PropertyLegal {
  section: string;
  paragraphs: PropertyLegalParagraph[];
}

export interface PropertyShared {
  propertyToken?: string | null;
  canEdit: boolean;
  property: AppraiserProperty;
  basicOrganization?: BasicOrganization;
  propertyComment?: PropertyComment | null;
  comparables?: ComparableFull[];
  predictedPrice?: PredictedPrice;
  compositeAssets?: CompositeAsset[];
  pricePrediction?: FinalPricePredictions;
  documents?: UploadedFile[];
  distances?: PropertyInfra[];
  purchasedAddons?: PropertyAddonWithPrice[];
  purchasedCompositeAssets?: CompositeAsset[];
  marketInsights?: MarketInsights;
  locationDetails?: PropertyLocationDetails[];
  legal?: PropertyLegal[];
  legalMarketValue?: PropertyLegal[];
  appraiserLegal?: PropertyLegal;
  confirmation?: Confirmation | null;
  hideProperty?: boolean;
  hideGallery?: boolean;
  methodology?: PropertyLegal[];
  totalDepositPaid?: number;
  similarPropertiesInsights?: SimilarListings;
}

export interface PropertyComment {
  comment: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface DiscountCodeCreateRequest {
  code: string;
  discount: number;
  validFrom: Date;
  validUntil: Date;
}

export interface DiscountCode extends DiscountCodeCreateRequest {
  _id: string;
  uuid: string;
  usedCount: number;
  isActive: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export interface PropertyAddon {
  type: PropertyAddonType;
}

export interface PropertyAddonWithPrice extends PropertyAddon {
  price?: number;
}

export interface CompositeAssetWithPrice {
  _id: string;
  designationTypeId: DesignationType;
  price?: number;
}

export interface TotalReportPrice {
  basePrice: number;
  discount: {
    discountAmount: number;
    discountPercentage: number;
  } | null;
  discountCodeError: string | null;
  addonsPrice: number;
  addonsWithPrices: PropertyAddonWithPrice[];
  compositeAssetsPrice: number;
  compositeAssetsWithPrices: CompositeAssetWithPrice[];
  fullPrice: number;
  totalPrice: number;
  deliveryTime?: PropertyDeliveryTimeType;
  deliveryTimePrice?: number;
  locationPrice?: number;
  locationPriceDescription?: string | null;
}

export enum PropertyLocationDetailsType {
  REGIA1 = "REGIA1",
  REGIA2 = "REGIA2",
  FLOODS = "FLOODS",
  AIR_POLLUTION = "AIR_POLLUTION",
  PROTECTED_TERRITORY = "PROTECTED_TERRITORY",
}

export interface PropertyLocationDetails {
  _id: string;
  locationType: PropertyLocationDetailsType;
  url?: string;
  screenshotUrl?: string;
  propertyId: string;
  generatingCompleted: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export enum PropertyInfraType {
  Pharmacy = "pharmacy",
  Shop = "shop",
}

export interface InfraByType {
  type: string;
  infras: PropertyInfraIndexed[];
}

export interface PropertyInfra {
  _id: string;
  type: PropertyInfraType;
  subtype: string;

  lat: number;
  lng: number;
  address: string;
  name?: string | null;
  meta?: any;
  work_times?: WorkTimes[] | null;

  // project exclusive
  is_project?: boolean | null;
  /** status of project about "how finished it is" */
  project_status?: "Planuojamas" | "Statomas" | "Baigtas" | null;
  // end

  distances: CommuteElement[];
  icon: string;

  // school exclusive
  rating_title?: string | null;
  examResultsId?: string | null;
}

export interface SchoolExamResults {
  school_name: string;
  municipality_name: string;
  exams: {
    subject_name: string;
    average_grade: number;
  }[];
}

export interface School {
  school_name: string;
  grade: number;
  error: string;
  distance: number;
}

export interface NearbySchools {
  location: string;
  location_desc: string;
  selected_school_rating: number;
  total_schools: number;
  top_schools: School[];
}

export interface RatingPosition {
  city: number;
  municipality: number;
  country: number;
  city_count: number;
  municipality_count: number;
  country_count: number;
}

export interface SchoolRating extends School {
  _id: string;
  city: string;
  municipality: string;
  rating_position: RatingPosition;
  school_id: string;
}

export interface PropertyInfraIndexed extends PropertyInfra {
  index: number;
}

export enum AreaUnit {
  SqMeters = "m2",
  Acres = "a",
  Hectares = "h",
}

export interface CompositeAssetRequest {
  propertyId: string;
  name: string;
  designationTypeId?: string;
  uniqueNr?: string;
  areaTotal: number;
  areaUnit: AreaUnit;
  assetPart1: number;
  assetPart2: number;
  value?: number;
  isCustomer: boolean;
  address?: string;
}

export enum CompositePresetType {
  ParkingOverground = "parking_overground",
  ParkingUnderground = "parking_underground",
  Storage = "storage",
  PlotArea = "plotArea",
}

export type CompositeAssetPreset = Partial<CompositeAssetRequest> & {
  presetType: CompositePresetType;
};

export interface CompositeAsset extends CompositeAssetRequest {
  _id: string;
  isPlotArea?: boolean;
  isPaid?: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export interface ConfirmationRequest {
  organizationName: string;
  appraiserFullName: string;
  certificateNumber?: string | null;
  certificateValidFrom?: Date | null;

  appraiserFullNameConfirm: string;
  confirmCheckbox?: boolean;
}

export interface Confirmation extends ConfirmationRequest {
  _id: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface UploadedFile {
  _id: string;
  fileName: string;
  url: string;
  key: string;
}

export enum PropertyAddonType {
  // ComparablesView = "comparables",
  // CalculationsView = "calculations",
  LocationReport = "locationReport",
  MarketInsights = "marketInsights",
}

export enum PropertyDeliveryTimeType {
  Standard = "standard",
  Fast = "fast",
  Express = "express",
}

export const PropertyAddonToPermission: Record<
  PropertyAddonType,
  PropertyPermission | null
> = {
  // [PropertyAddonType.ComparablesView]: PropertyPermission.ViewComparables,
  // [PropertyAddonType.CalculationsView]: PropertyPermission.ViewCalculations,
  [PropertyAddonType.LocationReport]: PropertyPermission.ViewDistances,
  [PropertyAddonType.MarketInsights]: PropertyPermission.ViewMarketInsights,
};

export interface DeliveryTimeConfiguration {
  type: PropertyDeliveryTimeType;
  deliveryHour: number;
  orderUntilHour: number;
  deliveryDays: number;
  price: number;
  label: string;
  sublabel: string;
  deliveryDate: string;
}

export type PropertyAddonPrices = Record<PropertyAddonType, number>;

export enum ChartType {
  Lines = "lines",
  Bars = "bars",
}
export interface ChartDataItem {
  x: string | number;
  y: number;
}

export enum ChartSection {
  MarketInsights = "market_insights",
  PopulationStatistics = "population_statistics",
  SchoolExamResultsStatistics = "school_exam_results_statistics",
}

export interface ChartData {
  id?: string;
  title: string;
  chart_type: ChartType;
  data: ChartDataItem[];
  x_axis_label?: string;
  y_axis_label?: string;
  source: string;
  section: ChartSection;
  subtitle?: string;
  description?: string;
  layout?: "vertical" | "horizontal";
  wrapSourceInLink?: boolean;
  trimSource?: boolean;
}

export enum MarketLevel {
  INACTIVE = "inactive", // Good for buyers
  NORMAL = "normal", // Balanced market
  HOT = "hot", // Good for sellers
}
export interface PricePrediction {
  summary?: string;
  marketLevel?: MarketLevel;
  priceChangePercentageMin?: number;
  priceChangePercentageMax?: number;
  keyInsights?: KeyInsight[];
}

export interface MarketInsights {
  marketInsights: ChartData[];
  pricePrediction?: PricePrediction | null;
}
export interface DepositPrices {
  depositPrice: number;
  depositDiscount: number;
}

export interface DepositPaid {
  totalDepositPaid: number;
}

export interface SimilarPricePrediction {
  property: AppraiserProperty;
  pricePrediction: FinalPricePredictions;
  finalPredictionCreatedAt: string;
}

export interface PolygonPoint {
  lat: number;
  lng: number;
}

export interface PropertyPolygon {
  polygon: PolygonPoint[];
}

export interface PropertyStatistics {
  id: string;
  createdAt: string;
  address: string;
  fullPrice?: number;
  totalPrice?: number;
  discountPercentage?: number;
  discountCode?: string;
  appraiser: string;
  customer?: string;
  evaluatedAt?: string;
  designationType?: DesignationType;
  deliveryDate?: string;
  executionDays?: number;
}

export interface TemplateMessagePart {
  param?: string;
  text: string;
}

export interface CreateTemplateMessagePart {
  param: string;
  text: string;
}

export interface PropertyEmailTemplate {
  id: string;
  templateName: string;
  messageParts: TemplateMessagePart[];
}

export interface CreatePropertyLeadMessage {
  templateId: string;
  messageParts: CreateTemplateMessagePart[];
  title: string;
}

export interface PropertyLeadMessage {
  _id: string;
  title: string;
  message: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface TransparencyAdjustmentsCoeffs {
  coeff: number | null;
}

export interface AdjustmentTableRow {
  range_min: number;
  range_max: number;
  coeff_min: number;
  coeff_max: number;
}

export interface PropertyReadingSingleOverview {
  /** Where from the data originates (can be file name) */
  origins: string[];
  isChecked?: boolean;
  data:
    | {
        property: MyPropertyAddRequest;
        asset?: undefined;
      }
    | {
        property?: undefined;
        asset: CompositeAssetRequest;
      };
}

export interface LoginResponse {
  appraiser: Appraiser;
  newDeviceLoginDetected: boolean;
  registrationToken?: string;
}

export interface ValuationZoneClosestCity
{
  city: string;
  population: number;
  employment_rate: number;
  average_salary: number;
  previous_population: number;
  previous_employment_rate: number;
  previous_average_salary: number;
}

export interface CityStatistic {
  city: string;
  count: number;
}

export interface RiskCounts {
  Low: number;
  Medium: number;
  High: number;
}

export interface ClimateRiskStatistic {
  riskType: "Heat" | "Wind" | "Flood" | "AirPollution";
  risks: RiskCounts;
}

export interface PropertyTypeCount {
  type: PropertyType;
  count: number;
}

export interface MonthlyPortfolioValue {
  month: string;
  nominalValue: number;
  predictedValue: number;
}

export interface PortfolioValue {
  monthlyValues: MonthlyPortfolioValue[];
}

export interface PortfolioStatistics {
  valuationsByCity: CityStatistic[];
  climateRisksStatistics: ClimateRiskStatistic[];
  propertiesBySimpleType: PropertyTypeCount[];
  portfolioValue: PortfolioValue;
}

export type ChartTrend = "increasing" | "decreasing" | "stable";

export interface KeyInsight {
  id: string;
  trend: ChartTrend;
}
