import React from "react";
import { Divider } from "@mui/material";
import colors from "common/styles/colors";
import { Typography } from "common/components/Typography";
import {
  ValuationZoneClosestCity,
  ValuationZoneFullCoeff,
} from "../../../types/api.types";
import { useQuery } from "react-query";
import { getValuationZoneClosestCityInfo } from "../../../api/geocode.api";
import { LoaderCentered } from "../../../components/loaders/LoaderCentered";
import { formatNumber } from "common/helpers/text.helpers";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import RemoveIcon from "@mui/icons-material/Remove";
import { useTranslation } from "react-i18next";

interface ValuationZoneCardProps {
  zoneInfo: ValuationZoneFullCoeff;
  coeffName?: string | null;
  distance: number | null;
  zoneCenter: { lat: number; lng: number };
}

export const ValuationZoneCard: React.FC<ValuationZoneCardProps> = ({
  zoneInfo,
  coeffName,
  distance,
  zoneCenter,
}) => {
  const { zone_nr } = zoneInfo;
  const coeff = zoneInfo.coeffs[coeffName ?? "flat_small"] ?? "-";
  const { t } = useTranslation();

  const { data: closestCityData, isLoading } =
    useQuery<ValuationZoneClosestCity>(
      ["closestCity", zoneCenter.lat, zoneCenter.lng],
      () => getValuationZoneClosestCityInfo(zoneCenter.lat, zoneCenter.lng),
      { enabled: !!zoneCenter }
    );

  const containerStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    borderRadius: "8px",
    padding: "20px",
    height: "100%",
    width: "320px",
    backgroundColor: colors.white,
    gap: "20px",
  };

  const sectionStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "10px",
  };

  const rowStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  };

  const trendStyle = (isPositive?: boolean): React.CSSProperties => ({
    display: "inline-flex",
    gap: "4px",
    color:
      isPositive === true
        ? colors.green
        : isPositive === false
        ? colors.red
        : colors.grey,
  });

  const renderTrend = (
    current: number,
    previous: number,
    unit = "",
    isIncreaseGood: boolean = true
  ): JSX.Element | null => {
    if (previous === undefined || previous === null) return null;

    const diff = current - previous;
    const percentage =
      previous !== 0 ? ((diff / previous) * 100).toFixed(1) : "0";
    const isPositive = isIncreaseGood ? diff > 0 : diff < 0;
    const isNeutral = diff === 0;
    const isIncreasing = diff > 0;

    const formattedDiff = `${diff > 0 ? "+" : ""}${diff.toLocaleString()}`;

    return (
      <span style={trendStyle(isPositive)}>
        {isIncreasing ? (
          <ArrowUpwardIcon style={{ fontSize: "1rem" }} />
        ) : isNeutral ? (
          <RemoveIcon style={{ fontSize: "1rem" }} />
        ) : (
          <ArrowDownwardIcon style={{ fontSize: "1rem" }} />
        )}
        {formattedDiff} {unit} ({percentage} % {t("valuationZoneCard.change")})
      </span>
    );
  };

  return (
    <div style={containerStyle}>
      <div style={sectionStyle}>
        <Typography variant="h3">
          {t("valuationZoneCard.valuationZone")} ({zone_nr})
        </Typography>
        <Typography variant="h5">
          {t("valuationZoneCard.coeff")}: <strong>{coeff}</strong>
        </Typography>
        <Typography variant="h5">
          {t("valuationZoneCard.distance")}:{" "}
          <strong>{distance?.toFixed(2)} km</strong>
        </Typography>
      </div>
      <Divider variant="fullWidth" />
      {!isLoading && closestCityData ? (
        <div style={sectionStyle}>
          <Typography variant="h3">
            {t("valuationZoneCard.closestCity")}
          </Typography>
          <Typography variant="h5">{closestCityData.city}</Typography>
          <Typography variant="h5">
            {t("valuationZoneCard.population")}:
          </Typography>
          <div style={rowStyle}>
            <Typography variant="h5" textStyle={{ flex: 0.75 }}>
              {formatNumber(closestCityData.population)}{" "}
            </Typography>
            <Typography
              variant="h5"
              textStyle={{ flex: 2.25, textAlign: "right" }}
            >
              {renderTrend(
                closestCityData.population,
                closestCityData.previous_population
              )}
            </Typography>
          </div>

          <Typography variant="h5">
            {t("valuationZoneCard.employment")}:
          </Typography>
          <div style={rowStyle}>
            <Typography variant="h5" textStyle={{ flex: 0.75 }}>
              {closestCityData.employment_rate} %{" "}
            </Typography>
            <Typography
              variant="h5"
              textStyle={{ flex: 2.25, textAlign: "right" }}
            >
              {renderTrend(
                closestCityData.employment_rate,
                closestCityData.previous_employment_rate,
                "%"
              )}
            </Typography>
          </div>

          <Typography variant="h5">
            {t("valuationZoneCard.averageSalary")}:
          </Typography>
          <div style={rowStyle}>
            <Typography variant="h5" textStyle={{ flex: 0.75 }}>
              {closestCityData.average_salary} €{" "}
            </Typography>
            <Typography
              variant="h5"
              textStyle={{ flex: 2.25, textAlign: "right" }}
            >
              {renderTrend(
                closestCityData.average_salary,
                closestCityData.previous_average_salary,
                "€"
              )}
            </Typography>
          </div>
        </div>
      ) : (
        <LoaderCentered />
      )}
    </div>
  );
};
