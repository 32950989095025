import React from "react";
import { CompositeAssetWithPrice } from "../../../types/api.types";
import { OrderSummarySection } from "./OrderSummarySection";
import { getDesignationTypeTranslation } from "../../../helpers/comparableFields.helpers";

interface CompositeAssetsSummaryProps {
  assets: CompositeAssetWithPrice[];
  title: string;
  isPurchased?: boolean;
}

export const CompositeAssetsSummary: React.FC<CompositeAssetsSummaryProps> = ({
  assets,
  title,
  isPurchased,
}) => {
  const totalPrice = assets.reduce((acc, asset) => acc + (asset.price ?? 0), 0);

  if (!assets.length) {
    return null;
  }

  return (
    <OrderSummarySection
      items={assets.map((asset) => ({
        title: getDesignationTypeTranslation(asset.designationTypeId),
        price: asset.price,
      }))}
      total={totalPrice}
      title={title}
      isPurchased={isPurchased}
    />
  );
};
