import React from "react";
import { Typography } from "common/components/Typography";
import { ContentCard } from "../../../components/ContentCard";
import { SimilarListings } from "common/types/common.types";
import { Chart } from "../../../components/charts/Chart";
import {
  createMarketDaysChartData,
  createSquareMeterPriceChartData,
  getAvgDaysToSellListing,
} from "../../../helpers/similarProperties.helpers";

import { ListingsWithMap } from "./ListingsWithMap";
import { AddonListingCard } from "../../../components/comparablesSummary/AddonListingCard";
import { AppraiserProperty } from "../../../types/appraiser.types";
import colors from "common/styles/colors";
import {
  ClusteredGenericMapControlProvider,
  ClusteredListingsMapControlContext,
} from "../../../components/ClusteredGenericMapControlProvider";
import { getPropertyListString } from "../../../helpers/property.helpers";
import { PredictionOverviewPrice } from "../../../components/predictionOverview/PredictionOverviewPrice";
import {
  ZonePolygonContext,
  ZonePolygonContextType,
  ZonePolygonProvider,
} from "./ZonePolygonProvider";
import { useTranslation } from "react-i18next";
import { Divider } from "@mui/material";

interface SimilarPropertiesInsightsProps {
  property: AppraiserProperty | undefined;
  similarListings: SimilarListings;
}

export const SimilarPropertiesInsights: React.FC<
  SimilarPropertiesInsightsProps
> = ({ property, similarListings }) => {
  const { t } = useTranslation();

  const marketDaysChartData = createMarketDaysChartData(
    similarListings.stats.sold_days_on_market || null,
    t
  );
  const avgDaysToSell = getAvgDaysToSellListing(
    similarListings.stats.sold_days_on_market || null
  );
  const sqmPriceChartData = createSquareMeterPriceChartData(
    similarListings.stats.map_square_meter_price || null,
    t
  );
  const sqmPriceMedian = similarListings.stats.map_square_meter_price.median;
  const myPropertyExpectedSqmPrice =
    property?.expectedValue && property?.area
      ? property.expectedValue / (property.area || 1)
      : null;

  return (
    <ContentCard title={t("propertyRoute.similarPropertiesOnMarket")}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "40px",
          marginBottom: "40px",
          pageBreakAfter: "always",
        }}
      >
        {marketDaysChartData && avgDaysToSell && (
          <>
            <Typography variant="h4">
              {t("similarListings.daysOnMarket.summary")}{" "}
              <strong style={{ color: colors.purple2 }}>
                {avgDaysToSell} {t("valuationsStatistics.days")}
              </strong>
            </Typography>
            <Chart chartData={marketDaysChartData} tickMinStep={1} />
          </>
        )}
        {!!sqmPriceChartData && !!sqmPriceMedian && !!property && (
          <>
            <PredictionOverviewPrice
              predictedPrice={{
                average_price_area: sqmPriceMedian,
                max: sqmPriceMedian,
                min: sqmPriceMedian,
              }}
              property={property}
              title={t("similarListings.areaPrice.summary")}
            />
            {!!myPropertyExpectedSqmPrice && (
              <Typography variant="h4">
                {t("similarListings.areaPrice.clientExpectedValue")}{" "}
                <strong style={{ color: colors.purple2 }}>
                  {property.expectedValue} €
                </strong>
              </Typography>
            )}
            <Chart chartData={sqmPriceChartData} tickMinStep={1} />
          </>
        )}
      </div>
      <ClusteredGenericMapControlProvider
        context={ClusteredListingsMapControlContext}
      >
        <Typography
          textStyle={{
            lineHeight: 30,
            fontSize: 24,
            paddingTop: "27px", // in pdf do not stick to very top
            display: "flex",
          }}
        >
          {t("similarListings.titleMap")}
        </Typography>
        <Divider style={{ paddingTop: "30px", marginBottom: "30px" }} />
        <ZonePolygonProvider
          usedZones={similarListings.used_zones}
          coeffName={similarListings.coeff_name}
          ineffectiveZones={similarListings.ineffective_zones}
        >
          <ListingsWithMap
            listings={similarListings.listings}
            mapCardComponent={(
              listing,
              paginationNode,
              onClick,
              badgeNum?: number
            ) => (
              <AddonListingCard
                listing={listing}
                price={listing.sold_price ?? 0}
                onClick={onClick}
                paginationNode={paginationNode}
                caption={getPropertyListString({
                  finishing: Boolean(listing.finishing),
                  rooms: listing.rooms,
                  area: listing.area,
                  floorNr:
                    listing.floor /* or should it be listing.floor_number? */,
                  floorCount: listing.floor_total,
                  year: listing.construction_year,
                  renovationYear: listing.reconstruction_year,
                })}
                numBadgeProps={
                  badgeNum === undefined ? undefined : { count: badgeNum }
                }
              />
            )}
            mapPriceFn={(listing) => listing.sold_price ?? 0}
            property={property}
          />
        </ZonePolygonProvider>
      </ClusteredGenericMapControlProvider>
    </ContentCard>
  );
};
