import {
  Comparable,
  ComparableTransaction,
  ComparableTransactionFull,
  Image,
  MyProperty,
} from "common/types/common.types";
import { getLithuanianDateString } from "./dateStringHelpers";
import {
  DesignationType,
  REType,
  getTransactionREType,
} from "../types/comparableFields.types";
import { getFinishingLabel } from "../../web-valuations/src/components/transactions/helpers";

import { getWallTypeTranslation } from "web-valuations/src/helpers/comparableFields.helpers";

export const getComparableAddress = (comparable: ComparableTransaction) => {
  return comparable.address;
};

export const getComparableHiddenAddress = (
  comparable: ComparableTransaction
) => {
  const addressParts = comparable.address.split(" ");
  const lastPart = addressParts.pop() ?? "";

  let houseNumber = parseInt(lastPart.split("-")[0]);
  houseNumber -= houseNumber % 10;
  return [...addressParts, `${houseNumber}..${houseNumber + 9}`].join(" ");
};

export const isCompMatched = (comparable: ComparableTransaction) =>
  !!comparable.matched_listing ||
  // !!comparable.matched_project_unit ||
  !!comparable.matched_project;

export const isCompFullArea = (comp: ComparableTransaction) => {
  if (!comp.area_total) {
    return true;
  }
  return comp.area + 0.01 >= comp.area_total;
};

export const getComparableImages = (
  comparable: ComparableTransaction | undefined
): Image[] => {
  if (!comparable) {
    return [];
  }
  if (!!comparable.matched_listing?.images) {
    return comparable.matched_listing.images.map((image) => ({ url: image }));
  }
  const project_visuals = comparable.matched_project?.visuals;
  if (!!project_visuals) {
    return project_visuals.map((visual) => ({
      url: visual.url_medium,
    }));
  }
  return [];
};

export type AdjustmentField = {
  fieldTranslationKey: string;
  fieldValue: (comparable: ComparableTransactionFull) => string;
  propertyFieldValue: (property: MyProperty) => string;
};

export const getTranslatedFieldName = (
  field: keyof typeof adjustmentFields,
  t: Function
): string => {
  const adjustmentField = adjustmentFields[field];
  if (!adjustmentField) return field as string;
  return t(adjustmentField.fieldTranslationKey);
};

export const adjustmentFields: Partial<
  Record<keyof ComparableTransactionFull, AdjustmentField | undefined>
> = {
  area: {
    fieldTranslationKey: "adjustmentFields.area",
    fieldValue: (comparable: ComparableTransactionFull) =>
      `${comparable["area"]} m²`,
    propertyFieldValue: (property: MyProperty) => `${property.area} m²`,
  },
  floor_position: {
    fieldTranslationKey: "adjustmentFields.floor_position",
    fieldValue: (comparable: ComparableTransactionFull) => {
      const reType = getTransactionREType(comparable.designation_type_id);
      if (reType === REType.House) {
        return `${comparable.floor_total} aukšt.`;
      }
      return `${comparable.floor}/${comparable.floor_total}`;
    },
    propertyFieldValue: (property: MyProperty) => {
      const reType = getTransactionREType(
        (property.designationType ??
          DesignationType.ResidentialApartments) as DesignationType
      );
      if (reType === REType.House) {
        return `${property.floorCount} aukšt.`;
      }
      return `${property.floorNr}/${property.floorCount}`;
    },
  },
  construction_year: {
    fieldTranslationKey: "adjustmentFields.construction_year",
    fieldValue: (comparable: ComparableTransactionFull) =>
      `${comparable["construction_year"]}${
        comparable.reconstruction_year
          ? `/${comparable.reconstruction_year}`
          : ""
      }`,
    propertyFieldValue: (property: MyProperty) =>
      `${property["year"]}${
        property.renovationYear ? `/${property.renovationYear}` : ""
      }`,
  },
  finishing: {
    fieldTranslationKey: "adjustmentFields.finishing",
    fieldValue: (comparable: ComparableTransactionFull) =>
      getFinishingLabel(comparable.finishing, comparable.finishing_full) ?? "",
    propertyFieldValue: (property: MyProperty) =>
      getFinishingLabel(property.finishing, property.finishingFull) ?? "",
  },
  sold_date: {
    fieldTranslationKey: "adjustmentFields.sold_date",
    fieldValue: (comparable: ComparableTransactionFull) =>
      !!comparable.sold_date
        ? getLithuanianDateString(new Date(comparable.sold_date))
        : "",
    propertyFieldValue: (property: MyProperty) =>
      !!property.soldDate
        ? getLithuanianDateString(new Date(property.soldDate))
        : "-",
  },
  valuation_zone: {
    fieldTranslationKey: "adjustmentFields.valuation_zone",
    fieldValue: (comparable: ComparableTransactionFull) =>
      comparable.valuation_zone ?? "",
    propertyFieldValue: (property: MyProperty) => property.valuationZone ?? "-",
  },
  walls: {
    fieldTranslationKey: "adjustmentFields.walls",
    fieldValue: (comparable: ComparableTransactionFull) =>
      getWallTypeTranslation(comparable.walls),
    propertyFieldValue: (property: MyProperty) => {
      if (property.walls) {
        return getWallTypeTranslation(property.walls);
      }
      return "-";
    },
  },
  energy_class: {
    fieldTranslationKey: "adjustmentFields.energy_class",
    fieldValue: (comparable: ComparableTransactionFull) =>
      comparable.energy_class ?? "",
    propertyFieldValue: (property: MyProperty) => property.energyClass ?? "-",
  },
};
