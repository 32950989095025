import React from "react";
import { PropertyLegal } from "../../types/api.types";
import { Typography } from "common/components/Typography";
import { Divider } from "@mui/material";
import colors from "common/styles/colors";

interface ParagraphsProps {
  paragraphs: PropertyLegal["paragraphs"];
}

export const Paragraphs: React.FC<ParagraphsProps> = ({ paragraphs }) => {
  return (
    <>
      {paragraphs.map((paragraph, index) => (
        <div key={index}>
          <p>{paragraph.text}</p>
          {!!paragraph.listItems && (
            <ul>
              {paragraph.listItems.map((listItem, index) => (
                <li key={index}>{listItem}</li>
              ))}
            </ul>
          )}
        </div>
      ))}
    </>
  );
};

interface LegalParagraphsProps {
  legals: PropertyLegal[];
}

export const LegalParagraphs: React.FC<LegalParagraphsProps> = ({ legals }) => {
  return (
    <div>
      {legals.map((legal, index) => (
        <section key={index}>
          <Typography variant="h3">{legal.section}</Typography>
          <Paragraphs paragraphs={legal.paragraphs} />
          {index !== legals.length - 1 && (
            <div
              style={{
                height: "1px",
                backgroundColor: colors.greyborder,
                margin: "30px 0",
              }}
            />
          )}
        </section>
      ))}
    </div>
  );
};
