import {
  MatchedListing,
  ParkingTypeTranslations,
} from "common/types/common.types";
import React from "react";
import {
  BasePropertyCard,
  BasePropertyCardProps,
} from "../cards/BasePropertyCard";
import { getLithuanianDateString } from "common/helpers/dateStringHelpers";
import { Button, Stack } from "@mui/material";
import { SimpleDialog } from "../dialogs/SimpleDialog";
import colors from "common/styles/colors";
import { IconBadge } from "common/components/map/IconBadge";
import { useTranslation } from "react-i18next";

export const ADDON_NUM_BADGE_PROPS: BasePropertyCardProps["numBadgeProps"] = {
  textColor: colors.purple2,
  backgroundColor: colors.white,
};

const _ADDON_NUM_BADGE_SHADOW: BasePropertyCardProps["numBadgeProps"] = {
  style: {
    shadowColor: "rgba(0,0,0,0.8)",
    shadowOpacity: 0.2,
    shadowRadius: 10,
  },
};

interface AddonListingCardProps {
  listing: MatchedListing;
  price: number;
  priceArea?: number;
  caption?: string;
  onClick?: () => void;
  paginationNode?: React.ReactNode;
  numBadgeProps?: BasePropertyCardProps["numBadgeProps"];
}

export const AddonListingCard: React.FC<AddonListingCardProps> = ({
  listing,
  price,
  priceArea,
  caption,
  onClick,
  paginationNode,
  numBadgeProps,
}) => {
  const [detailsOpen, setDetailsOpen] = React.useState(false);
  const { t } = useTranslation();

  const topLeftContent =
    listing.days_on_market || listing.days_not_sold ? (
      <IconBadge
        count={
          listing.days_on_market
            ? t("similarListings.soldIn", { days: listing.days_on_market })
            : t("similarListings.marketFor", { days: listing.days_not_sold })
        }
        width={130}
        height={30}
        backgroundColor={listing.days_on_market ? colors.red : colors.purple2}
      />
    ) : null;

  return (
    <BasePropertyCard
      images={listing.images}
      address={listing.address?.replace(/žiūrėti žemėlapyje/gi, "")}
      price={price}
      priceArea={priceArea}
      dateText={t("similarListings.soldOn", {
        date: listing.sold_date
          ? getLithuanianDateString(listing.sold_date)
          : t("similarListings.dateNotSold"),
      })}
      caption={caption}
      onClick={onClick}
      topLeftContent={topLeftContent}
      numBadgeProps={
        !!numBadgeProps
          ? {
              ...ADDON_NUM_BADGE_PROPS,
              ..._ADDON_NUM_BADGE_SHADOW,
              ...numBadgeProps,
            }
          : undefined
      }
    >
      <Stack
        direction="row-reverse"
        sx={{
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Button
          onClick={() => {
            setDetailsOpen(true);
          }}
          sx={{
            alignSelf: "flex-end",
          }}
        >
          {t("similarListings.more")}
        </Button>
        {paginationNode}
      </Stack>
      <SimpleDialog
        title={listing.address ?? ""}
        open={detailsOpen}
        onClose={() => setDetailsOpen(false)}
        hideButton
      >
        <div
          style={{
            marginBottom: "10px",
            fontWeight: 500,
          }}
        >
          {t("similarListings.description")}
        </div>
        <div>{listing.description}</div>
      </SimpleDialog>
    </BasePropertyCard>
  );
};
