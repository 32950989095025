import { TFunction } from "i18next";
import { ChartData, ChartType, ChartSection } from "../types/api.types";
import { SimilarListingStats } from "common/types/common.types";

export const createMarketDaysChartData = (
  soldStats: SimilarListingStats | null,
  t: TFunction
): ChartData => {
  const chartData: ChartData = {
    title: t("similarListings.daysOnMarket.title"),
    chart_type: ChartType.Bars,
    data: [],
    source: t("similarListings.daysOnMarket.source"),
    section: ChartSection.MarketInsights,
    wrapSourceInLink: false,
    trimSource: false,
  };

  if (!soldStats) return chartData;

  chartData.data = Object.entries(soldStats.range_to_count).map(
    ([key, value]) => ({
      x: key,
      y: value,
    })
  );

  return chartData;
};

export const getAvgDaysToSellListing = (
  soldStats: SimilarListingStats | null
): string => {
  if (!soldStats?.quarter1) return "";

  const { quarter1: q1, quarter3: q3 } = soldStats;

  return q1 === q3 ? `${q1}` : `${q1} - ${q3}`;
};

export const createSquareMeterPriceChartData = (
  squareMeterStats: SimilarListingStats | null,
  t: TFunction
): ChartData => {
  const chartData: ChartData = {
    title: t("similarListings.areaPrice.title"),
    chart_type: ChartType.Bars,
    data: [],
    source: t("similarListings.areaPrice.source"),
    section: ChartSection.MarketInsights,
    wrapSourceInLink: false,
    trimSource: false,
  };

  if (!squareMeterStats) return chartData;

  chartData.data = Object.entries(squareMeterStats.range_to_count).map(
    ([key, value]) => ({
      x: key,
      y: value,
    })
  );

  return chartData;
};
