import React from "react";
import { MonthDate } from "../../components/datepicker/types";
import { getMonthDateRangeFromCurrentDate } from "../../helpers/comparableFilters.helpers";
import { getPricePredictionsFeedStatistics } from "../../api/api";
import { useQuery } from "react-query";
import { LoaderCentered } from "../../components/loaders/LoaderCentered";
import { MonthRangePicker } from "../../components/datepicker/MonthRangePicker";
import BaseChart from "../../components/charts/BaseChart";
import {
  adjustmentFields,
  getTranslatedFieldName,
} from "common/helpers/comparables.helpers";
import { ContentCard } from "../../components/ContentCard";
import { ChartType } from "../../types/api.types";
import { getLithuanianDateString } from "common/helpers/dateStringHelpers";
import { ChartWrapper } from "./ChartWrapper";
import colors from "common/styles/colors";
import { addAlpha } from "common/helpers/colors.helpers";
import { useTranslation } from "react-i18next";
import { getMonthLabel } from "../../components/datepicker/helpers";

interface FeedStatisticsProps {}

interface PriceAdjustmentStatistic {
  month: string;
  medianDiffPercent: number | null;
  count: number;
}

export const FeedStatistics: React.FC<FeedStatisticsProps> = () => {
  const { t } = useTranslation();
  const [selectedDatesInFilter, setSelectedDatesInFilter] = React.useState<{
    startDate: MonthDate;
    endDate: MonthDate;
  }>(() => getMonthDateRangeFromCurrentDate(3));

  const { data: statisticsData, isLoading } = useQuery(
    [
      "pricePredictionsFeedStatistics",
      selectedDatesInFilter.startDate,
      selectedDatesInFilter.endDate,
    ],
    () =>
      getPricePredictionsFeedStatistics(
        selectedDatesInFilter.startDate,
        selectedDatesInFilter.endDate
      )
  );

  const dateRangeString = `${getMonthLabel(
    selectedDatesInFilter.startDate,
    t
  )} - ${getMonthLabel(selectedDatesInFilter.endDate, t)}`;

  if (isLoading) {
    return <LoaderCentered />;
  }
  if (!statisticsData) {
    return null;
  }

  return (
    <ContentCard title={t("feedStatistics.statistics")}>
      <div
        style={{
          maxWidth: "400px",
        }}
      >
        <div style={{ marginBottom: "15px" }}>
          {t("feedStatistics.reportingPeriod")}
        </div>
        <MonthRangePicker
          selectedDatesInFilter={{
            start: selectedDatesInFilter.startDate,
            end: selectedDatesInFilter.endDate,
          }}
          onDatesSelect={(start, end) => {
            setSelectedDatesInFilter({ startDate: start, endDate: end });
          }}
        />
      </div>
      <div>
        {statisticsData?.adjustmentStatistics && (
          <ChartWrapper
            title={t("feedStatistics.adjustmentStatistics.title")}
            subtitle={dateRangeString}
            description={t("feedStatistics.adjustmentStatistics.description")}
          >
            <BaseChart
              data={statisticsData.adjustmentStatistics.map((item) => ({
                x: getTranslatedFieldName(item.field, t),
                y: item.percentage * 100,
              }))}
              max={100}
              yAxisLabel={t("feedStatistics.chartLabels.percent")}
            />
          </ChartWrapper>
        )}

        {statisticsData.valuationStatistics && (
          <ChartWrapper
            title={t("feedStatistics.valuationRatio.title")}
            subtitle={dateRangeString}
            description={t("feedStatistics.valuationRatio.description")}
          >
            <BaseChart
              chartType={ChartType.Bars}
              data={statisticsData.valuationStatistics.map((item) => ({
                x: item.month,
                y: item.manualCount,
              }))}
              extraSeries={[
                {
                  data: statisticsData.valuationStatistics.map((item) => ({
                    x: item.month,
                    y: item.fullyAutoCount,
                  })),
                  label: t("feedStatistics.chartLabels.fullyAuto"),
                  color: colors.purple2,
                  curve: "linear",
                  stack: "A",
                },
                {
                  data: statisticsData.valuationStatistics.map((item) => ({
                    x: item.month,
                    y: item.autoWithAdjustmentsCount,
                  })),
                  label: t("feedStatistics.chartLabels.autoWithAdjustments"),
                  color: colors.purple3,
                  curve: "linear",
                  stack: "A",
                },
              ]}
              chartLabel={t("feedStatistics.chartLabels.manual")}
              color={addAlpha(colors.purple3, 0.5)}
            />
          </ChartWrapper>
        )}

        {statisticsData.priceAdjustmentStatistics && (
          <ChartWrapper
            title={t("feedStatistics.priceAdjustments.title")}
            subtitle={dateRangeString}
            description={t("feedStatistics.priceAdjustments.description")}
          >
            <BaseChart
              chartType={ChartType.Lines}
              curve="linear"
              data={statisticsData.priceAdjustmentStatistics.map(
                (item: PriceAdjustmentStatistic) => ({
                  x: `${item.month} (${item.count})`,
                  y: item.medianDiffPercent ?? 0,
                })
              )}
              yAxisLabel={t("feedStatistics.chartLabels.percent")}
              chartLabel={t("feedStatistics.chartLabels.averageDifference")}
              color={colors.purple2}
            />
          </ChartWrapper>
        )}

        {statisticsData.priceAdjustmentDistribution && (
          <ChartWrapper
            title={t("feedStatistics.priceDistribution.title")}
            subtitle={dateRangeString}
            description={t("feedStatistics.priceDistribution.description")}
          >
            <BaseChart
              chartType={ChartType.Bars}
              data={statisticsData.priceAdjustmentDistribution.map(
                (bucket) => ({
                  x:
                    bucket.min === null
                      ? `< ${bucket.max}%`
                      : bucket.max === null
                      ? `> ${bucket.min}%`
                      : `${bucket.min}% - ${bucket.max}%`,
                  y: bucket.count,
                })
              )}
              yAxisLabel={t("feedStatistics.chartLabels.valuationCount")}
              xAxisLabel={t("feedStatistics.chartLabels.percentageDifference")}
              color={colors.purple2}
              verticalLabels
              height={500}
            />
          </ChartWrapper>
        )}
      </div>
    </ContentCard>
  );
};
