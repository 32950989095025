import { useQuery } from "react-query";
import { getPropertyInfra } from "../api/api";
import { useInfraFilterByDistanceQuery, useInfraFilterQuery } from "./router.hooks";
import { InfraFilterByDistance } from "../types/api.types";

export const usePropertyInfra = (propertyId: string, token?: string) => {
  const infraFilter = useInfraFilterQuery();
  const infraFilterByDistance = useInfraFilterByDistanceQuery();

  return useQuery(
    ["propertyInfra", propertyId, infraFilter, infraFilterByDistance],
    () => 
      getPropertyInfra(propertyId, infraFilter, infraFilterByDistance, token),
    {
      enabled: !!propertyId,
      keepPreviousData: true
    }
  );
};
