import React from "react";
import { useQuery } from "react-query";
import { getMarketInsights } from "../../../api/api";
import { ContentCard } from "../../../components/ContentCard";
import { Chart } from "../../../components/charts/Chart";
import { Grid, Typography, Box, Stack } from "@mui/material";
import { Gauge } from "@mui/x-charts/Gauge";
import { LoaderCentered } from "../../../components/loaders/LoaderCentered";
import {
  ChartData,
  ChartSection,
  MarketInsights,
  MarketLevel,
  KeyInsight,
  ChartTrend,
} from "../../../types/api.types";
import colors from "common/styles/colors";
import { round } from "common/helpers/common.helpers";

// Utility function to format percentage values
const formatPercentage = (value: number): string => {
  return `${value > 0 ? "+" : ""}${value}%`;
};

// Only get the last value from the chart data
const getLastValueFromChartData = (chartData: ChartData): string | number => {
  if (!chartData.data || chartData.data.length === 0) return "";

  const lastPoint = chartData.data[chartData.data.length - 1];
  const formattedValue =
    typeof lastPoint.y === "number" && chartData.y_axis_label?.includes("%")
      ? formatPercentage(round(lastPoint.y, 2))
      : round(lastPoint.y, 2);

  return formattedValue;
};

// Find matching trend information from keyInsights
const findTrendForChart = (
  chartData: ChartData,
  keyInsights?: KeyInsight[]
): ChartTrend | undefined => {
  if (!keyInsights || keyInsights.length === 0) return undefined;

  // Find matching insight by title
  const matchingInsight = keyInsights.find(
    (insight) => insight.id === chartData.id
  );

  return matchingInsight?.trend;
};

interface PropertyMarketInsightsProps {
  marketInsights: MarketInsights;
}
import {
  GaugeContainer,
  GaugeValueArc,
  GaugeReferenceArc,
  useGaugeState,
} from "@mui/x-charts/Gauge";

function GaugePointer() {
  const { valueAngle, outerRadius, innerRadius, cx, cy } = useGaugeState();

  if (valueAngle === null) {
    // No value to display
    return null;
  }

  const midRadius = (innerRadius + outerRadius) / 2;

  const target = {
    x: cx + midRadius * Math.sin(valueAngle),
    y: cy - midRadius * Math.cos(valueAngle),
  };
  return (
    <g>
      <defs>
        <marker
          id="arrowhead"
          markerWidth="3"
          markerHeight="3"
          refX="2"
          refY="1.5"
          orient="auto"
          markerUnits="strokeWidth"
        >
          <path d="M 0 0 L 3 1.5 L 0 3 Z" fill="black" />
        </marker>
      </defs>
      <circle cx={cx} cy={cy} r={5} fill="black" />
      <path
        d={`M ${cx} ${cy} L ${target.x} ${target.y}`}
        stroke="black"
        strokeWidth={3}
        markerEnd="url(#arrowhead)"
      />
    </g>
  );
}

// Market level gauge component
export const MarketLevelGauge: React.FC<{ marketLevel: MarketLevel }> = ({
  marketLevel,
}) => {
  // Map market level to a value between 0-100 for the gauge
  const getGaugeValue = () => {
    switch (marketLevel) {
      case MarketLevel.INACTIVE:
        return 25;
      case MarketLevel.NORMAL:
        return 50;
      case MarketLevel.HOT:
        return 75;
      default:
        return 50;
    }
  };

  // Map market level to a text label
  const getMarketLevelLabel = () => {
    switch (marketLevel) {
      case MarketLevel.INACTIVE:
        return "Neaktyvus (palankus pirkėjams)";
      case MarketLevel.NORMAL:
        return "Normalus (subalansuotas)";
      case MarketLevel.HOT:
        return "Aktyvus (palankus pardavėjams)";
      default:
        return "Normalus";
    }
  };

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Typography>
        Rinkos aktyvumas: <strong>{getMarketLevelLabel()}</strong>
      </Typography>
      <svg width="0" height="0">
        <defs>
          <linearGradient id="gradientArc">
            <stop offset="0%" stopColor="green" />
            <stop offset="50%" stopColor="orange" />
            <stop offset="100%" stopColor="red" />
          </linearGradient>
        </defs>
      </svg>
      <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
        <Stack direction="column">
          <Typography textAlign="center">Subalansuota</Typography>
          <Stack direction="row" alignItems={"end"}>
            <Typography paddingBottom={1.2}>Neaktyvi</Typography>
            <GaugeContainer
              width={200}
              height={120}
              value={getGaugeValue()}
              startAngle={-90}
              endAngle={90}
            >
              <GaugeReferenceArc
                style={{
                  fill: "url(#gradientArc)",
                }}
              />

              <GaugeValueArc style={{ fill: "none" }} />
              <GaugePointer />
            </GaugeContainer>

            <Typography paddingBottom={1.2}>Perkaitus</Typography>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};

export const PropertyMarketInsights: React.FC<PropertyMarketInsightsProps> = ({
  marketInsights,
}) => {
  const { marketInsights: chartsData, pricePrediction } = marketInsights;

  const dataSections = [
    {
      sectionTitle: "Rinkos apžvalga",
      charts: chartsData.filter(
        (chart) => chart.section === ChartSection.MarketInsights
      ),
    },
    {
      sectionTitle: "Gyventojų statistika",
      charts: chartsData.filter(
        (chart) => chart.section === ChartSection.PopulationStatistics
      ),
    },
  ];

  return (
    <div>
      {!!pricePrediction && (
        <ContentCard title="Kainos prognozė">
          <Stack
            direction="column"
            alignItems="center"
            spacing={3}
            sx={{ width: "100%", py: 2 }}
          >
            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={{ xs: 4, md: 6 }}
              alignItems="center"
              justifyContent="center"
              sx={{
                width: "100%",
                px: { xs: 2, md: 4 },
                py: { xs: 1, md: 2 },
                borderRadius: 2,
                background: "rgba(0, 0, 0, 0.02)",
              }}
            >
              {pricePrediction.priceChangePercentageMin !== undefined &&
                pricePrediction.priceChangePercentageMax !== undefined && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      textAlign: "center",
                      minWidth: { xs: "auto", md: "220px" },
                    }}
                  >
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ mb: 0.5 }}
                    >
                      Prognozuojamas kainos pokytis:
                    </Typography>
                    <Typography
                      variant="h4"
                      fontWeight="bold"
                      color={
                        pricePrediction.priceChangePercentageMin >= 0
                          ? colors.green
                          : colors.red
                      }
                      sx={{
                        transition: "all 0.2s ease-in-out",
                        "&:hover": { transform: "scale(1.05)" },
                      }}
                    >
                      {pricePrediction.priceChangePercentageMin ===
                      pricePrediction.priceChangePercentageMax
                        ? formatPercentage(
                            pricePrediction.priceChangePercentageMin
                          )
                        : `${formatPercentage(
                            pricePrediction.priceChangePercentageMin
                          )} iki ${formatPercentage(
                            pricePrediction.priceChangePercentageMax
                          )}`}
                    </Typography>
                  </Box>
                )}

              {pricePrediction.marketLevel && (
                <Box
                  sx={{
                    borderLeft: { xs: "none", md: "1px solid" },
                    borderTop: { xs: "1px solid", md: "none" },
                    borderColor: "divider",
                    py: { xs: 2, md: 0 },
                    pl: { xs: 0, md: 6 },
                    minWidth: { xs: "auto", md: "300px" },
                  }}
                >
                  <MarketLevelGauge marketLevel={pricePrediction.marketLevel} />
                </Box>
              )}
            </Stack>

            <Typography
              paragraph
              sx={{
                mt: 1,
                px: { xs: 2, md: 4 },
                lineHeight: 1.6,
                fontSize: "1.05rem",
              }}
            >
              {pricePrediction.summary}
            </Typography>

            <Typography
              variant="caption"
              sx={{
                maxWidth: "800px",
                textAlign: "center",
                mt: 1,
                px: { xs: 2, md: 4 },
                color: "text.secondary",
                fontStyle: "italic",
              }}
            >
              Ši konsultacinio pobūdžio ataskaita yra sukurta pagal
              generatyvinio dirbtinio intelekto įžvalgas iš rinkos ir
              demografijos duomenų ataskaitos sukūrimo dieną.
            </Typography>
          </Stack>
        </ContentCard>
      )}
      {dataSections.map((section, index) => {
        return (
          <ContentCard title={section.sectionTitle} key={index}>
            <Grid container spacing={2}>
              {section.charts.map((data, index) => {
                if (!data.data.length) return null;

                // Get last value from chart data
                const lastValue = getLastValueFromChartData(data);

                // Get trend from backend keyInsights if available
                const trend = findTrendForChart(
                  data,
                  pricePrediction?.keyInsights
                );

                // Only create trendIndicator if we have trend data
                const trendIndicator = trend
                  ? {
                      trend,
                      value: lastValue,
                    }
                  : undefined;

                return (
                  <Grid
                    item
                    xs={12}
                    md={6}
                    key={index}
                    style={{
                      pageBreakInside: "avoid",
                    }}
                  >
                    <Chart
                      key={`${data.title}|${index}`}
                      chartData={data}
                      trendIndicator={trendIndicator}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </ContentCard>
        );
      })}
    </div>
  );
};
