import React from "react";
import { MapLegendRow } from "./MapLegendRow";
import { Typography } from "common/components/Typography";
import colors from "common/styles/colors";

interface LegendItem {
  icon: React.ReactElement;
  label: string;
}

interface MapLegendProps {
  items: LegendItem[];
}

export const MapLegend: React.FC<MapLegendProps> = ({ items }) => {
  return (
    <div
      style={{
        backgroundColor: "5px",
        padding: "8px",
        display: "flex",
        flexDirection: "column",
        gap: "5px",
      }}
    >
      <Typography variant="h4" textStyle={{ fontSize: 14, color: colors.grey }}>
        Sutartiniai ženklai
      </Typography>
      <div style={{ height: "5px" }} />
      {items.map((item, index) => (
        <MapLegendRow key={index} icon={item.icon} label={item.label} />
      ))}
    </div>
  );
};
