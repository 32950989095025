import React, { useState } from "react";
import {
  useBasicOrganization,
  useOrganization,
} from "../../hooks/organization.hooks";
import {
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { useAppraiser, useLogout } from "../../hooks/auth.hooks";
import colors from "common/styles/colors";
import { Typography } from "common/components/Typography";
import { Link, useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import { MenuLinkComponent } from "./MenuLinkComponent";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { AppraiserRole, DefaultRoles } from "../../types/appraiser.types";
import { setClipboard } from "common/clipboard/clipboard";
import { showToastSuccess } from "common/toast/toast";
import LinkIcon from "@mui/icons-material/Link";
import SettingsIcon from "@mui/icons-material/Settings";
import { getLeadLink } from "../../helpers/url.helpers";
import { useQuery } from "react-query";
import { getAppraiserOrganizationLink, getOrganization } from "../../api/api";
import { StyledImage } from "common/components/images/StyledImage";
import { LanguageSwitcher } from "../LanguageSwitcher";
import { useTranslation } from "react-i18next";

interface LayoutProps {
  children?: React.ReactNode;
  noBackButton?: boolean;
}

const headerHeight = "60px";

export const Layout: React.FC<LayoutProps> = ({ children, noBackButton }) => {
  const { t } = useTranslation();
  const { data: organization } = useBasicOrganization();
  const { data: appraiser } = useAppraiser();

  const { logout } = useLogout();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  const isDefaultRole = appraiser
    ? DefaultRoles.includes(appraiser.role)
    : false;
  const { data: organizationLink } = useQuery(
    ["organization_link", appraiser?._id],
    () => getAppraiserOrganizationLink(),
    {
      enabled: isDefaultRole,
    }
  );

  return (
    <div>
      <Box
        sx={{
          height: headerHeight,
          top: 0,
          width: "100%",
          boxShadow: 3,
          display: "flex",
          justifyContent: "space-between",
          position: "sticky",
          zIndex: 1000,
          backgroundColor: colors.white,
          alignItems: "center",
          px: "20px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
          {!noBackButton && (
            <IconButton onClick={() => navigate(-1)}>
              <ArrowBackIcon />
            </IconButton>
          )}
          <Link to="/" style={{ textDecoration: "none" }}>
            <Typography variant="h2">{organization?.name}</Typography>
          </Link>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "18px" }}>
          <MenuLinkComponent
            to="https://docs.valuations.lt"
            target="_blank"
            label={t("layout.aboutProduct")}
          />
          <MenuLinkComponent to="/" label={t("layout.viewValuations")} />
          {(appraiser?.role === AppraiserRole.ADMIN ||
            appraiser?.role === AppraiserRole.SPECTATOR) && (
            <MenuLinkComponent
              to={
                appraiser?.role === AppraiserRole.SPECTATOR
                  ? "/feed"
                  : "/appraisers"
              }
              label={t("layout.manageOrganization")}
            />
          )}
          {appraiser?.role === AppraiserRole.ADMIN && (
            <MenuLinkComponent
              to="/import"
              label={t("layout.importTransactions")}
            />
          )}
          <LanguageSwitcher />
          <IconButton
            size="small"
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
            }}
            style={{ marginLeft: "15px" }}
          >
            {!!appraiser?.profileImageUrl ? (
              <StyledImage
                borderRadius={40}
                width={40}
                height={40}
                showLoader
                imageProps={{
                  source: { uri: appraiser.profileImageUrl },
                }}
              />
            ) : (
              <AccountCircleIcon fontSize="large" />
            )}
          </IconButton>
        </div>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={(e: any) => {
          if (!!e.stopPropagation) e.stopPropagation();
          setAnchorEl(null);
        }}
      >
        <ListItemText sx={{ px: "16px", mb: "8px" }}>
          {appraiser?.email}
        </ListItemText>
        <Divider />
        {isDefaultRole &&
          ["org", "appr"].map((linkType) => (
            <MenuItem
              key={linkType}
              sx={{ mt: "8px" }}
              onClick={() => {
                if (!appraiser || !organizationLink) {
                  return;
                }
                setClipboard(
                  linkType === "appr"
                    ? getLeadLink({ appraiserId: appraiser._id })
                    : getLeadLink({ appraiserId: organizationLink.appraiserId })
                );
                showToastSuccess(t("layout.copyLinks.linkCopied"));
                setAnchorEl(null);
              }}
            >
              <ListItemIcon>
                <LinkIcon />
              </ListItemIcon>
              <ListItemText>
                {t(
                  linkType === "appr"
                    ? "layout.copyLinks.myRequestLink"
                    : "layout.copyLinks.companyRequestLink"
                )}
              </ListItemText>
            </MenuItem>
          ))}
        <Link
          to="/account-settings"
          style={{ textDecoration: "none", color: "unset" }}
          onClick={() => {
            setAnchorEl(null);
          }}
        >
          <MenuItem sx={{ mt: "8px" }}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText>{t("layout.profileSettings")}</ListItemText>
          </MenuItem>
        </Link>
        <MenuItem sx={{ mt: "8px" }} onClick={() => logout()}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t("layout.logout")}</ListItemText>
        </MenuItem>
      </Menu>
      {children}
    </div>
  );
};
