import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { FormAutocomplete } from "../../../components/form/FormAutocomplete";
import { getEnergyClass } from "../../../api/geocode.api";
import { useQuery } from "react-query";
import { FormSelect } from "../../../components/form/FormSelect";
import { useTranslation } from "react-i18next";

interface EnergyClassFieldProps {}

export const energyClassOptions = [
  "A++",
  "A+",
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
];

export const EnergyClassField: React.FC<EnergyClassFieldProps> = () => {
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext();

  const streetId = watch("streetId");
  const streetNumber = watch("streetNumber");

  const isFirstLoadRef = React.useRef(!!streetNumber && streetNumber !== "");

  const { isLoading } = useQuery(
    ["energy_class", streetId, streetNumber],
    () => getEnergyClass(streetId, streetNumber),
    {
      enabled: !!streetId && !!streetNumber,
      staleTime: Infinity,
      onSuccess: (data) => {
        if (isFirstLoadRef.current) {
          isFirstLoadRef.current = false;
          return;
        }
        if (data) {
          setValue("energyClass", data.energy_class);
        }
      },
    }
  );

  return (
    <FormSelect
      label={t("propertyForm.energyClass")}
      name="energyClass"
      values={energyClassOptions}
      disabled={isLoading}
      allowNull
    />
  );
};
