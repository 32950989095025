import colors from "common/styles/colors";
import React from "react";
import { Typography, TypographyProps } from "common/components/Typography";
import { useNavigate } from "react-router-dom";
import { IconButton, Stack } from "@mui/material";
import { getLithuanianDateTimeString } from "common/helpers/dateStringHelpers";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { BasePropertyCard, BasePropertyCardProps } from "./BasePropertyCard";
import { useTranslation } from "react-i18next";

interface IndexPropertyCardProps
  extends Pick<
    BasePropertyCardProps,
    "images" | "address" | "caption" | "children"
  > {
  createdAt: Date;
  appraiser: string;
  propertyId: string;
}

export const IndexPropertyCard: React.FC<IndexPropertyCardProps> = ({
  images,
  address,
  appraiser,
  createdAt,
  caption,
  children,
  propertyId,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const textStyle: TypographyProps["textStyle"] = {
    fontFamily: "Inter",
    fontWeight: "400",
    fontSize: 12,
    lineHeight: 16,
  };

  return (
    <BasePropertyCard
      images={images}
      gapSizePx={0}
      infoNode={
        <Stack
          // card minus image (right of the card)
          direction="column"
          style={{
            paddingTop: "12.5px",
            position: "relative",
            height: "100%",
          }}
        >
          <Stack // effect of padded info text, while sustaining full length of paginator stripe
            sx={{ paddingLeft: "10px" }}
          >
            <Typography
              textStyle={{
                fontFamily: "Inter",
                fontWeight: "600",
                fontSize: 14,
                lineHeight: 20,
              }}
            >
              {address}
            </Typography>

            {!!caption && (
              <Typography
                textStyle={{
                  ...textStyle,
                  fontFamily: "Roboto", // SF UI Text is in designs but it looks different when used
                  color: colors.grey,
                  paddingTop: "2.25px",
                }}
              >
                {caption}
              </Typography>
            )}
            <Stack direction="row" sx={{ paddingTop: "6.46px" }}>
              <Typography
                textStyle={{
                  ...textStyle,
                  flex: 21,
                  color: colors.grey,
                }}
              >
                {t("propertyDetails.assigned")}
              </Typography>
              <Typography
                textStyle={{
                  ...textStyle,
                  flex: 59,
                }}
              >
                {appraiser}
              </Typography>
            </Stack>
            <Stack direction="row" sx={{ paddingTop: "7.15px" }}>
              <Typography
                variant="caption"
                textStyle={{
                  ...textStyle,
                  flex: 21,
                  color: colors.grey,
                }}
              >
                {t("propertyDetails.created")}
              </Typography>
              <Typography
                textStyle={{
                  ...textStyle,
                  flex: 59,
                }}
              >
                {getLithuanianDateTimeString(createdAt)}
              </Typography>
            </Stack>
          </Stack>
          {children}
        </Stack>
      }
    >
      <IconButton
        onClick={() => {
          navigate(`/properties/${propertyId}`); // view property
        }}
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          paddingTop: "11px",
          paddingRight: "13.78px",
        }}
      >
        <VisibilityOutlinedIcon color="primary" />
      </IconButton>
    </BasePropertyCard>
  );
};
