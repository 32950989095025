import { useQuery } from "react-query";
import { getPortfolioStatistics } from "../api/api";
import React from "react";
import { LoaderCentered } from "../components/loaders/LoaderCentered";
import { ContentCard } from "../components/ContentCard";
import { useTranslation } from "react-i18next";
import BaseChart from "../components/charts/BaseChart";
import {
  ChartType,
  PropertyType,
  PropertyTypeTranslations,
} from "../types/api.types";
import colors from "common/styles/colors";
import { ChartWrapper } from "./feed/ChartWrapper";
import { Typography } from "@mui/material";

export const PortfolioStatisticsRoute = () => {
  const { t } = useTranslation();

  const { data, isLoading } = useQuery(["portfolioStatistics"], () =>
    getPortfolioStatistics()
  );

  if (isLoading) {
    return <LoaderCentered />;
  }

  return (
    <ContentCard title={t("portfolioStatistics.title")}>
      {data && (
        <div>
          {/* Portfolio Value Chart */}
          {data.portfolioValue &&
            data.portfolioValue.monthlyValues.length > 0 && (
              <ChartWrapper
                title={t("portfolioStatistics.portfolioValue.title")}
                description={t(
                  "portfolioStatistics.portfolioValue.description"
                )}
              >
                <BaseChart
                  chartType={ChartType.Bars}
                  data={data.portfolioValue.monthlyValues.map((item) => ({
                    x: item.month,
                    y: Math.round(item.nominalValue),
                  }))}
                  extraSeries={[
                    {
                      data: data.portfolioValue.monthlyValues.map((item) => ({
                        x: item.month,
                        y: Math.round(item.predictedValue),
                      })),
                      label: t(
                        "portfolioStatistics.chartLabels.predictedValue"
                      ),
                      color: colors.green,
                    },
                  ]}
                  chartLabel={t("portfolioStatistics.chartLabels.nominalValue")}
                  xAxisLabel={t("portfolioStatistics.chartLabels.month")}
                  yAxisLabel={t("portfolioStatistics.chartLabels.value")}
                  color={colors.purple2}
                  height={300}
                  leftLabelMargin={40}
                />
              </ChartWrapper>
            )}

          {/* Valuations by City and Properties by Type Charts in one row */}
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(450px, 1fr))",
            }}
          >
            {/* Valuations by City Chart */}
            {data.valuationsByCity && data.valuationsByCity.length > 0 && (
              <ChartWrapper
                title={t("portfolioStatistics.valuationsByCity.title")}
                description={t(
                  "portfolioStatistics.valuationsByCity.description"
                )}
              >
                <BaseChart
                  chartType={ChartType.Bars}
                  data={data.valuationsByCity.map((cityData) => ({
                    x: cityData.city.split(" (")[0],
                    y: cityData.count,
                  }))}
                  yAxisLabel={t(
                    "portfolioStatistics.chartLabels.valuationCount"
                  )}
                  color={colors.purple2}
                  layout="horizontal"
                  height={200}
                  leftMargin={90}
                  tickMinStep={1}
                />
              </ChartWrapper>
            )}

            {/* Properties by Type Chart */}
            {data.propertiesBySimpleType &&
              data.propertiesBySimpleType.length > 0 && (
                <ChartWrapper
                  title={t("portfolioStatistics.propertiesByType.title")}
                  description={t(
                    "portfolioStatistics.propertiesByType.description"
                  )}
                >
                  <BaseChart
                    chartType={ChartType.Bars}
                    data={data.propertiesBySimpleType.map((typeData) => ({
                      x: t(`propertyType.${typeData.type}`),
                      y: typeData.count,
                    }))}
                    yAxisLabel={t(
                      "portfolioStatistics.chartLabels.propertyCount"
                    )}
                    color={colors.green}
                    layout="horizontal"
                    height={200}
                    leftMargin={90}
                    tickMinStep={1}
                  />
                </ChartWrapper>
              )}
          </div>

          {/* Climate Risks Charts - Grid with 2 per row */}
          <Typography variant="h5">
            {t("portfolioStatistics.climateRisks.title")}
          </Typography>
          {data.climateRisksStatistics &&
            data.climateRisksStatistics.length > 0 && (
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fill, minmax(450px, 1fr))",
                  marginTop: "20px",
                }}
              >
                {data.climateRisksStatistics.map((riskData) => {
                  // Create data for all three risk levels
                  const chartData = [
                    {
                      x: t("portfolioStatistics.chartLabels.lowRisk"),
                      y: riskData.risks.Low,
                    },
                    {
                      x: t("portfolioStatistics.chartLabels.mediumRisk"),
                      y: riskData.risks.Medium,
                    },
                    {
                      x: t("portfolioStatistics.chartLabels.highRisk"),
                      y: riskData.risks.High,
                    },
                  ];

                  const riskType = riskData.riskType;

                  return (
                    <ChartWrapper
                      key={riskType}
                      title={t(
                        `portfolioStatistics.climateRisks.${riskType.toLowerCase()}`
                      )}
                      description={t(
                        "portfolioStatistics.climateRisks.typeDescription",
                        { riskType }
                      )}
                    >
                      <BaseChart
                        chartType={ChartType.Bars}
                        data={chartData}
                        yAxisLabel={t(
                          "portfolioStatistics.chartLabels.propertyCount"
                        )}
                        colorMap={{
                          type: "ordinal",
                          values: chartData.map((item) => item.x),
                          colors: [colors.green, colors.orange, colors.red],
                        }}
                        color={colors.green} // This will be overridden by colorMap
                        layout="horizontal"
                        height={200}
                        leftMargin={90}
                        tickMinStep={1}
                      />
                    </ChartWrapper>
                  );
                })}
              </div>
            )}
        </div>
      )}
    </ContentCard>
  );
};
