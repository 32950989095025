import React from "react";
import { VerificationForm } from "./VerificationForm";
import { useTranslation } from "react-i18next";

interface LoginAsAppraiserVerificationDialogProps {
  open: boolean;
  onClose: () => void;
  onVerified: (loginReason?: string) => void;
  adminPhoneNumber?: string;
}

export const LoginAsAppraiserVerificationDialog: React.FC<
  LoginAsAppraiserVerificationDialogProps
> = ({ open, onClose, onVerified, adminPhoneNumber }) => {
  const { t } = useTranslation();

  return (
    <VerificationForm
      title={t("verification.appraiserLoginConfirmation")}
      open={open}
      onClose={onClose}
      onVerified={onVerified}
      phoneNumber={adminPhoneNumber}
      buttonText="Prisijungti"
      verificationInformation={t("verification.appraiserCodeExplanation")}
      loginReasonRequired={true}
    />
  );
};
