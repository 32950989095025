import { Typography } from "common/components/Typography";
import React from "react";
import { MonthDate } from "./types";
import { getMonthLabel } from "./helpers";
import { labelStyle } from "../../styles/label.styles";
import { useTranslation } from "react-i18next";

interface MonthLabelProps {
  date?: MonthDate;
}

export const MonthLabel: React.FC<MonthLabelProps> = ({ date }) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        minWidth: "160px",
        flex: 1,
        ...labelStyle,
      }}
    >
      {!!date && (
        <Typography textStyle={{ fontSize: 16 }}>
          {getMonthLabel(date, t)}
        </Typography>
      )}
    </div>
  );
};
