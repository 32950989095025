import React from "react";
import {
  useAppraiser,
  useAppraisersInOrganization,
  useAuthSideEffects,
} from "../hooks/auth.hooks";
import { getLithuanianDateTimeString } from "common/helpers/dateStringHelpers";
import { AddAppraiserModal } from "../components/admin/AddAppraiserModal";
import { Typography } from "common/components/Typography";
import { IconButton, Tooltip } from "@mui/material";
import { useMutation, useQueryClient } from "react-query";
import { loginAsAppraiser, deleteAppraiser } from "../api/api";
import { showToastError, showToastSuccess } from "common/toast/toast";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";
import { RcCredentialsDialog } from "../components/admin/RcCredentialsDialog";
import { useOrganization } from "../hooks/organization.hooks";
import { AppraiserVerificationSwitch } from "../components/admin/AppraiserVerificationSwitch";
import { SimpleTableComponent } from "../components/tables/simpleTable/SimpleTableComponent";
import { SimpleTableRow } from "../components/tables/simpleTable/SimpleTableRow";
import { SimpleTableElement } from "../components/tables/simpleTable/SimpleTableElement";
import { SimpleTableHeader } from "../components/tables/simpleTable/SimpleTableHeader";
import colors from "common/styles/colors";
import { LoginAsAppraiserVerificationDialog } from "./auth/LoginAsAppraiserVerificationDialog";
import { useTranslation } from "react-i18next";
import { getStatusChip } from "../helpers/appraiserInfo.helpers";
import { AppraiserPasswordResetDialog } from "../components/admin/AppraiserPasswordResetDialog";
import { setClipboard } from "common/clipboard/clipboard";
import { Appraiser } from "../types/appraiser.types";
import AppraisersMenu from "../components/admin/AppraisersMenu";

export const AppraisersManagement: React.FC = () => {
  const { t } = useTranslation();
  const { data: appraisers } = useAppraisersInOrganization();
  const { onAuthSuccess } = useAuthSideEffects();
  const currentAppraiser = useAppraiser();
  const currentOrganization = useOrganization();
  const queryClient = useQueryClient();

  const [anchorEls, setAnchorEls] = React.useState<{
    [key: string]: HTMLElement | null;
  }>({});

  const [
    loginAsAppraiserVerificationDialogOpen,
    setLoginAsAppraiserVerificationDialogOpen,
  ] = React.useState(false);

  const [rcCredentialsDialogOpen, setRcCredentialsDialogOpen] =
    React.useState(false);

  const [passwordResetDialogOpen, setPasswordResetDialogOpen] =
    React.useState(false);

  const [dialogType, setDialogType] = React.useState<"registration" | "reset">(
    "registration"
  );

  const loginMutation = useMutation(
    ({
      appraiserId,
      loginReason,
    }: {
      appraiserId: string;
      loginReason: string;
    }) => loginAsAppraiser(appraiserId, loginReason),
    {
      onSuccess: (data) => {
        onAuthSuccess(data);
        window.location.href = "/account-settings";
      },
      onError: () => {
        showToastError();
      },
    }
  );

  const [selectedAppraiser, setSelectedAppraiser] = React.useState<
    Appraiser | undefined
  >(undefined);

  React.useEffect(() => {
    if (selectedAppraiser) {
      const updatedAppraiser = appraisers?.find(
        (a) => a._id === selectedAppraiser._id
      );
      if (updatedAppraiser) {
        setSelectedAppraiser(updatedAppraiser);
      }
    }
  }, [appraisers]);

  const deleteMutation = useMutation(deleteAppraiser, {
    onSuccess: () => {
      showToastSuccess(t("appraisers.deleteSuccess"));
      queryClient.invalidateQueries("appraisersInOrganization");
    },
    onError: () => {
      showToastError();
    },
  });

  const handleMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    appraiserId: string
  ) => {
    setAnchorEls((prevState) => ({
      ...prevState,
      [appraiserId]: event.currentTarget,
    }));
  };

  const handleMenuClose = (appraiserId: string) => {
    setAnchorEls((prevState) => ({
      ...prevState,
      [appraiserId]: null,
    }));
  };

  const navigate = useNavigate();

  const copyLinkToClipboard = async (link: string) => {
    await setClipboard(link ?? "");
    showToastSuccess(t("layout.copyLinks.linkCopied"));
  };

  return (
    <div>
      <div
        style={{
          marginBottom: "30px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h1">{t("appraisers.title")}</Typography>
        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
          <AppraiserVerificationSwitch
            currentVerificationStatus={
              currentOrganization.data?.requiresAppraiserVerification ?? false
            }
          />
          <AddAppraiserModal />
        </div>
      </div>

      <SimpleTableComponent>
        <SimpleTableRow style={{ backgroundColor: colors.white }}>
          <SimpleTableHeader textAlign="left" style={{ padding: "15px" }}>
            {t("appraisers.table.email")}
          </SimpleTableHeader>
          <SimpleTableHeader
            textAlign="left"
            style={{ paddingLeft: "30px", paddingTop: "15px" }}
          >
            {t("appraisers.table.role")}
          </SimpleTableHeader>
          <SimpleTableHeader
            textAlign="left"
            style={{ paddingLeft: "45px", paddingTop: "15px" }}
          >
            {t("appraisers.table.lastLogin")}
          </SimpleTableHeader>
          <SimpleTableHeader textAlign="left" style={{ paddingTop: "15px" }}>
            {t("appraisers.table.status")}
          </SimpleTableHeader>
          <SimpleTableHeader textAlign="right" style={{ padding: "15px" }}>
            {t("appraisers.table.action")}
          </SimpleTableHeader>
        </SimpleTableRow>

        {appraisers?.map((appraiser) => {
          const hasNotAcceptedInvitation =
            !!appraiser.invitedAt && !appraiser.acceptedAt;
          const hasActivity = !!appraiser.lastActiveAt;
          const invitation = hasNotAcceptedInvitation
            ? t("appraisers.status.invited", {
                date: getLithuanianDateTimeString(appraiser.invitedAt as Date),
              })
            : hasActivity
            ? t("appraisers.status.active", {
                date: getLithuanianDateTimeString(
                  appraiser.lastActiveAt as Date
                ),
              })
            : t("appraisers.status.memberSince", {
                date: getLithuanianDateTimeString(
                  appraiser.acceptedAt ?? appraiser.createdAt
                ),
              });

          const lastActivity = appraiser.lastActiveAt
            ? getLithuanianDateTimeString(new Date(appraiser.lastActiveAt))
            : "-";
          return (
            <SimpleTableRow
              key={appraiser._id}
              style={{ backgroundColor: colors.white }}
            >
              <SimpleTableElement
                textAlign="left"
                style={{ padding: "15px", maxWidth: "250px" }}
              >
                <Typography>{appraiser.email}</Typography>
              </SimpleTableElement>
              <SimpleTableElement
                textAlign="left"
                style={{ width: "150px", paddingLeft: "30px" }}
              >
                <Typography>
                  {t(`appraisers.roles.${appraiser.role}`)}
                </Typography>
              </SimpleTableElement>
              <SimpleTableElement
                textAlign="left"
                style={{ width: "300px", paddingLeft: "45px" }}
              >
                {!hasActivity ? (
                  <Tooltip title={invitation} placement="bottom-start">
                    <div>
                      <Typography>{lastActivity}</Typography>
                    </div>
                  </Tooltip>
                ) : (
                  <Typography>{lastActivity}</Typography>
                )}
              </SimpleTableElement>
              <SimpleTableElement textAlign="left">
                {getStatusChip(appraiser, t)}
              </SimpleTableElement>
              <SimpleTableElement textAlign="right" style={{ padding: "15px" }}>
                <Tooltip title={t("appraisers.tooltips.more")}>
                  <IconButton
                    onClick={(event) => handleMenuClick(event, appraiser._id)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </Tooltip>
                <AppraisersMenu
                  appraiser={appraiser}
                  currentAppraiser={currentAppraiser.data as Appraiser}
                  anchorEl={anchorEls[appraiser._id]}
                  onMenuClose={() => handleMenuClose(appraiser._id)}
                  onViewProfile={() => navigate(`${appraiser._id}/profile`)}
                  onLoginAs={() => {
                    setSelectedAppraiser(appraiser);
                    setLoginAsAppraiserVerificationDialogOpen(true);
                    handleMenuClose(appraiser._id);
                  }}
                  onRegeneratePasswordResetLink={() => {
                    setSelectedAppraiser(appraiser);
                    setPasswordResetDialogOpen(true);
                    setDialogType("reset");
                    handleMenuClose(appraiser._id);
                  }}
                  onRegenerateRegistrationLink={() => {
                    setSelectedAppraiser(appraiser);
                    setPasswordResetDialogOpen(true);
                    setDialogType("registration");
                    handleMenuClose(appraiser._id);
                  }}
                  onCopyLinkToClipboard={(link) => copyLinkToClipboard(link)}
                  onViewRCCredentials={() => {
                    setSelectedAppraiser(appraiser);
                    setRcCredentialsDialogOpen(true);
                    handleMenuClose(appraiser._id);
                  }}
                  onDeleteAppraiser={() => {
                    deleteMutation.mutate(appraiser._id);
                    handleMenuClose(appraiser._id);
                  }}
                  t={t}
                />
              </SimpleTableElement>
            </SimpleTableRow>
          );
        })}
      </SimpleTableComponent>

      <LoginAsAppraiserVerificationDialog
        open={loginAsAppraiserVerificationDialogOpen}
        onClose={() => setLoginAsAppraiserVerificationDialogOpen(false)}
        onVerified={(loginReason?: string) => {
          if (selectedAppraiser) {
            loginMutation.mutate({
              appraiserId: selectedAppraiser._id,
              loginReason: loginReason || "",
            });
          }
        }}
        adminPhoneNumber={currentAppraiser.data?.phoneNumber || ""}
      />

      <RcCredentialsDialog
        open={rcCredentialsDialogOpen}
        onClose={() => setRcCredentialsDialogOpen(false)}
        appraiser={selectedAppraiser}
      />

      <AppraiserPasswordResetDialog
        appraiser={selectedAppraiser}
        open={passwordResetDialogOpen}
        onClose={() => setPasswordResetDialogOpen(false)}
        type={dialogType}
      />
    </div>
  );
};
