import React from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import { LineChart } from "@mui/x-charts/LineChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import colors from "common/styles/colors";
import { ChartType } from "../../types/api.types";

export interface ChartDataItem {
  x: string | number;
  y: number;
}

export interface ChartViewProps {
  width?: number;
  leftMargin?: number;
  height?: number;
  chartLabel?: string;
  tickMinStep?: number;
  max?: number;
  min?: number | null;
}

export interface SeriesData {
  data: ChartDataItem[];
  label: string;
  color: string;
  curve?: "linear";
  stack?: string;
}

interface BaseChartProps extends ChartViewProps {
  data: ChartDataItem[];
  extraSeries?: SeriesData[];
  xAxisLabel?: string;
  yAxisLabel?: string;
  chartType?: ChartType;
  tickPlacement?: "middle" | "start" | "end" | "extremities";
  tickLabelPlacement?: "tick" | "middle";
  barLabel?: "value";
  colorMap?: any;
  curve?: "linear";
  layout?: "vertical" | "horizontal";
  color?: string;
  verticalLabels?: boolean;
  leftLabelMargin?: number;
}

export const BaseChart: React.FC<BaseChartProps> = ({
  data,
  extraSeries,
  xAxisLabel,
  yAxisLabel,
  chartType,
  tickPlacement,
  tickLabelPlacement,
  barLabel,
  colorMap,
  curve,
  layout = "vertical",
  width,
  leftMargin,
  height,
  chartLabel,
  tickMinStep,
  max,
  min,
  color,
  leftLabelMargin,
  verticalLabels = false,
}) => {
  const isDataEmpty = data.length === 0;

  // Create the x-axis labels and main series data.
  const xAxisData = isDataEmpty ? ["-"] : data.map((item) => item.x);
  const mainSeriesData = isDataEmpty ? [] : data.map((item) => item.y);

  const isHorizontal = layout === "horizontal";

  // Create a custom axis configuration with proper tickLabelStyle
  const xAxis = {
    data: xAxisData,
    label: xAxisLabel,
    scaleType: "band" as const,
    tickPlacement,
    tickLabelPlacement,
    colorMap,
    ...(verticalLabels && {
      tickLabelStyle: {
        angle: -90,
        textAnchor: "end",
        dominantBaseline: "middle",
      },
      labelStyle: {
        transform: "translateY(60px)",
      },
    }),
  };

  const yAxis = {
    label: yAxisLabel,
    tickMinStep: tickMinStep,
    max: max,
    min: min === null ? undefined : min ?? 0,
  };

  const transformSeriesData = (seriesData: any[]): number[] => {
    if (
      seriesData.length > 0 &&
      typeof seriesData[0] === "object" &&
      seriesData[0] !== null &&
      "y" in seriesData[0]
    ) {
      return seriesData.map((item: any) => item.y);
    }
    return seriesData;
  };

  const mainSeries = {
    curve,
    data: mainSeriesData,
    color: color ?? colors.purple2,
    label: !isDataEmpty ? chartLabel : undefined,
  };

  const transformedExtraSeries = extraSeries
    ? extraSeries.map((seriesItem) => ({
        ...seriesItem,
        data: transformSeriesData(seriesItem.data),
      }))
    : undefined;

  const series = transformedExtraSeries
    ? [mainSeries, ...transformedExtraSeries]
    : [mainSeries];

  // Calculate margins based on vertical labels and legend
  const bottomMargin = verticalLabels ? 110 : 80;
  const legendPadding = verticalLabels ? 60 : 0;

  // Common props for both chart types
  const commonProps = {
    xAxis: [isHorizontal ? yAxis : xAxis],
    yAxis: [isHorizontal ? xAxis : yAxis],
    series,
    height: height || 400,
    margin: {
      left: yAxisLabel ? leftMargin || 80 : leftMargin || 40,
      bottom: bottomMargin,
    },
    width,
    sx: {
      [`& .${axisClasses.directionY} .${axisClasses.label}`]: {
        transform: yAxisLabel
          ? `translateX(-${leftLabelMargin ?? 30}px)`
          : undefined,
      },
    },
    barLabel,
  };

  // Create common slot props
  const commonSlotProps = {
    legend: {
      position: {
        vertical: "bottom" as const,
        horizontal: "left" as const,
      },
      padding: legendPadding, // Use new padding value
    },
    noDataOverlay: { message: "Nėra duomenų." },
  };

  // Create specific props for BarChart
  const barChartProps: any = {
    ...commonProps,
    layout,
    slotProps: commonSlotProps,
  };

  // Create specific props for LineChart
  const lineChartProps: any = {
    ...commonProps,
    slotProps: commonSlotProps,
  };

  return (
    <div style={{ position: "relative", width: width, height: height }}>
      {chartType === ChartType.Lines ? (
        <LineChart {...lineChartProps} />
      ) : (
        <BarChart {...barChartProps} />
      )}
    </div>
  );
};

export default BaseChart;
