import React, { useState } from "react";
import { AddonPriceInfo } from "./AddonPriceInfo";
import { useProperty } from "../../hooks/property.hooks";
import { useQuery } from "react-query";
import { getListingsWithStorage } from "../../api/api";
import { AddonListingCard } from "./AddonListingCard";

interface StoragePriceInfoProps {
  propertyId: string;
}

export const StoragePriceInfo: React.FC<StoragePriceInfoProps> = ({
  propertyId,
}) => {
  const { data: property } = useProperty(propertyId);

  const [selectedRadius, setSelectedRadius] = useState(3);

  const { data, isLoading, isFetching } = useQuery(
    ["listings_with_storage", property?.lat, property?.lng, selectedRadius],
    () =>
      getListingsWithStorage(
        property?.lat ?? 0,
        property?.lng ?? 0,
        selectedRadius * 1000
      ),
    {
      enabled: !!property,
      staleTime: Infinity,
      keepPreviousData: true,
    }
  );

  return (
    <AddonPriceInfo
      title="Sandėliukų kainos"
      data={data}
      isLoading={isLoading}
      isFetching={isFetching}
      listingFilterFn={(listing, priceMin, priceMax) => {
        return (
          (listing.storage_price ?? 0) >= priceMin &&
          (listing.storage_price ?? 0) <= priceMax
        );
      }}
      selectedRadius={selectedRadius}
      setSelectedRadius={setSelectedRadius}
      mapPriceFn={(listing) => listing.storage_price ?? 0}
      mapCardComponent={(listing, paginationNode, onClick) => (
        <AddonListingCard
          listing={listing}
          price={listing.storage_price ?? 0}
          priceArea={
            !!listing.storage_area
              ? (listing.storage_price ?? 0) / listing.storage_area
              : undefined
          }
          caption={
            listing.storage_area ? `${listing.storage_area} m²` : undefined
          }
          onClick={onClick}
          paginationNode={paginationNode}
        />
      )}
      property={property}
    />
  );
};
