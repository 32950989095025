import { AdditionalREType, RelatedRE } from "common/types/common.types";
import React from "react";
import BasementIcon from "../assets/icons/basement.svg";
import ParkingIcon from "../assets/icons/parking.svg";
import PlotAreaIcon from "../assets/icons/plotAreaIcon.svg";
import { Typography } from "common/components/Typography";
import { formatArea, formatAreaAcres } from "common/helpers/text.helpers";
import { IconButton, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

interface RelatedRETagProps {
  re: RelatedRE;
  full?: boolean;
}

export const RelatedRETag: React.FC<RelatedRETagProps> = ({ re, full }) => {
  const { t } = useTranslation();

  const getIcon = () => {
    switch (re.type) {
      case AdditionalREType.Basement:
        return <BasementIcon />;
      case AdditionalREType.Garage:
        return <ParkingIcon />;
      case AdditionalREType.PlotArea:
        return <PlotAreaIcon />;
    }
  };

  const getArea = (area: number) => {
    if (re.type === AdditionalREType.PlotArea) {
      return formatAreaAcres(area);
    }
    return formatArea(area);
  };

  const getText = () => {
    switch (re.type) {
      case AdditionalREType.Basement:
        return t("relatedRE.withStorage");
      case AdditionalREType.Garage:
        return t("relatedRE.withParking");
      case AdditionalREType.PlotArea:
        return t("relatedRE.withPlot");
    }
  };

  const textForFull = `${getText()} (${formatArea(re.area)})`;

  const textTooltip = t("relatedRE.tooltip", {
    type: getText(),
    area: getArea(re.area),
    areaTotal: getArea(re.area_total),
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <Tooltip title={textTooltip}>
        <IconButton size="small" style={{ padding: 0 }}>
          {getIcon()}
        </IconButton>
      </Tooltip>
      {full && (
        <Typography
          variant="caption"
          textStyle={{ fontSize: 12, marginLeft: 3 }}
        >
          {textForFull}
        </Typography>
      )}
    </div>
  );
};
