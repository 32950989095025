import React, { useEffect } from "react";
import { Login } from "../../routes/auth/Login";
import { useAppraiser } from "../../hooks/auth.hooks";
import { Outlet, useLocation, useMatch } from "react-router-dom";
import { LoginLayout } from "./LoginLayout";

interface LoginOverlayProps {
  children?: React.ReactNode;
}

export const LoginOverlay: React.FC<LoginOverlayProps> = () => {
  const { data, isLoading } = useAppraiser();

  useEffect(() => {
    // HubSpot web chat script
    let script;
    script = document.createElement("script");
    script.type = "text/javascript";
    script.id = "hs-script-loader";
    script.async = true;
    script.defer = true;
    script.src = "//js.hs-scripts.com/6822891.js";
    document.body.appendChild(script);
  }, []);

  if (isLoading) {
    return null;
  }

  if (!data) {
    return (
      <LoginLayout>
        <Login />
      </LoginLayout>
    );
  }

  return <Outlet />;
};
