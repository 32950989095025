import { GoogleMap, OverlayViewF } from "@react-google-maps/api";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { mapStyle } from "../../../components/map/constants";
import { Button} from "@mui/material";
import { MapFieldDialog } from "./MapFieldDialog";
import MarkerIcon from "../../../assets/icons/marker.svg";
import { getCityLabel } from "./CityFormField";
import { getStreetLabel } from "./StreetFormField";
import { getBuildingNumberLabel } from "./BuildingNumberField";
import { useTranslation } from "react-i18next";

interface MapFieldProps {}

export const litCenterCoords = {
  lat: 55.231414,
  lng: 23.936029,
};

export const MapField: React.FC<MapFieldProps> = () => {
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext();

  const lat = watch("lat");
  const lng = watch("lng");

  const centerCoords = {
    lat: lat ?? litCenterCoords.lat,
    lng: lng ?? litCenterCoords.lng,
  };

  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      <div style={{ position: "relative" }}>
        <GoogleMap
          mapContainerStyle={{
            width: "100%",
            height: "215px",
            pointerEvents: "none",
            borderRadius: "5px",
          }}
          center={centerCoords}
          zoom={!!lat && !!lng ? 15 : 6}
          options={{
            fullscreenControl: false,
            mapTypeControl: false,
            streetViewControl: false,
            zoomControl: false,
            styles: mapStyle
          }}
        >
          {!!lat && !!lng && (
            <OverlayViewF
              mapPaneName="overlayLayer"
              position={{ lat, lng }}
              getPixelPositionOffset={(width, height) => ({
                x: -width / 2,
                y: -height,
              })}
            >
              <MarkerIcon />
            </OverlayViewF>
          )}
        </GoogleMap>
        <Button
          variant="outlined"
          style={{
            position: "absolute",
            bottom: "10px",
            left: "10px",
            backgroundColor: "white",
          }}
          onClick={() => setDialogOpen(true)}
        >
          {t("propertyForm.address.editLocationOnMap")}
        </Button>
      </div>
      <MapFieldDialog
        key={`${lat}-${lng}`}
        initialCoords={lat && lng ? { lat, lng } : undefined}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onSelect={(address) => {
          setValue("lat", address.coordinate.N_KOORD);
          setValue("lng", address.coordinate.E_KOORD);

          setValue("cityId", address.residential_area.GYV_KODAS);
          setValue(
            "city",
            getCityLabel({
              ...address.residential_area,
              municipality: address.municipality,
            })
          );
          setValue("streetId", address.street.GAT_KODAS);
          setValue("street", getStreetLabel(address.street));

          setValue(
            "streetNumber",
            getBuildingNumberLabel(address.land_plot_number)
          );
          setValue("apartmentNumber", undefined);

          setDialogOpen(false);
        }}
      />
    </>
  );
};
