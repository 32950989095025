import React from "react";
import {
  PAGE_KEY,
  usePaginatedQuery,
  useUpdateQueryParam,
} from "../../hooks/router.hooks";
import { Pagination as PaginationType } from "../../types/api.types";
import { Pagination } from "@mui/material";

interface PaginationComponentProps {
  pagination: PaginationType;
}

export const PaginationComponent: React.FC<PaginationComponentProps> = ({
  pagination,
}) => {
  const { page } = usePaginatedQuery();
  const totalPages = Math.ceil(pagination.total / pagination.pageSize);

  const { updateQueryParam } = useUpdateQueryParam();

  if (!totalPages) {
    return null;
  }

  return (
    <Pagination
      page={page}
      count={totalPages}
      onChange={(_e, value) => updateQueryParam(PAGE_KEY, value.toString())}
    />
  );
};
