import React from "react";
import { Select, MenuItem } from "@mui/material";
import { InfraFilter } from "../../../types/api.types";
import {
  useInfraFilterQuery,
  useUpdateQueryParam,
} from "../../../hooks/router.hooks";

export const PropertyInfraFilterSelection: React.FC = () => {
  const { updateQueryParam } = useUpdateQueryParam();
  const infraFilter = useInfraFilterQuery();

  const infraFilterOptions = [
    { label: "Visi objektai", value: InfraFilter.All },
    { label: "Esami objektai", value: InfraFilter.Existing },
    { label: "Planuojami objektai", value: InfraFilter.Planned },
  ];

  const handleChange = (infraFilter: string) => {
    updateQueryParam("infraFilter", infraFilter);
  };

  return (
    <Select
      value={infraFilter || ""}
      style={{ marginBottom: "20px" }}
      onChange={(e) => handleChange(e.target.value)}
    >
      {infraFilterOptions.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};
