import React from "react";
import { useParams } from "react-router-dom";
import { useFinalPricePredictions } from "../../hooks/pricePredictions.hooks";
import { LoaderCentered } from "../../components/loaders/LoaderCentered";
import { TransparencyAdjustmentsCorrection } from "./TransparencyAdjustmentsCorrection";
import { TransparencySimilarity } from "./TransparencySimilarity";
import { useScrollToTop } from "../../hooks/router.hooks";
import { useQuery } from "react-query";
import { getPricePredictionsForTransparency } from "../../api/api";

interface PricePredictionsTransparencyRouteProps {}

export const PricePredictionsTransparencyRoute: React.FC<
  PricePredictionsTransparencyRouteProps
> = () => {
  const { propertyId } = useParams();

  const { data, isLoading } = useQuery(
    ["transparency_predictions", propertyId],
    () => getPricePredictionsForTransparency(propertyId ?? "")
  );

  useScrollToTop();

  if (isLoading) {
    return <LoaderCentered />;
  }

  if (!data) {
    return null;
  }

  return (
    <div>
      <TransparencyAdjustmentsCorrection data={data} />
      <TransparencySimilarity
        comparablesForReview={data.comparablesForReview}
        appliedFilters={data.applied_filters}
      />
    </div>
  );
};
