import React, { useState } from "react";
import {
  AppraiserProperty,
  AppraiserRole,
  PropertyRequestStatus,
} from "../../types/appraiser.types";
import { PropertyPlaceholderImage } from "../imageGallery/PropertyPlaceholderImage";
import { Typography } from "common/components/Typography";
import { getListString } from "common/helpers/common.helpers";
import { ViewOnMapButton } from "./ViewOnMapButton";
import { AdjustmentsMapModal } from "../map/AdjustmentsMapModal";
import colors from "common/styles/colors";
import { PropertyRow } from "../PropertyRow";
import { getLithuanianDateTimeString } from "common/helpers/dateStringHelpers";
import { getYearText } from "../../helpers/comparable.helpers";
import { getFinishingLabel } from "../transactions/helpers";
import { LinkForButton } from "../links/LinkForButton";
import { Button, Link } from "@mui/material";
import { getPropertyListString } from "../../helpers/property.helpers";
import { PropertyLeadContact } from "./PropertyLeadContact";
import { PropertyRequestTypeLabel } from "./PropertyRequestTypeLabel";
import { useAppraiser } from "../../hooks/auth.hooks";
import { ConfirmationDialog } from "../dialogs/ConfirmationDialog";
import { useMutation } from "react-query";
import { sendPropertyReminder } from "../../api/api";
import { showToastError, showToastSuccess } from "common/toast/toast";
import { PropertyDeliveryTimeType } from "../../types/api.types";
import { TagComponent } from "../tags/TagComponent";
import { ExpirationTimeTag } from "../tags/ExpirationTimeTag";
import { useTranslation } from "react-i18next";

interface PropertyInListProps {
  property: AppraiserProperty;
}

export const PropertyInList: React.FC<PropertyInListProps> = ({ property }) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onViewOnMapClick = () => {
    setIsModalOpen(true);
  };
  const imageUrl = property.images[0]?.url;

  const captionString = getPropertyListString({
    area: property.area,
    rooms: property.rooms,
    floorNr: property.floorNr,
    floorCount: property.floorCount,
    year: property.year,
    renovationYear: property.renovationYear,
    energyClass: property.energyClass,
    finishing: property.finishing,
  });

  const { data: appraiser } = useAppraiser();

  const [reminderProperty, setReminderProperty] = useState<AppraiserProperty>();

  const { mutate, isLoading } = useMutation(sendPropertyReminder, {
    onSuccess: () => {
      showToastSuccess("Priminimas išsiųstas");
      setReminderProperty(undefined);
    },
    onError: () => {
      showToastError();
    },
  });

  const shouldShowPropertyReminder = (property: AppraiserProperty) => {
    return (
      !!property.leadContact &&
      appraiser?.role === AppraiserRole.ADMIN &&
      property.appraiser.role !== AppraiserRole.ORGANIZATION_LEAD &&
      property.appraiser.role !== AppraiserRole.DATA_ENTRY &&
      property.propertyRequestStatus !== PropertyRequestStatus.Completed
    );
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          padding: "30px",
          backgroundColor: colors.white,
          borderRadius: "10px",
          justifyContent: "space-between",
          position: "relative",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "30px",
          }}
        >
          <PropertyPlaceholderImage
            borderRadius={5}
            uri={imageUrl}
            height={266}
            width={266}
          />
          <div>
            <Typography variant="h2" textStyle={{ fontSize: 28 }}>
              {property.label}
            </Typography>
            <div style={{ margin: "5px 0" }}>
              <ViewOnMapButton onViewOnMapClick={onViewOnMapClick} />
            </div>
            <Typography variant="caption" textStyle={{ fontSize: 18 }}>
              {captionString}
            </Typography>
            <div style={{ marginTop: "20px" }}>
              <PropertyRow
                label={t("propertyDetails.assigned")}
                value={
                  <div>
                    {property.appraiser.role === AppraiserRole.ORGANIZATION_LEAD
                      ? "-"
                      : `${property.appraiser.email} (${property.appraiser.role})`}

                    {shouldShowPropertyReminder(property) && (
                      <Link
                        style={{
                          cursor: "pointer",
                          marginLeft: "10px",
                        }}
                        onClick={() => {
                          setReminderProperty(property);
                        }}
                      >
                        {t("propertyDetails.remind")}
                      </Link>
                    )}
                  </div>
                }
              />
              <PropertyRow
                label={t("propertyDetails.created")}
                value={getLithuanianDateTimeString(property.createdAt)}
              />
              {!!property.finalPredictionCreatedAt && (
                <PropertyRow
                  label={t("propertyDetails.completed")}
                  value={getLithuanianDateTimeString(
                    property.finalPredictionCreatedAt
                  )}
                />
              )}
              <div style={{ marginTop: "15px" }}>
                {!!property.order?.discountCode && (
                  <PropertyRow
                    label={t("propertyDetails.discountCode")}
                    value={property.order.discountCode}
                  />
                )}
                {!!property.leadContact && (
                  <PropertyRow
                    label={t("propertyDetails.customer")}
                    value={property.leadContact.email}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <LinkForButton
          to={`/properties/${property._id}`}
          style={{ alignSelf: "end" }}
        >
          <Button variant="contained">{t("propertyDetails.view")}</Button>
        </LinkForButton>

        <div
          style={{
            position: "absolute",
            top: 10,
            right: 10,
            display: "flex",
            gap: "10px",
            flexDirection: "column",
            alignItems: "end",
          }}
        >
          <PropertyRequestTypeLabel type={property.propertyRequestType} />
          {!!property.order?.deliveryDate &&
            property.propertyRequestStatus !==
              PropertyRequestStatus.Completed && (
              <ExpirationTimeTag deliveryDate={property.order.deliveryDate} />
            )}
        </div>
      </div>
      <AdjustmentsMapModal
        property={property}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
      <ConfirmationDialog
        isOpen={!!reminderProperty}
        onClose={() => setReminderProperty(undefined)}
        title={t("propertyDetails.reminderDialog.title")}
        description={t("propertyDetails.reminderDialog.description", {
          email: reminderProperty?.appraiser?.email,
        })}
        actionButtonText={t("propertyDetails.reminderDialog.action")}
        onConfirm={() => {
          if (!reminderProperty) return;
          mutate(reminderProperty?._id);
        }}
        isLoading={isLoading}
        hideDoNotShowAgain
      />
    </>
  );
};
