import React, { useContext, useEffect, useMemo } from "react";
import { Virtuoso } from "react-virtuoso";
import { AddonMap } from "../../../components/comparablesSummary/AddonMap";
import { MatchedListing } from "common/types/common.types";
import { AppraiserProperty } from "../../../types/appraiser.types";
import { ClusteredListingsMapControlContext } from "../../../components/ClusteredGenericMapControlProvider";
import { usePrintableQuery } from "../../../hooks/router.hooks";
import { groupByCoordinates } from "../../../helpers/comparableFilters.helpers";
import { Grid } from "@mui/material";

const getListingId = (listing: MatchedListing) =>
  `${listing.lat}|${listing.lng}|${listing.url}`;

interface ListingsWithMapProps {
  listings: MatchedListing[];
  mapCardComponent: (
    listing: MatchedListing,
    paginationNode: React.ReactNode,
    onClick?: () => void,
    badgeNum?: number
  ) => React.ReactNode;
  mapPriceFn: (listing: MatchedListing) => number;
  property: AppraiserProperty | undefined;
}

export const ListingsWithMap: React.FC<ListingsWithMapProps> = ({
  listings,
  mapCardComponent,
  mapPriceFn,
  property,
}) => {
  const printable = usePrintableQuery();
  const listingsToUse = useMemo(
    () =>
      printable // how to display 5 listings in same coords? - display only 1st listing
        ? groupByCoordinates(listings, (listing) => ({
            lat: listing.lat,
            lng: listing.lng,
          }))
            .map((group) => group[0])
            // we are most likely to find 16 properties after group elimination
            .slice(0, 25)
        : listings,
    [listings, printable]
  );
  const clustering = useContext(ClusteredListingsMapControlContext);
  const targetItems =
    (printable ? clustering?.soloItems : clustering?.clusterableItems) ?? [];

  useEffect(() => {
    if (!clustering || !listingsToUse || targetItems.length > 0) return;
    const items = listingsToUse
      .filter((listing) => listing.lat !== 0 && listing.lng !== 0)
      .map((listing) => ({
        id: getListingId(listing),
        lat: listing.lat,
        lng: listing.lng,
        entityCount: 1,
        data: listing,
      }));
    if (printable) {
      // solo items do not get clustered, do not get geo grouped
      clustering.setClusterableItems([]);
      clustering.setSoloItems(items);
    } else {
      clustering.setClusterableItems(items);
      clustering.setSoloItems([]);
    }
    const propertyItems = property
      ? [
          {
            data: property,
            lat: property.lat,
            lng: property.lng,
            entityCount: 1,
            id: property._id,
          },
        ]
      : [];
    clustering.calculateAndSetMapBounds([...items, ...propertyItems]);
  }, [listingsToUse, clustering, property, printable, targetItems]);

  if (!clustering) {
    return null;
  }

  const toCard = (listing: MatchedListing, index: number) =>
    mapCardComponent(
      listing,
      null,
      () => {
        clustering.panTo(listing, { isZoomInto: true });
        clustering.setSelectedItemId(getListingId(listing));
      },
      printable ? index + 1 : undefined
    );

  if (!printable) {
    return (
      <div style={{ display: "flex", flexDirection: "row", marginTop: "20px" }}>
        <div style={{ width: "500px", height: "600px" }}>
          <Virtuoso
            data={listingsToUse ?? []}
            itemContent={(index, listing) => (
              // why not margin-bottom: 15px
              // https://virtuoso.dev/troubleshooting/#list-does-not-scroll-to-the-bottom--items-jump-around
              <div style={{ paddingBottom: "15px" }}>
                {toCard(listing, index)}
              </div>
            )}
          />
        </div>

        <div style={{ width: "100%", flex: 1, height: "600px" }}>
          {targetItems.length > 0 && (
            <AddonMap
              mapCardComponent={mapCardComponent}
              mapPriceFn={mapPriceFn}
              property={property}
            />
          )}
        </div>
      </div>
    );
  }

  return (
    // printable down below
    <>
      <div
        style={{
          height: "700px",
          marginTop: "20px",
          pageBreakInside: "avoid", // don't cut map in half in pdf
        }}
      >
        {targetItems.length > 0 && (
          <AddonMap
            mapCardComponent={mapCardComponent}
            mapPriceFn={mapPriceFn}
            property={property}
          />
        )}
      </div>
      <Grid direction="row" container spacing={3} paddingTop={4}>
        {listingsToUse.map((listing, index) => {
          return (
            <Grid
              item
              key={index}
              style={{
                pageBreakInside: "avoid" /* don't cut card in half in pdf */,
              }}
            >
              {toCard(listing, index)}
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default ListingsWithMap;
