import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Button } from "common/components/buttons/Button";
import colors from "common/styles/colors";
import { useMutation } from "react-query";
import { getErrorMessage } from "common/helpers/error.helpers";
import { useLocation } from "react-router-dom";
import { ContentCard } from "../../components/ContentCard";
import { showToastError } from "common/toast/toast";
import { PropertyCreationContext } from "./PropertyCreationProvider";
import { useRouteQuery, useUpdateQueryParam } from "../../hooks/router.hooks";
import { useUpdateMyProperty } from "common/hooks/propertyDetails.hooks";
import {
  CompositeAssetRequest,
  PropertyReadingSingleOverview,
  PropertyType,
} from "../../types/api.types";
import { Typography } from "common/components/Typography";
import { PropertyReadingTable } from "./PropertyReadingTable";
import { addAppraiserProperty } from "../../api/appraiserProperties.api";
import { createCompositeAsset } from "../../api/api";
import { MyPropertyAddRequest } from "common/types/common.types";
import { useTranslation } from "react-i18next";

export const getAddressFromOverview = (
  overview: PropertyReadingSingleOverview
) => overview.data.property?.address || overview.data.asset?.address || "";

const extractProperty = (
  readings: PropertyReadingSingleOverview[],
  propertyPlaceholder: MyPropertyAddRequest
): MyPropertyAddRequest => {
  const properties = readings
    .map((item) => item.data.property)
    .filter((property) => !!property);
  if (properties.length > 1) {
    throw new Error(
      `Tik vieną gyvenamą turtą galima pasirinkti. Pasirinkta: ${properties.length}`
    );
  }
  const myProperty: MyPropertyAddRequest = properties[0] ?? propertyPlaceholder;
  return myProperty;
};

const extractAssets = (
  readings: PropertyReadingSingleOverview[],
  propertyId: string,
  fromLead?: boolean
): CompositeAssetRequest[] => {
  return readings
    .map((reading) => reading.data.asset)
    .filter((asset) => !!asset)
    .map(
      (asset) =>
        ({
          ...asset,
          isCustomer: !!fromLead,
          propertyId,
        } as CompositeAssetRequest)
    );
};

interface PropertyViaPDFConfirmRouteProps {}

export const PropertyViaPDFConfirmRoute: React.FC<
  PropertyViaPDFConfirmRouteProps
> = () => {
  const { t } = useTranslation();
  const { navigateWithQueryParams } = useUpdateQueryParam();
  const locationState = useLocation().state ?? {};
  const reading = locationState.readings as
    | PropertyReadingSingleOverview[]
    | undefined;
  const propertyPlaceholder = locationState.propertyPlaceholder as
    | MyPropertyAddRequest
    | undefined;
  const { fromLead } = useContext(PropertyCreationContext);
  const appraiserId = useRouteQuery().get("appraiserId") ?? undefined;
  const simpleType = useRouteQuery().get("simpleType") as
    | PropertyType
    | undefined;

  const [flatProperties, setFlatProperties] = useState<
    PropertyReadingSingleOverview[]
  >([]);
  const { submitHandler } = useUpdateMyProperty(
    undefined,
    async (property) => {
      if (!property) throw Error("Klaida");
      const assets = extractAssets(
        flatProperties.filter((property) => property.isChecked),
        property._id.toString(),
        fromLead
      );
      await Promise.all(assets.map((asset) => createCompositeAsset(asset)));
      const paramAlert = { paramName: "isFromFile", newValue: "true" };
      if (!!fromLead) {
        navigateWithQueryParams(
          "/lead/details",
          [
            { paramName: "createdPropertyId", newValue: property._id },
            paramAlert,
          ],
          { replace: true }
        );
      } else {
        navigateWithQueryParams(
          `/properties/${property._id}/details`,
          [paramAlert],
          { replace: true }
        );
      }
    },
    undefined,
    (property: MyPropertyAddRequest) =>
      addAppraiserProperty(property, fromLead, appraiserId, simpleType)
  );

  useEffect(() => {
    // transform read property to property overview for checkboxes
    // ignore if already transformed
    if (flatProperties.length > 0) return;
    if (!reading) {
      navigateWithQueryParams(
        fromLead ? "/lead/inputMethod" : "/properties/create/inputMethod",
        undefined,
        { replace: true }
      );
      return;
    }
    setFlatProperties(reading);
  }, [reading, navigateWithQueryParams, fromLead, flatProperties]);

  const uniqueAddresses = useMemo(() => {
    const addresses = flatProperties
      .map((x) => getAddressFromOverview(x))
      .filter((x) => !!x);
    return Array.from(new Set(addresses));
  }, [flatProperties]);

  const selectProperty = useCallback(
    (index: number, newValue: boolean) => {
      setFlatProperties((prev) =>
        prev.map((property, i) => ({
          ...property,
          isChecked: i === index ? newValue : property.isChecked,
        }))
      );
    },
    [setFlatProperties]
  );

  const selectAll = useCallback(
    (newValue: boolean) => {
      setFlatProperties((prev) =>
        prev.map((property) => ({ ...property, isChecked: newValue }))
      );
    },
    [setFlatProperties]
  );

  const { isLoading: isMutating, mutate } = useMutation(
    async () => {
      if (!propertyPlaceholder) {
        throw new Error("Klaida. Bandykite per naujo įkelti failus");
      }
      const readings = flatProperties.filter((property) => property.isChecked);
      const propertyRequest = extractProperty(readings, propertyPlaceholder);
      await submitHandler(propertyRequest, undefined, undefined);
    },
    {
      onError: (error: any) => {
        const message = getErrorMessage(error);

        if (message === undefined) {
          showToastError();
        } else {
          showToastError(message);
        }
      },
    }
  );

  return (
    <div>
      <ContentCard title={t("propertyCreation.confirmProperties.title")}>
        <Typography>
          {t("propertyCreation.confirmProperties.uniqueAddressesFound", {
            count: uniqueAddresses.length,
          })}
        </Typography>
        <div>
          <PropertyReadingTable
            flattenedProperties={flatProperties}
            onSelectAll={selectAll}
            onPropertySelect={selectProperty}
          />
          <div style={{ marginTop: 30, width: "200px", justifySelf: "center" }}>
            <Button
              backgroundColor="purple2"
              textStyle={{ color: colors.white }}
              isLoading={isMutating}
              onPress={() => mutate()}
            >
              {t("propertyCreation.confirmProperties.continue")}
            </Button>
          </div>
        </div>
      </ContentCard>
    </div>
  );
};
