import React, { useEffect, useRef } from "react";
import { useQuery } from "react-query";
import { getProperties } from "../api/api";
import { AppraiserFilter, PropertyAssessmentStatus } from "../types/api.types";
import { LoaderCentered } from "../components/loaders/LoaderCentered";
import { Button } from "@mui/material";
import { useAutoPredictPrice } from "../hooks/pricePredictions.hooks";
import { AppraiserProperty } from "../types/appraiser.types";
import { SimpleTableComponent } from "../components/tables/simpleTable/SimpleTableComponent";
import { SimpleTableHeader } from "../components/tables/simpleTable/SimpleTableHeader";
import { SimpleTableRow } from "../components/tables/simpleTable/SimpleTableRow";
import LoadingButton from "@mui/lab/LoadingButton";
import { SimpleTableElement } from "../components/tables/simpleTable/SimpleTableElement";
import { formatPrice } from "common/helpers/text.helpers";

interface AutoValuationRouteProps {}

export const AutoValuationRoute: React.FC<AutoValuationRouteProps> = () => {
  const { data: paginatedData, isLoading } = useQuery("propertiesAll", () =>
    getProperties(
      0,
      PropertyAssessmentStatus.InProgress,
      { appraiserFilter: AppraiserFilter.All },
      1000000000
    )
  );

  const { autoPredictPrice, isLoading: isPredicting } = useAutoPredictPrice();

  const [predictionsResults, setPredictionsResults] = React.useState<
    {
      property: AppraiserProperty;
      predictedPrice?: number;
      error?: string;
      confidence?: number;
    }[]
  >([]);

  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const predictPrice = async (property: AppraiserProperty) => {
    try {
      const prediction = await autoPredictPrice(property);

      if (!prediction) {
        throw new Error("Nerasta pakankamai sandorių");
      }

      setPredictionsResults((prev) => [
        ...prev,
        {
          property,
          predictedPrice:
            prediction.predicted_price.average_price_area * property.area,
        },
      ]);
    } catch (e: any) {
      setPredictionsResults((prev) => [
        ...prev,
        { property, error: e.message },
      ]);
    }
  };

  if (isLoading) {
    return <LoaderCentered />;
  }

  const data = paginatedData?.data;

  if (!data) {
    return null;
  }

  return (
    <div>
      <p>Vykdomų vertinimų: {data.length}</p>

      <LoadingButton
        variant="contained"
        loading={isPredicting}
        onClick={async () => {
          for (const property of data) {
            if (!isMounted.current) {
              return;
            }
            await predictPrice(property);
          }
        }}
      >
        Vertinti viską automatiškai
      </LoadingButton>

      <SimpleTableComponent>
        <SimpleTableRow>
          <SimpleTableHeader>Adresas</SimpleTableHeader>
          <SimpleTableHeader>Lūkestinė kaina</SimpleTableHeader>
          <SimpleTableHeader>Apskaičiuota kaina</SimpleTableHeader>
          <SimpleTableHeader>Klaida</SimpleTableHeader>
        </SimpleTableRow>
        {predictionsResults.map((result, index) => (
          <SimpleTableRow key={index}>
            <SimpleTableElement>{result.property.address}</SimpleTableElement>
            <SimpleTableElement>
              {formatPrice(result.property.expectedValue)}
            </SimpleTableElement>
            <SimpleTableElement>
              {formatPrice(result.predictedPrice)}
            </SimpleTableElement>
            <SimpleTableElement>{result.error}</SimpleTableElement>
          </SimpleTableRow>
        ))}
      </SimpleTableComponent>
    </div>
  );
};
