import React from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import { IconButton } from "common/components/buttons/IconButton";
import { setClipboard } from "common/clipboard/clipboard";
import { showToastSuccess } from "common/toast/toast";
import { useMutation } from "react-query";
import { regenerateAppraiserLink } from "../../api/api";
import { Appraiser } from "../../types/appraiser.types";
import LoadingButton from "@mui/lab/LoadingButton";
import { DecodedAppraiserToken } from "../../types/api.types";
import { LinkBox } from "../links/LinkBox";
import { TypographyType } from "common/types/typography.types";
import { useAppraiserInfo } from "../../hooks/api.hooks";
import { useAppraisersInOrganization } from "../../hooks/auth.hooks";
import { useTranslation } from "react-i18next";

interface AppraiserPasswordLinkProps {
  appraiser: Appraiser;
  type: "registration" | "reset";
  link?: string | null;
  decodedToken?: DecodedAppraiserToken;
  variant?: TypographyType;
}

export const AppraiserPasswordLink: React.FC<AppraiserPasswordLinkProps> = ({
  appraiser,
  type,
  link,
  decodedToken,
  variant
}) => {

  const { t } = useTranslation();
  const copyLinkToClipboard = async () => {
    await setClipboard(link ?? "");
    showToastSuccess(t("layout.copyLinks.linkCopied"));
  };
  const { refetch: refetchAppraisersInOrganization } = useAppraisersInOrganization();
  const { refetch: refetchAppraiserInfo } = useAppraiserInfo(appraiser._id);

  const { mutate, isLoading } = useMutation(
    async (appraiserId: string) => {
      await regenerateAppraiserLink(appraiserId);
      await Promise.all([
        await refetchAppraisersInOrganization(),
        await refetchAppraiserInfo(),
      ]);
    },
    {
      onSuccess: () => {
        showToastSuccess(t("appraiserInfo.passwordResetLinkDialog.successMessage"));
      },
    }
  );

  const isTokenValid = !decodedToken?.error;

  if (type === "reset" && !link) {
    return (
      <LoadingButton
        loading={isLoading}
        onClick={() => mutate(appraiser._id)}
        variant="outlined"
        style={{ width: "200px" }}
      >
        Atstatyti slaptažodį
      </LoadingButton>
    );
  }

  if (!appraiser.registrationLink && !appraiser.resetLink) {
    return null;
  }

  return (
    <LinkBox
      link={link}
      customButton={
        !isTokenValid ? (
          <IconButton
            isDisabled={isLoading}
            onPress={() => mutate(appraiser._id)}
          >
            <RefreshIcon
              sx={
                isLoading
                  ? {
                      animation: "rotate 2s linear infinite",
                      "@keyframes rotate": {
                        to: { transform: "rotate(360deg)" },
                      },
                    }
                  : undefined
              }
            />
          </IconButton>
        ) : undefined
      }
      customOpacity={isTokenValid ? 1 : 0.5}
      width="300px"
      variant={variant}
      withBorder={true}
    />
  );
};
