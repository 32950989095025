import React from "react";
import { Typography } from "common/components/Typography";
import { addAlpha } from "common/helpers/colors.helpers";
import ArrowAdjustmentDownIcon from "../../assets/icons/arrowAdjustmentDown.svg";
import ArrowAdjustmentUpIcon from "../../assets/icons/arrowAdjustmentUp.svg";
import colors from "common/styles/colors";
import { getPercentage } from "../../helpers/comparable.helpers";

interface PercentageIndicatorProps {
  originalValue: number;
  correctedValue: number;
}

export const PercentageIndicator: React.FC<PercentageIndicatorProps> = ({
  originalValue,
  correctedValue,
}) => {
  const perc = getPercentage(correctedValue, originalValue);
  const color = perc.percentage < 0 ? colors.red : colors.green;

  return (
    <div
      style={{
        padding: "4px",
        backgroundColor: addAlpha(color, 0.2),
        display: "flex",
        alignItems: "center",
        borderRadius: "5px",
        marginLeft: "8px",
      }}
    >
      <Typography
        textStyle={{
          fontSize: 12,
          color: color,
          marginRight: "2px",
        }}
      >
        {perc.percentageText}
      </Typography>
      {perc.percentage < 0 ? (
        <ArrowAdjustmentDownIcon />
      ) : (
        <ArrowAdjustmentUpIcon />
      )}
    </div>
  );
};
