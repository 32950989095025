import { Button, CircularProgress } from "@mui/material";
import { GoogleMap, OverlayViewF, PolygonF } from "@react-google-maps/api";
import React, { useMemo, useRef } from "react";
import { useParams } from "react-router-dom";
import { useProperty } from "../../hooks/property.hooks";
import MarkerIcon from "../../assets/icons/marker.svg";
import { useValuationZonesFromBoundingBox } from "../../hooks/valuationZones.hooks";
import colors from "common/styles/colors";
import { Typography } from "common/components/Typography";
import { mapStyle } from "../map/constants";

interface MapValuationZonesProps {
  onSelect: (selectedZones: string[]) => void;
  initialSelectedZones?: string[];
}

export const MapValuationZones: React.FC<MapValuationZonesProps> = ({
  initialSelectedZones,
  onSelect,
}) => {
  const { propertyId } = useParams();

  const { data: property } = useProperty(propertyId ?? "");

  const propertyCoords = useMemo(
    () => ({
      lat: property?.lat ?? 0,
      lng: property?.lng ?? 0,
    }),
    [property]
  );

  const [boundingBox, setBoundingBox] = React.useState<{
    minLat: number;
    maxLat: number;
    minLng: number;
    maxLng: number;
  }>();

  const { data: valuationZones, isFetching: isLoadingZones } =
    useValuationZonesFromBoundingBox(
      boundingBox?.minLat,
      boundingBox?.maxLat,
      boundingBox?.minLng,
      boundingBox?.maxLng
    );

  const mapRef = useRef<google.maps.Map>();

  const onLoad = React.useCallback(function callback(map: google.maps.Map) {
    mapRef.current = map;
  }, []);

  const onRegionChangeComplete = () => {
    const bounds = mapRef.current?.getBounds();
    if (!bounds) return;

    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();

    setBoundingBox({
      minLat: sw.lat(),
      maxLat: ne.lat(),
      minLng: sw.lng(),
      maxLng: ne.lng(),
    });
  };

  const scrollTimer = useRef<NodeJS.Timeout>();

  const [hoveredZone, setHoveredZone] = React.useState<string>();

  const [selectedZones, setSelectedZones] = React.useState<string[]>(
    initialSelectedZones ?? []
  );

  return (
    <>
      <div style={{ flex: 1 }}>
        <GoogleMap
          mapContainerStyle={{
            height: "100%",
            width: "100%",
          }}
          options={{
            fullscreenControl: true,
            mapTypeControl: false,
            streetViewControl: false,
            styles: mapStyle
          }}
          center={propertyCoords}
          zoom={15}
          onBoundsChanged={() => {
            clearTimeout(scrollTimer.current);
            scrollTimer.current = setTimeout(function () {
              onRegionChangeComplete();
            }, 300);
          }}
          onLoad={onLoad}
        >
          {valuationZones?.map((zone) => {
            const isSelected = selectedZones.includes(zone.zone_nr);

            return (
              <PolygonF
                key={zone.zone_nr}
                paths={zone.geometry.coordinates.map((polygon) =>
                  polygon.map((point) => ({ lat: point[1], lng: point[0] }))
                )}
                onMouseOver={() => setHoveredZone(zone.zone_nr)}
                onMouseOut={() => setHoveredZone(undefined)}
                onClick={(e) => {
                  e.stop();
                  e.domEvent.stopPropagation();
                  if (isSelected) {
                    setSelectedZones(
                      selectedZones.filter((selectedZone) => {
                        return selectedZone !== zone.zone_nr;
                      })
                    );
                  } else {
                    setSelectedZones([...selectedZones, zone.zone_nr]);
                  }
                }}
                options={{
                  fillOpacity: isSelected
                    ? 0.4
                    : hoveredZone === zone.zone_nr
                    ? 0.1
                    : 0,
                  strokeWeight: 2.5,
                  strokeColor: colors.purple2,
                  fillColor: colors.purple2,
                }}
              />
            );
          })}
          <OverlayViewF
            position={propertyCoords}
            mapPaneName="overlayLayer"
            getPixelPositionOffset={(width, height) => ({
              x: -width / 2,
              y: -height,
            })}
            zIndex={1000}
          >
            <MarkerIcon />
          </OverlayViewF>
          <div
            style={{
              display: !!hoveredZone ? "block" : "none",
              position: "absolute",
              left: 20,
              top: 15,
            }}
          >
            <Typography variant="h2">{hoveredZone}</Typography>
          </div>
          <div
            style={{
              display: isLoadingZones ? "block" : "none",
              position: "absolute",
              left: "50%",
              top: 15,
              transform: "translate(-50%, 0)",
            }}
          >
            <CircularProgress />
          </div>
        </GoogleMap>
      </div>
      <Button
        onClick={() => {
          onSelect?.(selectedZones);
        }}
        style={{
          marginTop: "20px",
          width: "300px",
          alignSelf: "center",
        }}
        variant="contained"
      >
        Išsaugoti
      </Button>
    </>
  );
};
