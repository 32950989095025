import React from "react";
import { UploadedFile } from "../../types/api.types";
import { CircularProgress, Link } from "@mui/material";
import { IconButton } from "common/components/buttons/IconButton";
import RemoveButtonIcon from "../../assets/icons/removeButton.svg";
import ExternalLink from "../../components/links/ExternalLink";
import { useTranslation } from "react-i18next";

interface PropertyFileListProps {
  files?: UploadedFile[];
  isLoading?: boolean;
  onDelete?: (file: UploadedFile) => void;
}

export const PropertyFileList: React.FC<PropertyFileListProps> = ({
  files,
  onDelete,
  isLoading,
}) => {
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }

  if (!files?.length) {
    return <div>{t("propertyFiles.noDocuments")}</div>;
  }

  return (
    <div>
      {files?.map((file) => (
        <div
          key={file._id}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <ExternalLink href={file.url} style={{ padding: "5px 0" }}>
            {file.fileName}
          </ExternalLink>
          {!!onDelete && (
            <IconButton
              onPress={() => {
                onDelete(file);
              }}
            >
              <RemoveButtonIcon />
            </IconButton>
          )}
        </div>
      ))}
    </div>
  );
};
