import { Typography } from "common/components/Typography";
import React from "react";
import { AdminListItem } from "./AdminListItem";
import { AppraiserRole } from "../../types/appraiser.types";
import { useTranslation } from "react-i18next";

interface AdminMenuProps {
  children?: React.ReactNode;
}

export const AdminMenu: React.FC<AdminMenuProps> = ({ children }) => {
  const { t } = useTranslation();

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div
        style={{
          minWidth: "200px",
          backgroundColor: "white",
          position: "fixed",
          height: "100%",
        }}
      >
        <AdminListItem to="/settings" label={t("adminMenu.settings")} />
        <AdminListItem to="/appraisers" label={t("adminMenu.members")} />
        <AdminListItem to="/payments" label={t("adminMenu.payments")} />
        <AdminListItem to="/adjustments" label={t("adminMenu.adjustments")} />
        <AdminListItem to="/import" label={t("adminMenu.import")} />
        <AdminListItem
          to="/statistics"
          label={t("adminMenu.statistics")}
          allowedRoles={[AppraiserRole.SPECTATOR]}
        />
        <AdminListItem
          to="/feed"
          label={t("adminMenu.valuationsOverview")}
          allowedRoles={[AppraiserRole.SPECTATOR]}
        />
        <AdminListItem
          to="/portfolio-statistics"
          label={t("adminMenu.portfolioStatistics")}
        />
        {/* <AdminListItem to="/auto-valuation" label="Automatinis vertinimas" /> */}
      </div>
      <div style={{ paddingLeft: "200px", width: "100%" }}>{children}</div>
    </div>
  );
};
