import i18n from "i18next";

export const modifyErrorText = (error: string, label?: string) => {
  const errorText =
    error.includes("required") || error.includes("cast from the value `NaN`")
      ? i18n.t("validation.fieldRequired", { field: label })
      : error;
  return errorText;
};

export const getMinErrorText = (label: string, min: number) => {
  return i18n.t("validation.fieldMin", { field: label, min });
};

export const getMaxErrorText = (label: string, max: number) => {
  return i18n.t("validation.fieldMax", { field: label, max });
};
