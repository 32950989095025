import { Dialog, DialogContent, DialogProps, DialogTitle } from "@mui/material";
import {
  ClusteredGenericMap,
  SelectedItemBoxProps,
} from "./ClusteredGenericMap";
import { usePropertiesBrief } from "../../hooks/property.hooks";
import { LoaderCentered } from "../loaders/LoaderCentered";
import React, { useContext, useEffect } from "react";
import { AppraiserProperty } from "../../types/appraiser.types";
import { PropertyIcon } from "common/components/map/PropertyIcon";
import { ClusteredAppraiserPropertiesMapControlContext } from "../ClusteredGenericMapControlProvider";
import { getPropertyListString } from "../../helpers/property.helpers";
import colors from "common/styles/colors";
import { useAppraiser } from "../../hooks/auth.hooks";
import { ClusterMarkerIcon } from "common/components/icons/ClusterMarkerIcon";
import { usePropertyAssessmentStatusQuery } from "../../hooks/router.hooks";
import { IndexPropertyCard } from "../cards/IndexPropertyCard";
import { useTranslation } from "react-i18next";

const SelectedPropertyBox: React.FC<
  SelectedItemBoxProps<AppraiserProperty>
> = ({ item, paginatorNode }) => {
  return (
    <IndexPropertyCard
      key={item.id}
      address={item.data.address}
      images={item.data.images.map((image) => image.url)}
      caption={getPropertyListString(item.data)}
      createdAt={item.data.createdAt}
      appraiser={item.data.appraiser.fullName}
      propertyId={item.id}
    >
      {paginatorNode}
    </IndexPropertyCard>
  );
};

type AppraiserPropertiesMapDialogProps = Omit<DialogProps, "children"> &
  Required<Pick<DialogProps, "onClose">>;

export const AppraiserPropertiesMapDialog = (
  props: AppraiserPropertiesMapDialogProps
) => {
  const clustering = useContext(ClusteredAppraiserPropertiesMapControlContext);
  const { data } = usePropertiesBrief({ isPaged: false });
  const { data: appraiser } = useAppraiser();
  const status = usePropertyAssessmentStatusQuery();
  const { t } = useTranslation();

  useEffect(() => {
    if (!clustering || !data || clustering.clusterableItems.length > 0) return;
    const clusterables = data
      .filter((property) => property.lat != 0 && property.lng != 0)
      .map((property) => ({
        id: property._id,
        lat: property.lat,
        lng: property.lng,
        entityCount: 1,
        data: property,
      }));
    clustering.setClusterableItems(clusterables);
    clustering.calculateAndSetMapBounds(clusterables);
  }, [data, clustering]);

  if (!clustering || !appraiser) return null;

  return (
    <Dialog maxWidth="lg" fullWidth {...props}>
      <DialogTitle fontWeight="600" fontSize="30px">
        {t(`propertyList.statusTabs.${status}`)}
      </DialogTitle>
      <DialogContent>
        <div style={{ height: "75vh", width: "inherit" }}>
          {data === undefined ? ( // can be empty array
            // when all properties are ~"empty property created via file with one garage"
            <LoaderCentered />
          ) : clustering.clusterableItems.length < 1 ? (
            <div>{t("propertyList.noResults")}</div>
          ) : (
            <ClusteredGenericMap
              context={ClusteredAppraiserPropertiesMapControlContext}
              displayItemGroupIcon={(itemGroup) => (
                <PropertyIcon
                  multipleCount={
                    itemGroup.length > 1 ? itemGroup.length : undefined
                  }
                  backgroundColor={
                    itemGroup.some(
                      (item) => item.data.appraiser._id === appraiser._id
                    )
                      ? colors.black
                      : colors.grey
                  }
                />
              )}
              SelectedItemBox={SelectedPropertyBox}
              paginatorSettings={{
                name: t("indexMapPageItem"),
                isBottomRight: true,
                wrapperStyle: { width: "100%", height: "37px" },
              }}
              displayClusterIcon={(cluster) => (
                <ClusterMarkerIcon
                  propertyCount={cluster.entityCount}
                  color={
                    cluster
                      .getClusterData()
                      .some((item) => item.data.appraiser._id === appraiser._id)
                      ? colors.black
                      : colors.grey
                  }
                  textColor={colors.white}
                />
              )}
            />
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};
