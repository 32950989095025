import React from "react";
import { useTranslation } from "react-i18next";
import { Switch, FormControlLabel } from "@mui/material";
import { useMutation, useQueryClient } from "react-query";
import { toggleAppraiserVerificationWithinOrganization } from "../../api/api";
import { showToastSuccess, showToastError } from "common/toast/toast";

interface AppraiserVerificationSwitchProps {
  currentVerificationStatus: boolean;
}

export const AppraiserVerificationSwitch: React.FC<
  AppraiserVerificationSwitchProps
> = ({ currentVerificationStatus }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const toggleRequireAppraiserVerificationWithinOrganization = useMutation(
    (verificationEnabled: boolean) =>
      toggleAppraiserVerificationWithinOrganization(verificationEnabled),
    {
      onSuccess: (_, verificationEnabled) => {
        showToastSuccess(
          verificationEnabled
            ? t("appraiserVerification.success.enabled")
            : t("appraiserVerification.success.disabled")
        );
        queryClient.invalidateQueries("organization");
      },
      onError: () => {
        showToastError();
      },
    }
  );

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isEnabled = event.target.checked;
    toggleRequireAppraiserVerificationWithinOrganization.mutate(isEnabled);
  };

  return (
    <FormControlLabel
      control={
        <Switch
          checked={currentVerificationStatus}
          onChange={handleSwitchChange}
          disabled={
            toggleRequireAppraiserVerificationWithinOrganization.isLoading
          }
        />
      }
      label={t("appraiserVerification.2faLabel")}
      labelPlacement="start"
    />
  );
};
