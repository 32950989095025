import React, { useState } from "react";
import { LoginRequest, LoginResponse } from "../../types/api.types";
import { useForm, FormProvider } from "react-hook-form";
import { FormInput } from "common/components/form/hookForms/FormInput";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Typography } from "common/components/Typography";
import { useMutation } from "react-query";
import { loginAppraiser } from "../../api/api";
import { useAuthSideEffects } from "../../hooks/auth.hooks";
import { MUILoadingButton } from "../../components/buttons/MUILoadingButton";
import { ErrorAlert } from "../../components/alerts/ErrorAlert";
import { FormContainer } from "../../components/containers/FormContainer";
import { Link } from "@mui/material";
import { AppraiserVerificationDialog } from "./AppraiserVerificationDialog";
import { useTranslation } from "react-i18next";

interface LoginProps {}

export const Login: React.FC<LoginProps> = () => {
  const { t } = useTranslation();

  const validationSchema: Yup.ObjectSchema<LoginRequest> = Yup.object({
    email: Yup.string().email().required(),
    password: Yup.string().required(),
  });

  const methods = useForm<LoginRequest>({
    resolver: yupResolver(validationSchema),
  });
  const [showNewDeviceDialog, setShowNewDeviceDialog] = useState(false);
  const { onAuthSuccess } = useAuthSideEffects();
  const [appraiserPhoneNumber, setAppraiserPhoneNumber] = useState<
    string | null
  >();
  const [appraiserRegistrationToken, setAppraiserRegistrationToken] = useState<
    string | null
  >();
  const { mutate, isLoading, error } = useMutation<
    LoginResponse,
    Error,
    LoginRequest
  >(loginAppraiser, {
    onSuccess: (response: LoginResponse) => {
      if (response.newDeviceLoginDetected) {
        setShowNewDeviceDialog(true);
        setAppraiserPhoneNumber(response.appraiser.phoneNumber);
        setAppraiserRegistrationToken(response.registrationToken);
      } else {
        onAuthSuccess(response.appraiser);
      }
    },
  });

  return (
    <FormContainer onSubmit={methods.handleSubmit((data) => mutate(data))}>
      <Typography variant="h1" textStyle={{ textAlign: "center" }}>
        {t("login.title")}
      </Typography>
      <ErrorAlert error={error} />
      <FormProvider {...methods}>
        <FormInput
          label={t("login.email")}
          name="email"
          variant="text"
          maxWidth={700}
        />
        <FormInput
          label={t("login.password")}
          name="password"
          variant="password"
          maxWidth={700}
        />
      </FormProvider>
      <MUILoadingButton loading={isLoading} type="submit">
        {t("login.loginButton")}
      </MUILoadingButton>

      <Link
        style={{ alignSelf: "center", marginTop: "30px" }}
        href="https://docs.valuations.lt"
        target="_blank"
        underline="none"
      >
        {t("login.aboutProduct")}
      </Link>

      {showNewDeviceDialog && (
        <AppraiserVerificationDialog
          title={t("login.newDeviceVerification")}
          appraiserPhoneNumber={appraiserPhoneNumber ?? ""}
          open={showNewDeviceDialog}
          onClose={() => setShowNewDeviceDialog(false)}
          onVerified={() => {
            setShowNewDeviceDialog(false);
            methods.handleSubmit((data) => mutate(data))();
          }}
          token={appraiserRegistrationToken ?? ""}
        />
      )}
    </FormContainer>
  );
};
