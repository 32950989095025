import React, { useState } from "react";
import { MonthPicker } from "./MonthPicker";
import { MonthDate } from "./types";
import { getMaxDate } from "./helpers";
import { MonthLabel } from "./MonthLabel";
import { DatesSelectButton } from "./DatesSelectButton";
import { Box, Popper } from "@mui/material";
import colors from "common/styles/colors";
import { Divider } from "common/components/listItems/Divider";
import { Button } from "common/components/buttons/Button";
import { OutlinedButton } from "common/components/buttons/OutlinedButton";
import { IconButton } from "common/components/buttons/IconButton";
import CloseIcon from "../../assets/icons/close.svg";
import { MonthRangePreset } from "./MonthRangePreset";
import { useTranslation } from "react-i18next";

interface MonthRangePickerProps {
  onDatesSelect?: (start: MonthDate, end: MonthDate) => void;
  selectedDatesInFilter?: { start: MonthDate; end: MonthDate };
  customPresets?: number[];
}

export const MonthRangePicker: React.FC<MonthRangePickerProps> = ({
  onDatesSelect,
  selectedDatesInFilter,
  customPresets,
}) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [selectedStartDate, setSelectedStartDate] = useState<MonthDate>();
  const [selectedEndDate, setSelectedEndDate] = useState<MonthDate>();

  const defaultLeftYear = new Date().getFullYear() - 1;
  const defaultRightYear = new Date().getFullYear();

  const [leftYear, setLeftYear] = useState(defaultLeftYear);
  const [rightYear, setRightYear] = useState(defaultRightYear);

  const setDefaultValues = () => {
    setSelectedEndDate(undefined);
    setSelectedStartDate(undefined);
    setLeftYear(defaultLeftYear);
    setRightYear(defaultRightYear);
  };

  const selectedDates = [selectedStartDate, selectedEndDate].filter(
    (x) => x !== undefined
  ) as MonthDate[];

  const maxDate: MonthDate = {
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  };

  const onDateSelect = (date: MonthDate) => {
    if (!!selectedStartDate && !!selectedEndDate) {
      setSelectedStartDate(date);
      setSelectedEndDate(undefined);
    } else if (!!selectedStartDate) {
      setSelectedEndDate(date);
    } else {
      setSelectedStartDate(date);
    }
  };

  const isSelectingEndDate = !!selectedStartDate && !selectedEndDate;
  const minDate = isSelectingEndDate ? selectedStartDate : undefined;

  let rightMinDate = { month: 1, year: leftYear + 1 };
  if (!!minDate) {
    rightMinDate = getMaxDate(rightMinDate, minDate);
  }

  let leftMaxDate = {
    month: 12,
    year: rightYear - 1,
  };

  const onClose = () => {
    setDefaultValues();
    setOpen(false);
  };

  const setSelectedDates = (startDate: MonthDate, endDate: MonthDate) => {
    setSelectedStartDate(startDate);
    setSelectedEndDate(endDate);
    let startYear = startDate.year;
    let endYear = endDate.year;
    if (startYear === endYear) {
      if (startYear === new Date().getFullYear()) {
        startYear--;
      } else {
        endYear++;
      }
    }
    setLeftYear(startYear);
    setRightYear(endYear);
  };

  const onOpen = () => {
    if (!!selectedDatesInFilter) {
      setSelectedDates(selectedDatesInFilter.start, selectedDatesInFilter.end);
    }
    setOpen(true);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          marginBottom: "20px",
        }}
      >
        {(customPresets ?? [3, 6, 12]).map((months) => (
          <MonthRangePreset
            key={months}
            monthLength={months}
            onSelect={(start, end) => {
              onDatesSelect?.(start, end);
              setOpen(false);
            }}
            selectedDatesInFilter={selectedDatesInFilter}
          />
        ))}
      </div>
      <DatesSelectButton
        onClick={(event) => {
          if (open) onClose();
          else {
            setAnchorEl(event.currentTarget);
            onOpen();
          }
        }}
        selectedDates={selectedDatesInFilter}
      />
      <Popper open={open} anchorEl={anchorEl} style={{ zIndex: 10000 }}>
        <Box
          sx={{
            backgroundColor: colors.white,
            boxShadow: 3,
            padding: "20px",
            borderRadius: "5px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "start",
              justifyContent: "space-between",
              gap: "40px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <MonthLabel date={selectedStartDate} />
              -
              <MonthLabel date={selectedEndDate} />
            </div>
            <IconButton
              onPress={() => {
                onClose();
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <Divider style={{ marginVertical: "15px" }} />
          <div style={{ display: "flex", flexDirection: "row", gap: "50px" }}>
            <MonthPicker
              selectedDates={selectedDates}
              onYearChange={(year) => setLeftYear(year)}
              year={leftYear}
              minDate={minDate}
              maxDate={leftMaxDate}
              onDateSelect={onDateSelect}
            />
            <MonthPicker
              selectedDates={selectedDates}
              onYearChange={(year) => setRightYear(year)}
              year={rightYear}
              maxDate={maxDate}
              minDate={rightMinDate}
              onDateSelect={onDateSelect}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
              gap: "15px",
              marginTop: "20px",
            }}
          >
            <OutlinedButton
              style={{ paddingHorizontal: 15 }}
              onPress={() => {
                setDefaultValues();
              }}
            >
              {t("datepicker.buttons.clear")}
            </OutlinedButton>
            <Button
              backgroundColor="purple2"
              textStyle={{ color: colors.white }}
              style={{ padding: 15 }}
              disabled={!selectedStartDate || !selectedEndDate}
              onPress={() => {
                if (!selectedStartDate || !selectedEndDate) {
                  return;
                }
                onDatesSelect?.(selectedStartDate, selectedEndDate);
                setOpen(false);
              }}
            >
              {t("datepicker.buttons.select")}
            </Button>
          </div>
        </Box>
      </Popper>
    </div>
  );
};
