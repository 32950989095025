import colors from "common/styles/colors";
import React from "react";
import { ComparablesCountOverviewRow } from "./ComparablesCountOverviewRow";
import { ComparablesCountOverviewWrapper } from "./ComparablesCountOverviewWrapper";
import { PercentageBar } from "./PercentageBar";
import { usePropertyIconsProps } from "../../hooks/propertyIcons.hooks";
import UnlockedIcon from "../../assets/icons/comparableResults/unlocked.svg";
import LockedBlueIcon from "../../assets/icons/comparableResults/lockedBlue.svg";
import LockedWhiteIcon from "../../assets/icons/comparableResults/lockedWhite.svg";
import { PriceFilter } from "./PriceFilter";
import { Button } from "@mui/material";
import { BuyTransactionsButton } from "./BuyTransactionsButton";
import { TransactionsFilteringData } from "../TransactionsRCProvider";
interface ComparablesCountOverviewProps {
  fullTransactionsCount: number;
  baseTransactionsCount: number;
  filteringData: TransactionsFilteringData;
}

export const ComparablesCountOverview: React.FC<
  ComparablesCountOverviewProps
> = ({ fullTransactionsCount, baseTransactionsCount, filteringData }) => {
  const allCount =
    fullTransactionsCount +
    baseTransactionsCount +
    filteringData.failedGeocodingCount +
    filteringData.multipleObjectsCount;

  const otherTransactions = [
    {
      label: "geokodavimo klaidos",
      count: filteringData.failedGeocodingCount,
    },
    {
      label: "kelių objektų sandoriai",
      count: filteringData.multipleObjectsCount,
      caption:
        "Tai sandoriai su keliais pagrindiniais objektais. Pvz. dviem butais",
    },
  ];

  return (
    <ComparablesCountOverviewWrapper
      title="RC sandorių rezultatai:"
      noBackground
    >
      <PercentageBar
        items={[
          {
            icon: <UnlockedIcon />,
            value: fullTransactionsCount / allCount,
            color: colors.purple3,
          },
          {
            icon: <LockedBlueIcon />,
            value: baseTransactionsCount / allCount,
            color: colors.white,
          },
          ...otherTransactions.map((transaction) => ({
            icon: <LockedWhiteIcon />,
            value: transaction.count / allCount,
            color: colors.beige,
          })),
        ]}
      />
      <ComparablesCountOverviewRow
        label="įsigyti"
        count={fullTransactionsCount}
        allCount={allCount}
        icon={<UnlockedIcon />}
        iconBackgroundColor={colors.purple3}
      />
      <PriceFilter />
      <ComparablesCountOverviewRow
        label="neįsigyti"
        count={baseTransactionsCount}
        allCount={allCount}
        icon={<LockedBlueIcon />}
        iconBackgroundColor={colors.white}
        iconBorder={`1px solid ${colors.grey}`}
      >
        <BuyTransactionsButton transactionsToBuy={3} />
      </ComparablesCountOverviewRow>
      {otherTransactions.map((transaction) => (
        <ComparablesCountOverviewRow
          key={transaction.label}
          label={transaction.label}
          count={transaction.count}
          allCount={allCount}
          tooltipText={transaction.caption}
          icon={<LockedWhiteIcon />}
          iconBackgroundColor={colors.beige}
        />
      ))}
    </ComparablesCountOverviewWrapper>
  );
};
