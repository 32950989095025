import React from "react";
import { InfoRow } from "../propertiesCRUD/InfoRow";
import { PropertyComment } from "../../types/api.types";
import { getLithuanianDateString } from "common/helpers/dateStringHelpers";
import { useTranslation } from "react-i18next";

interface PropertyCommentContentProps {
  data: PropertyComment;
}

export const PropertyCommentContent: React.FC<PropertyCommentContentProps> = ({
  data,
}) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <InfoRow
        label={t("propertyComment.date")}
        value={getLithuanianDateString(data.updatedAt)}
        customLabelWidth="100px"
      />
      <InfoRow
        label={t("propertyComment.comment")}
        value={data.comment}
        customLabelWidth="100px"
      />
    </div>
  );
};
