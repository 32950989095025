import { CircleIconButton } from "common/components/buttons/CircleIconButton";
import React, { useState } from "react";
import InfoSignIcon from "../../../assets/icons/infoSign.svg";
import { Popover } from "@mui/material";
import { MapLegend } from "./MapLegend";

interface LegendItem {
  icon: React.ReactElement;
  label: string;
}

interface MapInfoProps {
  legendItems: LegendItem[];
}

export const MapInfo: React.FC<MapInfoProps> = ({ legendItems }) => {
  const [open, setOpen] = useState(false);
  const divRef = React.useRef<HTMLDivElement>(null);

  return (
    <>
      <div ref={divRef}>
        <CircleIconButton onPress={() => setOpen(true)}>
          <InfoSignIcon />
        </CircleIconButton>
      </div>
      <Popover
        open={open}
        anchorEl={divRef.current}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <MapLegend items={legendItems} />
      </Popover>
    </>
  );
};
