import { alpha } from "@mui/material/styles";
import React from "react";

interface ValuationZoneIconProps {
  color: string;
}

export const ValuationZoneIcon: React.FC<ValuationZoneIconProps> = ({
  color,
}) => {
  return (
    <div
      style={{
        width: "32px",
        height: "30px",
        border: `2px solid ${color}`,
        borderRadius: "2px",
        backgroundColor: alpha(color, 0.2),
      }}
    />
  );
};
