import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { usePricePredictions } from "../../hooks/pricePredictions.hooks";
import { ComparableCard } from "./ComparableCard";
import { Comparable } from "common/types/common.types";
import { IconButton } from "common/components/buttons/IconButton";
import BackwardIcon from "../../assets/icons/arrowBackward.svg";
import { Typography } from "common/components/Typography";
import ForwardIcon from "../../assets/icons/arrowForward.svg";
import colors from "common/styles/colors";
import { useMessagesStatus } from "../messages/hooks";
import { isComparableFull } from "../../helpers/comparable.helpers";
import { TransactionsBuyConfirmationModal } from "../transactions/TransactionsBuyConfirmationModal";

interface ComparableCardWithPricePredictionsProps {
  comparables: Comparable[];
  selectedCompId?: string;
  onClick?: () => void;
  onSelectedComparableChange?: (compId: string) => void;
}

export const ComparableCardWithPricePredictions: React.FC<
  ComparableCardWithPricePredictionsProps
> = ({ comparables, selectedCompId, onClick, onSelectedComparableChange }) => {
  const { propertyId } = useParams();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    isComparableInCart,
    addComparableToCart,
    removeComparableFromCart,
    getComparableAdjustments,
  } = usePricePredictions(propertyId ?? "");

  const { data: messagesStatus } = useMessagesStatus();

  const selectedComparable =
    comparables.find(
      (comp) => comp.comparable_transaction.id === selectedCompId
    ) ?? comparables[0];

  if (!selectedComparable) {
    return null;
  }

  const adjustments = getComparableAdjustments(selectedComparable);

  const compId = selectedComparable.comparable_transaction.id;

  const isInCart = isComparableInCart(compId);
  const isCorrected = adjustments?.is_corrected;

  const status = messagesStatus?.[compId];

  const getCurrentComparableIndex = () => {
    return comparables.findIndex(
      (comp) =>
        comp.comparable_transaction.id ===
        selectedComparable.comparable_transaction.id
    );
  };

  const onComparablesNavigate = (toAdd: number) => {
    const currentIndex = getCurrentComparableIndex();
    const nextIndex =
      (currentIndex + toAdd + comparables.length) % comparables.length;
    onSelectedComparableChange?.(
      comparables[nextIndex].comparable_transaction.id
    );
  };

  return (
    <>
      <ComparableCard
        comparable={selectedComparable}
        onComparableAdd={() => {
          if (
            localStorage.getItem("doNotShowTransactionsBuyConfirmationModal") ||
            isComparableFull(selectedComparable)
          ) {
            addComparableToCart({ ...selectedComparable, adjustments });
            return;
          }
          setIsModalOpen(true);
        }}
        onComparableRemove={() => {
          removeComparableFromCart(compId);
        }}
        isInCart={isInCart}
        isCorrected={isCorrected}
        onClick={onClick}
        adjustments={adjustments}
        status={status}
      >
        {comparables.length > 1 && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "end",
              backgroundColor: colors.greybg,
              borderBottomRightRadius: "10px",
            }}
          >
            <IconButton
              onPress={() => {
                onComparablesNavigate(-1);
              }}
            >
              <BackwardIcon />
            </IconButton>
            <Typography variant="caption" textStyle={{ fontSize: 12 }}>{`${
              getCurrentComparableIndex() + 1
            }/${comparables.length} sandoris`}</Typography>
            <IconButton
              onPress={() => {
                onComparablesNavigate(1);
              }}
            >
              <ForwardIcon />
            </IconButton>
          </div>
        )}
      </ComparableCard>

      <TransactionsBuyConfirmationModal
        isModalOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={(doNotShowAgain) => {
          if (doNotShowAgain) {
            localStorage.setItem(
              "doNotShowTransactionsBuyConfirmationModal",
              "true"
            );
          }
          addComparableToCart({ ...selectedComparable, adjustments });
          setIsModalOpen(false);
        }}
      />
    </>
  );
};
