import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { DrawingManagerF, GoogleMap } from "@react-google-maps/api";
import {
  ComparableFilterType,
  getBoundsFilterFunction,
  getPolygonFilterFunction,
} from "../../helpers/comparableFilters.helpers";
import { ComparablesFiltersContext } from "../ComparablesFilterProvider";
import { MapActionButton } from "./MapActionButton";
import { MapListInteractionsContext } from "../MapListInteractionsProvider";
import { ComparableIconOverlayView } from "./ComparableIconOverlayView";
import { AppraiserProperty } from "../../types/appraiser.types";
import { ComparableMarkerWithPricePredictions } from "./ComparableMarkerWithPricePredictions";
import { MY_PROPERTY_SIZE_MULTIPLIER, mapStyle } from "./constants";
import { CircleIconButton } from "common/components/buttons/CircleIconButton";
import PropertyIcon from "../../assets/icons/property.svg";
import { PropertyIcon as PropertyMapIcon } from "common/components/map/PropertyIcon";
import { useComparablesData } from "../../hooks/property.hooks";
import { BulkBuyButton } from "./BulkBuyButton";
import { MapInfo } from "./legend/MapInfo";
import {
  mainPropertyBGColor,
  usePropertyIconsProps,
} from "../../hooks/propertyIcons.hooks";
import { BulkDownloadButton } from "./BulkDownloadButton";
import { TransactionsRCContext } from "../TransactionsRCProvider";
import AreaSelectIcon from "../../assets/icons/areaSelect.svg";
import RemoveSelectionIcon from "../../assets/icons/removeSelection.svg";
import { ClusteredComparablesMapControlContext } from "../ClusteredGenericMapControlProvider";
import { ClusterOverlayView } from "./ClusterOverlayView";
import OkCheckIcon from "common/assets/icons/okCheck.svg";

interface ComparablesMapProps {
  property: AppraiserProperty;
  // cursor: number;
}

export const ComparablesMap: React.FC<ComparablesMapProps> = ({
  property,
  // cursor,
}) => {
  const clustering = useContext(ClusteredComparablesMapControlContext);

  const { dataCombined: comparables, comparablesInCart } = useComparablesData();

  const { getFilteredComparables, addFilter, getAppliedFilter, removeFilter } =
    useContext(ComparablesFiltersContext);

  const filteredComparables = useMemo(() => {
    return (
      getFilteredComparables?.(comparables, [ComparableFilterType.Bounds]) ?? []
    );
  }, [getFilteredComparables, comparables]);

  const filteredComparablesWithCart = useMemo(() => {
    const allComparables = [...filteredComparables];

    const compsInCart =
      getFilteredComparables?.(comparablesInCart, [
        ...Object.values(ComparableFilterType).filter(
          (f) => f !== ComparableFilterType.Polygon
        ),
      ]) ?? [];

    for (const comp of compsInCart) {
      if (
        !allComparables.some(
          (fComp) =>
            fComp.comparable_transaction.id.toString() ===
            comp.comparable_transaction.id.toString()
        )
      ) {
        allComparables.push(comp);
      }
    }
    return allComparables;
  }, [getFilteredComparables, filteredComparables, comparablesInCart]);

  const { searchCount } = useContext(TransactionsRCContext);

  const { getIcon, getIconBGColor } = usePropertyIconsProps();

  useEffect(() => {
    if (!clustering) return;
    const remainingCompsInCart = comparablesInCart.filter(
      (comp) =>
        !filteredComparables.some(
          (fComp) =>
            fComp.comparable_transaction.id.toString() ===
            comp.comparable_transaction.id.toString()
        )
    );
    const items = [...remainingCompsInCart, ...filteredComparables].map(
      (comparable) => ({
        data: comparable,
        entityCount: 1,
        id: comparable.comparable_transaction.id.toString(),
        lat: comparable.comparable_transaction.location.lat,
        lng: comparable.comparable_transaction.location.lng,
      })
    );
    clustering.setClusterableItems(items);
  }, [
    clustering?.setClusterableItems,
    filteredComparables,
    comparablesInCart,
    property.lat,
    property.lng,
  ]);

  const onBoundsChanged = (bounds: google.maps.LatLngBounds) => {
    addFilter?.({
      type: ComparableFilterType.Bounds,
      filterFunction: getBoundsFilterFunction(bounds),
      values: bounds,
    });
  };

  useEffect(() => {
    if (!clustering?.mapRef.current) return;
    clustering.calculateAndSetMapBounds([
      ...clustering.clusterableItems,
      { lat: property.lat, lng: property.lng },
    ]);
    clustering.onLoad(clustering.mapRef.current); // to update markers
  }, [searchCount, clustering?.mapRef.current]);

  const [isHovered, setIsHovered] = useState(false);

  const [selectedDrawingMode, setSelectedDrawingMode] =
    useState<google.maps.drawing.OverlayType | null>(null);

  const selectedPolygons =
    getAppliedFilter?.(ComparableFilterType.Polygon)?.values ?? [];

  const { setOnListComparableClickHandler } = useContext(
    MapListInteractionsContext
  );

  useEffect(() => {
    setOnListComparableClickHandler?.((comp) => {
      if (!clustering?.mapRef.current || !clustering) return;
      clustering.panTo(comp.comparable_transaction.location, {
        isZoomInto: true,
      });
      clustering.setSelectedItemId(comp.comparable_transaction.id.toString());
    });
  }, [
    clustering?.panTo,
    clustering?.setSelectedItemId,
    clustering?.mapRef.current,
  ]);

  const onMouseEnter = useCallback(() => {
    setIsHovered(true);
  }, [setIsHovered]);

  const onMouseLeave = useCallback(() => {
    setIsHovered(false);
  }, [setIsHovered]);

  if (!clustering) {
    return null;
  }

  const legendItems = [
    {
      icon: <PropertyMapIcon backgroundColor={mainPropertyBGColor} />,
      label: "vertinamasis objektas",
    },
    {
      icon: (
        <PropertyMapIcon
          customIcon={getIcon("notFull")}
          backgroundColor={getIconBGColor("notFull")}
        />
      ),
      label: "neįsigytas sandoris",
    },
    {
      icon: (
        <PropertyMapIcon
          customIcon={getIcon("full")}
          backgroundColor={getIconBGColor("full")}
        />
      ),
      label: "įsigytas sandoris",
    },
    {
      icon: (
        <PropertyMapIcon
          customIcon={getIcon("inCart")}
          backgroundColor={getIconBGColor("inCart")}
        />
      ),
      label: "įsigytas ir įtrauktas į kainos skaičiavimą sandoris",
    },
    {
      icon: <OkCheckIcon />,
      label: "ikonėlė rodanti, kad sandoris identifikuotas",
    },
  ];

  return (
    <GoogleMap
      options={{
        fullscreenControl: false,
        clickableIcons: false,
        disableDoubleClickZoom: isHovered,
        styles: mapStyle,
        streetViewControlOptions: {
          position: google.maps.ControlPosition.RIGHT_CENTER,
        },
        cameraControlOptions: {
          position: google.maps.ControlPosition.RIGHT_CENTER,
        },
      }}
      mapContainerStyle={{
        height: "100%",
        width: "100%",
      }}
      onBoundsChanged={() =>
        clustering.onBoundsChangedDebounce(onBoundsChanged)
      }
      zoom={15}
      onClick={clustering.onMapClick}
      onLoad={clustering.onLoad}
    >
      <DrawingManagerF
        onPolygonComplete={(polygon) => {
          polygon.addListener("click", clustering.onMapClick);
          const newPolygonsArray = [...selectedPolygons, polygon];
          addFilter?.({
            type: ComparableFilterType.Polygon,
            filterFunction: getPolygonFilterFunction(newPolygonsArray),
            values: newPolygonsArray,
          });
          setSelectedDrawingMode(null);
        }}
        options={{
          drawingControl: false,
          drawingMode: selectedDrawingMode,
        }}
      />
      <ComparableIconOverlayView
        lat={property.lat}
        lng={property.lng}
        backgroundColor={mainPropertyBGColor}
        sizeMultiplier={MY_PROPERTY_SIZE_MULTIPLIER}
      />
      {clustering.itemsOnMap.map((groupComps) => (
        <ComparableMarkerWithPricePredictions
          key={`${groupComps[0].data.comparable_transaction.id}`}
          comparables={groupComps.map((group) => group.data)}
          onCompSelect={clustering.setSelectedItemId}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          selectedCompId={
            clustering.selectedItemMeta.item
              ? clustering.selectedItemMeta.id
              : undefined // dont show card untill selected item icon appears on map
          }
        />
      ))}

      {clustering.clustersOnMap.map((cluster) => (
        <ClusterOverlayView
          key={cluster.id}
          cluster={cluster}
          onClusterPress={clustering.onClusterPress}
        />
      ))}

      <div
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          display: "flex",
          flexDirection: "row",
          gap: "10px",
        }}
      >
        <MapInfo legendItems={legendItems} />
      </div>
      <div
        style={{
          position: "absolute",
          right: "10px",
          bottom: "175px",
        }}
      >
        <CircleIconButton
          onPress={() => {
            if (!clustering.initialBoundsRef.current) {
              return;
            }
            clustering.mapRef.current?.fitBounds(
              clustering.initialBoundsRef.current
            );
          }}
        >
          <PropertyIcon />
        </CircleIconButton>
      </div>
      <div
        style={{
          position: "absolute",
          left: "50%",
          bottom: "20px",
          transform: "translateX(-50%)",
          display: "flex",
          gap: "15px",
        }}
      >
        {!!selectedDrawingMode && (
          <MapActionButton
            onClick={() => {
              setSelectedDrawingMode(null);
            }}
            title="Atšaukti"
          />
        )}
        {!selectedDrawingMode && (
          <MapActionButton
            onClick={() => {
              setSelectedDrawingMode(google.maps.drawing.OverlayType.POLYGON);
            }}
            icon={<AreaSelectIcon />}
            title="Žymėti plotą"
          />
        )}
        {!!selectedPolygons.length && (
          <>
            <MapActionButton
              title="Naikinti žymėjimą"
              icon={<RemoveSelectionIcon />}
              onClick={() => {
                for (const polygon of selectedPolygons) {
                  polygon.setMap(null);
                }
                removeFilter?.(ComparableFilterType.Polygon);
              }}
            />
            <BulkBuyButton filteredComparables={filteredComparables} />
            <BulkDownloadButton
              filteredComparables={filteredComparablesWithCart}
            />
          </>
        )}
      </div>
    </GoogleMap>
  );
};
