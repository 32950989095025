import React from "react";
import { MUILoadingButton } from "../../components/buttons/MUILoadingButton";
import { useTranslation } from "react-i18next";

interface SendVerificationCodeButtonProps {
  loading: boolean;
  disabled: boolean;
  onClick: () => void;
}

export const SendVerificationCodeButton: React.FC<
  SendVerificationCodeButtonProps
> = ({ loading, disabled, onClick }) => {
  const { t } = useTranslation();

  return (
    <MUILoadingButton
      variant="contained"
      loading={loading}
      onClick={onClick}
      disabled={disabled}
      sx={{ marginTop: "40px" }}
    >
      {t("verification.getConfirmationCode")}
    </MUILoadingButton>
  );
};
