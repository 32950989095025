import React from "react";
import { MenuItem, ListItemIcon, ListItemText } from "@mui/material";

interface MenuItemWithIconProps {
  icon: React.ReactNode;
  label: string;
  onClick: () => void;
  disabled?: boolean;
  color?: string;
}

export const MenuItemWithIcon: React.FC<MenuItemWithIconProps> = ({
  icon,
  label,
  onClick,
  disabled = false,
  color,
}) => {
  return (
    <MenuItem onClick={onClick} disabled={disabled} style={{
      gap: "10px",
      marginTop: "10px",
      marginBottom: "10px",
    }}>
      <ListItemIcon style={{ color: color || "inherit" }}>{icon}</ListItemIcon>
       <ListItemText style={{ color }}>{label}</ListItemText>
    </MenuItem>
  );
};
