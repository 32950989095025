import { PropertiesList } from "./components/PropertiesList";
import { Typography } from "common/components/Typography";
import { Button } from "@mui/material";
import { LinkForButton } from "./components/links/LinkForButton";
import { useAppraiser } from "./hooks/auth.hooks";
import { AppraiserRole } from "./types/appraiser.types";
import { useTranslation } from "react-i18next";

function App() {
  const { t } = useTranslation();
  const { data: appraiser, isLoading } = useAppraiser();

  if (isLoading) {
    return null;
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <Typography variant="h1">{t("propertyList.title")}</Typography>
        {appraiser?.role !== AppraiserRole.SPECTATOR && (
          <LinkForButton to="/properties/create/inputMethod">
            <Button variant="contained">
              {t("propertyList.createValuation")}
            </Button>
          </LinkForButton>
        )}
      </div>
      <PropertiesList />
    </div>
  );
}

export default App;
