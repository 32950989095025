import React from "react";
import { useTranslation } from "react-i18next";
import {
  useAppraisersInOrganizationCredits,
  useOrganizationUsedCredits,
} from "../../hooks/auth.hooks";
import { PaymentRow } from "./PaymentRow";
import { OrganizationCredits } from "./OrganizationCredits";
import colors from "common/styles/colors";
import { Typography } from "common/components/Typography";
import { Divider } from "common/components/listItems/Divider";
import { TableComponent } from "../../components/tables/TableComponent";
import { SimpleTableComponent } from "../../components/tables/simpleTable/SimpleTableComponent";
import { SimpleTableRow } from "../../components/tables/simpleTable/SimpleTableRow";
import { SimpleTableHeader } from "../../components/tables/simpleTable/SimpleTableHeader";
import { useRouteQuery, useUpdateQueryParam } from "../../hooks/router.hooks";
import { Tab, Tabs } from "@mui/material";
import { PaymentsLimits } from "./PaymentsLimits";
import { PaymentsStatistics } from "./PaymentsStatistics";

interface PaymentsRouteProps {}

export const PaymentsRoute: React.FC<PaymentsRouteProps> = () => {
  const { t } = useTranslation();

  const tabs = [
    {
      value: "limits",
      label: t("payments.tabs.limits"),
    },
    {
      value: "statistics",
      label: t("payments.tabs.statistics"),
    },
  ];

  const query = useRouteQuery();
  const paymentsType = query.get("type") ?? "limits";

  const { updateQueryParam } = useUpdateQueryParam();

  return (
    <div>
      <Tabs
        value={paymentsType}
        onChange={(_, value) => {
          updateQueryParam("type", value);
        }}
        style={{ marginBottom: "20px" }}
      >
        {tabs.map((tab) => (
          <Tab key={tab.value} value={tab.value} label={tab.label} />
        ))}
      </Tabs>
      <div
        style={{
          backgroundColor: colors.white,
          padding: "20px",
          borderRadius: "10px",
        }}
      >
        {paymentsType === "limits" && <PaymentsLimits />}
        {paymentsType === "statistics" && <PaymentsStatistics />}
      </div>
    </div>
  );
};
