import React from "react";
import {
  ComparableIconOverlayView,
  ComparableIconOverlayViewProps,
} from "./ComparableIconOverlayView";
import { PropertyIconProps } from "common/components/map/PropertyIcon";
import { CardOverlayView } from "./CardOverlayView";

interface ComparableMarkerProps {
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  isSelected: boolean;
  markerProps: ComparableIconOverlayViewProps & PropertyIconProps;
  children?: React.ReactNode;
  xOffSet?: number;
}

export const ComparableMarker: React.FC<ComparableMarkerProps> = ({
  isSelected,
  onMouseEnter,
  onMouseLeave,
  children,
  markerProps,
  xOffSet,
}) => {
  return (
    <>
      <ComparableIconOverlayView {...markerProps} xOffSet={xOffSet} />
      {isSelected && (
        <CardOverlayView
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          markerProps={markerProps}
          xOffSet={xOffSet}
          children={children}
        />
      )}
    </>
  );
};
