import { GoogleMap, OverlayViewF } from "@react-google-maps/api";
import { useState, useCallback, useContext } from "react";
import {
  ClusteredGenericMapControlContextProps,
  ClusteredItem,
  ClusterItem,
} from "../ClusteredGenericMapControlProvider";
import { Box } from "@mui/material";
import {
  CardPagination,
  CardPaginationProps,
} from "../pagination/CardPagination";
import { ClusterOverlayView } from "./ClusterOverlayView";
import { mapStyle } from "./constants";

export interface SelectedItemBoxProps<T> {
  item: ClusterItem<T>;
  index: number;
  maxIndex: number;
  paginatorNode: ReturnType<React.FC<CardPaginationProps>>;
}

interface ClusteredGenericMapProps<T> {
  context: React.Context<ClusteredGenericMapControlContextProps<T> | undefined>;
  /** items that reside at equal coords. If only one property is at given coords, array length will be 1 item */
  displayItemGroupIcon: (itemGroup: ClusterItem<T>[]) => React.ReactNode;
  /** items with different coords combined into one cluster */
  displayClusterIcon?: (cluster: ClusteredItem<T>) => React.ReactNode;
  onItemGroupClick?: (itemGroup: ClusterItem<T>[]) => void;
  SelectedItemBox: (
    props: SelectedItemBoxProps<T>
  ) => React.ReactElement | null;
  itemGroupIconXOffset?: number;
  paginatorSettings?: Partial<CardPaginationProps>;
}

export function ClusteredGenericMap<T>({
  context,
  displayItemGroupIcon,
  displayClusterIcon,
  onItemGroupClick,
  SelectedItemBox,
  itemGroupIconXOffset,
  paginatorSettings
}: ClusteredGenericMapProps<T>): JSX.Element | null {
  const clustering = useContext(context);
  const [isHovered, setIsHovered] = useState(false);

  const onMouseEnter = useCallback(() => {
    setIsHovered(true);
  }, [setIsHovered]);

  const onMouseLeave = useCallback(() => {
    setIsHovered(false);
  }, [setIsHovered]);

  if (!clustering) return null;

  const selection = clustering.selectedItemMeta;

  return (
    <>
      <GoogleMap
        options={{
          fullscreenControl: true,
          clickableIcons: false,
          disableDoubleClickZoom: isHovered,
          gestureHandling: "greedy",
          styles: mapStyle,
        }}
        mapContainerStyle={{
          height: "100%",
          width: "100%",
        }}
        onBoundsChanged={clustering.onBoundsChangedDebounce}
        zoom={15}
        onClick={clustering.onMapClick}
        onLoad={clustering.onLoad}
      >
        {/* item groups */}
        {clustering.itemsOnMap.map((itemStack, i) => (
          <OverlayViewF
            key={`${i}-${itemStack[0].id}`}
            mapPaneName="overlayMouseTarget"
            position={{
              lat: itemStack[0].lat,
              lng: itemStack[0].lng,
            }}
            getPixelPositionOffset={(width, height) => ({
              x: -width / 2 + (itemGroupIconXOffset ?? 0),
              y: -height / 2,
            })}
          >
            <div
              onClick={(e) => {
                e.stopPropagation();
                onItemGroupClick?.(itemStack);
                clustering.setSelectedItemId(itemStack[0].id);
              }}
              style={{ cursor: "pointer" }}
            >
              {displayItemGroupIcon(itemStack)}
            </div>
          </OverlayViewF>
        ))}
        {/* selected item */}
        {!!selection.item && (
          <OverlayViewF
            key={selection.id}
            mapPaneName="floatPane"
            position={{ lat: selection.item.lat, lng: selection.item.lng }}
            getPixelPositionOffset={(width) => ({
              x: -width / 2 + (itemGroupIconXOffset ?? 0),
              y: 15,
            })}
          >
            <Box
              sx={{
                maxWidth: "490px",
                boxShadow: 5,
                borderRadius: "10px",
              }}
              onClick={(e) => e.stopPropagation()}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            >
              <SelectedItemBox
                index={selection.indexInGroup}
                item={selection.item}
                maxIndex={selection.group.length}
                paginatorNode={
                  selection.group.length > 1 ? (
                    <CardPagination
                      currentPage={selection.indexInGroup + 1}
                      maxPage={selection.group.length}
                      onPageRelativeChange={
                        clustering.selectRelativeItemByOffset
                      }
                      {...paginatorSettings}
                    />
                  ) : null
                }
              />
            </Box>
          </OverlayViewF>
        )}
        {/* clusters */}
        {clustering.clustersOnMap.map((cluster) => (
          <ClusterOverlayView
            key={cluster.id}
            cluster={cluster}
            onClusterPress={clustering.onClusterPress}
            displayClusterIcon={displayClusterIcon}
          />
        ))}
      </GoogleMap>
    </>
  );
}
