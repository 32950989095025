import React from 'react';
import { VerificationForm } from "./VerificationForm";
import { useTranslation } from "react-i18next";

interface AppraiserVerificationDialogProps {
  title: string;
  open: boolean;
  onClose: () => void;
  onVerified: () => void;
  token?: string;
  appraiserPhoneNumber?: string;
}

export const AppraiserVerificationDialog: React.FC<
  AppraiserVerificationDialogProps
> = ({ title, open, onClose, onVerified, token, appraiserPhoneNumber }) => {
  const { t } = useTranslation();

  return (
    <VerificationForm
      title={title || t("verification.accountConfirmation")}
      open={open}
      onClose={onClose}
      onVerified={onVerified}
      phoneNumber={appraiserPhoneNumber}
      token={token}
      buttonText={t("verification.confirm")}
      verificationInformation={t("verification.codeExplanation")}
    />
  );
};
