import { getListString } from "common/helpers/common.helpers";
import { getYearText } from "./comparable.helpers";
import { getFinishingLabel } from "../components/transactions/helpers";
import { AppraiserProperty, PropertyPurpose } from "../types/appraiser.types";
import i18n from "i18next";

interface PropertyFieldsForListString {
  rooms?: number | null;
  area?: number | null;
  areaTotal?: number | null;
  floorNr?: number | null;
  floorCount?: number | null;
  year?: number | null;
  renovationYear?: number | null;
  finishing?: boolean | null;
  energyClass?: string | null;
}

export const getPropertyListString = (fields: PropertyFieldsForListString) => {
  const t = i18n.t;

  return getListString([
    fields.area !== null &&
      fields.area !== undefined &&
      `${fields.area}${!!fields.areaTotal ? ` (${fields.areaTotal})` : ""} ${t(
        "property.units.sqm"
      )}`,
    fields.rooms !== null &&
      fields.rooms !== undefined &&
      `${fields.rooms} ${t("property.units.rooms")}`,
    fields.floorNr !== null && fields.floorNr !== undefined
      ? `${fields.floorNr}/${fields.floorCount} ${t("property.units.floor")}`
      : fields.floorCount !== null && fields.floorCount !== undefined
      ? `${fields.floorCount} ${t("property.units.floor")}`
      : undefined,
    fields.year !== null &&
      fields.year !== undefined &&
      getYearText(fields.year, fields.renovationYear),
    !!fields.energyClass && fields.energyClass,
    getFinishingLabel(fields.finishing, undefined),
  ]);
};

export const getPropertyValueString = (
  property: AppraiserProperty | undefined
) => {
  const t = i18n.t;

  if (property?.purpose === PropertyPurpose.Pledge) {
    return t("property.value.marketValue");
  }
  return t("property.value.possiblePrice");
};
