import React from "react";
import { useProperties } from "../hooks/property.hooks";
import { PropertyInList } from "./properties/PropertyInList";
import { PaginationComponent } from "./pagination/PaginationComponent";
import {
  PAGE_KEY,
  useAppraiserFilterQuery,
  usePropertyAssessmentStatusQuery,
  usePropertyTextFilterQuery,
  useRouteQuery,
  useUpdateQueryParam,
} from "../hooks/router.hooks";
import {
  Box,
  Button,
  MenuItem,
  Select,
  Stack,
  Tabs,
  Typography,
} from "@mui/material";
import { AppraiserFilter, PropertyAssessmentStatus } from "../types/api.types";
import { useAppraiser } from "../hooks/auth.hooks";
import { AppraiserRole } from "../types/appraiser.types";
import { PropertyListTab } from "./PropertyListTab";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import colors from "common/styles/colors";
import { AppraiserPropertiesMapDialog } from "./map/AppraiserPropertiesMapDialog";
import {
  ClusteredAppraiserPropertiesMapControlContext,
  ClusteredGenericMapControlProvider,
} from "./ClusteredGenericMapControlProvider";
import { TextFilterField } from "./filters/TextFilterField";
import { useTranslation } from "react-i18next";

interface PropertiesListProps {}

export const PropertiesList: React.FC<PropertiesListProps> = () => {
  const { t } = useTranslation();
  const status = usePropertyAssessmentStatusQuery();

  const { data } = useProperties();
  const { updateQueryParam } = useUpdateQueryParam();

  const appraiserFilter = useAppraiserFilterQuery();
  const textFilter = usePropertyTextFilterQuery();

  const mapStateKey = "openMap";
  const isOpenMap = useRouteQuery().get(mapStateKey) === "true";
  const setIsOpenMap = (newValue: boolean) => {
    updateQueryParam(
      mapStateKey,
      newValue ? "true" : "delme",
      newValue ? undefined : [mapStateKey],
      true // replace
    );
  };

  const { data: appraiser } = useAppraiser();

  const tabs = Object.values(PropertyAssessmentStatus).map((status) => ({
    value: status as PropertyAssessmentStatus,
    label: t(`propertyList.statusTabs.${status}`),
  }));

  const appraiserFilterOptions = [
    {
      label: t("propertyList.filters.allValuations"),
      value: AppraiserFilter.All,
    },
    {
      label: t("propertyList.filters.myValuations"),
      value: AppraiserFilter.My,
    },
  ];

  const filteredTabs =
    appraiser?.role === AppraiserRole.DATA_ENTRY
      ? tabs.filter((tab) => tab.value === PropertyAssessmentStatus.New)
      : tabs;

  /** search used + no results */
  const isResultlessSearch = (data?.data.length ?? 0) < 1 && !!textFilter;

  return (
    <div>
      {appraiser?.role !== AppraiserRole.DATA_ENTRY && (
        <Stack direction={"row"} spacing={1}>
          <Select value={appraiserFilter ?? ""}>
            {appraiserFilterOptions.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                onClick={() =>
                  updateQueryParam("appraiserFilter", option.value)
                }
              >
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </Stack>
      )}
      <Tabs
        value={status}
        style={{
          marginBottom: "20px",
        }}
      >
        {filteredTabs.map((tab) => (
          <PropertyListTab
            key={tab.value}
            value={tab.value}
            label={tab.label}
            onClick={() => {
              updateQueryParam("status", tab.value, [PAGE_KEY]);
            }}
          />
        ))}
      </Tabs>
      <Box paddingBottom="10px">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          paddingBottom="4px"
        >
          <TextFilterField />
          <Button
            color="primary"
            variant="outlined"
            startIcon={<MapOutlinedIcon />}
            onClick={() => setIsOpenMap(true)}
            disabled={isOpenMap || (data?.data.length ?? 0) < 1}
          >
            {t("propertyList.viewOnMap")}
          </Button>
          {isOpenMap && (
            <ClusteredGenericMapControlProvider
              context={ClusteredAppraiserPropertiesMapControlContext}
            >
              <AppraiserPropertiesMapDialog
                open={isOpenMap}
                onClose={() => setIsOpenMap(false)}
              />
            </ClusteredGenericMapControlProvider>
          )}
        </Stack>
        {isResultlessSearch && (
          <Typography variant="body2" sx={{ color: colors.grey }}>
            {t("propertyList.noSearchResults")}
          </Typography>
        )}
      </Box>
      {data?.data.map((property) => (
        <div style={{ marginBottom: "10px" }} key={property._id}>
          <PropertyInList property={property} />
        </div>
      ))}
      {data?.data.length === 0 && (
        <Typography
          style={{
            textAlign: "center",
            fontSize: "16px",
            padding: "20px 0px 20px 0",
            marginTop: "20px",
            color: colors.grey,
            backgroundColor: colors.white,
            borderRadius: "10px",
          }}
        >
          {t("propertyList.noResults")}
          {isResultlessSearch && t("propertyList.refineSearch")}
        </Typography>
      )}
      {!!data?.pagination && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <PaginationComponent pagination={data.pagination} />
        </div>
      )}
    </div>
  );
};
