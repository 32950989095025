import React, { useState } from "react";
import { useQuery } from "react-query";
import { useProperty } from "../../hooks/property.hooks";
import { getListingsWithParking } from "../../api/api";
import { AddonPriceInfo } from "./AddonPriceInfo";
import { AddonListingCard } from "./AddonListingCard";
import {
  ParkingType,
  ParkingTypeTranslations,
} from "common/types/common.types";
import { MenuItem, Select } from "@mui/material";
import { FieldLabel } from "common/components/form/FieldLabel";
import { CompositePresetType } from "../../types/api.types";

interface ParkingPriceInfoProps {
  propertyId: string;
  selectedType?: CompositePresetType;
}

export const ParkingPriceInfo: React.FC<ParkingPriceInfoProps> = ({
  propertyId,
  selectedType,
}) => {
  const { data: property } = useProperty(propertyId);

  const [selectedRadius, setSelectedRadius] = useState(3);

  const [selectedParkingType, setSelectedParkingType] = useState<
    ParkingType | ""
  >(() => {
    if (selectedType === CompositePresetType.ParkingOverground) {
      return ParkingType.Overground;
    } else if (selectedType === CompositePresetType.ParkingUnderground) {
      return ParkingType.Underground;
    }
    return "";
  });

  const { data, isLoading, isFetching } = useQuery(
    [
      "listings_with_parking",
      property?.lat,
      property?.lng,
      selectedRadius,
      selectedParkingType,
    ],
    () =>
      getListingsWithParking(
        property?.lat ?? 0,
        property?.lng ?? 0,
        selectedRadius * 1000,
        selectedParkingType === "" ? undefined : selectedParkingType
      ),
    {
      enabled: !!property,
      staleTime: Infinity,
      keepPreviousData: true,
    }
  );

  return (
    <AddonPriceInfo
      title="Parkavimo vietų kainos"
      data={data}
      isLoading={isLoading}
      isFetching={isFetching}
      listingFilterFn={(listing, priceMin, priceMax) => {
        return (
          (listing.parking_price ?? 0) >= priceMin &&
          (listing.parking_price ?? 0) <= priceMax
        );
      }}
      selectedRadius={selectedRadius}
      setSelectedRadius={setSelectedRadius}
      mapPriceFn={(listing) => listing.parking_price ?? 0}
      mapCardComponent={(listing, paginationNode, onClick) => (
        <AddonListingCard
          listing={listing}
          price={listing.parking_price ?? 0}
          caption={
            !!listing.parking_type
              ? ParkingTypeTranslations[listing.parking_type]
              : ""
          }
          onClick={onClick}
          paginationNode={paginationNode}
        />
      )}
      filtersComponent={
        <FieldLabel label="Parkingo tipas">
          <Select
            value={selectedParkingType}
            onChange={(e) =>
              setSelectedParkingType(e.target.value as ParkingType | "")
            }
            style={{
              width: "150px",
            }}
          >
            <MenuItem value={""}>
              <em>Bet kuris</em>
            </MenuItem>
            {Object.values(ParkingType).map((type, index) => (
              <MenuItem key={index} value={type}>
                {ParkingTypeTranslations[type]}
              </MenuItem>
            ))}
          </Select>
        </FieldLabel>
      }
      property={property}
    />
  );
};
