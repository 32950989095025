import React, { useContext, useEffect, useState } from "react";
import {
  InputMethod,
  InputMethodSelection,
} from "common/components/propertyCRUD/InputMethodSelection";
import { Button } from "common/components/buttons/Button";
import colors from "common/styles/colors";
import { useMutation } from "react-query";
import { getErrorMessage } from "common/helpers/error.helpers";
import { getUrlErrorMessage } from "common/helpers/url.helpers";
import { useNavigate } from "react-router-dom";
import { ContentCard } from "../../components/ContentCard";
import { showToastError, showToastSuccess } from "common/toast/toast";
import { addAppraiserPropertyFromUrl } from "../../api/appraiserProperties.api";
import { PropertyCreationContext } from "./PropertyCreationProvider";
import { useRouteQuery, useUpdateQueryParam } from "../../hooks/router.hooks";
import { setPropertyToken } from "common/hooks/propertyDetails.hooks";
import { PropertyType } from "../../types/api.types";
import { PreliminaryPrice } from "./reportPayments/PreliminaryPrice";
import { analyzePdfProperty } from "../../api/api";
import { InputDocumentSelection } from "common/components/propertyCRUD/InputDocumentSelection";
import { FileWrapper } from "common/types/common.types";
import { Dialog, DialogContent, Typography } from "@mui/material";
import { LoaderCentered } from "../../components/loaders/LoaderCentered";
import { useTranslation } from "react-i18next";

interface InputMethodSelectionRouteProps {}

export const InputMethodSelectionRoute: React.FC<
  InputMethodSelectionRouteProps
> = () => {
  const { t } = useTranslation();
  const [selectedInputMethod, setSelectedInputMethod] = useState<InputMethod>(
    InputMethod.Input
  );
  const [isFileUseAgreed, setIsFileUseAgreed] = useState(false);
  const { navigateWithQueryParams } = useUpdateQueryParam();

  const navigate = useNavigate();

  const [url, setUrl] = useState("");
  const { fromLead } = useContext(PropertyCreationContext);

  const appraiserId = useRouteQuery().get("appraiserId") ?? undefined;

  const simpleType = useRouteQuery().get("simpleType") as
    | PropertyType
    | undefined;

  const [selectedFiles, setSelectedFiles] = useState<FileWrapper[]>([]);

  const { isLoading, mutate } = useMutation(
    (url: string) =>
      addAppraiserPropertyFromUrl(url, fromLead, appraiserId, simpleType),
    {
      onSuccess: (property) => {
        if (!!fromLead) {
          setPropertyToken(property);
          navigateWithQueryParams("../contact", [
            { paramName: "createdPropertyId", newValue: property._id },
          ]);
        } else {
          navigate(`/properties/${property._id}`);
        }
      },
      onError: (error: any) => {
        const message = getErrorMessage(error);

        if (message === undefined) {
          showToastError();
        } else {
          showToastError(message);
        }
      },
    }
  );

  const { isLoading: isAnalyzing, mutate: analyzeFiles } = useMutation(
    async (files: FileWrapper[]) => {
      return await analyzePdfProperty(files, {
        fromLead,
        appraiserId,
        simpleType,
      });
    },
    {
      onSuccess: (result) => {
        showToastSuccess(t("propertyCreation.fileUploadSuccess"));
        const navigationState = {
          readings: result.results,
          propertyPlaceholder: result.propertyPlaceholder,
        };
        if (!!fromLead) {
          navigateWithQueryParams("/lead/reading", [], {
            state: navigationState,
          });
        } else {
          navigateWithQueryParams("/properties/create/reading", [], {
            state: navigationState,
          });
        }
      },
      onError: (error) => {
        const message = getErrorMessage(error);
        showToastError(message);
      },
    }
  );

  const query = useRouteQuery();
  const designationType = query.get("type");

  return (
    <>
      <PreliminaryPrice />
      <ContentCard
        title={t("propertyCreation.createNewProperty")}
        caption={t("propertyCreation.selectMethod")}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ width: 500 }}>
            <InputMethodSelection
              selectedInputMethod={selectedInputMethod}
              onInputMethodChange={(inputMethod) =>
                setSelectedInputMethod(inputMethod)
              }
              urlInput={url}
              onUrlChange={(url) => setUrl(url)}
              noCaption
            />
            <InputDocumentSelection
              hidden={selectedInputMethod !== InputMethod.Files}
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              isAgreed={isFileUseAgreed}
              setIsAgreed={setIsFileUseAgreed}
            />
            <div style={{ marginTop: 30 }}>
              <Button
                backgroundColor="purple2"
                textStyle={{ color: colors.white }}
                isLoading={isLoading || isAnalyzing}
                onPress={() => {
                  if (selectedInputMethod === InputMethod.Input) {
                    if (!!fromLead) {
                      navigateWithQueryParams(
                        designationType ? "../details" : "../type"
                      );
                    } else {
                      navigate("/properties/create/type");
                    }
                  } else if (selectedInputMethod === InputMethod.Url) {
                    const errorMessage = getUrlErrorMessage(url);

                    if (errorMessage !== undefined) {
                      showToastError(errorMessage);

                      return;
                    }
                    mutate(url);
                  } else if (selectedInputMethod === InputMethod.Files) {
                    if (selectedFiles.length > 0) {
                      analyzeFiles(selectedFiles);
                    } else {
                      showToastError(t("propertyCreation.uploadDocuments"));
                    }
                  } else {
                    // not implemented
                  }
                }}
                disabled={
                  selectedInputMethod === InputMethod.Files && !isFileUseAgreed
                }
              >
                {t("common.next").toUpperCase()}
              </Button>
            </div>
          </div>
        </div>
      </ContentCard>
      <Dialog open={isAnalyzing} fullWidth maxWidth="md">
        <DialogContent>
          <LoaderCentered wrapperStyle={{ padding: "40px 0px 20px 0px" }} />
          <Typography variant="h6" align="center" fontSize={16}>
            {t("propertyCreation.pleaseWait")}
          </Typography>
          <Typography align="center" fontSize={14} paddingBottom={"10px"}>
            {t("propertyCreation.scanningDocuments")}
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};
