import colors from "common/styles/colors";
import BackwardIcon from "../../assets/icons/arrowBackward.svg";
import ForwardIcon from "../../assets/icons/arrowForward.svg";
import { Typography, TypographyProps } from "common/components/Typography";
import { IconButton } from "common/components/buttons/IconButton";

export interface CardPaginationProps {
  onPageRelativeChange: (indexDifference: number) => void;
  currentPage: number;
  maxPage: number;
  wrapperStyle?: React.CSSProperties;
  textStyle?: Pick<TypographyProps, "textStyle">;
  name?: string;
  isBottomRight?: boolean;
}

export const CardPagination: React.FC<CardPaginationProps> = ({
  onPageRelativeChange,
  currentPage,
  maxPage,
  wrapperStyle,
  textStyle,
  name,
  isBottomRight,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "end",
        backgroundColor: colors.greybg,
        ...(isBottomRight
          ? {
              borderBottomRightRadius: "10px",
              position: "absolute",
              bottom: 0,
              right: 0,
            }
          : {}),
        ...wrapperStyle,
      }}
    >
      <IconButton
        onPress={() => {
          onPageRelativeChange(-1);
        }}
      >
        <BackwardIcon />
      </IconButton>
      <Typography
        variant="caption"
        textStyle={{ fontSize: 12, ...textStyle }}
      >{`${currentPage}/${maxPage}${name ? " " + name : ""}`}</Typography>
      <IconButton
        onPress={() => {
          onPageRelativeChange(1);
        }}
      >
        <ForwardIcon />
      </IconButton>
    </div>
  );
};
