import React, { useContext, useState } from "react";

import { useMutation, useQuery } from "react-query";
import {
  getSimilarPricePredictions,
  setSimilarPricePredictions,
} from "../../api/api";
import { PropertyPlaceholderImage } from "../imageGallery/PropertyPlaceholderImage";
import { getPropertyListString } from "../../helpers/property.helpers";
import { Typography } from "common/components/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import { showToastError } from "common/toast/toast";
import { CenteredContentWrapper } from "../wrappers/CenteredContentWrapper";
import { formatAreaPrice, formatPrice } from "common/helpers/text.helpers";
import { useQueriesInvalidation } from "common/hooks/api.hooks";
import { pricePredictionsQueryKey } from "../../hooks/pricePredictions.hooks";
import colors from "common/styles/colors";
import { Text } from "react-native";
import { getLithuanianDateString } from "common/helpers/dateStringHelpers";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ConfirmationDialog } from "../dialogs/ConfirmationDialog";
import { useProperty } from "../../hooks/property.hooks";
import {
  AppraiserProperty,
  PropertyRequestStatus,
} from "../../types/appraiser.types";
import { BasePropertyCard } from "../cards/BasePropertyCard";
import { useTranslation } from "react-i18next";

interface ValuationsRecommendationsProps {
  propertyId: string;
  onUsePrediction: () => void;
}

export const ValuationsRecommendations: React.FC<
  ValuationsRecommendationsProps
> = ({ propertyId, onUsePrediction }) => {
  const { t } = useTranslation();
  const { data, isSuccess } = useQuery(
    ["similarPricePredictions", propertyId],
    () => getSimilarPricePredictions(propertyId)
  );

  const { resetQueriesToUndefined } = useQueriesInvalidation();

  const { mutateAsync, isLoading: isMutating } = useMutation(
    (predictionsPropertyId: string) =>
      setSimilarPricePredictions(propertyId, predictionsPropertyId),
    {
      onError: () => {
        showToastError();
      },
      onSuccess: () => {
        resetQueriesToUndefined([pricePredictionsQueryKey, propertyId]);
      },
    }
  );

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data: property } = useProperty(propertyId);

  const editedPropertyRef = React.useRef<AppraiserProperty | null>(null);

  const mutatePrediction = async (property: AppraiserProperty) => {
    const predictionsPropertyId = property._id;

    await mutateAsync(predictionsPropertyId);

    onUsePrediction();
  };

  return (
    <div>
      <div
        style={{
          marginBottom: "15px",
        }}
      >
        <Typography>
          {t("valuationsRecommendations.similarValuations")}
        </Typography>
      </div>
      {isSuccess && data?.length === 0 && (
        <CenteredContentWrapper>
          <div
            style={{
              marginTop: "20px",
            }}
          >
            <Typography variant="caption">
              {t("valuationsRecommendations.noSimilarValuations")}
            </Typography>
          </div>
        </CenteredContentWrapper>
      )}
      <div
        style={{
          flexDirection: "row",
          display: "flex",
          gap: "20px",
          flexWrap: "wrap",
        }}
      >
        {data?.map((item) => {
          const caption = getPropertyListString({
            area: item.property.area,
            rooms: item.property.rooms,
            floorNr: item.property.floorNr,
            floorCount: item.property.floorCount,
            year: item.property.year,
            renovationYear: item.property.renovationYear,
          });

          return (
            <BasePropertyCard
              key={item.property._id}
              address={item.property.address}
              price={
                item.pricePrediction.predicted_price.average_price_area *
                item.property.area
              }
              priceArea={
                item.pricePrediction.predicted_price.average_price_area
              }
              dateText={t("valuationsRecommendations.propertyValuationDate", {
                date: getLithuanianDateString(item.finalPredictionCreatedAt),
              })}
              caption={caption}
              images={item.property.images.map((image) => image.url)}
            >
              <LoadingButton
                variant="text"
                style={{
                  alignSelf: "flex-end",
                }}
                loading={isMutating}
                onClick={async () => {
                  if (
                    property?.propertyRequestStatus ===
                    PropertyRequestStatus.InProgress
                  ) {
                    editedPropertyRef.current = item.property;
                    setIsModalOpen(true);

                    return;
                  }

                  mutatePrediction(item.property);
                }}
                startIcon={<ContentCopyIcon />}
              >
                {t("valuationsRecommendations.copy")}
              </LoadingButton>
            </BasePropertyCard>
          );
        })}
      </div>
      <ConfirmationDialog
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={async () => {
          await mutatePrediction(editedPropertyRef.current!);
          setIsModalOpen(false);
        }}
        title={t("valuationsRecommendations.confirmCopyTitle")}
        description={t("valuationsRecommendations.confirmCopyDescription")}
        actionButtonText={t("valuationsRecommendations.confirm")}
        hideDoNotShowAgain
        isLoading={isMutating}
      />
    </div>
  );
};
