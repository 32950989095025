import React from "react";
import { useTranslation } from "react-i18next";
import { SimpleTableComponent } from "../../components/tables/simpleTable/SimpleTableComponent";
import { SimpleTableRow } from "../../components/tables/simpleTable/SimpleTableRow";
import { SimpleTableHeader } from "../../components/tables/simpleTable/SimpleTableHeader";
import { AppraiserUsedCredits } from "../../types/api.types";
import { SimpleTableElement } from "../../components/tables/simpleTable/SimpleTableElement";
import { formatMonthlyLimitPrice } from "common/helpers/text.helpers";

interface AppraiserMonthUsageTableProps {
  appraisers: AppraiserUsedCredits[];
}

export const AppraiserMonthUsageTable: React.FC<
  AppraiserMonthUsageTableProps
> = ({ appraisers }) => {
  const { t } = useTranslation();

  return (
    <SimpleTableComponent customFontSize="14px">
      <SimpleTableRow>
        <SimpleTableHeader>
          {t("payments.appraiserUsage.table.email")}
        </SimpleTableHeader>
        <SimpleTableHeader>
          {t("payments.appraiserUsage.table.amount")}
        </SimpleTableHeader>
      </SimpleTableRow>
      {appraisers.map((appraiser) => (
        <SimpleTableRow key={appraiser.appraiserId}>
          <SimpleTableElement>{appraiser.email}</SimpleTableElement>
          <SimpleTableElement>
            {formatMonthlyLimitPrice(appraiser.totalCredits)}
          </SimpleTableElement>
        </SimpleTableRow>
      ))}
    </SimpleTableComponent>
  );
};
