interface ExternalLinkProps {
  style?: React.CSSProperties;
  href: string;
  children: React.ReactNode;
}

export const getExternalLink = (href: string) => {
  if (href.includes(":")) {
    return href;
  }
  return `//${href}`;
};

const ExternalLink = ({ style, href, children }: ExternalLinkProps) => {
  const url = getExternalLink(href);

  return (
    <a href={url} target="_blank" rel="noreferrer" style={style}>
      {children}
    </a>
  );
};

export default ExternalLink;
