import { OverlayViewF } from "@react-google-maps/api";
import React from "react";
import { ClusteredItem } from "../ClusteredGenericMapControlProvider";
import { ClusterMarkerIcon } from "common/components/icons/ClusterMarkerIcon";
import colors from "common/styles/colors";

interface ClusterOverlayViewProps {
  cluster: ClusteredItem;
  onClusterPress: (cluster: ClusteredItem) => void;
  displayClusterIcon?: (cluster: ClusteredItem) => React.ReactNode;
}

export const ClusterOverlayView: React.FC<ClusterOverlayViewProps> = ({
  cluster,
  onClusterPress,
  displayClusterIcon,
}) => {
  return (
    <OverlayViewF
      mapPaneName="overlayMouseTarget"
      position={{
        lat: cluster.lat,
        lng: cluster.lng,
      }}
      getPixelPositionOffset={(width, height) => ({
        x: -width / 2,
        y: -height / 2,
      })}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
          onClusterPress(cluster);
        }}
        style={{ cursor: "pointer" }}
      >
        {displayClusterIcon?.(cluster) || (
          <ClusterMarkerIcon
            key={cluster.id}
            propertyCount={cluster.entityCount}
            color={colors.white}
          />
        )}
      </div>
    </OverlayViewF>
  );
};
