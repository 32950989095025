import { useInfiniteQuery, useQuery } from "react-query";
import {
  getComparables,
  getProperties,
  getPropertiesBrief,
  getPropertiesTotal,
  getProperty,
  getPropertyPurchasedAddons,
  getPropertyPurchasedCompositeAssets,
  getPropertyShare,
  getPropertyValuation,
  getSimilarListings,
} from "../api/api";
import {
  AppraiserProperty,
  AppraiserRole,
  PropertyRequestStatus,
} from "../types/appraiser.types";
import {
  useAppraiserFilterQuery,
  usePaginatedQuery,
  usePropertyAssessmentStatusQuery,
  usePropertyTextFilterQuery,
} from "./router.hooks";
import { useAppraiser } from "./auth.hooks";
import { useContext, useMemo } from "react";
import { TransactionsRCContext } from "../components/TransactionsRCProvider";
import { useParams } from "react-router-dom";
import { usePricePredictions } from "./pricePredictions.hooks";
import { Comparable, ComparableFull } from "common/types/common.types";
import { AppraiserFilter, PropertyAssessmentStatus } from "../types/api.types";

export const useProperties = () => {
  const { page } = usePaginatedQuery();
  const status = usePropertyAssessmentStatusQuery();
  const appraiserFilter = useAppraiserFilterQuery();
  const { data: appraiser } = useAppraiser();
  const textFilter = usePropertyTextFilterQuery();
  return useQuery(
    [
      "appraiserProperties",
      page,
      status,
      appraiser?._id,
      appraiserFilter,
      textFilter,
    ],
    () =>
      getProperties(page, status, {
        appraiserFilter: appraiserFilter ?? undefined,
        textFilter,
      }),
    { keepPreviousData: true, enabled: !!appraiserFilter }
  );
};

export const usePropertiesBrief = ({
  isPaged = true,
  isAppraiserFiltered = true,
  isStatusFiltered = true,
  isTextFiltered = true,
}: {
  isPaged?: boolean;
  isAppraiserFiltered?: boolean;
  isStatusFiltered?: boolean;
  isTextFiltered?: boolean;
} = {}) => {
  const { page: _page } = usePaginatedQuery();
  const _status = usePropertyAssessmentStatusQuery();
  const _appraiserFilter = useAppraiserFilterQuery();
  const { data: appraiser } = useAppraiser();
  const _textFilter = usePropertyTextFilterQuery();
  const page = isPaged ? _page : undefined;
  const status = isStatusFiltered ? _status : undefined;
  const appraiserFilter = isAppraiserFiltered ? _appraiserFilter : undefined;
  const textFilter = isTextFiltered ? _textFilter : undefined;
  return useQuery(
    [
      "appraiserProperties",
      page,
      status,
      appraiser?._id,
      appraiserFilter,
      textFilter,
    ],
    () =>
      getPropertiesBrief({
        appraiserFilter: appraiserFilter ?? undefined,
        textFilter,
        page,
        status,
      }),
    { keepPreviousData: true, enabled: !!appraiserFilter }
  );
};

export const usePropertiesTotal = (status: PropertyAssessmentStatus) => {
  const appraiserFilter = useAppraiserFilterQuery();
  const { data: appraiser } = useAppraiser();
  return useQuery(
    ["appraiserPropertiesTotal", status, appraiser?._id, appraiserFilter],
    () => getPropertiesTotal(status, appraiserFilter ?? undefined),
    { keepPreviousData: true, enabled: !!appraiserFilter }
  );
};

export const useProperty = (
  propertyId: string,
  enabled = true,
  onSuccess?: (data: AppraiserProperty) => void
) => {
  return useQuery(
    ["appraiserProperties", propertyId],
    () => getProperty(propertyId),
    {
      enabled,
      onSuccess,
    }
  );
};

export function useComparables() {
  const { baseTransactions, fullTransactions } = useContext(
    TransactionsRCContext
  );

  const data = useMemo(() => {
    return (
      baseTransactions?.filter(
        (d) => d.comparable_transaction.location !== null
      ) ?? []
    );
  }, [baseTransactions]);

  const dataFull = useMemo(() => {
    return (
      fullTransactions?.filter(
        (d) => d.comparable_transaction.location !== null
      ) ?? []
    );
  }, [fullTransactions]);

  return {
    data,
    dataFull,
  };
}

export function useComparablesData() {
  const { propertyId } = useParams();

  const { isComparableInCart, getComparableIndex } = usePricePredictions(
    propertyId ?? ""
  );

  const data = useComparables();

  const dataCombined = useMemo(() => {
    return [...data.data, ...data.dataFull];
  }, [data.data, data.dataFull]);

  const comparablesInCart = useMemo(() => {
    const comps = dataCombined.filter((comp) =>
      isComparableInCart(comp.comparable_transaction.id)
    );
    comps?.sort((a, b) => getComparableIndex(a) - getComparableIndex(b));
    return comps;
  }, [dataCombined, isComparableInCart, getComparableIndex]);

  return {
    ...data,
    dataCombined,
    comparablesInCart,
  };
}

export function useComparableFull(comparableId: string) {
  const { dataFull: comparables } = useComparablesData();
  return comparables?.find(
    (comp) => comp.comparable_transaction.id.toString() === comparableId
  );
}

export function useComparable(comparableId: string) {
  const { dataCombined: comparables } = useComparablesData();
  return comparables?.find(
    (comp) => comp.comparable_transaction.id.toString() === comparableId
  );
}

export function usePropertyShare(token: string) {
  return useQuery(["property_share", token], () => getPropertyShare(token));
}

export function usePropertyValuation(id: string) {
  return useQuery(["property_valuation", id], () => getPropertyValuation(id));
}

export function usePropertyEditRights(propertyId: string) {
  const { data: appraiser } = useAppraiser();
  const { data: property } = useProperty(propertyId);
  return {
    canEdit:
      (appraiser?._id === property?.appraiser?._id &&
        appraiser?.role === AppraiserRole.APPRAISER) ||
      (appraiser?._id === property?.appraiser?._id &&
        appraiser?.role === AppraiserRole.DATA_ENTRY &&
        (property?.propertyRequestStatus === PropertyRequestStatus.Pending ||
          property?.propertyRequestStatus === PropertyRequestStatus.Created)) ||
      appraiser?.role === AppraiserRole.ADMIN,
  };
}

export function usePropertyPurchasedAddons(propertyId: string) {
  return useQuery(["addons", propertyId], () =>
    getPropertyPurchasedAddons(propertyId)
  );
}

export function usePropertyPurchasedCompositeAssets(propertyId: string) {
  return useQuery(["composite_assets", propertyId], () =>
    getPropertyPurchasedCompositeAssets(propertyId)
  );
}

export const useSimilarListings = (property?: AppraiserProperty) => {
  return useQuery(
    ["similar_listings", property?._id],
    () => getSimilarListings(property?._id ?? ""),
    {
      enabled: !!property,
      staleTime: Infinity,
      keepPreviousData: true,
    }
  );
};
