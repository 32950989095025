import { ReactNode, useEffect, useState } from "react";
import i18n, { getStoredLanguage, languageChangedHandler } from "./i18n";

interface LocalizedSectionProps {
  language: string;
  children: ReactNode;
}

export function LocalizedSection({
  language,
  children,
}: LocalizedSectionProps) {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    // Temporarily disable the languageChanged event handler
    i18n.off("languageChanged");

    // Change language without triggering storage
    (async () => {
      await i18n.changeLanguage(language);
      setIsReady(true);
    })();

    return () => {
      // Restore the original handler
      i18n.on("languageChanged", languageChangedHandler);

      // Restore previous language
      i18n.changeLanguage(getStoredLanguage());
    };
  }, [language]);

  if (!isReady) {
    return null;
  }

  return <>{children}</>;
}
