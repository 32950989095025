import React from "react";
import { PropertyAddonType } from "../../../types/api.types";
import { getPropertyAddonPrices } from "../../../api/api";
import { useQuery } from "react-query";
import { AddonSelect } from "./AddonSelect";
import { AddonsWrapper } from "./AddonsWrapper";

interface AddonListSelectProps {}

export const addonsLabels = {
  // [PropertyAddonType.ComparablesView]: "Panašūs sandoriai",
  // [PropertyAddonType.CalculationsView]: "Panašūs sandoriai ir jų skaičiavimai",
  [PropertyAddonType.LocationReport]: "Vietos ataskaita",
  [PropertyAddonType.MarketInsights]: "Rinkos apžvalga",
};

const addonsDescriptions = {
  // [PropertyAddonType.ComparablesView]: "Panašių sandorių analizė",
  // [PropertyAddonType.CalculationsView]:
  //   "Panašių sandorių analizė ir pataisų skaičiavimai.",
  [PropertyAddonType.LocationReport]:
    "Atstumų ir kelionių trukmių analizė iki svarbiausių infrastruktūros objektų.",
  [PropertyAddonType.MarketInsights]:
    "Rinkos statistika ir tendencijos, turinčios įtakos turto vertei.",
};

export const AddonListSelect: React.FC<AddonListSelectProps> = () => {
  const { data: addonsPrices } = useQuery(
    "addonsPrices",
    getPropertyAddonPrices
  );

  if (!addonsPrices) {
    return null;
  }

  const addonsToDisclude: PropertyAddonType[] = [
    // PropertyAddonType.ComparablesView
  ];

  const addonsList = Object.entries(addonsPrices)
    .map(([type, price]) => ({
      type: type as PropertyAddonType,
      label: addonsLabels[type as PropertyAddonType] ?? "",
      description: addonsDescriptions[type as PropertyAddonType] ?? "",
      price,
    }))
    .filter((addon) => !addonsToDisclude.includes(addon.type));

  return (
    <AddonsWrapper>
      {addonsList.map((addon) => (
        <AddonSelect
          key={addon.type}
          addon={addon.type}
          label={addon.label}
          price={addon.price}
          description={addon.description}
        />
      ))}
    </AddonsWrapper>
  );
};
