import React, { useEffect, useState } from "react";
import { FormAutocomplete } from "../../../components/form/FormAutocomplete";
import { getBuildingNumbers } from "../../../api/geocode.api";
import { useQuery, useQueryClient } from "react-query";
import { useFormContext } from "react-hook-form";
import { BuildingAutocompleteResult } from "../../../types/geocoding.types";
import { useTranslation } from "react-i18next";

interface BuildingNumberFieldProps {}

export const getBuildingNumberLabel = (
  buildingNumber: BuildingAutocompleteResult
) => {
  return `${buildingNumber.NR}`;
};

export const BuildingNumberField: React.FC<BuildingNumberFieldProps> = ({}) => {
  const { t } = useTranslation();
  const [query, setQuery] = useState("");

  const { watch } = useFormContext();

  const cityId = watch("cityId");
  const streetId = watch("streetId");

  const enabled = !!cityId && !!streetId;
  const { data, isLoading } = useQuery(
    [query, "building_numbers_autocomplete", streetId ?? "", cityId ?? ""],
    () => getBuildingNumbers(query, streetId ?? "", cityId ?? ""),
    {
      enabled,
      keepPreviousData: true,
    }
  );

  const options = data?.map(getBuildingNumberLabel);

  const { setValue } = useFormContext();

  return (
    <FormAutocomplete
      label={t("propertyForm.address.buildingNumber")}
      name="streetNumber"
      options={options ?? []}
      isRequired
      isLoading={isLoading}
      onChange={(value) => {
        setQuery(value ?? "");
      }}
      onSelect={(value) => {
        const selectedBuildingNumber = data?.find(
          (buildingNumber) => buildingNumber.NR === value
        );

        setValue("lat", selectedBuildingNumber?.coordinate.N_KOORD);
        setValue("lng", selectedBuildingNumber?.coordinate.E_KOORD);
      }}
      disableFilterOptions
      disabled={!enabled}
    />
  );
};
