import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Toggle } from "../../components/form/toggle/Toggle";
import { getEndingByCountLT4 } from "common/helpers/text.helpers";
import { useMutation } from "react-query";
import { setOrganizationTransactionsRetention } from "../../api/api";
import { Organization } from "../../types/api.types";
import { showToastError, showToastSuccess } from "common/toast/toast";
import { queryClient } from "../../modules/queryClient";
import { SimpleDialog } from "../../components/dialogs/SimpleDialog";
import { Button } from "@mui/material";

interface TransactionsRetentionSettingsProps {
  organization: Organization;
}

export const TransactionsRetentionSettings: React.FC<
  TransactionsRetentionSettingsProps
> = ({ organization }) => {
  const { t, i18n } = useTranslation();
  const [selectedRetentionDays, setSelectedRetentionDays] = React.useState(
    organization.transactionRetentionDays ?? null
  );
  const [isOpen, setIsOpen] = useState(false);
  const { mutate, isLoading } = useMutation(
    setOrganizationTransactionsRetention,
    {
      onSuccess: (data) => {
        queryClient.setQueryData<Organization | undefined>(
          "organization",
          data
        );
        queryClient.invalidateQueries("organization");
        setIsOpen(false);
      },
      onError: () => {
        showToastError();
      },
    }
  );

  const isChecked = (months: number | null) => {
    if (selectedRetentionDays === null || selectedRetentionDays === undefined) {
      return months === null;
    }
    if (months === null) {
      return false;
    }
    return selectedRetentionDays === months * 30;
  };

  const getLabel = (months: number | null) => {
    if (months !== null) {
      if (i18n.language === "lt") {
        return `${months} mėnes${getEndingByCountLT4(months).toLowerCase()}`;
      }
      // For English, handle plural form directly
      return `${months} ${months === 1 ? "month" : "months"}`;
    }
    return t("transactionRetention.unlimited");
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "20px",
          alignItems: "center",
        }}
      >
        <b>
          {getLabel(
            !organization.transactionRetentionDays
              ? null
              : organization.transactionRetentionDays / 30
          )}
        </b>
        <Button variant="outlined" onClick={() => setIsOpen(true)}>
          {t("transactionRetention.change")}
        </Button>
      </div>
      <SimpleDialog
        title={t("transactionRetention.dialogTitle")}
        onSubmit={() => {
          mutate(selectedRetentionDays);
        }}
        isLoading={isLoading}
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <div style={{ display: "flex", flexDirection: "row", gap: "12px" }}>
          {[3, 12, null].map((months) => (
            <Toggle
              key={months}
              isChecked={isChecked(months)}
              onSelect={() =>
                setSelectedRetentionDays(months === null ? null : months * 30)
              }
              label={getLabel(months)}
            />
          ))}
        </div>
      </SimpleDialog>
    </div>
  );
};
