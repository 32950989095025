import { OverlayViewF } from "@react-google-maps/api";
import {
  PropertyIcon,
  PropertyIconProps,
} from "common/components/map/PropertyIcon";
import _ from "lodash";
import React from "react";

export interface ComparableIconOverlayViewProps {
  lat: number;
  lng: number;
  onClick?: () => void;
  xOffSet?: number;
  backgroundColor?: string;
}

export const ComparableIconOverlayView: React.FC<
  ComparableIconOverlayViewProps & PropertyIconProps
> = React.memo(
  ({ lat, lng, onClick, ...props }) => {
    return (
      <OverlayViewF
        mapPaneName={!!onClick ? "overlayMouseTarget" : "overlayLayer"}
        position={{
          lat,
          lng,
        }}
        getPixelPositionOffset={(width, height) => ({
          x: -width / 2 + (props.xOffSet ?? 0),
          y: -height / 2,
        })}
      >
        <div
          onClick={(e) => {
            e.stopPropagation();
            onClick?.();
          }}
          style={{ cursor: "pointer" }}
        >
          <PropertyIcon {...props} backgroundColor={props.backgroundColor}/>
        </div>
      </OverlayViewF>
    );
  },
  (prevProps, nextProps) => {
    return (
      _.isEqual(
        _.omit(prevProps, ["customIcon", "onClick"]),
        _.omit(nextProps, ["customIcon", "onClick"])
      ) && prevProps.customIcon === nextProps.customIcon
    );
  }
);
