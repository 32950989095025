import React, { useContext, useRef } from "react";
import { ContentCard } from "../../components/ContentCard";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  deletePropertyFile,
  deletePropertyFileCustomer,
  getPropertyFiles,
  getPropertyFilesFromCustomer,
  uploadPropertyFile,
  uploadPropertyFileCustomer,
} from "../../api/api";
import { Button, Link } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { IconButton } from "common/components/buttons/IconButton";
import RemoveButtonIcon from "../../assets/icons/removeButton.svg";
import { ConfirmationDialog } from "../../components/dialogs/ConfirmationDialog";
import { UploadedFile } from "../../types/api.types";
import { showToastError, showToastSuccess } from "common/toast/toast";
import { PropertyFileList } from "./PropertyFileList";
import { usePropertyEditRights } from "../../hooks/property.hooks";
import { useTranslation } from "react-i18next";

interface PropertyFileUploadProps {
  propertyId: string;
  isCustomer?: boolean;
  customEditRight?: boolean;
  customHeader?: string;
  caption?: string;
}

export const PropertyFileUpload: React.FC<PropertyFileUploadProps> = ({
  propertyId,
  isCustomer,
  customEditRight,
  customHeader,
  caption,
}) => {
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const queryGetKey = ["files", propertyId, isCustomer ?? false];
  const { data: files, isLoading } = useQuery(queryGetKey, () =>
    isCustomer
      ? getPropertyFilesFromCustomer(propertyId)
      : getPropertyFiles(propertyId)
  );

  const queryClient = useQueryClient();

  const { mutate: upload, isLoading: isUploading } = useMutation(
    (file: File) =>
      isCustomer
        ? uploadPropertyFileCustomer(propertyId, file)
        : uploadPropertyFile(propertyId, file),
    {
      onSuccess: () => {
        showToastSuccess(t("propertyFiles.uploadSuccess"));
        queryClient.invalidateQueries(queryGetKey);
      },
      onError: (error: any) => {
        showToastError(t("propertyFiles.uploadError"));
      },
    }
  );

  const { mutate: deleteFile, isLoading: isDeleting } = useMutation(
    (fileId: string) =>
      isCustomer
        ? deletePropertyFileCustomer(propertyId, fileId)
        : deletePropertyFile(propertyId, fileId),
    {
      onSuccess: () => {
        showToastSuccess(t("propertyFiles.deleteSuccess"));
        setOpenDelete(false);
        queryClient.invalidateQueries(queryGetKey);
      },
      onError: (error: any) => {
        showToastError(t("propertyFiles.deleteError"));
      },
    }
  );

  const [selectedDeleteFile, setSelectedDeleteFile] =
    React.useState<UploadedFile>();
  const [openDelete, setOpenDelete] = React.useState(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      upload(file);
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleDelete = (file: UploadedFile) => {
    setSelectedDeleteFile(file);
    setOpenDelete(true);
  };

  const { canEdit: hasEditRight } = usePropertyEditRights(propertyId);
  const canEdit =
    customEditRight !== undefined ? customEditRight : hasEditRight;

  return (
    <ContentCard
      title={customHeader ?? t("propertyFiles.myUploadedDocuments")}
      caption={caption}
    >
      <PropertyFileList
        files={files}
        isLoading={isLoading}
        onDelete={canEdit ? handleDelete : undefined}
      />
      {canEdit && (
        <>
          <input
            type="file"
            style={{ display: "none" }}
            id="upload-file-input"
            onChange={handleFileChange}
            ref={fileInputRef}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <label htmlFor="upload-file-input">
              <LoadingButton
                variant="contained"
                component="span"
                loading={isUploading}
              >
                {t("propertyFiles.uploadDocument")}
              </LoadingButton>
            </label>
          </div>
        </>
      )}
      <ConfirmationDialog
        isOpen={openDelete}
        title={t("propertyFiles.deleteDocument")}
        description={t("propertyFiles.deleteConfirmation", {
          fileName: selectedDeleteFile?.fileName,
        })}
        actionButtonText={t("common.delete")}
        onClose={() => setOpenDelete(false)}
        onConfirm={() => {
          if (!selectedDeleteFile) return;
          deleteFile(selectedDeleteFile._id);
        }}
        hideDoNotShowAgain
        isLoading={isDeleting}
      />
    </ContentCard>
  );
};
