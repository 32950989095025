import { Tab, Tabs } from "@mui/material";
import React from "react";
import { useRouteQuery, useUpdateQueryParam } from "../../hooks/router.hooks";
import { Property } from "./Property";
import { useParams } from "react-router-dom";
import { PropertyMarketInsightsRoute } from "./marketInsights/PropertyMarketInsightsRoute";
import { PropertyLocationReport } from "./PropertyLocationReport";
import { PropertyShareButton } from "./PropertyShareButton";
import { SimilarPropertiesInsightsRoute } from "./timeOnMarket/SimilarPropertiesInsightsRoute";
import { useAppraiser } from "../../hooks/auth.hooks";
import { AppraiserRole } from "../../types/appraiser.types";
import { LoaderCentered } from "../../components/loaders/LoaderCentered";
import { useTranslation } from "react-i18next";

interface PropertyRouteProps {}

export const PropertyRoute: React.FC<PropertyRouteProps> = () => {
  const { t } = useTranslation();

  const tabs = [
    {
      value: "info",
      label: t("propertyRoute.propertyInfo"),
    },
    {
      value: "location",
      label: t("propertyRoute.locationReport"),
    },
    {
      value: "marketInsights",
      label: t("propertyRoute.marketOverview"),
    },
    {
      value: "timeOnMarket",
      label: t("propertyRoute.similarPropertiesOnMarket"),
    },
  ];

  const query = useRouteQuery();
  const section = query.get("section") ?? "info";

  const { updateQueryParam } = useUpdateQueryParam();

  const { propertyId } = useParams();

  const { data: appraiser, isLoading: isAppraiserLoading } = useAppraiser();

  if (!propertyId) {
    return <div>{t("common.pathNotFound")}</div>;
  }

  if (isAppraiserLoading) {
    return <LoaderCentered />;
  }

  if (!appraiser) {
    return null;
  }

  const filteredTabs =
    appraiser?.role === AppraiserRole.DATA_ENTRY
      ? tabs.filter((tab) => tab.value === "info")
      : tabs;

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {appraiser?.role !== AppraiserRole.DATA_ENTRY && (
        <PropertyShareButton propertyId={propertyId} />
      )}
      <Tabs
        value={section}
        onChange={(_, value) => {
          updateQueryParam("section", value);
        }}
        style={{ marginBottom: "20px" }}
      >
        {filteredTabs.map((tab) => (
          <Tab key={tab.value} value={tab.value} label={tab.label} />
        ))}
      </Tabs>
      <div>
        {section === "info" && <Property propertyId={propertyId} />}
        {section === "location" && (
          <PropertyLocationReport propertyId={propertyId} />
        )}
        {section === "marketInsights" && (
          <PropertyMarketInsightsRoute propertyId={propertyId} />
        )}
        {section === "timeOnMarket" && (
          <SimilarPropertiesInsightsRoute propertyId={propertyId} />
        )}
      </div>
    </div>
  );
};
