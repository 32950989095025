import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Button } from "common/components/buttons/Button";
import { OutlinedButton } from "common/components/buttons/OutlinedButton";
import { CheckboxInput } from "common/components/form/CheckboxInput";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

interface ConfirmationDialogProps {
  isOpen: boolean;
  onClose?: () => void;
  onConfirm: (doNotShowAgain: boolean) => void;

  title: string;
  description?: string;
  actionButtonText: string;
  hideDoNotShowAgain?: boolean;
  isLoading?: boolean;
  hideCancel?: boolean;
  children?: React.ReactNode;
  width?: string;
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  isOpen,
  onClose,
  onConfirm,
  hideDoNotShowAgain,
  title,
  description,
  actionButtonText,
  isLoading,
  hideCancel,
  children,
  width,
}) => {
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState(false);

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth={false}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent style={{ width: width ?? "500px" }}>
        {description}
        {children}
        {!hideDoNotShowAgain && (
          <div style={{ marginTop: "15px", marginLeft: "-10px" }}>
            <CheckboxInput
              isChecked={isChecked}
              onChange={(value) => setIsChecked(value)}
              label="Daugiau nerodyti šio pranešimo"
              style={{ alignSelf: "center", fontSize: 16, marginBottom: 2 }}
            />
          </div>
        )}
      </DialogContent>
      <DialogActions
        style={{
          padding: "15px",
          display: "flex",
          gap: "5px",
        }}
      >
        {!hideCancel && (
          <OutlinedButton onPress={onClose} style={{ width: "150px" }}>
            {t("common.cancel").toUpperCase()}
          </OutlinedButton>
        )}
        <Button
          onPress={() => onConfirm(isChecked)}
          backgroundColor="purple2"
          style={{ width: "150px" }}
          textStyle={{
            color: "white",
          }}
          isLoading={isLoading}
        >
          {actionButtonText.toUpperCase()}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
