import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { MonthRangePicker } from "../components/datepicker/MonthRangePicker";
import { MonthDate } from "../components/datepicker/types";
import { getMonthDateRangeFromCurrentDate } from "../helpers/comparableFilters.helpers";
import { useQuery } from "react-query";
import { getAppraiserPropertiesStatistics } from "../api/api";
import { CircularProgress } from "@mui/material";
import { formatPrice, getMonthLabel } from "common/helpers/text.helpers";
import { BarChart } from "@mui/x-charts";
import colors from "common/styles/colors";
import { SimpleTableComponent } from "../components/tables/simpleTable/SimpleTableComponent";
import { SimpleTableRow } from "../components/tables/simpleTable/SimpleTableRow";
import { SimpleTableHeader } from "../components/tables/simpleTable/SimpleTableHeader";
import { PropertyStatistics } from "../types/api.types";
import { CenteredContentWrapper } from "../components/wrappers/CenteredContentWrapper";
import { SimpleTableElement } from "../components/tables/simpleTable/SimpleTableElement";
import {
  getLithuanianDateString,
  getLithuanianDateTimeString,
} from "common/helpers/dateStringHelpers";
import { DesignationType } from "common/types/comparableFields.types";
import { getDesignationTypeTranslation } from "../helpers/comparableFields.helpers";

interface ValuationsStatisticsProps {}

// Get field names directly from the PropertyStatistics interface
const propertyStatisticsFields: (keyof PropertyStatistics)[] = [
  "createdAt",
  "designationType",
  "address",
  "fullPrice",
  "totalPrice",
  "discountPercentage",
  "discountCode",
  "appraiser",
  "customer",
  "evaluatedAt",
  "deliveryDate",
  "executionDays",
];

export const ValuationsStatistics: React.FC<ValuationsStatisticsProps> = () => {
  const { t } = useTranslation();

  const fieldParser = (
    field: keyof PropertyStatistics,
    property: PropertyStatistics
  ) => {
    if (!property[field]) {
      return "";
    }
    if (
      field === "createdAt" ||
      field === "evaluatedAt" ||
      field === "deliveryDate"
    ) {
      return getLithuanianDateTimeString(property[field] ?? "");
    }
    if (field === "designationType") {
      return getDesignationTypeTranslation(
        property[field] ?? DesignationType.Residential1Flat
      );
    }
    if (field === "discountPercentage") {
      return `${property[field]}%`;
    }
    if (field === "fullPrice" || field === "totalPrice") {
      return formatPrice(property[field]);
    }
    if (field === "executionDays") {
      return `${property[field]} ${t("valuationsStatistics.days")}`;
    }

    return property[field];
  };

  const getFieldWidth = (field: keyof PropertyStatistics) => {
    if (field === "address") {
      return "250px";
    }
    if (field === "designationType") {
      return "200px";
    }
    if (
      field === "createdAt" ||
      field === "evaluatedAt" ||
      field === "deliveryDate"
    ) {
      return "100px";
    }
    if (field === "discountPercentage") {
      return "100px";
    }
    if (field === "fullPrice" || field === "totalPrice") {
      return "80px";
    }
    if (field === "appraiser" || field === "customer") {
      return "250px";
    }
    if (field === "executionDays") {
      return "80px";
    }

    return "100px";
  };

  const [selectedDates, setSelectedDates] = useState(() => {
    const monthDate = getMonthDateRangeFromCurrentDate(3);

    return {
      start: monthDate.startDate,
      end: monthDate.endDate,
    };
  });

  const { data, isLoading } = useQuery(
    ["appraiserPropertiesStatistics", selectedDates],
    () =>
      getAppraiserPropertiesStatistics(
        selectedDates.start.month,
        selectedDates.start.year,
        selectedDates.end.month,
        selectedDates.end.year
      )
  );

  const dataByMonth = React.useMemo(() => {
    if (!data) return [];

    const dict_data: Record<string, number> = {};

    for (const property of data) {
      const date = new Date(property.createdAt);
      const key = getMonthLabel(date.getMonth() + 1, date.getFullYear());
      dict_data[key] = dict_data[key] ? dict_data[key] + 1 : 1;
    }

    return Object.entries(dict_data).map(([key, value]) => ({
      month: key,
      count: value,
    }));
  }, [data]);

  const cellSpacing = "15px";

  return (
    <div
      style={{
        backgroundColor: "white",
        padding: "20px",
        borderRadius: "10px",
      }}
    >
      <div
        style={{
          maxWidth: "400px",
        }}
      >
        <div style={{ marginBottom: "15px" }}>
          {t("valuationsStatistics.reportingPeriod")}
        </div>
        <MonthRangePicker
          selectedDatesInFilter={selectedDates}
          onDatesSelect={(start, end) => setSelectedDates({ start, end })}
          customPresets={[3, 6, 12]}
        />
      </div>
      {isLoading && (
        <CenteredContentWrapper>
          <CircularProgress />
        </CenteredContentWrapper>
      )}
      {!!dataByMonth && (
        <BarChart
          series={[
            {
              data: dataByMonth.map((d) => d.count),
              color: colors.purple2,
            },
          ]}
          xAxis={[
            {
              id: "months",
              scaleType: "band",
              data: dataByMonth.map((d) => d.month),
            },
          ]}
          height={300}
        />
      )}
      {!!data && (
        <div style={{ overflowX: "scroll" }}>
          <SimpleTableComponent>
            <SimpleTableRow>
              {propertyStatisticsFields.map((field, index) => (
                <SimpleTableHeader
                  key={field}
                  width={getFieldWidth(field)}
                  isFirstElement={index === 0}
                  forceWidth
                >
                  {t(`valuationsStatistics.fields.${field}`)}
                </SimpleTableHeader>
              ))}
            </SimpleTableRow>
            {data.map((property) => (
              <SimpleTableRow key={property.id}>
                {propertyStatisticsFields.map((field, index) => (
                  <SimpleTableElement
                    key={field}
                    style={{
                      paddingRight: cellSpacing,
                    }}
                    isFirstElement={index === 0}
                  >
                    {fieldParser(field, property)}
                  </SimpleTableElement>
                ))}
              </SimpleTableRow>
            ))}
          </SimpleTableComponent>
        </div>
      )}
    </div>
  );
};
