import i18next from "i18next";

export function isUrl(str: string) {
  // Regular expression for a valid URL
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_;:\\.,\\(\\)\\+=\\/]*)?$", // fragment locator
    "i"
  );

  return pattern.test(str);
}

export function getUrlErrorMessage(url: string) {
  if (!url.length) {
    return i18next.t("validation.url.required");
  }
  if (!isUrl(url)) {
    return i18next.t("validation.url.invalid");
  }
}
