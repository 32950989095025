import { useEffect, useRef } from "react";
import { useMutation } from "react-query";
import { sendPhoneVerificationCode, verifyAppraiserAccount } from "../api/api";
import { showToastError, showToastSuccess } from "common/toast/toast";
import { getErrorMessage } from "common/helpers/error.helpers";
import { useTranslation } from "react-i18next";

interface UseVerificationProps {
  phoneNumber?: string;
  token?: string;
  open: boolean;
  onVerified: (loginReason?: string) => void;
  onClose: () => void;
}

export const useAppraiserVerification = ({
  phoneNumber,
  token,
  open,
  onVerified,
  onClose,
}: UseVerificationProps) => {
  const isMounted = useRef(false);
  const { t } = useTranslation();

  const {
    mutate: sendVerificationCode,
    isLoading: isSendVerificationCodeLoading,
    reset: resetSendMutation,
  } = useMutation({
    mutationFn: async (phoneNumber: string) => sendPhoneVerificationCode(phoneNumber, token),
    onSuccess: () => {
      showToastSuccess(t("verification.codeSent"));
      resetSendMutation();
    },
    onError: () => {
      showToastError(t("verification.codeError"));
      resetSendMutation();
    },
  });

  const verifyCodeMutation = useMutation({
    mutationFn: async ({ verificationCode, loginReason }: { verificationCode: string; loginReason?: string }) =>
      verifyAppraiserAccount({ verificationCode, token, loginReason }),
    onSuccess: (_, { loginReason }) => {
      onVerified(loginReason);
      onClose();
    },
    onError: (error: any) => {
      showToastError(getErrorMessage(error));
    },
  });

  useEffect(() => {
    if (open && phoneNumber && !isMounted.current) {
      isMounted.current = true;
      sendVerificationCode(phoneNumber);
    }
  }, [open, phoneNumber, sendVerificationCode]);

  return {
    sendVerificationCode,
    verifyCodeMutation,
    isSendVerificationCodeLoading,
  };
};
