import { addAlpha } from "common/helpers/colors.helpers";
import colors from "common/styles/colors";
import React from "react";
import { TagComponent } from "../tags/TagComponent";
import { PropertyRequestType } from "../../types/appraiser.types";
import { useTranslation } from "react-i18next";

interface PropertyRequestTypeLabelProps {
  type?: PropertyRequestType;
}

export const PropertyRequestTypeLabel: React.FC<
  PropertyRequestTypeLabelProps
> = ({ type }) => {
  const { t } = useTranslation();

  if (type === PropertyRequestType.LeadOrganization) {
    return (
      <TagComponent color={colors.purple2}>
        {t("propertyRequestType.organization")}
      </TagComponent>
    );
  }
  if (type === PropertyRequestType.LeadAppraiser) {
    return (
      <TagComponent color={colors.green}>
        {t("propertyRequestType.appraiser")}
      </TagComponent>
    );
  }
  return null;
};
