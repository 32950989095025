import React from "react";
import { Select, MenuItem } from "@mui/material";
import { InfraFilterByDistance } from "../../../types/api.types";
import {
  useInfraFilterByDistanceQuery,
  useUpdateQueryParam,
} from "../../../hooks/router.hooks";

export const PropertyInfraFilterByDistanceSelection: React.FC = () => {
  const { updateQueryParam } = useUpdateQueryParam();
  const infraFilterByDistance = useInfraFilterByDistanceQuery();

  const infraFilterOptions = [
    { label: "Visi atstumai", value: InfraFilterByDistance.All },
    { label: "15 min. pėsčiomis (~1 km)", value: InfraFilterByDistance.Walking },
  ];

  const handleChange = (infraFilterByDistance: string) => {
    updateQueryParam("infraFilterByDistance", infraFilterByDistance);
  };

  return (
    <Select
      value={infraFilterByDistance || ""}
      style={{ marginBottom: "20px" }}
      onChange={(e) => handleChange(e.target.value)}
    >
      {infraFilterOptions.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};
