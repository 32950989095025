import React from "react";

interface SimpleTableElementProps {
  children?: React.ReactNode;
  isFirstElement?: boolean;
  textAlign?: "left" | "center" | "right";
  style?: React.CSSProperties;
  colSpan?: number;
}

export const SimpleTableElement: React.FC<SimpleTableElementProps> = ({
  children,
  isFirstElement,
  textAlign,
  style,
  colSpan,
}) => {
  return (
    <td
      style={{
        textAlign: textAlign ?? (isFirstElement ? "left" : "right"),
        ...style,
      }}
      colSpan={colSpan}
    >
      {children}
    </td>
  );
};
