import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AppraiserProperty } from "../types/appraiser.types";
import Supercluster, { AnyProps, PointFeature } from "supercluster";
import { getGoogleMapsBoundsFromCoords } from "../helpers/map.helpers";
import { groupByCoordinates } from "../helpers/comparableFilters.helpers";
import { Comparable, MatchedListing } from "common/types/common.types";

const MAX_CLUSTER_ZOOM = 15;

export const getMaxClusterZoom = () =>
  window.innerWidth < 1600 ? MAX_CLUSTER_ZOOM : MAX_CLUSTER_ZOOM + 1;

export function toGeoJSONFeature<T extends AnyProps = AnyProps>({
  lat,
  lng,
  props,
}: {
  lat: number;
  lng: number;
  props: T;
}): PointFeature<T & AnyProps> {
  return {
    type: "Feature",
    geometry: {
      coordinates: [lng, lat],
      type: "Point",
    },
    properties: {
      ...props,
    },
  };
}

type _PointProps = ClusterItemWrapper & Supercluster.AnyProps;
type _Point = Supercluster.PointFeature<_PointProps>;
type _Cluster = Supercluster.ClusterFeature<Supercluster.AnyProps>;

/** item outside cluster */
export interface ClusterItem<T = any> {
  lat: number;
  lng: number;
  entityCount: number;
  id: string;
  /** for clusters instead use getClusterData (fn is undefined for not clusters) */
  data: T;
}

/** cluster */
export interface ClusteredItem<T = any> extends ClusterItem<T[]> {
  getClusterData: () => ClusterItem<T>[];
}

interface ClusterItemWrapper<T = any> {
  item: ClusterItem<T>;
}

export interface ClusteredGenericMapControlContextProps<T, SoloT = T> {
  /** items that can be auto-clustered */
  clusterableItems: ClusterItem<T>[];
  /** items that will not be auto-clustered, will not be grouped */
  soloItems: ClusterItem<SoloT>[];
  /** also updates map to display item groups, clusters */
  setClusterableItems: React.Dispatch<React.SetStateAction<ClusterItem<T>[]>>;
  setSoloItems: React.Dispatch<React.SetStateAction<ClusterItem<SoloT>[]>>;
  calculateAndSetMapBounds: typeof getGoogleMapsBoundsFromCoords;
  mapRef: React.MutableRefObject<google.maps.Map | undefined>;
  clustersOnMap: ClusteredItem<T>[];
  itemsOnMap: ClusterItem<T>[][];
  onBoundsChangedDebounce: (
    onBoundsChanged?: (bounds: google.maps.LatLngBounds) => void
  ) => void;
  onRegionChangeComplete: (
    onBoundsChanged?: (bounds: google.maps.LatLngBounds) => void
  ) => void;
  onClusterPress: (cluster: ClusteredItem<T>) => void;
  onLoad: (
    map: google.maps.Map,
    initialBounds?: google.maps.LatLngBounds,
    onBoundsChanged?: (bounds: google.maps.LatLngBounds) => void
  ) => void;
  onMapClick: () => void;
  setSelectedItemId: React.Dispatch<string | undefined>;
  selectedItemMeta: {
    /** if id is not found among clusterable items or solo items, item and soloItem will be undefined */
    id: string | undefined;
  } & (
    | {
        /** item from clusterable items. Always inside a group of clusterable items */
        item: ClusterItem<T>;
        /** group of clusterable items. Is not applicable to solo items */
        group: ClusterItem<T>[];
        indexInGroup: number;
        soloItem?: undefined;
      }
    | {
        item?: undefined;
        group?: undefined;
        indexInGroup?: undefined;
        /** item from soloItems can be selected. Then clusterable item will be unselected */
        soloItem: ClusterItem<SoloT>;
      }
    // id can be set to inexistant item, no restriction for id
    | {
        item?: undefined;
        group?: undefined;
        indexInGroup?: undefined;
        soloItem?: undefined;
      }
  );
  selectRelativeItemByOffset(offset: number): void;
  panTo: (
    point: { lat: number; lng: number },
    settings?: { isZoomInto?: boolean }
  ) => void;
  initialBoundsRef: React.MutableRefObject<
    google.maps.LatLngBounds | undefined
  >;
  prevBoundsRef: React.MutableRefObject<google.maps.LatLngBounds | undefined>;
}

export const ClusteredAppraiserPropertiesMapControlContext = createContext<
  ClusteredGenericMapControlContextProps<AppraiserProperty> | undefined
>(undefined);

export const ClusteredListingsMapControlContext = createContext<
  ClusteredGenericMapControlContextProps<MatchedListing> | undefined
>(undefined);

export const ClusteredComparablesMapControlContext = createContext<
  | ClusteredGenericMapControlContextProps<Comparable, AppraiserProperty>
  | undefined
>(undefined);

export const ClusteredGenericMapControlProvider = ({
  children,
  context,
}: {
  children: React.ReactNode;
  context: React.Context<
    ClusteredGenericMapControlContextProps<any> | undefined
  >;
}) => {
  const { Provider } = context;
  const [clusterableItems, _setClusterableItems] = useState<ClusterItem[]>([]);
  const [soloItems, setSoloItems] = useState<ClusterItem[]>([]);
  const superClusterRef = useRef<Supercluster<_PointProps> | null>(null);
  const maxClusterZoom = useRef(getMaxClusterZoom());
  const initialBoundsRef = useRef<google.maps.LatLngBounds>();
  const scrollTimer = useRef<NodeJS.Timeout>();
  const mapRef = useRef<google.maps.Map>();
  const [selectedItemId, _setSelectedItemId] = useState<string | undefined>(
    undefined
  );
  const [selectedItemGroup, setSelectedItemGroup] = useState<
    ClusterItem[] | undefined
  >([]);
  const [selectedItemIndexInGroup, setSelectedItemIndexInGroup] = useState<
    number | undefined
  >(undefined);
  const [selectedItem, setSelectedItem] = useState<ClusterItem | undefined>();
  const [isSelectedItemIdTooFar, setIsSelectedItemIdTooFar] = useState(false);
  const [clustersOnMap, setClustersOnMap] = useState<ClusteredItem[]>([]);
  const [itemsOnMap, setItemsOnMap] = useState<ClusterItem[][]>([]);
  const prevBoundsRef = useRef<google.maps.LatLngBounds | undefined>();

  const panTo = useCallback(
    (
      point: { lat: number; lng: number },
      { isZoomInto }: { isZoomInto?: boolean } = {}
    ) => {
      if (!mapRef.current) return;
      // order matters: first zoom, then pan. Otherwise no transition animation will be displayed (sharp jump)
      if (isZoomInto) {
        // maxClusterZoom.current results into clusters, while +1 results into no clusters
        mapRef.current.setZoom(
          Math.max(maxClusterZoom.current + 1, mapRef.current.getZoom() ?? 0)
        );
      }
      mapRef.current.panTo(point);
    },
    [mapRef]
  );

  const calculateAndSetMapBounds = useCallback(
    (...args: Parameters<typeof getGoogleMapsBoundsFromCoords>) => {
      const bounds = getGoogleMapsBoundsFromCoords(...args);
      initialBoundsRef.current = bounds;
      prevBoundsRef.current = bounds;
      mapRef.current?.fitBounds(bounds);
      return bounds;
    },
    [initialBoundsRef, mapRef, prevBoundsRef]
  );

  const setSelectedItemId = useCallback(
    (
      valueOrFn:
        | string
        | undefined
        | ((value: string | undefined) => string | undefined),
      { isSearchInCurrentGroup }: { isSearchInCurrentGroup?: boolean } = {}
    ) => {
      const newId =
        typeof valueOrFn === "function" ? valueOrFn(selectedItemId) : valueOrFn;
      if (newId === undefined && selectedItemId === undefined) return;
      const findById = (item: ClusterItem) => item.id === newId;
      const _selectedItemGroup = isSearchInCurrentGroup
        ? selectedItemGroup
        : itemsOnMap.find((group) => group.some(findById));
      const _selectedItem =
        _selectedItemGroup?.find(findById) ?? soloItems.find(findById);
      _setSelectedItemId(newId);
      setSelectedItemGroup(_selectedItemGroup);
      setSelectedItemIndexInGroup(_selectedItemGroup?.findIndex(findById));
      setSelectedItem(_selectedItem);
      setIsSelectedItemIdTooFar(
        // happens when item is selected not via map and map is dragged too far from item (or zoomed out)
        // useEffect will actively try to select item again on true
        !!newId && !_selectedItem && clusterableItems.some(findById)
      );
    },
    [
      selectedItemId,
      itemsOnMap,
      _setSelectedItemId,
      setSelectedItemGroup,
      setSelectedItemIndexInGroup,
      setSelectedItem,
      selectedItemGroup,
      soloItems,
      setIsSelectedItemIdTooFar,
      clusterableItems,
    ]
  );

  const selectRelativeItemByOffset = useCallback(
    (offset: number) => {
      if (
        selectedItemIndexInGroup === undefined ||
        !selectedItemGroup ||
        selectedItemGroup.length < 2
      )
        // cant select relative item in a group of 1 or inexistant group
        return;
      const loopedForwardIndex = // true for positive offset
        (selectedItemIndexInGroup + offset) % selectedItemGroup.length;
      const newIndex = // correct negative offset
        loopedForwardIndex < 0
          ? selectedItemGroup.length + loopedForwardIndex
          : loopedForwardIndex;
      setSelectedItemId(selectedItemGroup[newIndex].id, {
        isSearchInCurrentGroup: true,
      });
      // if we were to search among all item groups, we might close the item box
      // - at that moment itemGroup would be inexistant, replaced by cluster
    },
    [
      selectedItemGroup,
      selectedItemIndexInGroup,
      _setSelectedItemId,
      setSelectedItemIndexInGroup,
    ]
  );

  const updateMapMarkers = useCallback(() => {
    const bounds = mapRef.current?.getBounds();
    if (!superClusterRef.current || !bounds || !mapRef.current) return;
    if (!bounds) return;
    const bBox = bounds.toJSON();
    // convert bBox to geojson bbox

    const geoJsonBbox = [
      bBox.west,
      bBox.south,
      bBox.east,
      bBox.north,
    ] as GeoJSON.BBox;

    const zoom = mapRef.current.getZoom();
    if (zoom === undefined) return;

    // if clustered, properties will have lat, lng, point_count, cluster_id
    // else cluster (point) will have our item in properties
    const clusters: (_Point | _Cluster)[] =
      superClusterRef.current?.getClusters(geoJsonBbox, zoom);
    const singleClusters = clusters.filter(
      (cluster) => (cluster.properties.point_count || 1) === 1
    ) as _Point[]; // if point_count is inexistant or 1, we are dealing with point feature

    const multipleClusters = clusters.filter(
      (cluster) => (cluster.properties.point_count || 1) > 1
    ) as _Cluster[];
    const mapClusters: ClusteredItem[] = multipleClusters.map((cluster) => {
      const cache: { data: ClusterItem<any>[] } = { data: [] };

      return {
        lng: cluster.geometry.coordinates[0],
        lat: cluster.geometry.coordinates[1],
        entityCount: cluster.properties.point_count,
        id: String(cluster.properties.cluster_id),
        data: [],
        getClusterData: () => {
          if (cache.data.length > 0) {
            return cache.data;
          }
          cache.data =
            superClusterRef.current
              ?.getLeaves(cluster.properties.cluster_id, Infinity)
              .map((leaf) => leaf.properties.item) || [];
          return cache.data;
        },
      };
    });
    const mapSingles: ClusterItem[] = singleClusters
      .map((cluster) => cluster.properties.item)
      .sort((a, b) => a.id.localeCompare(b.id));
    setClustersOnMap(mapClusters);
    const _itemsOnMap = groupByCoordinates(mapSingles, (item) => item);
    setItemsOnMap(_itemsOnMap);
  }, [
    mapRef,
    superClusterRef,
    setClustersOnMap,
    setItemsOnMap,
    groupByCoordinates,
  ]);

  const forceMapUpdate = useCallback(
    (clusterableItems: ClusterItem[]) => {
      maxClusterZoom.current = getMaxClusterZoom();

      superClusterRef.current = new Supercluster({
        radius: 150,
        maxZoom: maxClusterZoom.current,
        minPoints: 1,
      });
      superClusterRef.current.load(
        [...clusterableItems].map((item) =>
          toGeoJSONFeature<ClusterItemWrapper>({ ...item, props: { item } })
        )
      );
      updateMapMarkers();
    },
    [superClusterRef, maxClusterZoom, updateMapMarkers, _setClusterableItems]
  );

  useEffect(() => {
    // purpose: we selected item via a list. The map was scrolled so far that the item is not displayed on map.
    // Thus we cannot select item if it is not in map. So after new items appear on map, we check, is our item among them.
    // It would be ideal if the hook user was to panTo and zoom into the item.
    if (!mapRef.current || !selectedItemId || !isSelectedItemIdTooFar) return;
    setSelectedItemId(selectedItemId);
  }, [
    isSelectedItemIdTooFar,
    itemsOnMap,
    selectedItemId,
    setSelectedItemId,
    mapRef,
  ]);

  const onRegionChangeComplete = useCallback(
    (onBoundsChanged?: (bounds: google.maps.LatLngBounds) => void) => {
      if (!mapRef.current) return;
      const bounds = mapRef.current.getBounds();
      if (bounds) {
        onBoundsChanged?.(bounds);
      }
      updateMapMarkers();
    },
    [mapRef, updateMapMarkers]
  );

  const onBoundsChangedDebounce = useCallback(
    (onBoundsChanged?: (bounds: google.maps.LatLngBounds) => void) => {
      clearTimeout(scrollTimer.current);
      scrollTimer.current = setTimeout(function () {
        onRegionChangeComplete(onBoundsChanged);
        prevBoundsRef.current = mapRef.current?.getBounds();
      }, 100);
    },
    [onRegionChangeComplete, scrollTimer, prevBoundsRef]
  );

  const onClusterPress = useCallback(
    (cluster: ClusterItem) => {
      if (!superClusterRef.current || !mapRef.current) return;
      const zoom = mapRef.current.getZoom();
      if (zoom === undefined) return;
      try {
        const clusterExpansionZoom =
          superClusterRef.current.getClusterExpansionZoom(
            Number.parseInt(cluster.id)
          );

        if (clusterExpansionZoom === undefined) return;
        mapRef.current.setZoom(clusterExpansionZoom);
        mapRef.current.panTo({
          lat: cluster.lat,
          lng: cluster.lng,
        });
      } catch {}
    },
    [superClusterRef, mapRef]
  );

  const onLoad = useCallback(
    (
      map: google.maps.Map,
      initialBounds?: google.maps.LatLngBounds, // defined in custom onLoad
      onBoundsChanged?: (bounds: google.maps.LatLngBounds) => void
    ) => {
      mapRef.current = map;
      const bounds = initialBounds || initialBoundsRef.current; // defined before map was even displayed
      if (!!bounds) {
        map.fitBounds(bounds);
        onRegionChangeComplete(onBoundsChanged);
        prevBoundsRef.current = bounds;
      }
    },
    [mapRef, onRegionChangeComplete, initialBoundsRef, prevBoundsRef]
  );

  const setClusterableItems: typeof _setClusterableItems = useCallback(
    (itemsOrFn) => {
      const newItems =
        typeof itemsOrFn === "function"
          ? itemsOrFn(clusterableItems)
          : itemsOrFn;
      forceMapUpdate(newItems);
      _setClusterableItems(newItems);
    },
    [_setClusterableItems, forceMapUpdate]
  );

  const onMapClick = useCallback(() => {
    setSelectedItemId(undefined);
  }, [setSelectedItemId]);

  const toReturn = useMemo<ClusteredGenericMapControlContextProps<any>>(
    () => ({
      clusterableItems,
      setClusterableItems,
      soloItems,
      setSoloItems,
      calculateAndSetMapBounds,
      mapRef,
      clustersOnMap,
      itemsOnMap,
      onBoundsChangedDebounce,
      onRegionChangeComplete,
      onClusterPress,
      onLoad,
      onMapClick,
      setSelectedItemId,
      selectedItemMeta:
        selectedItem &&
        selectedItemGroup &&
        selectedItemIndexInGroup !== undefined
          ? {
              id: selectedItemId,
              group: selectedItemGroup,
              indexInGroup: selectedItemIndexInGroup,
              item: selectedItem,
            }
          : selectedItem && selectedItemGroup === undefined
          ? { id: selectedItemId, soloItem: selectedItem }
          : { id: selectedItemId },
      selectRelativeItemByOffset,
      panTo,
      initialBoundsRef,
      prevBoundsRef,
    }),
    [
      clusterableItems,
      setClusterableItems,
      soloItems,
      setSoloItems,
      calculateAndSetMapBounds,
      mapRef,
      clustersOnMap,
      itemsOnMap,
      onBoundsChangedDebounce,
      onRegionChangeComplete,
      onClusterPress,
      onLoad,
      onMapClick,
      setSelectedItemId,
      selectedItemId,
      selectedItem,
      selectedItemGroup,
      selectedItemIndexInGroup,
      selectRelativeItemByOffset,
      panTo,
      initialBoundsRef,
      prevBoundsRef,
    ]
  );

  if (!Provider) {
    return null;
  }

  return <Provider value={toReturn}>{children}</Provider>;
};
