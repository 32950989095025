import { DesignationType } from "common/types/comparableFields.types";
import { AppraiserRole, ClimateRiskType } from "../types/appraiser.types";
import i18n from "../i18n/i18n";

export enum FloorType {
  All = -1,
  Basement = 0,
  First = 1,
  Middle = 2,
  Last = 3,
}

// Also requires changes in backend (valuations)
export enum WallType {
  StoneConcrete = "StoneConcrete",
  Blocks = "Blocks",
  ReinforcedConcretePlates = "ReinforcedConcretePlates",
  MonolithicReinforcedConcrete = "MonolithicReinforcedConcrete",
  WoodWithFrame = "WoodWithFrame",
  MetalWithFrame = "MetalWithFrame",
  Bricks = "Bricks",
  Logs = "Logs",
}

export const getDesignationTypeTranslation = (
  type: DesignationType
): string => {
  return i18n.t(`propertyTypes.designationType.${type}`);
};

export const getWallTypeTranslation = (type: WallType): string => {
  return i18n.t(`propertyTypes.wallType.${type}`);
};

export const DesignationTypesForValuation = [
  DesignationType.ResidentialApartments,
  DesignationType.Recreation,
  DesignationType.Residential1Flat,
  DesignationType.Residential2Flats,
  DesignationType.Residential3OrMoreFlats,
  DesignationType.Hotels,
  DesignationType.Administrative,
  // DesignationType.ManufacturingIndustry,
  DesignationType.Manufacturing,
  DesignationType.Other,
];

export const ClimateRiskTypesTranslations: Record<ClimateRiskType, string> = {
  [ClimateRiskType.Low]: "Maža",
  [ClimateRiskType.Medium]: "Vidutinė",
  [ClimateRiskType.High]: "Didelė",
};