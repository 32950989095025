import React from "react";
import { useTranslation } from "react-i18next";
import { SimpleTableComponent } from "../../components/tables/simpleTable/SimpleTableComponent";
import { SimpleTableRow } from "../../components/tables/simpleTable/SimpleTableRow";
import { SimpleTableHeader } from "../../components/tables/simpleTable/SimpleTableHeader";
import { useQuery } from "react-query";
import { getAdminAdjustments } from "../../api/api";
import { AdjustmentsAdminRow } from "./AdjustmentsAdminRow";
import {
  adjustmentFields,
  getTranslatedFieldName,
} from "common/helpers/comparables.helpers";
import { ComparableTransactionFull } from "common/types/common.types";
import colors from "common/styles/colors";
import { ContentCardTitle } from "../../components/ContentCardTitle";

interface AdjustmentsAdminRouteProps {}

export const AdjustmentsAdminRoute: React.FC<
  AdjustmentsAdminRouteProps
> = () => {
  const { t } = useTranslation();
  const { data } = useQuery("admin_adjustments", getAdminAdjustments);

  return (
    <div
      style={{
        backgroundColor: colors.white,
        padding: "20px",
        borderRadius: "10px",
        paddingTop: "30px",
      }}
    >
      <ContentCardTitle title={t("adjustments.title")} />
      <div style={{ height: "30px" }} />

      <SimpleTableComponent>
        <SimpleTableRow>
          <SimpleTableHeader>
            {t("adjustments.table.adjustment")}
          </SimpleTableHeader>
          <SimpleTableHeader>
            {t("adjustments.table.description")}
          </SimpleTableHeader>
          <SimpleTableHeader>
            {t("adjustments.table.methodology")}
          </SimpleTableHeader>
          <SimpleTableHeader>{t("adjustments.table.action")}</SimpleTableHeader>
        </SimpleTableRow>
        {data?.adjustmentFields.map((field) => (
          <AdjustmentsAdminRow
            key={field.field}
            field={field.field}
            fieldFullName={getTranslatedFieldName(
              field.field as keyof ComparableTransactionFull,
              t
            )}
            description={field.description}
            providerSelected={field.providerSelected}
            providers={field.providers}
          />
        ))}
        {data?.additionalFields.map((field) => (
          <AdjustmentsAdminRow
            key={field.field}
            field={field.field}
            description={field.description}
            appraiserPropertyFieldId={field.appraiserPropertyFieldId}
          />
        ))}
      </SimpleTableComponent>
    </div>
  );
};
