import { CheckboxInput } from "common/components/form/CheckboxInput";
import { SimpleTableComponent } from "../../components/tables/simpleTable/SimpleTableComponent";
import { SimpleTableElement } from "../../components/tables/simpleTable/SimpleTableElement";
import { SimpleTableHeader } from "../../components/tables/simpleTable/SimpleTableHeader";
import { SimpleTableRow } from "../../components/tables/simpleTable/SimpleTableRow";
import { PropertyReadingSingleOverview } from "../../types/api.types";
import { getAddressFromOverview } from "./PropertyViaPDFConfirmRoute";
import { DesignationType } from "common/types/comparableFields.types";
import { IconButton, Tooltip } from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { getDesignationTypeTranslation } from "../../helpers/comparableFields.helpers";
import { useTranslation } from "react-i18next";

interface PropertyReadingTableProps {
  flattenedProperties: PropertyReadingSingleOverview[];
  onPropertySelect: (index: number, newCheckedValue: boolean) => void;
  onSelectAll: (newCheckedValue: boolean) => void;
}

export const PropertyReadingTable: React.FC<PropertyReadingTableProps> = ({
  flattenedProperties,
  onPropertySelect,
  onSelectAll,
}) => {
  const { t } = useTranslation();

  return (
    <SimpleTableComponent>
      <SimpleTableRow>
        <SimpleTableHeader>
          <CheckboxInput
            isChecked={flattenedProperties.every(
              (property) => !!property.isChecked
            )}
            onChange={onSelectAll}
          />
        </SimpleTableHeader>
        <SimpleTableHeader textAlign="left">
          {t("propertyTable.address")}
        </SimpleTableHeader>
        <SimpleTableHeader textAlign="left">
          {t("propertyTable.designation")}
        </SimpleTableHeader>
        <SimpleTableHeader textAlign="left">
          {t("propertyTable.fileList")}
        </SimpleTableHeader>
        <SimpleTableHeader textAlign="center">
          {t("propertyTable.filePreview")}
        </SimpleTableHeader>
      </SimpleTableRow>
      {flattenedProperties.map((propertyOrAsset, index) => (
        <SimpleTableRow key={index}>
          <SimpleTableElement>
            <CheckboxInput
              isChecked={!!propertyOrAsset.isChecked}
              onChange={(newValue) => onPropertySelect(index, newValue)}
            />
          </SimpleTableElement>
          <SimpleTableElement textAlign="left">
            {getAddressFromOverview(propertyOrAsset || "")}
          </SimpleTableElement>
          <SimpleTableElement textAlign="left">
            {getDesignationTypeTranslation(
              (propertyOrAsset.data.property?.designationType ||
                propertyOrAsset.data.asset?.designationTypeId ||
                DesignationType.Other) as DesignationType
            )}
          </SimpleTableElement>
          <SimpleTableElement textAlign="left">
            {propertyOrAsset.origins.join("; ")}
          </SimpleTableElement>
          <SimpleTableElement>
            <Tooltip title={t("propertyTable.notImplemented")}>
              <span>
                <IconButton disabled>
                  <VisibilityOutlinedIcon />
                </IconButton>
              </span>
            </Tooltip>
          </SimpleTableElement>
        </SimpleTableRow>
      ))}
    </SimpleTableComponent>
  );
};
