import { OverlayViewF } from "@react-google-maps/api";
import React from "react";
import { Box } from "@mui/material";
import { ComparableIconOverlayViewProps } from "./ComparableIconOverlayView";
import { PropertyIconProps } from "common/components/map/PropertyIcon";

interface CardOverlayViewProps {
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  markerProps: ComparableIconOverlayViewProps & PropertyIconProps;
  children?: React.ReactNode;
  xOffSet?: number;
}

export const CardOverlayView: React.FC<CardOverlayViewProps> = ({
  onMouseEnter,
  onMouseLeave,
  children,
  markerProps,
  xOffSet,
}) => {
  return (
    <OverlayViewF
      mapPaneName="floatPane"
      position={{ lat: markerProps.lat, lng: markerProps.lng }}
      getPixelPositionOffset={(width) => ({
        x: -width / 2 + (xOffSet ?? 0),
        y: 15,
      })}
    >
      <Box
        sx={{
          maxWidth: "490px",
          boxShadow: 5,
          borderRadius: "10px",
        }}
        onClick={(e) => e.stopPropagation()}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {children}
      </Box>
    </OverlayViewF>
  );
};
