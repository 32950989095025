import { GoogleMap, MarkerF, PolygonF } from "@react-google-maps/api";
import React from "react";
import { PropertyPolygon } from "../../types/api.types";
import colors from "common/styles/colors";
import { mapStyle } from "./constants";

interface MarkerMapProps {
  lat: number;
  lng: number;
  width?: string;
  height: string;
  polygonCoordinates?: PropertyPolygon;
}

export const MarkerMap: React.FC<MarkerMapProps> = ({
  lat,
  lng,
  width,
  height,
  polygonCoordinates,
}) => {
  const containerStyle = {
    width,
    height: height,
  };

  const polygonOptions = {
    fillColor: colors.grey,
    strokeColor: colors.premium,
    clickable: false,
    draggable: false,
    editable: false,
  };

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={{ lat, lng }}
      zoom={15}
      options={{
        fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: true,
        styles: mapStyle,
      }}
    >
      <MarkerF position={{ lat, lng }} />
      {polygonCoordinates?.polygon.length && (
        <PolygonF path={polygonCoordinates.polygon} options={polygonOptions} />
      )}
    </GoogleMap>
  );
};
