import { useEffect } from "react";
import { SimpleDialog } from "../dialogs/SimpleDialog";
import { LoadingButton } from "@mui/lab";
import { useMutation } from "react-query";
import { showToastSuccess } from "common/toast/toast";
import { regenerateAppraiserLink } from "../../api/api";
import { AppraiserPasswordLink } from "../../components/admin/AppraiserPasswordLink";
import { Appraiser } from "../../types/appraiser.types";
import { Typography } from "common/components/Typography";
import { LoaderCentered } from "../loaders/LoaderCentered";
import { useTranslation } from "react-i18next";

interface AppraiserPasswordResetDialogProps {
  appraiser: Appraiser | undefined;
  open: boolean;
  onClose: () => void;
  type: "registration" | "reset";
}

export const AppraiserPasswordResetDialog: React.FC<
  AppraiserPasswordResetDialogProps
> = ({ appraiser, open, onClose, type }) => {
  const { t } = useTranslation();

  const { mutate, isLoading, reset } = useMutation(
    async () => {
      await regenerateAppraiserLink(appraiser?._id || "");
    },
    {
      onSuccess: () => {
        showToastSuccess(
          t("appraiserInfo.passwordResetLinkDialog.successMessage")
        );
      },
    }
  );

  useEffect(() => {
    if (
      open &&
      appraiser &&
      ((type === "reset" && appraiser?.decodedPasswordResetToken?.error) ||
        (type === "registration" && appraiser?.decodedRegistrationToken?.error))
    ) {
      reset();
      mutate();
    }
  }, [open, appraiser, type, mutate, reset]);

  return (
    <SimpleDialog
      title={
        type === "registration"
          ? t("appraiserInfo.passwordResetLinkDialog.registrationLinkTitle")
          : t("appraiserInfo.passwordResetLinkDialog.passwordResetLinkTitle")
      }
      open={open}
      onClose={onClose}
      hideButton={true}
    >
      {isLoading ? (
        <LoaderCentered />
      ) : (
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Typography variant="h4" textStyle={{ fontWeight: "normal" }}>
            {type === "reset"
              ? t(
                  "appraiserInfo.passwordResetLinkDialog.passwordResetLinkLabel",
                  {
                    email: appraiser?.email
                  }
                )
              : t(
                  "appraiserInfo.passwordResetLinkDialog.registrationLinkLabel",
                  {
                    email: appraiser?.email
                  }
                )}
          </Typography>
          <AppraiserPasswordLink
            appraiser={appraiser as Appraiser}
            type={type}
            decodedToken={
              type === "registration"
                ? appraiser?.decodedRegistrationToken
                : appraiser?.decodedPasswordResetToken
            }
            link={
              type === "registration"
                ? appraiser?.registrationLink
                : appraiser?.resetLink
            }
          />
          <Typography variant="h4" textStyle={{ fontWeight: "normal" }}>
            {t("appraiserInfo.passwordResetLinkDialog.or")}
          </Typography>
          <LoadingButton variant="outlined" style={{ width: "200px" }} disabled={true}>
            {t("appraiserInfo.passwordResetLinkDialog.buttons.sendEmail")}
          </LoadingButton>
        </div>
      )}
    </SimpleDialog>
  );
};
