import React, { useRef, useState } from "react";
import {
  basicPermissions,
  PropertyPermission,
  PropertyShareSettings,
} from "./PropertyShareSettings";
import { useMutation } from "react-query";
import { generatePropertyShareLink } from "../../api/api";
import { MUILoadingButton } from "../../components/buttons/MUILoadingButton";
import { showToastError, showToastSuccess } from "common/toast/toast";
import { setClipboard } from "common/clipboard/clipboard";
import { useProperty } from "../../hooks/property.hooks";
import { useTranslation } from "react-i18next";
import { Checkbox, FormControlLabel } from "@mui/material";
import { getExternalLink } from "../../components/links/ExternalLink";

interface PropertyNewLinkShareSettingsProps {
  propertyId: string;
}

export const PropertyNewLinkShareSettings: React.FC<
  PropertyNewLinkShareSettingsProps
> = ({ propertyId }) => {
  const { data: property } = useProperty(propertyId);
  const { t } = useTranslation();
  const [stickPdfs, setStickPdfs] = useState(false);

  const { isLoading: isLoadingLink, mutate: mutateLink } = useMutation(
    generatePropertyShareLink,
    {
      onSuccess: (data) => {
        setClipboard(data.link);
        showToastSuccess(t("propertyShare.linkCopied"));
      },
      onError: () => {
        showToastError();
      },
    }
  );

  const { isLoading: isLoadingPdf, mutate: mutatePdf } = useMutation(
    generatePropertyShareLink,
    {
      onSuccess: (data) => {
        if (!data.pdfUrl) {
          showToastError();
          return;
        }
        window.location.href = getExternalLink(data.pdfUrl);
      },
      onError: () => {
        showToastError();
      },
    }
  );

  const currentPermissions = useRef<PropertyPermission[]>(basicPermissions);

  const checkPermissions = () => {
    if (currentPermissions.current.length === 0) {
      showToastError(t("propertyShare.selectAtLeastOne"));
      return false;
    }
    return true;
  };

  const mutate = (pdf?: boolean) => {
    const data = {
      propertyId,
      permissions: currentPermissions.current,
      pdf,
    };

    if (pdf) {
      mutatePdf({ ...data, stickPdfs });
    } else {
      mutateLink(data);
    }
  };

  return (
    <PropertyShareSettings
      property={property}
      initialPermissions={basicPermissions}
      onChange={(permissions) => {
        currentPermissions.current = permissions;
      }}
    >
      <div
        style={{
          marginTop: "30px",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={stickPdfs}
              onChange={(e) => setStickPdfs(e.target.checked)}
            />
          }
          label={t("propertyShare.attachUploadedFiles")}
        />
        <div
          style={{
            display: "flex",
            gap: "10px",
            flexDirection: "row",
            justifyContent: "end",
          }}
        >
          <MUILoadingButton
            loading={isLoadingPdf}
            variant="outlined"
            onClick={() => {
              if (checkPermissions()) {
                mutate(true);
              }
            }}
          >
            {t("propertyShare.createPdf")}
          </MUILoadingButton>
          <MUILoadingButton loading={isLoadingLink} onClick={() => mutate()}>
            {t("propertyShare.createLink")}
          </MUILoadingButton>
        </div>
      </div>
    </PropertyShareSettings>
  );
};
