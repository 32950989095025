import React from "react";
import { UsedCreditsRow } from "../../types/api.types";
import { Typography } from "common/components/Typography";
import { useTranslation } from "react-i18next";

interface PropertyCreditsRowProps {
  usedCreditsRow: UsedCreditsRow;
}

export const PropertyCreditsRow: React.FC<PropertyCreditsRowProps> = ({
  usedCreditsRow,
}) => {
  const { t } = useTranslation();

  const getActionTypeLabel = (actionType: string) => {
    const actionTypes = {
      "197": t("propertyCredits.transactionSearches"),
      "198": t("propertyCredits.purchasedTransactions"),
    };

    return actionTypes[actionType as keyof typeof actionTypes] || actionType;
  };

  return (
    <tr>
      <td>
        <Typography variant="caption" textStyle={{ fontSize: 14 }}>
          {getActionTypeLabel(usedCreditsRow.actionType)}
        </Typography>
      </td>
      <td style={{ paddingLeft: "20px" }}>
        <Typography textStyle={{ fontSize: 14 }}>
          {usedCreditsRow.actionsCount}
        </Typography>
      </td>
      <td style={{ paddingLeft: "20px" }}>
        <Typography textStyle={{ fontSize: 14 }}>{`${
          usedCreditsRow.creditsUsed / 100
        } €`}</Typography>
      </td>
    </tr>
  );
};
