import React, { useState } from "react";
import { useQuery } from "react-query";
import { useFormContext } from "react-hook-form";
import { getStreets } from "../../../api/geocode.api";
import { FormAutocomplete } from "../../../components/form/FormAutocomplete";
import { StreetAutocompleteResult } from "../../../types/geocoding.types";
import { useTranslation } from "react-i18next";

interface StreetFormFieldProps {}

export const getStreetLabel = (street: StreetAutocompleteResult) => {
  return `${street.VARDAS_K} ${street.TIPO_SANTRUMPA}`;
};

export const StreetFormField: React.FC<StreetFormFieldProps> = ({}) => {
  const { t } = useTranslation();
  const [query, setQuery] = useState("");

  const { watch } = useFormContext();

  const cityId = watch("cityId");

  const enabled = !!cityId;

  const { data, isLoading } = useQuery(
    ["streets_autocomplete", query, cityId ?? ""],
    () => getStreets(query, cityId ?? ""),
    {
      keepPreviousData: true,
      enabled,
    }
  );

  const options = data?.map(getStreetLabel);

  const { setValue } = useFormContext();

  return (
    <FormAutocomplete
      label={t("propertyForm.address.street")}
      name="street"
      options={options ?? []}
      onChange={(value) => {
        setQuery(value ?? "");
      }}
      isLoading={isLoading}
      isRequired
      disableFilterOptions
      disabled={!enabled}
      onSelect={(value) => {
        const selectedStreet = data?.find(
          (street) => getStreetLabel(street) === value
        );

        setValue("streetId", selectedStreet?.GAT_KODAS ?? null);
        setValue("streetNumber", null);
      }}
    />
  );
};
