import { useParams } from "react-router-dom";
import { AppraiserLoginDetails } from "./accountLoginInfo/AppraiserLoginDetails";
import { useQuery } from "react-query";
import { getAppraiserLoginDetails } from "../api/api";
import { LoaderCentered } from "../components/loaders/LoaderCentered";
import { useTranslation } from "react-i18next";

interface AppraiserLoginDetailsRouteProps {}

export const AppraiserLoginDetailsRoute: React.FC<
  AppraiserLoginDetailsRouteProps
> = () => {
  const { appraiserId } = useParams();

  const { t } = useTranslation();

  const { data: appraiserLoginInfo = [], isLoading } = useQuery(
    ["appraiserLoginInfo", appraiserId],
    () => getAppraiserLoginDetails(appraiserId ?? "")
  );

  if (isLoading) {
    return <LoaderCentered />;
  }

  if (!appraiserLoginInfo || appraiserLoginInfo.length === 0) {
    return <div>{t("appraiserLoginInfo.noInfoMessage")}</div>;
  }

  return (
    <AppraiserLoginDetails appraiserLoginInfo={appraiserLoginInfo ?? ""} />
  );
};
