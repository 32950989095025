import { GoogleMap, PolygonF } from "@react-google-maps/api";
import { MatchedListing } from "common/types/common.types";
import React, { useMemo, useContext } from "react";
import { ComparableMarker } from "../map/ComparableMarker";
import { ComparableIconOverlayView } from "../map/ComparableIconOverlayView";
import { mainPropertyBGColor } from "../../hooks/propertyIcons.hooks";
import { MY_PROPERTY_SIZE_MULTIPLIER, mapStyle } from "../map/constants";
import colors from "common/styles/colors";
import { AppraiserProperty } from "../../types/appraiser.types";
import { ClusteredListingsMapControlContext } from "../ClusteredGenericMapControlProvider";
import { CardPagination } from "../pagination/CardPagination";
import { CardOverlayView } from "../map/CardOverlayView";
import { ClusterOverlayView } from "../map/ClusterOverlayView";
import {
  ZonePolygonContext,
  ZonePolygonContextType,
} from "../../routes/property/timeOnMarket/ZonePolygonProvider";
import { ValuationZoneCard } from "../../routes/property/timeOnMarket/ValuationZoneCard";
import { PropertyIcon } from "common/components/map/PropertyIcon";
import { ValuationZoneIcon } from "./ValuationZoneIcon";
import { MapInfo } from "../map/legend/MapInfo";
import { usePrintableQuery } from "../../hooks/router.hooks";
import { printableListingIconSize } from "common/components/map/constants";
import { ADDON_NUM_BADGE_PROPS } from "./AddonListingCard";

interface ZonePolygonProps {
  zone: ZonePolygonContextType["usedZones"][0];
  color: string;
  zoning: ZonePolygonContextType;
  boundingBox: google.maps.LatLngBounds | undefined;
  zoomLevel: number | undefined;
}

const ZonePolygon = ({
  zone,
  color,
  zoning,
  boundingBox,
  zoomLevel,
}: ZonePolygonProps) => {
  if (!zoning) return null;
  const isSelected = zoning?.selectedZone === zone.zone_nr;
  const coordinates =
    zoomLevel && zoomLevel < 10
      ? zone._simpleGeometry.coordinates
      : zone.geometry.coordinates;

  const handleClick = (e: google.maps.MapMouseEvent) => {
    e.stop();
    e.domEvent.stopPropagation();
    if (!e.latLng) return;
    const clickedPoint = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    zoning.setSelectedZonePoint(clickedPoint);

    if (zoning.selectedZone === zone.zone_nr) {
      return;
    } else {
      zoning.setSelectedZone(zone.zone_nr);
    }
  };

  if (boundingBox && !boundingBox.intersects(zone._box)) {
    return null;
  }

  return (
    <PolygonF
      key={zone.zone_nr}
      paths={coordinates.map((polygon) =>
        polygon.map((point) => ({ lat: point[1], lng: point[0] }))
      )}
      onMouseOver={() => zoning.setHoveredZone(zone.zone_nr)}
      onMouseOut={() => zoning.setHoveredZone(null)}
      onClick={handleClick}
      options={{
        fillOpacity: isSelected
          ? 0.5
          : zoning.hoveredZone === zone.zone_nr
          ? 0.3
          : 0.2,
        strokeWeight: 2.5,
        strokeColor: color,
        fillColor: color,
      }}
    />
  );
};

interface AddonMapProps {
  mapCardComponent: (
    listing: MatchedListing,
    paginationNode: React.ReactNode,
    onClick?: () => void,
    badgeNum?: number
  ) => React.ReactNode;
  mapPriceFn: (listing: MatchedListing) => number;
  property: AppraiserProperty | undefined;
}

export const AddonMap: React.FC<AddonMapProps> = ({
  mapCardComponent,
  mapPriceFn,
  property,
}) => {
  const clustering = useContext(ClusteredListingsMapControlContext);
  const zoning = useContext(ZonePolygonContext);
  const printable = usePrintableQuery();

  const minDistance = useMemo(() => {
    if (!property) return null;
    return zoning?.calculateDistanceToPolygon(property).minDistance;
  }, [zoning?.calculateDistanceToPolygon, property]);

  const center = useMemo(() => {
    if (!property) {
      return {
        lat: 0,
        lng: 0,
      };
    }
    return {
      lat: property?.lat,
      lng: property?.lng,
    };
  }, [property?.lat, property?.lng]);

  if (!property || !clustering) {
    return null;
  }

  const formatAsPrice = (listing: MatchedListing) =>
    `${Math.ceil(mapPriceFn(listing) / 1000)}k`;
  const getListingColor = (listing: MatchedListing, isGeoAlone = true) =>
    listing.sold_date !== null && isGeoAlone ? colors.red : colors.green;

  const boundingBox = clustering.mapRef.current?.getBounds();

  const handleMapClick = () => {
    zoning?.setSelectedZone(null);
    clustering.onMapClick();
  };

  const zoomLevel = clustering.mapRef.current?.getZoom();

  const legendItems = [
    {
      icon: <PropertyIcon backgroundColor={mainPropertyBGColor} />,
      label: "Vertinamasis objektas",
    },
    {
      icon: <ValuationZoneIcon color={colors.green} />,
      label: "Verčių zona, kurioje buvo rasti panašūs objektai",
    },
    {
      icon: <ValuationZoneIcon color={colors.red} />,
      label:
        "Verčių zona, kurioje buvo ieškoma panašių objektų, tačiau jų nebuvo rasta",
    },
  ];

  return (
    <GoogleMap
      options={{
        fullscreenControl: true,
        clickableIcons: false,
        mapTypeControl: false,
        gestureHandling: "greedy",
        styles: mapStyle,
      }}
      mapContainerStyle={{
        height: "100%",
        width: "100%",
      }}
      zoom={15}
      center={center}
      onLoad={clustering.onLoad}
      onClick={handleMapClick}
      onBoundsChanged={clustering.onBoundsChangedDebounce}
    >
      <div
        style={{
          position: "absolute",
          top: "75px",
          right: "10px",
          display: "flex",
          flexDirection: "row",
          gap: "10px",
        }}
      >
        <MapInfo legendItems={legendItems} />
      </div>
      {zoning?.usedZones.map((zone) => (
        <ZonePolygon
          key={zone.zone_nr}
          zone={zone}
          zoning={zoning}
          color={colors.green}
          boundingBox={boundingBox}
          zoomLevel={zoomLevel}
        />
      ))}
      {zoning?.ineffectiveZones.map((zone) => (
        <ZonePolygon
          key={zone.zone_nr}
          zone={zone}
          zoning={zoning}
          color={colors.red}
          boundingBox={boundingBox}
          zoomLevel={zoomLevel}
        />
      ))}
      {clustering.clustersOnMap.map((cluster) => (
        <ClusterOverlayView
          key={cluster.id}
          cluster={cluster}
          onClusterPress={clustering.onClusterPress}
        />
      ))}
      {clustering.itemsOnMap.map((listingGroup) => {
        const rootListing = listingGroup[0];
        const customText =
          listingGroup.length > 1
            ? `${listingGroup.length}`
            : formatAsPrice(rootListing.data);
        const isGeoAlone = listingGroup.length === 1;
        const selection = clustering.selectedItemMeta;

        return (
          <ComparableMarker
            key={rootListing.id}
            isSelected={listingGroup === selection.group}
            markerProps={{
              lat: rootListing.lat,
              lng: rootListing.lng,
              onClick: () => clustering.setSelectedItemId(rootListing.id),
              customText: customText,
              backgroundColor: getListingColor(rootListing.data, isGeoAlone),
            }}
          />
        );
      })}

      {clustering.soloItems.map((listingItem, index) => (
        // printable
        <ComparableMarker
          key={listingItem.id}
          isSelected={listingItem.id === clustering.selectedItemMeta.id}
          markerProps={{
            lat: listingItem.lat,
            lng: listingItem.lng,
            onClick: () => clustering.setSelectedItemId(listingItem.id),
            customText: formatAsPrice(listingItem.data),
            backgroundColor: getListingColor(listingItem.data),
            badgeCount: index + 1,
            iconSize: printableListingIconSize,
            badgeProps: ADDON_NUM_BADGE_PROPS,
          }}
        />
      ))}

      {!!clustering.selectedItemMeta.soloItem && (
        // printable
        <CardOverlayView
          markerProps={{
            lat: clustering.selectedItemMeta.soloItem.lat,
            lng: clustering.selectedItemMeta.soloItem.lng,
          }}
        >
          {mapCardComponent(
            clustering.selectedItemMeta.soloItem.data,
            false, // no pagination
            undefined, // no onclick
            printable
              ? (clustering.soloItems.indexOf(
                  clustering.selectedItemMeta.soloItem
                ) ?? -1) + 1
              : undefined
          )}
        </CardOverlayView>
      )}

      {!!clustering.selectedItemMeta.item && (
        <CardOverlayView
          markerProps={{
            lat: clustering.selectedItemMeta.item.lat,
            lng: clustering.selectedItemMeta.item.lng,
          }}
        >
          {mapCardComponent(
            clustering.selectedItemMeta.item.data,
            clustering.selectedItemMeta.group.length > 1 && (
              <CardPagination
                currentPage={clustering.selectedItemMeta.indexInGroup + 1}
                maxPage={clustering.selectedItemMeta.group.length}
                onPageRelativeChange={clustering.selectRelativeItemByOffset}
              />
            )
          )}
        </CardOverlayView>
      )}

      <ComparableIconOverlayView
        lat={property.lat}
        lng={property.lng}
        backgroundColor={mainPropertyBGColor}
        sizeMultiplier={MY_PROPERTY_SIZE_MULTIPLIER}
      />

      {!!zoning?.selectedZone &&
        !!zoning?.selectedZoneData &&
        !!zoning?.selectedZonePoint && (
          <CardOverlayView
            markerProps={{
              lat: zoning.selectedZonePoint.lat,
              lng: zoning.selectedZonePoint.lng,
            }}
          >
            <ValuationZoneCard
              zoneInfo={zoning.selectedZoneData}
              coeffName={zoning.coeffName}
              distance={minDistance ?? null}
              zoneCenter={zoning.selectedZoneData.center}
            />
          </CardOverlayView>
        )}
    </GoogleMap>
  );
};
