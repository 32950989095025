import React, { useEffect } from "react";
import { SimpleDialog } from "../../components/dialogs/SimpleDialog";
import { FormProvider, useForm } from "react-hook-form";
import { FormInput } from "common/components/form/hookForms/FormInput";
import { FormPhoneInput } from "../../components/form/FormPhoneInput";
import { Box, TextField } from "@mui/material";
import { SendVerificationCodeButton } from "./SendVerificationCodeButton";
import { Typography } from "common/components/Typography";
import { useAppraiserVerification } from "../../helpers/appraiserVerification.helpers";
import { useTranslation } from "react-i18next";

interface VerificationFormProps {
  title: string;
  open: boolean;
  onClose: () => void;
  onVerified: (loginReason?: string) => void;
  phoneNumber?: string;
  token?: string;
  buttonText: string;
  verificationInformation: string;
  loginReasonRequired?: boolean;
}

export const VerificationForm: React.FC<VerificationFormProps> = ({
  title,
  open,
  onClose,
  onVerified,
  phoneNumber,
  token,
  buttonText,
  verificationInformation,
  loginReasonRequired = false,
}) => {
  const methods = useForm<{
    phoneNumber: string;
    verificationCode: string;
    loginReason?: string;
  }>();

  const {
    sendVerificationCode,
    verifyCodeMutation,
    isSendVerificationCodeLoading,
  } = useAppraiserVerification({
    phoneNumber,
    token,
    open,
    onVerified,
    onClose,
  });

  const { t } = useTranslation();
  const phoneNumberInput = methods.watch("phoneNumber");
  const verificationCode = methods.watch("verificationCode");
  const loginReason = methods.watch("loginReason");

  const handleSendVerificationCode = () => {
    const number = phoneNumber || phoneNumberInput;
    sendVerificationCode(number);
  };

  const handleVerifyCode = () => {
    const verificationCode = methods.getValues("verificationCode");
    const loginReason = methods.getValues("loginReason");
    if (!verificationCode || (loginReasonRequired && !loginReason)) return;
    verifyCodeMutation.mutate({
      verificationCode,
      loginReason,
    });
  };

  useEffect(() => {
    if (!open) {
      methods.reset({
        phoneNumber: "",
        verificationCode: "",
        loginReason: "",
      });
    }
  }, [open, methods]);

  return (
    <SimpleDialog
      title={title}
      open={open}
      onClose={onClose}
      buttonText={buttonText}
      onSubmit={handleVerifyCode}
      buttonDisabled={
        !verificationCode || (loginReasonRequired && !loginReason)
      }
    >
      <FormProvider {...methods}>
        <Box display="flex" flexDirection="column" gap={2}>
          {phoneNumber ? (
            <Box display="flex" flexDirection="column" gap={"20px"}>
              <Box>
                <Typography>{verificationInformation}</Typography>
              </Box>
              <Box display="flex" justifyContent="center" alignItems="center">
                <SendVerificationCodeButton
                  loading={isSendVerificationCodeLoading}
                  disabled={!!phoneNumberInput && !!phoneNumber}
                  onClick={handleSendVerificationCode}
                />
              </Box>
            </Box>
          ) : (
            <Box display="flex" alignItems="center" gap={2} width="100%">
              <Box
                flexGrow={1}
                maxWidth="300px"
                display="flex"
                alignItems="center"
              >
                <FormPhoneInput label={t("verification.phoneNumber")} name="phoneNumber" />
              </Box>
              <SendVerificationCodeButton
                loading={isSendVerificationCodeLoading}
                disabled={!!phoneNumberInput && !!phoneNumber}
                onClick={handleSendVerificationCode}
              />
            </Box>
          )}

          <FormInput
            label={t("verification.confirmationCode")}
            variant="number"
            name="verificationCode"
            maxWidth={400}
          />

          {loginReasonRequired && (
            <TextField
              {...methods.register("loginReason")}
              name="loginReason"
              label={t("verification.loginReason")}
              multiline
              rows={3}
            />
          )}
        </Box>
      </FormProvider>
    </SimpleDialog>
  );
};
