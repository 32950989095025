import React from "react";
import { SimpleDialog } from "../../components/dialogs/SimpleDialog";
import { PropertyComment } from "../../types/api.types";
import { TextField } from "@mui/material";
import { useMutation, useQueryClient } from "react-query";
import { updatePropertyComment } from "../../api/api";
import { showToastError } from "common/toast/toast";
import { useTranslation } from "react-i18next";

interface PropertyCommentEditProps {
  initialComment?: PropertyComment | null;
  propertyId: string;
  open: boolean;
  onClose: () => void;
}

export const PropertyCommentEdit: React.FC<PropertyCommentEditProps> = ({
  initialComment,
  open,
  onClose,
  propertyId,
}) => {
  const { t } = useTranslation();
  const [comment, setComment] = React.useState<string>(
    initialComment?.comment ?? ""
  );

  const queryClient = useQueryClient();

  const { mutate: mutateComment, isLoading } = useMutation(
    updatePropertyComment,
    {
      onSuccess: (newComment) => {
        queryClient.setQueryData(["propertyComment", propertyId], newComment);
        onClose();
      },
      onError: () => {
        showToastError();
      },
    }
  );

  return (
    <SimpleDialog
      title={
        !!initialComment
          ? t("propertyComment.editComment")
          : t("propertyComment.newComment")
      }
      open={open}
      onClose={onClose}
      onSubmit={() => {
        mutateComment({
          comment,
          propertyId,
        });
      }}
      isLoading={isLoading}
    >
      <TextField
        label={t("propertyComment.comment")}
        multiline
        rows={5}
        value={comment}
        onChange={(e) => {
          setComment(e.target.value);
        }}
      />
    </SimpleDialog>
  );
};
