import { OpacityButton } from "common/components/buttons/base/OpacityButton";
import React from "react";
import MapIcon from "../../assets/icons/map.svg";
import colors from "common/styles/colors";
import { Typography } from "common/components/Typography";
import { useTranslation } from "react-i18next";

interface ViewOnMapButtonProps {
  onViewOnMapClick?: () => void;
}

export const ViewOnMapButton: React.FC<ViewOnMapButtonProps> = ({
  onViewOnMapClick,
}) => {
  const { t } = useTranslation();

  return (
    <OpacityButton onPress={onViewOnMapClick} style={{ width: 200 }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "8px",
          height: "30px",
        }}
      >
        <MapIcon />
        <Typography
          variant="h4"
          textStyle={{ fontSize: 16, color: colors.purple2, marginTop: 1 }}
        >
          {t("propertyList.viewOnMap")}
        </Typography>
      </div>
    </OpacityButton>
  );
};
