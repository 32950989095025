import { useState } from "react";
import { getLithuanianDateTimeString } from "common/helpers/dateStringHelpers";
import { ContentCard } from "../../components/ContentCard";
import { Appraiser } from "../../types/appraiser.types";
import { InfoRow } from "../propertiesCRUD/InfoRow";
import { getStatusChip } from "../../helpers/appraiserInfo.helpers";
import { Typography } from "common/components/Typography";
import { useTranslation } from "react-i18next";
import colors from "common/styles/colors";
import { useNavigate } from "react-router-dom";
import { Button, Divider } from "@mui/material";
import { AppraiserPasswordLink } from "../../components/admin/AppraiserPasswordLink";
import { AppraiserPasswordResetDialog } from "../../components/admin/AppraiserPasswordResetDialog";
import { AppraiserRoleUpdateDialog } from "../../components/admin/AppraiserRoleUpdateDialog";

interface AppraiserProfileInfoRouteProps {
  appraiser: Appraiser;
}

export const AppraiserProfileInfo: React.FC<AppraiserProfileInfoRouteProps> = ({
  appraiser,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [passwordResetDialogOpen, setPasswordResetDialogOpen] = useState(false);

  return (
    <ContentCard title={t("appraiserInfo.title")}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "40px",
        }}
      >
        <InfoRow
          label={t("appraiserInfo.email")}
          value={appraiser.email}
          customLabelWidth="200px"
        />
        <InfoRow
          label={t("appraiserInfo.fullName")}
          value={appraiser.fullName ?? "-"}
          customLabelWidth="200px"
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "50%",
          }}
        >
          <div style={{ width: "700px" }}>
            <InfoRow
              label={t("appraiserInfo.role")}
              value={t("appraisers.roles." + appraiser.role)}
              customLabelWidth="200px"
            />
          </div>
          <div
            style={{ display: "flex", alignItems: "center", height: "15px" }}
          >
            <AppraiserRoleUpdateDialog appraiser={appraiser} />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "50%",
          }}
        >
          <div style={{ flex: 1 }}>
            <InfoRow
              label={t("appraiserInfo.lastLogin")}
              value={
                appraiser.lastActiveAt
                  ? getLithuanianDateTimeString(appraiser.lastActiveAt)
                  : "-"
              }
              customLabelWidth="200px"
            />
          </div>
          <div
            style={{ display: "flex", alignItems: "center", height: "15px" }}
          >
            <Button
              variant="outlined"
              style={{
                width: "100px",
                borderColor: colors.beige,
              }}
              onClick={() => navigate("login-details")}
            >
              {t("appraiserInfo.buttons.more")}
            </Button>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            columnGap: "10px",
            alignItems: "center",
          }}
        >
          <Typography variant="caption" textStyle={{ width: "200px" }}>
            {t("appraiserInfo.status")}
          </Typography>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "15px",
            }}
          >
            {getStatusChip(appraiser, t)}
          </div>
        </div>

        <Divider />

        <InfoRow
          label={t("appraiserInfo.invitedAt")}
          value={
            appraiser.invitedAt
              ? getLithuanianDateTimeString(appraiser.invitedAt)
              : "-"
          }
          customLabelWidth="200px"
        />
        {!appraiser.acceptedAt && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              columnGap: "10px",
            }}
          >
            <Typography variant="caption" textStyle={{ width: "200px" }}>
              {t("appraiserInfo.invitationLink")}
            </Typography>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                height: "15px",
              }}
            >
              <AppraiserPasswordLink
                appraiser={appraiser}
                type="registration"
                decodedToken={appraiser.decodedRegistrationToken}
                link={appraiser.registrationLink}
                variant="link"
              />
            </div>
          </div>
        )}
        {appraiser.acceptedAt && (
          <div>
            <Button
              variant="outlined"
              onClick={() => setPasswordResetDialogOpen(true)}
              style={{
                width: "200px",
              }}
            >
              {t("appraiserInfo.buttons.resetPassword")}
            </Button>

            <AppraiserPasswordResetDialog
              appraiser={appraiser}
              onClose={() => setPasswordResetDialogOpen(false)}
              open={passwordResetDialogOpen}
              type="reset"
            />
          </div>
        )}
      </div>
    </ContentCard>
  );
};
