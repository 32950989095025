import { AppraiserProfileInfo } from "./appraiserProfileInfo/AppraiserProfileInfo";
import { useParams } from "react-router-dom";
import { useAppraiserInfo } from "../hooks/api.hooks";
import { LoaderCentered } from "../components/loaders/LoaderCentered";

interface AppraiserProfileInfoRouteProps {}

export const AppraiserProfileInfoRoute: React.FC<
  AppraiserProfileInfoRouteProps
> = () => {
  const { appraiserId } = useParams();

  const { data: appraiser, isLoading } = useAppraiserInfo(appraiserId ?? "");

  if (isLoading) {
    return <LoaderCentered />;
  }

  if (!appraiser) {
    return null;
  }

  return <AppraiserProfileInfo appraiser={appraiser} />;
};
