import { Tab, Tabs } from "@mui/material";
import React from "react";
import { FeedStatistics } from "./feed/FeedStatistics";
import { FeedPredictions } from "./feed/FeedPredictions";
import { useTranslation } from "react-i18next";

interface FeedRouteProps {}

const tabs = [
  {
    value: "review",
    translationKey: "feed.tabs.overview",
  },
  {
    value: "objects",
    translationKey: "feed.tabs.objects",
  },
];

export const FeedRoute: React.FC<FeedRouteProps> = () => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = React.useState("review");

  return (
    <div>
      <Tabs value={selectedTab} style={{ marginBottom: "20px" }}>
        {tabs.map((tab) => (
          <Tab
            key={tab.value}
            value={tab.value}
            label={t(tab.translationKey)}
            onClick={() => setSelectedTab(tab.value)}
          />
        ))}
      </Tabs>
      {selectedTab === "review" && <FeedStatistics />}
      {selectedTab === "objects" && <FeedPredictions />}
    </div>
  );
};
