import React, { useMemo } from "react";
import { ContentCard } from "../../../components/ContentCard";
import { PropertyDistancesMap } from "./PropertyDistancesMap";
import { PropertyDistancesList } from "./PropertyDistancesList";
import { InfraByType } from "../../../types/api.types";
import { AppraiserProperty } from "../../../types/appraiser.types";
import { usePropertyInfra } from "../../../hooks/infra.hooks";
import { CircularProgress } from "@mui/material";

interface PropertyDistancesProps {
  property: AppraiserProperty;
  propertyToken?: string;
}

export const PropertyDistances: React.FC<PropertyDistancesProps> = ({
  property,
  propertyToken,
}) => {
  
  const token = propertyToken || "";

  const { data: infras, isLoading, isFetched } = usePropertyInfra(property._id, token);

  const infrasByType = useMemo(() => {
    if (!infras) return [];
    const infrasByType: InfraByType[] = [];
    for (const [index_in_whole_list, infra] of infras.entries()) {
      const infraType = infra.type;
      const index = infrasByType.findIndex((i) => i.type === infraType);
      const _infra = { ...infra, index: index_in_whole_list };

      if (index === -1) {
        infrasByType.push({
          type: infraType,
          infras: [_infra],
        });
      } else {
        infrasByType[index].infras.push(_infra);
      }
    }

    return infrasByType;
  }, [infras]);

  return !isFetched || isLoading ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "200px",
      }}
    >
      <CircularProgress />
    </div>
  ) : (
    <ContentCard title="Atstumai">
      <PropertyDistancesMap infrasByType={infrasByType} property={property} />
      <PropertyDistancesList infrasByType={infrasByType} property={property} />
    </ContentCard>
  );
};
