import React from "react";
import { LoaderCentered } from "../../../components/loaders/LoaderCentered";
import { SimilarPropertiesInsights } from "./SimilarPropertiesInsights";
import { useProperty, useSimilarListings } from "../../../hooks/property.hooks";

interface SimilarPropertiesInsightsRouteProps {
  propertyId: string;
}

export const SimilarPropertiesInsightsRoute: React.FC<
  SimilarPropertiesInsightsRouteProps
> = ({ propertyId }) => {
  const { data: property } = useProperty(propertyId);
  const { data: similarProperties, isLoading } = useSimilarListings(property);

  if (isLoading) {
    return (
      <div
        style={{
          paddingTop: "50px",
        }}
      >
        <LoaderCentered />
      </div>
    );
  }

  if (!similarProperties?.listings.length) {
    return (
      <div style={{ textAlign: "center", fontSize: "18px", marginTop: "70px" }}>
        Panašių objektų nerasta
      </div>
    );
  }

  return (
    <SimilarPropertiesInsights
      property={property}
      similarListings={similarProperties}
    />
  );
};
