import React from "react";
import { FormControlLabel, Switch, Typography } from "@mui/material";
import { ContentCard } from "../../components/ContentCard";
import { useMutation, useQueryClient } from "react-query";
import { setPropertyPortfolioFlag } from "../../api/api";
import { showToastError, showToastSuccess } from "common/toast/toast";
import { useTranslation } from "react-i18next";
import { AppraiserProperty } from "../../types/appraiser.types";
interface PropertyPortfolioSettingsProps {
  property: AppraiserProperty;
}

export const PropertyPortfolioSettings: React.FC<
  PropertyPortfolioSettingsProps
> = ({ property }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutate: updatePortfolioFlag } = useMutation(
    (newValue: boolean) => setPropertyPortfolioFlag(property._id, newValue),
    {
      onMutate: async (newValue: boolean) => {
        await queryClient.cancelQueries(["appraiserProperties", property._id]);

        const previousProperty = queryClient.getQueryData<AppraiserProperty>([
          "appraiserProperties",
          property._id,
        ]);

        if (previousProperty) {
          queryClient.setQueryData<AppraiserProperty>(
            ["appraiserProperties", property._id],
            {
              ...previousProperty,
              inPortfolio: newValue,
            }
          );
        }

        return { previousProperty };
      },
      onSuccess: () => {
        showToastSuccess(t("propertyDetails.portfolioUpdateSuccess"));
      },
      onError: (err, newValue, context) => {
        if (context?.previousProperty) {
          queryClient.setQueryData(
            ["appraiserProperties", property._id],
            context.previousProperty
          );
        }
        showToastError(t("propertyDetails.portfolioUpdateError"));
      },
      onSettled: () => {
        queryClient.invalidateQueries(["appraiserProperties", property._id]);
      },
    }
  );

  const handlePortfolioToggle = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = event.target.checked;
    updatePortfolioFlag(newValue);
  };

  return (
    <ContentCard title={t("propertyDetails.portfolioSettings")}>
      <FormControlLabel
        control={
          <Switch
            checked={!!property.inPortfolio}
            onChange={handlePortfolioToggle}
            color="primary"
          />
        }
        label={t("propertyDetails.includeInPortfolio")}
      />
      <Typography variant="body2" style={{ marginTop: 8 }}>
        {t("propertyDetails.portfolioDescription")}
      </Typography>
    </ContentCard>
  );
};
