import React, { useCallback, useMemo, useState } from "react";
import { ValuationZoneFullCoeff } from "../../../types/api.types";
import {
  calculateDistanceToPolygon,
  calculatePolygonCenter,
  getPolygonBox,
  simplifyClosedPolygon,
} from "../../../helpers/map.helpers";

export type ValuationZoneFullCoeffDerived = ValuationZoneFullCoeff & {
  _box: google.maps.LatLngBoundsLiteral;
  _simpleGeometry: ValuationZoneFullCoeff["geometry"];
  center: { lat: number; lng: number };
};

export type ZonePolygonProviderProps = {
  usedZones: ValuationZoneFullCoeff[];
  ineffectiveZones: ValuationZoneFullCoeff[];
  children: React.ReactNode;
  coeffName?: string | null;
};

export type ZonePolygonContextType = {
  usedZones: ValuationZoneFullCoeffDerived[];
  ineffectiveZones: ValuationZoneFullCoeffDerived[];
  coeffName?: string | null;
  selectedZone: string | null;
  setSelectedZone: (zoneNr: string | null) => void;
  selectedZonePoint: { lat: number; lng: number } | null;
  selectedZoneData: null | ValuationZoneFullCoeffDerived;
  setSelectedZonePoint: (point: { lat: number; lng: number } | null) => void;
  hoveredZone: string | null;
  setHoveredZone: (zoneNr: string | null) => void;
  calculateDistanceToPolygon: (property: { lat: number; lng: number }) => {
    closestPoint: google.maps.LatLng | null;
    minDistance: number;
  };
};

export const ZonePolygonContext = React.createContext<
  undefined | ZonePolygonContextType
>(undefined);

export const ZonePolygonProvider: React.FC<ZonePolygonProviderProps> = ({
  usedZones,
  children,
  coeffName,
  ineffectiveZones,
}) => {
  const [selectedZone, setSelectedZone] = useState<string | null>(null);
  const [selectedZoneData, setSelectedZoneData] =
    useState<ValuationZoneFullCoeffDerived | null>(null);
  const [selectedZonePoint, setSelectedZonePoint] = React.useState<{
    lat: number;
    lng: number;
  } | null>(null);
  const [hoveredZone, setHoveredZone] = useState<string | null>(null);
  const makeBoxedAndSimpled = (
    zone: ValuationZoneFullCoeff
  ): ValuationZoneFullCoeffDerived => {
    const center = calculatePolygonCenter(zone.geometry.coordinates);
    return {
      ...zone,
      _box: getPolygonBox(zone.geometry.coordinates[0]),
      _simpleGeometry: {
        coordinates: zone.geometry.coordinates.map((polygon) =>
          simplifyClosedPolygon(polygon, 0.001)
        ),
      },
      center: center || { lat: 0, lng: 0 },
    };
  };
  const _usedZones = useMemo(
    () => usedZones.map(makeBoxedAndSimpled),
    [usedZones]
  );
  const _ineffectiveZones = useMemo(
    () => ineffectiveZones.map(makeBoxedAndSimpled),
    [ineffectiveZones]
  );

  const _setSelectedZone = useCallback(
    (zoneNr: string | null) => {
      setSelectedZone(zoneNr);
      const selectedZoneData = [..._usedZones, ..._ineffectiveZones].find(
        (zone) => zone.zone_nr === zoneNr
      );
      setSelectedZoneData(selectedZoneData ?? null);
    },
    [
      setSelectedZone,
      selectedZone,
      _usedZones,
      _ineffectiveZones,
      setSelectedZoneData,
    ]
  );

  const _calculateDistanceToPolygon = useCallback(
    (property: { lat: number; lng: number }) => {
      if (!selectedZoneData)
        return { closestPoint: null, minDistance: Infinity };
      return calculateDistanceToPolygon(property, selectedZoneData);
    },
    [selectedZoneData]
  );

  return (
    <ZonePolygonContext.Provider
      value={{
        usedZones: _usedZones,
        ineffectiveZones: _ineffectiveZones,
        selectedZone,
        setSelectedZone: _setSelectedZone,
        selectedZonePoint,
        setSelectedZonePoint,
        hoveredZone,
        setHoveredZone,
        coeffName,
        selectedZoneData,
        calculateDistanceToPolygon: _calculateDistanceToPolygon,
      }}
    >
      {children}
    </ZonePolygonContext.Provider>
  );
};
