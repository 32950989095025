import React from "react";
import { ChartData, ChartTrend } from "../../types/api.types";
import { BarChart } from "@mui/x-charts/BarChart";
import { LineChart } from "@mui/x-charts/LineChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import colors from "common/styles/colors";
import { trimText } from "../../helpers/text.helpers";
import { BaseChart, ChartViewProps } from "./BaseChart";
import { useTranslation } from "react-i18next";
import { Box, Typography, Stack } from "@mui/material";
import {
  KeyboardArrowUp,
  KeyboardArrowDown,
  Remove,
} from "@mui/icons-material";

// Component for trend indicators
interface TrendIndicatorProps {
  trend: ChartTrend;
  value: string | number;
  label?: string;
}

export const TrendIndicator: React.FC<TrendIndicatorProps> = ({
  trend,
  value,
  label,
}) => {
  const { t } = useTranslation();

  // Use translation if no custom label is provided
  const getLabel = () => {
    if (label) return label;
    return t(`common.trends.${trend}`);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        p: 2,
        borderRadius: 2,
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
        width: "100%",
        minHeight: "100px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "50%",
          width: 40,
          height: 40,
          bgcolor:
            trend === "increasing"
              ? colors.green
              : trend === "decreasing"
              ? colors.red
              : colors.grey,
          mb: 1,
        }}
      >
        {trend === "increasing" && (
          <KeyboardArrowUp fontSize="medium" sx={{ color: "white" }} />
        )}
        {trend === "decreasing" && (
          <KeyboardArrowDown fontSize="medium" sx={{ color: "white" }} />
        )}
        {trend === "stable" && (
          <Remove fontSize="medium" sx={{ color: "white" }} />
        )}
      </Box>
      <Typography variant="h6" fontWeight="bold">
        {value}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {getLabel()}
      </Typography>
    </Box>
  );
};

interface ChartProps {
  chartData: ChartData;
  trendIndicator?: {
    trend: ChartTrend;
    value: string | number;
    label?: string;
  };
}

export const Chart: React.FC<ChartProps & ChartViewProps> = ({
  chartData,
  trendIndicator,
  ...props
}) => {
  const { t } = useTranslation();
  const {
    title,
    chart_type,
    data,
    x_axis_label,
    y_axis_label,
    source,
    subtitle,
    description,
    layout,
    wrapSourceInLink = true,
    trimSource = true,
  } = chartData;

  const formattedSource = trimSource ? trimText(source) : source;
  const sourceDisplay = wrapSourceInLink ? (
    <a href={source} target="_blank">
      {formattedSource}
    </a>
  ) : (
    formattedSource
  );

  return (
    <div>
      <Stack direction="row" alignItems="flex-start" spacing={2}>
        <Box sx={{ flex: 1 }}>
          <h3>{title}</h3>
          {subtitle && (
            <p
              style={{
                color: colors.grey,
              }}
            >
              {subtitle}
            </p>
          )}
          {description && (
            <p
              style={{
                color: colors.grey,
              }}
            >
              {description}
            </p>
          )}
        </Box>

        {trendIndicator && (
          <Box sx={{ width: "120px", flexShrink: 0 }}>
            <TrendIndicator
              trend={trendIndicator.trend}
              value={trendIndicator.value}
              label={trendIndicator.label}
            />
          </Box>
        )}
      </Stack>

      <BaseChart
        data={data}
        chartType={chart_type}
        xAxisLabel={x_axis_label}
        yAxisLabel={y_axis_label}
        layout={layout}
        {...props}
      />
      <div style={{ fontSize: "14px", textAlign: "center" }}>
        {t("common.source")} {sourceDisplay}
      </div>
    </div>
  );
};
