import { Appraiser } from "../types/appraiser.types";
import { Chip, lighten } from "@mui/material";
import colors from "common/styles/colors";

export const getStatusChip = (appraiser: Appraiser, t: Function) => {

    let label = "";
    let color = "";
    if (appraiser.lastActiveAt) {
      label = t("appraisers.appraiserStatus.active");
      color = colors.green;
    } else if (
      !appraiser.acceptedAt &&
      appraiser.registrationLink &&
      !appraiser.decodedRegistrationToken?.error
    ) {
      label = t("appraisers.appraiserStatus.pending");
      color = colors.orange;
    } else if (
      !appraiser.acceptedAt &&
      (!appraiser.registrationLink || appraiser.decodedRegistrationToken?.error)
    ) {
      label = t("appraisers.appraiserStatus.expired");
      color = colors.grey;
    } else {
      label = t("appraisers.appraiserStatus.inactive");
      color = colors.red;
    }

    return (
      <Chip
        label={label}
        style={{
          fontSize: "14px",
          backgroundColor: lighten(color, 0.8),
          color: color,
          borderRadius: "5px",
        }}
        size="medium"
      />
    );
  };