import React from "react";
import { useFormContext } from "react-hook-form";
import { useQuery } from "react-query";
import { getReconstructionYearFromListings } from "../../../api/api";
import { FormInput } from "common/components/form/hookForms/FormInput";
import { View } from "react-native";
import { FormCheckboxInput } from "common/components/form/hookForms/FormCheckboxInput";
import { Alert, Link } from "@mui/material";
import colors from "common/styles/colors";
import { useTranslation } from "react-i18next";

interface ConstructionYearFieldProps {
  gap?: number;
  buildYearLabel: string;
}

export const ConstructionYearField: React.FC<ConstructionYearFieldProps> = ({
  gap,
  buildYearLabel,
}) => {
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext();

  const lat = watch("lat");
  const lng = watch("lng");

  const { isLoading, data } = useQuery(
    ["year_reconstruction_extracted", lat, lng],
    () => getReconstructionYearFromListings(lat, lng),
    {
      enabled: !!lat && !!lng,
      staleTime: Infinity,
    }
  );

  return (
    <>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <View
          style={{
            flex: 1,
          }}
        >
          <FormInput
            name="year"
            label={buildYearLabel}
            isRequired
            variant="number"
          />
        </View>
        <View
          style={{
            flex: 1,
            marginLeft: 20,
            marginTop: 15,
          }}
        >
          <FormCheckboxInput
            label={t("propertyForm.buildingRenovated")}
            name="isRenovated"
          />
        </View>
      </View>
      {!!watch("isRenovated") && (
        <View style={{ marginTop: gap }}>
          <FormInput
            name="renovationYear"
            label={t("propertyForm.renovationYear")}
            isRequired
            variant="number"
            disabled={isLoading}
          />
        </View>
      )}
      {!!data?.year_reconstruction && (
        <Alert
          severity="info"
          variant="outlined"
          style={{ marginTop: gap }}
          color="info"
        >
          {t("propertyForm.renovationYearFound", {
            year: data.year_reconstruction,
          })}{" "}
          <Link
            component="button"
            onClick={() => {
              setValue("isRenovated", true);
              setValue("renovationYear", data.year_reconstruction);
            }}
          >
            {t("propertyForm.useThisYear")}
          </Link>
        </Alert>
      )}
    </>
  );
};
