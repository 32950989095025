import React from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import colors from "../../styles/colors";
import { Typography } from "../Typography";

export interface IconBadgeProps {
  count?: number | string;
  width?: number;
  height?: number;
  backgroundColor?: string;
  children?: React.ReactNode;
  textColor?: string;
  style?: StyleProp<ViewStyle>;
}

export const IconBadge: React.FC<IconBadgeProps> = ({
  count,
  width,
  height,
  backgroundColor,
  children,
  textColor,
  style,
}) => {
  return (
    <View
      style={[
        {
          width: width || 20,
          height: height || 20,
          backgroundColor: backgroundColor || colors.purple2,
          overflow: "visible",
          padding: 2,
          borderRadius: 20,
          alignItems: "center",
          justifyContent: "center",
        },
        style,
      ]}
    >
      {children ? (
        children
      ) : (
        <Typography
          textStyle={{
            color: textColor ?? colors.white,
            fontSize: 12,
            fontWeight: "500",
          }}
        >
          {count}
        </Typography>
      )}
    </View>
  );
};
