import React from "react";
import { useTranslation } from "react-i18next";
import {
  useAppraisersInOrganizationCredits,
  useOrganizationUsedCredits,
} from "../../hooks/auth.hooks";
import { PaymentRow } from "./PaymentRow";
import { OrganizationCredits } from "./OrganizationCredits";
import colors from "common/styles/colors";
import { Typography } from "common/components/Typography";
import { Divider } from "common/components/listItems/Divider";
import { TableComponent } from "../../components/tables/TableComponent";
import { SimpleTableComponent } from "../../components/tables/simpleTable/SimpleTableComponent";
import { SimpleTableRow } from "../../components/tables/simpleTable/SimpleTableRow";
import { SimpleTableHeader } from "../../components/tables/simpleTable/SimpleTableHeader";

interface PaymentsLimitsProps {}

export const PaymentsLimits: React.FC<PaymentsLimitsProps> = () => {
  const { t } = useTranslation();
  const { data: appraisers } = useAppraisersInOrganizationCredits();

  return (
    <div
      style={{
        backgroundColor: colors.white,
        padding: "20px",
        borderRadius: "10px",
      }}
    >
      <Typography variant="h2">{t("payments.limits.title")}</Typography>
      <div style={{ padding: "30px 0" }}>
        <OrganizationCredits />
      </div>
      <Divider />
      <div style={{ padding: "30px 0" }}>
        <Typography textStyle={{ fontSize: 16 }}>
          {t("payments.limits.perMemberSettings")}
        </Typography>
      </div>
      <SimpleTableComponent>
        <SimpleTableRow>
          <SimpleTableHeader isFirstElement>
            {t("payments.limits.table.appraiserEmail")}
          </SimpleTableHeader>
          <SimpleTableHeader>
            {t("payments.limits.table.monthlyLimit")}
          </SimpleTableHeader>
          <SimpleTableHeader>
            {t("payments.limits.table.usedLimit")}
          </SimpleTableHeader>
          <SimpleTableHeader>
            {t("payments.limits.table.manageLimit")}
          </SimpleTableHeader>
        </SimpleTableRow>
        {appraisers?.map((appraiser) => {
          return <PaymentRow key={appraiser._id} appraiser={appraiser} />;
        })}
      </SimpleTableComponent>
    </div>
  );
};
