import React from "react";
import ReactDOM from "react-dom/client";
import { GoogleOAuthProvider } from "@react-oauth/google";
import App from "./App.tsx";
import CommonConfig from "common/commonConfig.ts";
import axiosMobileInstance from "./api/axiosMobileInstance/axiosMobileInstance.ts";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { QueryClientProvider } from "react-query";
import { Property } from "./routes/property/Property.tsx";
import { InputMethodSelectionRoute } from "./routes/propertiesCRUD/InputMethodSelectionRoute.tsx";
import { PropertyDetailsEditRoute } from "./routes/propertiesCRUD/PropertyDetailsEditRoute.tsx";
import { useGreyBackground } from "./hooks/layout.hooks.ts";
import "./main.css";
import { SnackbarProvider } from "notistack";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ComparablesSelection } from "./routes/ComparablesSelection.tsx";
import { ComparablesFilterProvider } from "./components/ComparablesFilterProvider.tsx";
import { MapJSApiLoader } from "./components/MapJSApiLoader.tsx";
import { MapListInteractionsProvider } from "./components/MapListInteractionsProvider.tsx";
import { ComparableAdjustments } from "./routes/ComparableAdjustments.tsx";
import { showToastError } from "common/toast/toast.ts";
import { LoginOverlay } from "./components/auth/LoginOverlay.tsx";
import { queryClient } from "./modules/queryClient.ts";
import { theme } from "./styles/MUITheme.ts";
import { Register } from "./routes/auth/Register.tsx";
import { AppraisersManagement } from "./routes/AppraisersManagement.tsx";
import { AppraiserRole } from "./types/appraiser.types.ts";
import { RoleRoute } from "./components/auth/RoleRoute.tsx";
import { PageContainer } from "./components/containers/PageContainer.tsx";
import { Layout } from "./components/layout/Layout.tsx";
import { ComparablesSummary } from "./routes/ComparablesSummary.tsx";
import { TransactionsCompare } from "./components/transactions/TransactionsCompare.tsx";
import { ComparableSummaryPrint } from "./routes/ComparableSummaryPrint.tsx";
import { PdfReport } from "./routes/PdfReport.tsx";
import { ComparableMessages } from "./routes/ComparableMessages.tsx";
import { TransactionsRCProvider } from "./components/TransactionsRCProvider.tsx";
import { PaymentsRoute } from "./routes/payments/PaymentsRoute.tsx";
import { AdminMenu } from "./components/layout/AdminMenu.tsx";
import { OrganizationSettingsRoute } from "./routes/organizationSettings/OrganizationSettingsRoute.tsx";
import { ResetPassword } from "./routes/auth/ResetPassword.tsx";
import { PropertyTypeSelectRoute } from "./routes/propertiesCRUD/PropertyTypeSelectRoute.tsx";
import { AdjustmentsAdminRoute } from "./routes/adjustments/AdjustmentsAdminRoute.tsx";
import { TransactionsImport } from "./routes/TransactionsImport.tsx";
import { PropertyShareLink } from "./routes/PropertyShareLink.tsx";
import { LeadContactForm } from "./routes/propertiesCRUD/LeadContactForm.tsx";
import { PropertyCreationProvider } from "./routes/propertiesCRUD/PropertyCreationProvider.tsx";
import { LeadSuccess } from "./routes/propertiesCRUD/LeadSuccess.tsx";
import { ReportPaymentRoute } from "./routes/propertiesCRUD/reportPayments/ReportPaymentRoute.tsx";
import { AccountSettingsRoute } from "./routes/accountSettings/AccountSettingsRoute.tsx";
import { LeadLandingPage } from "./routes/propertiesCRUD/LeadLandingPage.tsx";
import { PropertyShareFromValuation } from "./routes/PropertyShareFromValuation.tsx";
import { PropertyRoute } from "./routes/property/PropertyRoute.tsx";

import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { ValuationsStatistics } from "./routes/ValuationsStatistics.tsx";
import { PropertyMessagesRoute } from "./routes/property/PropertyMessagesRoute.tsx";
import { PricePredictionsTransparencyRoute } from "./routes/property/PricePredictionsTransparencyRoute.tsx";
import { AutoValuationRoute } from "./routes/AutoValuationRoute.tsx";
import { InitialFiltersLoaderProvider } from "./components/InitialFiltersLoaderProvider.tsx";
import { PropertyDetailsNextRoute } from "./routes/propertiesCRUD/PropertyDetailsNextRoute.tsx";
import { TransactionsRCProviderWithReset } from "./components/TransactionsRCProviderWithReset.tsx";
import { PropertyViaPDFConfirmRoute } from "./routes/propertiesCRUD/PropertyViaPDFConfirmRoute.tsx";
import { FeedRoute } from "./routes/FeedRoute.tsx";
import { AppraiserLoginDetailsRoute } from "./routes/AppraiserLoginDetailsRoute.tsx";
import { PropertySimpleValuationRoute } from "./routes/property/PropertySimpleValuationRoute.tsx";
import {
  ClusteredComparablesMapControlContext,
  ClusteredGenericMapControlProvider,
} from "./components/ClusteredGenericMapControlProvider.tsx";
import { LoginLayout } from "./components/auth/LoginLayout.tsx";
import { LocalizedSection } from "./i18n/LocalizedSection.tsx";
import { init } from "./i18n/i18n";
import { AppraiserProfileInfoRoute } from "./routes/AppraiserProfileInfoRoute.tsx";
import { PortfolioStatisticsRoute } from "./routes/PortfolioStatisticsRoute.tsx";

Sentry.init({
  dsn: "https://e745002fda379d55dc1be5ba42601fa8@o4507503017656320.ingest.de.sentry.io/4507503876112464",
  environment: import.meta.env.DEV ? "development" : "production",
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],

  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
});

CommonConfig.init({
  errorHandler: (error: any) => {
    console.error(error);
    showToastError();
  },
  axiosInstance: axiosMobileInstance,
  loggingHandler: (eventName, meta) => {
    console.log(eventName, meta);
  },
});

const router = createBrowserRouter([
  {
    element: (
      <LoginLayout>
        <Register />
      </LoginLayout>
    ),
    path: "/register",
  },
  {
    element: (
      <LoginLayout>
        <ResetPassword />
      </LoginLayout>
    ),
    path: "/password-reset",
  },
  {
    element: (
      <LocalizedSection language="lt">
        <PageContainer />
      </LocalizedSection>
    ),
    children: [
      {
        element: (
          <MapListInteractionsProvider>
            <PropertyShareLink />
          </MapListInteractionsProvider>
        ),
        path: "/property_share",
      },
    ],
  },
  {
    element: (
      <LocalizedSection language="lt">
        <PageContainer />
      </LocalizedSection>
    ),
    children: [
      {
        element: (
          <MapListInteractionsProvider>
            <PropertyShareFromValuation />
          </MapListInteractionsProvider>
        ),
        path: "/valuations/:id",
      },
    ],
  },

  // Property creation routes from lead
  {
    path: "/lead",
    element: (
      <LocalizedSection language="lt">
        <PropertyCreationProvider fromLead />
      </LocalizedSection>
    ),

    children: [
      {
        path: "landing",
        element: <LeadLandingPage />,
      },
      {
        path: "contact",
        element: <LeadContactForm />,
      },
      {
        path: "inputMethod",
        element: <InputMethodSelectionRoute />,
      },
      {
        path: "reading",
        element: <PropertyViaPDFConfirmRoute />,
      },
      // {
      //   path: "type",
      //   element: <PropertyTypeSelectRoute />,
      // },
      {
        path: "details",
        element: <PropertyDetailsEditRoute />,
      },
      {
        path: "payment",
        element: <ReportPaymentRoute />,
      },
    ],
  },
  {
    path: "/lead/success",
    element: (
      <LocalizedSection language="lt">
        <LeadSuccess />
      </LocalizedSection>
    ),
  },

  {
    element: (
      <TransactionsRCProviderWithReset>
        <LoginOverlay />
      </TransactionsRCProviderWithReset>
    ),
    children: [
      {
        element: (
          <Layout noBackButton>
            <PageContainer />
          </Layout>
        ),
      },
      {
        element: (
          <Layout noBackButton>
            <PageContainer />
          </Layout>
        ),
        children: [
          {
            path: "/",
            element: <App />,
          },
          {
            path: "/properties/:propertyId",

            element: (
              <MapListInteractionsProvider>
                <PropertyRoute />
              </MapListInteractionsProvider>
            ),
          },
          {
            path: "/properties/:propertyId/messages",
            element: <PropertyMessagesRoute />,
          },
          {
            path: "/properties/:propertyId/transparency",
            element: <PricePredictionsTransparencyRoute />,
          },
          {
            path: "/properties/:propertyId/simple-valuation",
            element: <PropertySimpleValuationRoute />,
          },
          {
            path: "/pdf",
            element: (
              <RoleRoute allowedRoles={[AppraiserRole.ADMIN]}>
                <PdfReport />
              </RoleRoute>
            ),
          },
          {
            path: "account-settings",
            element: <AccountSettingsRoute />,
          },
        ],
      },
      {
        element: (
          <Layout>
            <AdminMenu>
              <PageContainer />
            </AdminMenu>
          </Layout>
        ),
        children: [
          {
            path: "/appraisers",
            element: (
              <RoleRoute allowedRoles={[AppraiserRole.ADMIN]}>
                <AppraisersManagement />
              </RoleRoute>
            ),
          },
          {
            path: "/appraisers/:appraiserId/profile",
            element: (
              <RoleRoute allowedRoles={[AppraiserRole.ADMIN]}>
                <AppraiserProfileInfoRoute />
              </RoleRoute>
            ),
          },
          {
            path: "/appraisers/:appraiserId/profile/login-details",
            element: (
              <RoleRoute allowedRoles={[AppraiserRole.ADMIN]}>
                <AppraiserLoginDetailsRoute />
              </RoleRoute>
            ),
          },
          {
            path: "/payments",
            element: (
              <RoleRoute allowedRoles={[AppraiserRole.ADMIN]}>
                <PaymentsRoute />
              </RoleRoute>
            ),
          },
          {
            path: "/settings",
            element: (
              <RoleRoute allowedRoles={[AppraiserRole.ADMIN]}>
                <OrganizationSettingsRoute />
              </RoleRoute>
            ),
          },
          {
            path: "/adjustments",
            element: (
              <RoleRoute allowedRoles={[AppraiserRole.ADMIN]}>
                <AdjustmentsAdminRoute />
              </RoleRoute>
            ),
          },
          {
            path: "/import",
            element: (
              <RoleRoute allowedRoles={[AppraiserRole.ADMIN]}>
                <TransactionsImport />
              </RoleRoute>
            ),
          },
          {
            path: "/statistics",
            element: (
              <RoleRoute
                allowedRoles={[AppraiserRole.ADMIN, AppraiserRole.SPECTATOR]}
              >
                <ValuationsStatistics />
              </RoleRoute>
            ),
          },
          {
            path: "/auto-valuation",
            element: (
              <RoleRoute allowedRoles={[AppraiserRole.ADMIN]}>
                <TransactionsRCProvider>
                  <AutoValuationRoute />
                </TransactionsRCProvider>
              </RoleRoute>
            ),
          },
          {
            path: "/feed",
            element: (
              <RoleRoute
                allowedRoles={[AppraiserRole.ADMIN, AppraiserRole.SPECTATOR]}
              >
                <FeedRoute />
              </RoleRoute>
            ),
          },
          {
            path: "/portfolio-statistics",
            element: (
              <RoleRoute allowedRoles={[AppraiserRole.ADMIN]}>
                <PortfolioStatisticsRoute />
              </RoleRoute>
            ),
          },
        ],
      },
      {
        element: (
          <Layout>
            <PageContainer />
          </Layout>
        ),
        children: [
          {
            path: "/properties/create/inputMethod",
            element: <InputMethodSelectionRoute />,
          },
          {
            path: "/properties/create/type",
            element: <PropertyTypeSelectRoute />,
          },
          {
            path: "/properties/create/details",
            element: <PropertyDetailsEditRoute />,
          },
          {
            path: "/properties/create/reading",
            element: <PropertyViaPDFConfirmRoute />,
          },
          {
            path: "/properties/:propertyId/details",
            element: <PropertyDetailsEditRoute />,
          },
          {
            path: "/properties/:propertyId/details-next",
            element: <PropertyDetailsNextRoute />,
          },
        ],
      },
      {
        path: "/properties/:propertyId/comparables",
        element: (
          <InitialFiltersLoaderProvider>
            <MapListInteractionsProvider>
              <ClusteredGenericMapControlProvider
                context={ClusteredComparablesMapControlContext}
              >
                <ComparablesSelection />
              </ClusteredGenericMapControlProvider>
            </MapListInteractionsProvider>
          </InitialFiltersLoaderProvider>
        ),
        children: [
          {
            element: (
              <Layout>
                <PageContainer />
              </Layout>
            ),
            children: [
              {
                path: ":comparableId/adjustments",
                element: <ComparableAdjustments />,
              },
              {
                path: ":comparableId/compare",
                element: <TransactionsCompare />,
              },
              {
                path: ":comparableId/messages",
                element: <ComparableMessages />,
              },
            ],
          },
          {
            path: "summary",
            element: (
              <Layout>
                <ComparablesSummary />
              </Layout>
            ),
          },
        ],
      },
      {
        path: "/properties/:propertyId/valuation-summary",
        element: (
          <Layout>
            <ComparableSummaryPrint />
          </Layout>
        ),
      },
    ],
  },
]);

const MainApp = () => {
  useGreyBackground();
  return (
    // Disable google translate
    <div className="notranslate">
      <GoogleOAuthProvider
        clientId={import.meta.env.VITE_GOOGLE_MAPS_CLIENT_ID}
      >
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <MapJSApiLoader>
              <SnackbarProvider autoHideDuration={3000}>
                <RouterProvider router={router} />
              </SnackbarProvider>
            </MapJSApiLoader>
          </ThemeProvider>
        </QueryClientProvider>
      </GoogleOAuthProvider>
    </div>
  );
};

const initializeApp = async () => {
  await init();
  ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
      <MainApp />
    </React.StrictMode>
  );
};

initializeApp();
