import React from "react";
import { useQuery } from "react-query";
import { getCompositeAssets } from "../../api/api";
import { ContentCard } from "../../components/ContentCard";
import { CompositeAssetsTable } from "../../components/comparablesSummary/CompositeAssetsTable";
import { useTranslation } from "react-i18next";

interface PropertyCompositeAssetsProps {
  propertyId: string;
}

export const PropertyCompositeAssets: React.FC<
  PropertyCompositeAssetsProps
> = ({ propertyId }) => {
  const { t } = useTranslation();
  const { data: compositeAssets, isLoading } = useQuery(
    ["compositeAssets", propertyId],
    () => getCompositeAssets(propertyId)
  );

  // Don't render anything if there are no composite assets or still loading
  if (isLoading || !compositeAssets || compositeAssets.length === 0) {
    return null;
  }

  return (
    <ContentCard title={t("propertyDetails.compositeAssets.title")}>
      <CompositeAssetsTable
        propertyId={propertyId}
        readonly
        hideValue
        compositeAssets={compositeAssets}
      />
    </ContentCard>
  );
};
