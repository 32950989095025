import React from "react";
import ArrowBackwardIcon from "../../assets/icons/arrowBackward.svg";
import ArrowForwardIcon from "../../assets/icons/arrowForward.svg";
import { MonthDate } from "./types";
import { IconButton } from "common/components/buttons/IconButton";
import { Typography } from "common/components/Typography";
import { Box } from "@mui/material";
import colors from "common/styles/colors";
import { useTranslation } from "react-i18next";

interface MonthPickerProps {
  minDate?: MonthDate;
  maxDate?: MonthDate;
  selectedDates?: MonthDate[];
  onYearChange: (year: number) => void;
  year: number;
  onDateSelect?: (date: MonthDate) => void;
}

const monthDict: { [key: number]: string } = {
  1: "Sau",
  2: "Vas",
  3: "Kov",
  4: "Bal",
  5: "Geg",
  6: "Bir",
  7: "Lie",
  8: "Rugp",
  9: "Rugs",
  10: "Spa",
  11: "Lap",
  12: "Gruo",
};

export const MonthPicker: React.FC<MonthPickerProps> = ({
  minDate,
  maxDate,
  year,
  onYearChange,
  selectedDates,
  onDateSelect,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <div
        style={{
          justifyContent: "space-between",
          display: "flex",
          alignItems: "center",
        }}
      >
        <IconButton
          isDisabled={year === minDate?.year}
          onPress={() => onYearChange(year - 1)}
        >
          <ArrowBackwardIcon />
        </IconButton>
        <Typography textStyle={{ fontSize: 16 }}>{year}</Typography>
        <IconButton
          isDisabled={year === maxDate?.year}
          onPress={() => onYearChange(year + 1)}
        >
          <ArrowForwardIcon />
        </IconButton>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "8px",
        }}
      >
        {Array.from({ length: 3 }, (_, row) => (
          <div
            key={row}
            style={{ display: "flex", flexDirection: "row", gap: "8px" }}
          >
            {Array.from({ length: 4 }, (_, col) => {
              const month = row * 4 + col + 1;

              const isSelected = selectedDates?.some(
                (date) => date.month === month && date.year === year
              );
              const isDisabled =
                (!!maxDate &&
                  (year > maxDate.year ||
                    (year == maxDate.year && month > maxDate.month))) ||
                (!!minDate &&
                  (year < minDate.year ||
                    (year <= minDate.year && month < minDate.month)));

              return (
                <Box
                  key={col}
                  sx={{
                    width: "35px",
                    height: "35px",
                    borderRadius: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    userSelect: "none",
                    ...(isSelected
                      ? {
                          backgroundColor: colors.purple2,
                          color: colors.white,
                          cursor: "pointer",
                        }
                      : isDisabled
                      ? { opacity: 0.4 }
                      : {
                          cursor: "pointer",
                          ":hover": {
                            backgroundColor: colors.purple4,
                          },
                        }),
                  }}
                  onClick={() => {
                    if (isDisabled) return;
                    onDateSelect?.({ year, month });
                  }}
                >
                  <Typography textStyle={{ fontSize: 12, color: "inherit" }}>
                    {t(`datepicker.shortMonths.${month}`)}
                  </Typography>
                </Box>
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
};
