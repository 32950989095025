import React, { useMemo } from "react";
import { PropertyValuationSummary } from "../PropertyValuationSummary";
import { usePricePredictions } from "../../hooks/pricePredictions.hooks";
import { useParams } from "react-router-dom";
import { ValuationSummary } from "../../components/comparablesSummary/ValuationSummary";
import { ContentCard } from "../../components/ContentCard";
import { useProperty } from "../../hooks/property.hooks";
import { LoaderCentered } from "../../components/loaders/LoaderCentered";
import { getPropertyValueString } from "../../helpers/property.helpers";
import { FormInput } from "common/components/form/hookForms/FormInput";
import { InputField } from "common/components/form/InputField";
import LoadingButton from "@mui/lab/LoadingButton";
import { useQueryClient } from "react-query";

interface PropertySimpleValuationRouteProps {}

export const PropertySimpleValuationRoute: React.FC<
  PropertySimpleValuationRouteProps
> = () => {
  const { propertyId } = useParams();

  const { data: property, isLoading } = useProperty(propertyId ?? "");

  const {
    getFinalPricePredictions,
    isLoadingPricePredictions,
    isLoading: isSettingPrice,
    setSimplePropertyValue,
  } = usePricePredictions(propertyId ?? "");

  const finalPricePredictions = useMemo(
    () => getFinalPricePredictions(true),
    [getFinalPricePredictions]
  );

  const [inputValue, setInputValue] = React.useState<number | null>(null);

  const queryClient = useQueryClient();

  if (isLoading || isLoadingPricePredictions) {
    return <LoaderCentered />;
  }

  if (!property || !finalPricePredictions) {
    return null;
  }
  return (
    <PropertyValuationSummary ignoreComparables>
      <ContentCard title="Pagrindinis turtas">
        <div style={{ display: "flex" }}>
          <div style={{ display: "flex", flex: 1, alignItems: "center" }}>
            <div style={{ width: "300px" }}>
              <InputField
                label={getPropertyValueString(property)}
                units="€"
                variant="number"
                maxWidth={250}
                value={inputValue}
                onChange={(value) => {
                  setInputValue(typeof value === "number" ? value : null);
                }}
              />
            </div>
            <LoadingButton
              loading={isSettingPrice}
              disabled={!inputValue}
              onClick={async () => {
                if (!inputValue) return;
                await setSimplePropertyValue(inputValue / property.area);
                setInputValue(null);
                queryClient.invalidateQueries("compositeAssets");
              }}
              variant="contained"
            >
              Išsaugoti
            </LoadingButton>
          </div>
          <ValuationSummary
            finalPricePredictions={finalPricePredictions}
            propertyId={propertyId ?? ""}
          />
        </div>
      </ContentCard>
    </PropertyValuationSummary>
  );
};
