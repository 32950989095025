import i18n from "i18next";

export const getFinishingLabel = (
  finishing: 0 | 1 | boolean | number | null | undefined,
  finishingFull: number | null | undefined
) => {
  if (finishing === null || finishing === undefined) {
    return null;
  }

  const text = !!finishing
    ? i18n.t("finishing.finished")
    : i18n.t("finishing.partiallyFinished");

  if (finishingFull === null || finishingFull === undefined) {
    return text;
  }

  return `${text} (${finishingFull}%)`;
};
