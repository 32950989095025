import colors from "common/styles/colors";
import React from "react";
import { PropertyPlaceholderImage } from "../imageGallery/PropertyPlaceholderImage";
import { Typography } from "common/components/Typography";
import { Text } from "react-native";
import { formatAreaPrice, formatPrice } from "common/helpers/text.helpers";
import { PropertyGallery } from "../../routes/property/PropertyGallery";
import { ImageGallery } from "../imageGallery/ImageGallery";
import { PropertyImageGallery } from "../imageGallery/PropertyImageGallery";
import { IconBadge, IconBadgeProps } from "common/components/map/IconBadge";
import { Stack } from "@mui/material";

export interface BasePropertyCardProps {
  images?: string[];
  address?: string;
  price?: number;
  priceArea?: number;
  dateText?: string;
  caption?: string;
  children?: React.ReactNode;
  onClick?: () => void;
  topLeftContent?: React.ReactNode;
  noBorder?: boolean;
  gapSizePx?: number;
  /** changes info body to custom one */
  infoNode?: React.ReactNode;
  numBadgeProps?: IconBadgeProps;
}

export const BasePropertyCard: React.FC<BasePropertyCardProps> = ({
  address,
  price,
  priceArea,
  dateText,
  caption,
  children,
  images,
  onClick,
  topLeftContent,
  noBorder,
  gapSizePx = 10,
  infoNode,
  numBadgeProps,
}) => {
  const formattedPrice = price ? formatPrice(price) : null;
  const formattedPriceArea = priceArea ? formatAreaPrice(priceArea) : null;
  const gapSize = `${gapSizePx}px`;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: gapSize,
        width: "480px",
        border: noBorder ? "none" : `1px solid ${colors.greyborder}`,
        borderRadius: "10px",
        backgroundColor: colors.white,
        minHeight: "162px",
        maxHeight: "177px",
      }}
    >
      <div style={{ position: "relative" }}>
        <PropertyImageGallery
          images={images?.map((url) => ({ url }))}
          height="100%"
          width={160}
          borderBottomLeftRadius={10}
          borderTopLeftRadius={10}
          borderRadius={0}
          headerText={address}
        />
        {topLeftContent && (
          <div
            style={{
              position: "absolute",
              top: "0px",
              left: "0px",
            }}
          >
            {topLeftContent}
          </div>
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          flexGrow: 1,
          cursor: !!onClick ? "pointer" : "default",
          position: "relative",
        }}
        onClick={() => onClick?.()}
      >
        {!!infoNode ? (
          infoNode
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingTop: "10px",
            }}
          >
            <Stack direction={"row"} alignItems={"start"} spacing={1}>
              {!!numBadgeProps?.count && <IconBadge {...numBadgeProps} />}
              <Typography variant="h4">{address}</Typography>
            </Stack>
            <Text
              style={{
                marginTop: 5,
              }}
            >
              <Typography variant="h4">
                {formattedPriceArea ?? formattedPrice}
              </Typography>
              {!!formattedPriceArea && !!formattedPrice && (
                <Typography
                  textStyle={{
                    fontSize: 16,
                    marginLeft: 5,
                    fontWeight: "400",
                  }}
                >
                  {`(${formattedPrice})`}
                </Typography>
              )}
            </Text>

            {!!dateText && (
              <Typography
                textStyle={{
                  fontSize: 14,
                  color: colors.green,
                  marginTop: 10,
                }}
              >
                {dateText}
              </Typography>
            )}

            {!!caption && (
              <Typography
                variant="caption"
                textStyle={{
                  marginTop: 10,
                }}
              >
                {caption}
              </Typography>
            )}
          </div>
        )}
        {children}
      </div>
    </div>
  );
};
