import { useState, useEffect } from "react";
import { SimpleDialog } from "../dialogs/SimpleDialog";
import { Appraiser, AppraiserRole } from "../../types/appraiser.types";
import { useForm, FormProvider } from "react-hook-form";
import { FormInput } from "common/components/form/hookForms/FormInput";
import { FormSelect } from "../form/FormSelect";
import { updateAppraiserRole } from "../../api/api";
import { useQueryClient, useMutation } from "react-query";
import { ErrorAlert } from "../alerts/ErrorAlert";
import { Button } from "@mui/material";
import colors from "common/styles/colors";
import { FormColumns } from "common/components/form/layout/FormColumns";
import { useAppraiser } from "../../hooks/auth.hooks";
import { useTranslation } from "react-i18next";

interface AppraiserRoleUpdateDialogProps {
  appraiser: Appraiser;
}

export const AppraiserRoleUpdateDialog: React.FC<
  AppraiserRoleUpdateDialogProps
> = ({ appraiser }) => {
  const [open, setOpen] = useState(false);
  const { data: currentAppraiser } = useAppraiser();
  const { t } = useTranslation();

  const methods = useForm({
    defaultValues: {
      email: appraiser.email,
      role: appraiser.role,
    },
  });

  const queryClient = useQueryClient();
  const { mutate, error } = useMutation(
    ({ appraiserId, role }: { appraiserId: string; role: string }) =>
      updateAppraiserRole(appraiserId, role),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("appraiser_info");
        setOpen(false);
      },
    }
  );

  useEffect(() => {
    if (open && appraiser) {
      methods.reset({
        email: appraiser.email,
        role: appraiser.role,
      });
    }
  }, [open, appraiser, methods]);

  const handleOpen = () => setOpen(true);

  const onSubmit = (data: { email: string; role: string }) => {
    mutate({
      appraiserId: appraiser._id,
      role: data.role,
    });
  };

  const handleSubmit = () => {
    methods.handleSubmit(onSubmit)();
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        variant="outlined"
        disabled={currentAppraiser?._id === appraiser._id}
        style={{
          width: "100px",
          borderColor: colors.beige,
        }}
      >
        {t("appraiserInfo.buttons.update")}
      </Button>
      <SimpleDialog
        title={t("appraiserInfo.updateRoleDialog.title")}
        open={open}
        onClose={() => setOpen(false)}
        buttonText={t("appraiserInfo.updateRoleDialog.update")}
        onSubmit={handleSubmit}
        buttonDisabled={appraiser.role === methods.watch("role")}
      >
        <ErrorAlert error={error} />
        <FormProvider {...methods}>
          <FormColumns>
            <FormInput
              name="email"
              label={t("appraiserInfo.email")}
              variant="text"
              disabled
              maxWidth={350}
            />

          <FormSelect
            label={t("appraiserInfo.role")}
            name="role"
            values={Object.values(AppraiserRole).filter(
              (role) => role !== AppraiserRole.ORGANIZATION_LEAD
            )}
            getTitle={(role) => t(`appraisers.roles.${role}`)}
            height={52}
          />
          </FormColumns>
        </FormProvider>
      </SimpleDialog>
    </>
  );
};
