import React from "react";
import { useTranslation } from "react-i18next";
import { Link, Stack } from "@mui/material";
import colors from "common/styles/colors";

export const LanguageSwitcher: React.FC = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Link
        component="button"
        underline={i18n.language === "en" ? "always" : "hover"}
        onClick={() => changeLanguage("en")}
        sx={{
          color: i18n.language === "en" ? colors.purple2 : colors.black,
          fontWeight: 500,
          // cursor: "pointer",
          // fontSize: "0.875rem",
        }}
      >
        EN
      </Link>
      <span>|</span>
      <Link
        component="button"
        underline={i18n.language === "lt" ? "always" : "hover"}
        onClick={() => changeLanguage("lt")}
        sx={{
          color: i18n.language === "lt" ? colors.purple2 : colors.black,
          fontWeight: 500,
          // cursor: "pointer",
          // fontSize: "0.875rem",
        }}
      >
        LT
      </Link>
    </Stack>
  );
};
