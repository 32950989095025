import React from "react";
import { Typography } from "common/components/Typography";
import { SimpleDialog } from "web-valuations/src/components/dialogs/SimpleDialog";
import { LinkForButton } from "web-valuations/src/components/links/LinkForButton";
import { useTranslation } from "react-i18next";

interface DocumentUploadHelperDialogProps {
  open: boolean;
  onClose: () => void;
}

export const DocumentUploadHelperDialog: React.FC<
  DocumentUploadHelperDialogProps
> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const divStyling: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  };

  return (
    <SimpleDialog
      open={open}
      onClose={onClose}
      title={t("propertyCreation.documents.helper.title")}
      hideButton
    >
      <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <div style={divStyling}>
          <Typography variant="h4">
            {t("propertyCreation.documents.helper.registry.title")}
          </Typography>
          <Typography variant="caption">
            {t("propertyCreation.documents.helper.registry.description")}
          </Typography>
          <Typography variant="caption">
            {t("propertyCreation.documents.helper.registry.orderInfo")}{" "}
            <LinkForButton to="https://www.registrucentras.lt/p/1531">
              <Typography variant="link">{t("common.online")}</Typography>
            </LinkForButton>{" "}
            {t("propertyCreation.documents.helper.registry.orderInfoEnd")}
          </Typography>
        </div>
        <div style={divStyling}>
          <Typography variant="h4">
            {t("propertyCreation.documents.helper.valuation.title")}
          </Typography>
          <Typography variant="caption">
            {t("propertyCreation.documents.helper.valuation.description")}
          </Typography>
        </div>
        <div style={divStyling}>
          <Typography variant="h4">
            {t("propertyCreation.documents.helper.cadastral.title")}
          </Typography>
          <Typography variant="caption">
            {t("propertyCreation.documents.helper.cadastral.description")}
          </Typography>
          <Typography variant="caption">
            {t("propertyCreation.documents.helper.cadastral.orderInfo")}
          </Typography>
        </div>
        <div>
          <Typography variant="h5">
            {t("propertyCreation.documents.helper.conclusion")}
          </Typography>
        </div>
      </div>
    </SimpleDialog>
  );
};
