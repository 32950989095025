import React from "react";
import { useQuery } from "react-query";
import { getMarketInsights } from "../../../api/api";
import { ContentCard } from "../../../components/ContentCard";
import { Chart } from "../../../components/charts/Chart";
import { Grid } from "@mui/material";
import { LoaderCentered } from "../../../components/loaders/LoaderCentered";
import { PropertyMarketInsights } from "./PropertyMarketInsights";

interface PropertyMarketInsightsRouteProps {
  propertyId: string;
}

export const PropertyMarketInsightsRoute: React.FC<
  PropertyMarketInsightsRouteProps
> = ({ propertyId }) => {
  const { data, isLoading } = useQuery(["market_insights", propertyId], () =>
    getMarketInsights(propertyId)
  );

  if (isLoading) {
    return (
      <div
        style={{
          paddingTop: "50px",
        }}
      >
        <LoaderCentered />
      </div>
    );
  }

  if (!data) {
    return null;
  }

  return <PropertyMarketInsights marketInsights={data} />;
};
