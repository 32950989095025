import { Comparable, ComparableFull } from "common/types/common.types";
import React, { useCallback, useState } from "react";
import {
  ComparableFilter,
  ComparableFilterType,
  defaultFilters,
  getDefaultAreaValues,
  getDefaultDesignationFilter,
  getDefaultInitialFilters,
  getDefaultValuationZoneFilter,
  getDefaultYearValues,
  getFilterFunctionByType,
  getModalFilters,
  otherFilters,
} from "../helpers/comparableFilters.helpers";
import _ from "lodash";
import { SavedComparableFilter } from "../types/api.types";
import { AppraiserProperty } from "../types/appraiser.types";
export interface ComparablesFiltersContextProps {
  getFilteredComparables?: (
    comparables: Comparable[],
    ignoredFilters?: ComparableFilterType[]
  ) => Comparable[];
  removeFilter?: (filterType: ComparableFilterType) => void;
  addFilter?: (filter: ComparableFilter) => void;
  addSavedFilters?: (filters: SavedComparableFilter[]) => void;
  appliedFilters?: ComparableFilter[];
  setAppliedFilters?: (filters: ComparableFilter[]) => void;
  getAppliedFilter?: (
    filterType: ComparableFilterType
  ) => ComparableFilter | undefined;
  getAppliedFiltersCount?: () => number;
  clearFilters?: () => void;
  setInitialFilters?: (property: AppraiserProperty) => void;
}

export const ComparablesFiltersContext =
  React.createContext<ComparablesFiltersContextProps>({});

interface ComparablesFilterProviderProps {
  children?: React.ReactNode;
}

export const ComparablesFilterProvider: React.FC<
  ComparablesFilterProviderProps
> = ({ children }) => {
  const [appliedFilters, setAppliedFilters] =
    useState<ComparableFilter[]>(defaultFilters);

  const getAppliedFiltersCount = useCallback(() => {
    return getModalFilters(appliedFilters).length;
  }, [getModalFilters, appliedFilters]);

  const clearFilters = useCallback(() => {
    setAppliedFilters((filters) => [
      ...filters.filter((filter) =>
        otherFilters.some((other) => other === filter.type)
      ),
      ...defaultFilters,
    ]);
  }, [setAppliedFilters, otherFilters, defaultFilters]);

  const removeFilter = useCallback(
    (filterType: ComparableFilterType) => {
      setAppliedFilters((appliedFilters) =>
        appliedFilters.filter((filter) => filter.type !== filterType)
      );
    },
    [setAppliedFilters]
  );

  const addFilter = useCallback(
    (filter: ComparableFilter) => {
      removeFilter(filter.type);
      setAppliedFilters((appliedFilters) => [...appliedFilters, filter]);
    },
    [removeFilter, setAppliedFilters]
  );

  const setInitialFilters = useCallback(
    (property: AppraiserProperty) => {
      clearFilters();
      const initialFilters = getDefaultInitialFilters(property);
      for (const filter of initialFilters) {
        addFilter({
          ...filter,
          filterFunction: getFilterFunctionByType(filter.type, filter.values),
        });
      }
    },
    [clearFilters, getDefaultInitialFilters, addFilter, getFilterFunctionByType]
  );

  const getFilteredComparables = useCallback(
    (comparables: Comparable[], ignoredFilters?: ComparableFilterType[]) => {
      return comparables.filter((comp) =>
        appliedFilters
          .filter(
            (filter) =>
              !ignoredFilters?.some((ignored) => filter.type === ignored)
          )
          .every((filter) => filter.filterFunction(comp))
      );
    },
    [appliedFilters]
  );

  const addSavedFilters = useCallback(
    (filters: SavedComparableFilter[]) => {
      const fullFilters: ComparableFilter[] =
        filters?.map((filter) => ({
          ...filter,
          filterFunction: getFilterFunctionByType(filter.type, filter.values),
        })) ?? [];

      for (const filter of fullFilters) {
        if (filter.filterFunction === undefined) continue;
        addFilter?.(filter);
      }
    },
    [getFilterFunctionByType, addFilter]
  );

  const getAppliedFilter = useCallback(
    (filterType: ComparableFilterType) => {
      return appliedFilters.find((filter) => filter.type === filterType);
    },
    [appliedFilters]
  );

  return (
    <ComparablesFiltersContext.Provider
      value={{
        getFilteredComparables,
        removeFilter,
        addFilter,
        addSavedFilters,
        appliedFilters,
        setAppliedFilters,
        getAppliedFilter,
        getAppliedFiltersCount,
        clearFilters,
        setInitialFilters,
      }}
    >
      {children}
    </ComparablesFiltersContext.Provider>
  );
};
