import { ClimateRiskType } from "../types/appraiser.types";
import colors from "common/styles/colors";

export const determineBackgroundColor = (risk: ClimateRiskType) => {
  switch (risk) {
    case ClimateRiskType.Low:
      return colors.green;
    case ClimateRiskType.Medium:
      return colors.orange;
    case ClimateRiskType.High:
      return colors.red;
  }
};

export const getFloodRiskDescription = (floodRisk: ClimateRiskType) => {
  switch (floodRisk) {
    case ClimateRiskType.Low:
      return "Turtas nepatenka į potvynio rizikos zoną.";
    case ClimateRiskType.High:
      return "Turtas patenka į potvynio rizikos zoną.";
  }
};

export const getHeatRiskDescription = (hotDaysCount: number) => {
  if (hotDaysCount <= 5) return "Ne daugiau nei 5 karštos dienos.";
  else if (hotDaysCount <= 12) return "6-12 karštų dienų.";
  return "Daugiau nei 12 karštų dienų.";
};

export const getWindRiskDescription = (windyDaysCount: number) => {
  if (windyDaysCount <= 15) return "Ne daugiau nei 15 vėjuotų dienų.";
  else if (windyDaysCount <= 40) return "16-40 vėjuotų dienų.";
  return "Daugiau nei 40 vėjuotų dienų.";
};

export const getAirPollutionRiskDescription = (pm_10: number) => {
  if (pm_10 <= 25) return "KD koncentracija neviršija 25 μg/m3.";
  else if (pm_10 <= 40) return "Nustatyta 25-40 μg/m3 KD koncentracija.";
  return "KD koncentracija viršija 40 μg/m3.";
};
