import { ValuationZoneFullCoeffDerived } from "../routes/property/timeOnMarket/ZonePolygonProvider";
import { isCoordinatesInPolygon } from "./comparableFilters.helpers";

export const getGoogleMapsBoundsFromCoords = (
  coords: { lat: number; lng: number }[],
  extensionFactor: number = 0.001 // Adjust this factor to control the extension
) => {
  const bounds = new google.maps.LatLngBounds();

  // Extend the bounds to include each coordinate
  coords.forEach((coord) => {
    bounds.extend(coord);
  });

  if (coords.length > 0) {
    const sw = bounds.getSouthWest();
    const ne = bounds.getNorthEast();

    // Check if the bounds are already extended
    const latDiff = ne.lat() - sw.lat();
    const lngDiff = ne.lng() - sw.lng();

    if (latDiff < extensionFactor * 2 || lngDiff < extensionFactor * 2) {
      // Calculate new extended bounds
      const extendedSW = new google.maps.LatLng(
        sw.lat() - extensionFactor,
        sw.lng() - extensionFactor
      );
      const extendedNE = new google.maps.LatLng(
        ne.lat() + extensionFactor,
        ne.lng() + extensionFactor
      );

      // Update the bounds to the new extended bounds
      bounds.extend(extendedSW);
      bounds.extend(extendedNE);
    }
  }

  return bounds;
};

/* RDP generated by chatgpt START */
type Point = number[];

function perpendicularDistance(point: Point, start: Point, end: Point): number {
  const dx = end[0] - start[0];
  const dy = end[1] - start[1];
  if (dx === 0 && dy === 0) {
    return Math.hypot(point[0] - start[0], point[1] - start[1]);
  }
  const t =
    ((point[0] - start[0]) * dx + (point[1] - start[1]) * dy) /
    (dx * dx + dy * dy);
  const nearestX = start[0] + t * dx;
  const nearestY = start[1] + t * dy;
  return Math.hypot(point[0] - nearestX, point[1] - nearestY);
}

function ramerDouglasPeucker(points: Point[], epsilon: number): Point[] {
  if (points.length < 3) return points;

  let maxDist = 0;
  let index = -1;
  const end = points.length - 1;

  for (let i = 1; i < end; i++) {
    const dist = perpendicularDistance(points[i], points[0], points[end]);
    if (dist > maxDist) {
      maxDist = dist;
      index = i;
    }
  }

  if (maxDist > epsilon) {
    const firstPart = ramerDouglasPeucker(points.slice(0, index + 1), epsilon);
    const secondPart = ramerDouglasPeucker(points.slice(index), epsilon);
    return firstPart.slice(0, -1).concat(secondPart);
  } else {
    return [points[0], points[end]];
  }
}

export function simplifyClosedPolygon(
  points: Point[],
  epsilon: number
): Point[] {
  if (points.length < 4) return points;
  const simplified = ramerDouglasPeucker(points.slice(0, -1), epsilon);
  if (simplified[0] !== simplified[simplified.length - 1]) {
    simplified.push(simplified[0]);
  }
  return simplified;
}
/* RDP generated by chatgpt END */

export const getPolygonBox = (
  polygon: number[][]
): ValuationZoneFullCoeffDerived["_box"] => {
  if (polygon.length === 0) {
    return { north: 0, south: 0, east: 0, west: 0 };
  }
  let [minLng, minLat] = polygon[0];
  let [maxLng, maxLat] = polygon[0];
  for (const [lng, lat] of polygon) {
    if (lng < minLng) {
      minLng = lng;
    }
    if (lng > maxLng) {
      maxLng = lng;
    }
    if (lat < minLat) {
      minLat = lat;
    }
    if (lat > maxLat) {
      maxLat = lat;
    }
  }
  return { north: maxLat, south: minLat, east: maxLng, west: minLng };
};

export const calculateDistanceToPolygon = (
  property: { lat: number; lng: number },
  selectedZoneData: ValuationZoneFullCoeffDerived
): { closestPoint: google.maps.LatLng | null; minDistance: number } => {
  let minDistance = Infinity;
  let closestPoint = null;

  const polygonCoordinates = selectedZoneData?.geometry?.coordinates;

  if (!polygonCoordinates) return { closestPoint, minDistance };

  polygonCoordinates.forEach((polygon: number[][]) => {
    for (let i = 0; i < polygon.length - 1; i++) {
      const start = polygon[i];
      const end = polygon[i + 1];

      const distanceData = getClosestPointOnSegment(
        property,
        { lat: start[1], lng: start[0] },
        { lat: end[1], lng: end[0] }
      );

      if (distanceData.distance < minDistance) {
        minDistance = distanceData.distance;
        closestPoint = distanceData.closestPoint;
      }
    }
  });

  // Convert to google.maps.Polygon
  const googlePolygon = new google.maps.Polygon({
    paths: polygonCoordinates[0].map((coord) => ({
      lat: coord[1],
      lng: coord[0],
    })),
  });

  if (isCoordinatesInPolygon(property.lat, property.lng, googlePolygon))
    minDistance = 0;

  return { closestPoint, minDistance };
};

// Point to segment algorithm
export const getClosestPointOnSegment = (
  property: { lat: number; lng: number },
  start: { lat: number; lng: number },
  end: { lat: number; lng: number }
): { closestPoint: google.maps.LatLng; distance: number } => {
  const startLatLng = new google.maps.LatLng(start.lat, start.lng);
  const endLatLng = new google.maps.LatLng(end.lat, end.lng);
  const propertyLatLng = new google.maps.LatLng(property.lat, property.lng);

  const segmentLength = google.maps.geometry.spherical.computeDistanceBetween(
    startLatLng,
    endLatLng
  );
  if (segmentLength === 0) {
    return {
      closestPoint: startLatLng,
      distance: google.maps.geometry.spherical.computeDistanceBetween(
        propertyLatLng,
        startLatLng
      ),
    };
  }

  const projection =
    google.maps.geometry.spherical.computeDistanceBetween(
      propertyLatLng,
      startLatLng
    ) / segmentLength;
  const projectionClamped = Math.max(0, Math.min(1, projection));

  const projectedLatLng = google.maps.geometry.spherical.interpolate(
    startLatLng,
    endLatLng,
    projectionClamped
  );

  const distance =
    google.maps.geometry.spherical.computeDistanceBetween(
      propertyLatLng,
      projectedLatLng
    ) / 1000;

  return { closestPoint: projectedLatLng, distance };
};

export const calculatePolygonCenter = (
  coordinates: number[][][]
): { lat: number; lng: number } => {
  if (!coordinates || coordinates.length === 0) return { lat: 0, lng: 0 };
  const path = coordinates[0].map(
    ([lng, lat]) => new google.maps.LatLng(lat, lng)
  );

  const bounds = new google.maps.LatLngBounds();
  path.forEach((latLng) => bounds.extend(latLng));
  return {
    lat: bounds.getCenter().lat(),
    lng: bounds.getCenter().lng(),
  };
};
