import React from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { getLithuanianDateTimeString } from "common/helpers/dateStringHelpers";
import { AppraiserLoginInfo } from "../../types/appraiser.types";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

interface AppraiserLoginDetailsProps {
  appraiserLoginInfo: AppraiserLoginInfo[];
}

export const AppraiserLoginDetails: React.FC<AppraiserLoginDetailsProps> = ({
  appraiserLoginInfo,
}) => 
  
  {
  const { t } = useTranslation();
  const columns: GridColDef[] = [
    {
      field: "device",
      headerName: t("appraiserLoginInfo.table.device"),
      width: 200,
    },
    {
      field: "os",
      headerName: t("appraiserLoginInfo.table.deviceOS"),
      width: 200,
    },
    {
      field: "browser",
      headerName:t("appraiserLoginInfo.table.browser"),
      width: 200,
    },
    {
      field: "location",
      headerName: t("appraiserLoginInfo.table.location"),
      width: 200,
    },
    {
      field: "createdAt",
      headerName: t("appraiserLoginInfo.table.createdAt"),
      width: 200,
    },
    {
      field: "loggedInBy",
      headerName: t("appraiserLoginInfo.table.loggedInBy"),
      width: 200,
      renderCell: (params) => {
        const loggedInBy = params.value;
        return (
          <Tooltip title={params.row.loginReason || "Nežinoma"}>
            <span
              style={{
                textDecoration: "underline",
                textDecorationStyle: "dotted",
              }}
            >
              {loggedInBy}
            </span>
          </Tooltip>
        );
      },
    }
  ];

  const rows = appraiserLoginInfo.map((loginInfo) => ({
    id: loginInfo._id,
    device: loginInfo.device,
    os: loginInfo.os,
    browser: loginInfo.browser,
    location: loginInfo.location,
    createdAt: getLithuanianDateTimeString(loginInfo.createdAt),
    loggedInBy: loginInfo.loggedInBy,
    loginReason: loginInfo.loginReason,
  }));

  return (
    <div style={{ width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
          sorting: {
            sortModel: [
              {
                field: "createdAt",
                sort: "desc",
              },
            ],
          },
        }}
        pageSizeOptions={[5, 10, 20]}
        pagination
        disableRowSelectionOnClick
      />
    </div>
  );
};
