import {
  Comparable,
  ComparableFull,
  ComparableTransaction,
} from "common/types/common.types";
import React, { useState } from "react";
import { ComparableWeightEditModal } from "./ComparableWeightEditModal";
import { LinkButton } from "../buttons/LinkButton";
import { ComparableNumbersEditModal } from "./ComparableNumbersEditModal";
import { usePricePredictions } from "../../hooks/pricePredictions.hooks";
import _ from "lodash";
import {
  adjustmentFields,
  getTranslatedFieldName,
} from "common/helpers/comparables.helpers";
import { getAdjustmentPercent } from "../../helpers/pricePredictions.helpers";
import { recalcAdjustments } from "../../helpers/comparable.helpers";
import { formatAreaPrice } from "common/helpers/text.helpers";
import { useTranslation } from "react-i18next";

interface AdjustmentHeaderProps {
  comps: ComparableFull[];
  propertyId: string;
  readonly?: boolean;
  fieldName: keyof ComparableTransaction;
}

export const AdjustmentHeader: React.FC<AdjustmentHeaderProps> = ({
  comps,
  propertyId,
  readonly,
  fieldName,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { updateComparableAdjustments, updateAdjustmentDescription } =
    usePricePredictions(propertyId);

  const adjustments = comps.map((comp) =>
    comp.adjustments?.adjusted_fields.find((field) => field.field === fieldName)
  );

  const getDefaultValues = () => {
    if (!comps) {
      return [];
    }
    return adjustments.map((adj) => getAdjustmentPercent(adj?.percent));
  };

  const getUpdatedAdjustments = (comp: ComparableFull, value: number) => {
    let adjustments = _.cloneDeep(comp.adjustments);
    const adjustedField = adjustments?.adjusted_fields.find(
      (field) => field.field === fieldName
    );
    if (adjustedField) {
      adjustedField.percent = value - 1;
    }

    return adjustments;
  };

  const updateAdjustments = async (values: number[]) => {
    await Promise.all(
      comps.map((comp, index) => {
        if (!comp.adjustments) {
          return null;
        }

        const newAdjustments = getUpdatedAdjustments(comp, values[index]);

        if (!newAdjustments) {
          return null;
        }

        return updateComparableAdjustments(
          comp.comparable_transaction.id,
          newAdjustments
        );
      })
    );

    if (values.every((value) => value === 1)) {
      await updateAdjustmentDescription(fieldName, "");
    }
  };

  const { t } = useTranslation();

  const name = getTranslatedFieldName(fieldName, t);

  return (
    <div>
      {name}
      {!readonly && (
        <>
          <LinkButton onClick={() => setIsModalOpen(true)}>
            Koreguoti pataisas
          </LinkButton>
          <ComparableNumbersEditModal
            open={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            comps={comps}
            getDefaultValues={getDefaultValues}
            updateValues={updateAdjustments}
            headerText={`Koreguoti pataisą "${name}"`}
            withUpdateAll
            getDescriptions={(comp, value) => {
              const newAdjustments = getUpdatedAdjustments(comp, value);

              if (!newAdjustments) {
                return [];
              }

              const recalculatedAdjustments = recalcAdjustments(
                newAdjustments,
                comp.comparable_transaction
              );

              return [
                {
                  label: name,
                  value:
                    adjustmentFields[fieldName]?.fieldValue(
                      comp.comparable_transaction
                    ) ?? "",
                },
                {
                  label: "Pataisa €/m2",
                  value: formatAreaPrice(
                    recalculatedAdjustments.adjusted_fields.find(
                      (field) => field.field === fieldName
                    )?.price_area ?? 0
                  ),
                },
              ];
            }}
          />
        </>
      )}
    </div>
  );
};
