import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Import translation resources
import translationsEN from "./locales/en/translation.json";
import translationsLT from "./locales/lt/translation.json";

const LANGUAGE_KEY = "i18nextLng";

export const getStoredLanguage = () => {
  const storedLanguage = localStorage.getItem(LANGUAGE_KEY);
  return storedLanguage || "lt";
};

export const languageChangedHandler = () => {
  localStorage.setItem(LANGUAGE_KEY, i18n.language);
};

export const init = async () => {
  const initialLanguage = getStoredLanguage();

  await i18n.use(initReactI18next).init({
    resources: {
      en: {
        translation: translationsEN,
      },
      lt: {
        translation: translationsLT,
      },
    },
    lng: initialLanguage, // Set initial language
    fallbackLng: "lt",
    interpolation: {
      escapeValue: false,
    },
  });

  i18n.on("languageChanged", languageChangedHandler);
};

export default i18n;
