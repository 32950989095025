import React from "react";
import { useTranslation } from "react-i18next";
import { useUsedCreditsByMonth } from "../../hooks/auth.hooks";
import { SimpleTableComponent } from "../../components/tables/simpleTable/SimpleTableComponent";
import { SimpleTableHeader } from "../../components/tables/simpleTable/SimpleTableHeader";
import { SimpleTableRow } from "../../components/tables/simpleTable/SimpleTableRow";
import { StatisticsRow, getMonthCreditsUsed } from "./StatisticsRow";
import { MonthRangePicker } from "../../components/datepicker/MonthRangePicker";
import { MonthDate } from "../../components/datepicker/types";
import { CircularProgress } from "@mui/material";
import { UsedCreditsByMonth } from "../../types/api.types";
import {
  formatMonthlyLimitPrice,
  getMonthLabel,
} from "common/helpers/text.helpers";
import colors from "common/styles/colors";
import { BarChart } from "@mui/x-charts/BarChart";
import { getMonthDateRangeFromCurrentDate } from "../../helpers/comparableFilters.helpers";

interface PaymentsStatisticsProps {}

const getTotalUsedCredits = (data: UsedCreditsByMonth[]) => {
  let count = 0;

  for (const month of data) {
    count += getMonthCreditsUsed(month);
  }

  return count;
};

export const PaymentsStatistics: React.FC<PaymentsStatisticsProps> = () => {
  const { t } = useTranslation();
  const [selectedDatesInFilter, setSelectedDatesInFilter] = React.useState<{
    startDate: MonthDate;
    endDate: MonthDate;
  }>(() => getMonthDateRangeFromCurrentDate(3));

  const { data, isLoading } = useUsedCreditsByMonth(
    selectedDatesInFilter.startDate,
    selectedDatesInFilter.endDate
  );

  return (
    <div>
      <div style={{ marginBottom: "20px" }}>
        {t("payments.statistics.reportingPeriod")}
      </div>
      <div
        style={{
          maxWidth: "400px",
          marginBottom: "30px",
        }}
      >
        <MonthRangePicker
          selectedDatesInFilter={{
            start: selectedDatesInFilter.startDate,
            end: selectedDatesInFilter.endDate,
          }}
          onDatesSelect={(start, end) => {
            setSelectedDatesInFilter({ startDate: start, endDate: end });
          }}
        />
      </div>
      {isLoading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      )}
      {!!data && (
        <>
          <div style={{ fontSize: "18px" }}>
            {t("payments.statistics.usedLimit")}:{" "}
            <span style={{ fontWeight: 600, color: colors.purple2 }}>
              {formatMonthlyLimitPrice(getTotalUsedCredits(data))}
            </span>
          </div>
          <BarChart
            series={[
              {
                data: data.map((month) => getMonthCreditsUsed(month)),
                valueFormatter: (value) => formatMonthlyLimitPrice(value),
                color: colors.purple2,
              },
            ]}
            xAxis={[
              {
                id: "months",
                scaleType: "band",
                data: data.map((month) =>
                  getMonthLabel(month.month, month.year)
                ),
              },
            ]}
            yAxis={[
              {
                valueFormatter: (value) => formatMonthlyLimitPrice(value),
              },
            ]}
            height={300}
          />

          <SimpleTableComponent>
            <SimpleTableRow>
              <SimpleTableHeader width="70px" />
              <SimpleTableHeader textAlign="left">
                {t("payments.statistics.period")}
              </SimpleTableHeader>
              <SimpleTableHeader textAlign="right">
                {t("payments.statistics.usedLimit")}
              </SimpleTableHeader>
            </SimpleTableRow>
            {[...data].reverse().map((month) => (
              <StatisticsRow
                key={`${month.month} ${month.year}`}
                data={month}
              />
            ))}
          </SimpleTableComponent>
        </>
      )}
    </div>
  );
};
