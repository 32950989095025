import React, { useContext, useEffect, useRef, useState } from "react";
import { AppraiserProperty } from "../../types/appraiser.types";
import { Comparable } from "common/types/common.types";
import { GoogleMap } from "@react-google-maps/api";
import { getBoundingBoxFromCoords } from "common/helpers/map.helpers";
import { ComparableIconOverlayView } from "./ComparableIconOverlayView";
import { ComparableMarker } from "./ComparableMarker";
import { ComparableCard } from "./ComparableCard";
import { MapListInteractionsContext } from "../MapListInteractionsProvider";
import { MY_PROPERTY_SIZE_MULTIPLIER, mapStyle } from "./constants";
import { isCompMatched } from "common/helpers/comparables.helpers";
import {
  comparablePropertyBGColor,
  mainPropertyBGColor,
} from "../../hooks/propertyIcons.hooks";

export interface AdjustmentsMapProps {
  comparables?: Comparable[];
  property: AppraiserProperty;
  children?: React.ReactNode;
  mapOptions?: google.maps.MapOptions;
}

export const AdjustmentsMap: React.FC<AdjustmentsMapProps> = ({
  comparables,
  property,
  children,
  mapOptions,
}) => {
  const getInitialBounds = () => {
    if (!comparables) {
      return undefined;
    }
    return getBoundingBoxFromCoords([
      ...(comparables?.map((comp) => ({
        lat: comp.comparable_transaction.location.lat,
        lng: comp.comparable_transaction.location.lng,
      })) ?? []),
      { lat: property.lat, lng: property.lng },
    ]);
  };

  const mapRef = useRef<google.maps.Map>();

  const onLoad = React.useCallback(function callback(map: google.maps.Map) {
    const initialBounds = getInitialBounds();
    if (!!initialBounds) {
      map.fitBounds(
        new google.maps.LatLngBounds(
          { lat: initialBounds.minLat, lng: initialBounds.minLng },
          { lat: initialBounds.maxLat, lng: initialBounds.maxLng }
        )
      );
    }
    mapRef.current = map;
  }, []);

  const { setOnListComparableClickHandler } = useContext(
    MapListInteractionsContext
  );
  const [selectedCompId, setSelectedCompId] = useState<string>();

  useEffect(() => {
    setOnListComparableClickHandler?.((comp) => {
      if (!mapRef.current) return;
      mapRef.current?.panTo({
        lat: comp.comparable_transaction.location.lat,
        lng: comp.comparable_transaction.location.lng,
      });

      setSelectedCompId(comp.comparable_transaction.id);
    });
  }, [comparables]);

  return (
    <GoogleMap
      options={{
        fullscreenControl: false,
        clickableIcons: false,
        scrollwheel: true,
        ...mapOptions,
        styles: mapStyle
      }}
      center={
        !comparables ? { lat: property.lat, lng: property.lng } : undefined
      }
      mapContainerStyle={{
        height: "100%",
        width: "100%",
      }}
      zoom={15}
      onLoad={onLoad}
      onClick={() => {
        setSelectedCompId(undefined);
      }}
    >
      <ComparableIconOverlayView
        lat={property.lat}
        lng={property.lng}
        backgroundColor={mainPropertyBGColor}
        sizeMultiplier={MY_PROPERTY_SIZE_MULTIPLIER}
      />
      {comparables?.map((comp, index) => (
        <ComparableMarker
          key={index}
          markerProps={{
            lat: comp.comparable_transaction.location.lat,
            lng: comp.comparable_transaction.location.lng,
            onClick: () => setSelectedCompId(comp.comparable_transaction.id),
            backgroundColor: comparablePropertyBGColor,
            withOkIcon: isCompMatched(comp.comparable_transaction),
            multipleCount: index + 1,
            forceMultipleCount: true,
          }}
          isSelected={selectedCompId === comp.comparable_transaction.id}
        >
          <ComparableCard
            comparable={comp}
            adjustments={comp.adjustments}
            isOverview
          />
        </ComparableMarker>
      ))}
      {children}
    </GoogleMap>
  );
};
