import React from "react";
import { Typography } from "../Typography";
import { Text } from "react-native";
import { termsOfServiceLink } from "../../constants/externalLinks.constants";
import { openInBrowser } from "../../webBrowser/webBrowser";
import colors from "../../styles/colors";
import { useTranslation } from "react-i18next";

interface PropertyFormCaptionProps {}

export const PropertyFormCaption: React.FC<PropertyFormCaptionProps> = () => {
  const { t } = useTranslation();

  return (
    <Typography variant="caption">
      {t("propertyForm.termsAgreement")}{" "}
      <Text
        onPress={() => openInBrowser(termsOfServiceLink)}
        style={{ color: colors.purple2 }}
      >
        {t("propertyForm.termsOfService")}
      </Text>
    </Typography>
  );
};
