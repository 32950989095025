import React from "react";
import { LinkForButton } from "../links/LinkForButton";
import { Button, Divider } from "@mui/material";
import { Typography } from "common/components/Typography";
import { ValuationsRecommendations } from "./ValuationsRecommendations";
import { useNavigate } from "react-router-dom";
import { CenteredContentWrapper } from "../wrappers/CenteredContentWrapper";
import { TransactionsRCProvider } from "../TransactionsRCProvider";
import { AutoPricePrediction } from "./AutoPricePrediction";
import { AppraiserProperty } from "../../types/appraiser.types";
import { getPropertyValueString } from "../../helpers/property.helpers";
import { LinkButton } from "../buttons/LinkButton";
import { useTranslation } from "react-i18next";

interface PredictionNotEvaluatedProps {
  hasEditRights: boolean;
  comparablesSelectionUri: string;
  property: AppraiserProperty;
}

export const PredictionNotEvaluated: React.FC<PredictionNotEvaluatedProps> = ({
  hasEditRights,
  comparablesSelectionUri,
  property,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {hasEditRights ? (
        <div>
          <CenteredContentWrapper
            style={{
              marginBottom: "30px",
            }}
          >
            <Typography variant="caption">
              {t("predictionNotEvaluated.notCalculated", {
                propertyValue: getPropertyValueString(property),
              })}
            </Typography>
          </CenteredContentWrapper>
          <CenteredContentWrapper>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                gap: "15px",
              }}
            >
              <AutoPricePrediction property={property} />
              <LinkForButton to={comparablesSelectionUri}>
                <Button variant="contained">
                  {t("predictionNotEvaluated.evaluateProperty")}
                </Button>
              </LinkForButton>
            </div>
          </CenteredContentWrapper>
          <CenteredContentWrapper style={{ marginTop: "20px" }}>
            <LinkForButton to="simple-valuation">
              <LinkButton>
                {t("predictionNotEvaluated.evaluateWithoutComparables")}
              </LinkButton>
            </LinkForButton>
          </CenteredContentWrapper>
          <Divider
            sx={{
              my: "30px",
            }}
          />
          <div style={{ marginBottom: "30px" }}>
            <ValuationsRecommendations
              propertyId={property._id}
              onUsePrediction={() => {
                navigate(comparablesSelectionUri);
              }}
            />
          </div>
        </div>
      ) : (
        <Typography>
          {t("predictionNotEvaluated.propertyNotEvaluated")}
        </Typography>
      )}
    </div>
  );
};
