import React, { useMemo } from "react";
import { ContentCard } from "../../components/ContentCard";
import { SimpleTableComponent } from "../../components/tables/simpleTable/SimpleTableComponent";
import { SimpleTableRow } from "../../components/tables/simpleTable/SimpleTableRow";
import { SimpleTableHeader } from "../../components/tables/simpleTable/SimpleTableHeader";
import {
  Adjustments,
  ComparableFull,
  ComparableTransactionFull,
  FinalPricePredictions,
  Image,
} from "common/types/common.types";
import { SimpleTableElement } from "../../components/tables/simpleTable/SimpleTableElement";
import {
  adjustmentFields,
  getComparableAddress,
  getComparableImages,
} from "common/helpers/comparables.helpers";
import { TableComponent } from "../../components/tables/TableComponent";
import { TableRow } from "../../components/tables/types";
import { useParams } from "react-router-dom";
import { useProperty } from "../../hooks/property.hooks";
import { TransparencyAdjustmentsCoeffs } from "./TransparencyAdjustmentsCoeffs";
import { TranparencyAdjustmentsHeader } from "./TranparencyAdjustmentsHeader";
import { PropertyImageGallery } from "../../components/imageGallery/PropertyImageGallery";
import { formatAreaPrice } from "common/helpers/text.helpers";
import colors from "common/styles/colors";
import { round } from "common/helpers/common.helpers";
import { PropertyRow } from "../../components/PropertyRow";
import {
  getLithuanianDateString,
  getLithuanianDateTimeString,
} from "common/helpers/dateStringHelpers";
import { useTranslation } from "react-i18next";
import { getTranslatedFieldName } from "common/helpers/comparables.helpers";

interface TransparencyAdjustmentsCorrectionProps {
  data: FinalPricePredictions;
}

const HeaderElement: React.FC<{ images: Image[]; address: string }> = ({
  images,
  address,
}) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      width: "220px",
    }}
  >
    <PropertyImageGallery
      height={200}
      images={images}
      headerText={address}
      borderRadius={5}
    />
    {address}
  </div>
);

export const TransparencyAdjustmentsCorrection: React.FC<
  TransparencyAdjustmentsCorrectionProps
> = ({ data }) => {
  const { t } = useTranslation();
  const { data: property } = useProperty(data?.myPropertyId ?? "");

  const fields = useMemo(() => {
    const fieldsSet = new Set<keyof ComparableTransactionFull>();

    data?.comparables.forEach((c) => {
      c.adjustments?.adjusted_fields.forEach((f) => {
        fieldsSet.add(f.field);
      });
      c.adjustments_original?.adjusted_fields.forEach((f) => {
        fieldsSet.add(f.field);
      });
    });

    return Array.from(fieldsSet);
  }, [data]);

  const firstRow: TableRow = {
    header: null,
    elements: [
      <HeaderElement
        images={property?.images ?? []}
        address={property?.address ?? ""}
      />,
      ...data.comparables.map((c) => (
        <HeaderElement
          images={getComparableImages(c.comparable_transaction)}
          address={c.comparable_transaction.address}
        />
      )),
    ],
  };

  const getAdjustedField = (
    field: string,
    adjustments: Adjustments | undefined
  ) => {
    return adjustments?.adjusted_fields.find((f) => f.field === field);
  };

  const tableRows: TableRow[] = fields.map((f) => ({
    header: getTranslatedFieldName(f, t),
    elements: [
      <b style={{ color: colors.purple2 }}>
        {!property ? null : adjustmentFields[f]?.propertyFieldValue(property)}
      </b>,
      ...data.comparables.map((c) => {
        const adjustedField = getAdjustedField(f, c.adjustments);
        const adjustedFieldOriginal = getAdjustedField(
          f,
          c.adjustments_original ?? c.adjustments
        );

        const percentOriginal = round(
          (adjustedFieldOriginal?.percent ?? 0) + 1,
          3
        );
        const percentAdjusted = round((adjustedField?.percent ?? 0) + 1, 3);

        const priceOriginal = adjustedFieldOriginal?.price_area ?? 0;
        const priceAdjusted = adjustedField?.price_area ?? 0;

        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "15px",
            }}
          >
            {percentOriginal !== percentAdjusted && (
              <div
                style={{
                  textDecoration: "line-through",
                }}
              >
                {`${percentOriginal} (${formatAreaPrice(priceOriginal)})`}
              </div>
            )}
            {`${percentAdjusted} (${formatAreaPrice(priceAdjusted)})`}
          </div>
        );
      }),
    ],
    isCollapsible: true,
    children: [
      {
        header: (
          <TranparencyAdjustmentsHeader
            field={f}
            provider={
              getAdjustedField(f, data.comparables[0].adjustments)?.config_name
            }
            providerFullname={
              getAdjustedField(f, data.comparables[0].adjustments)
                ?.config_fullname
            }
            propertyId={data.myPropertyId ?? ""}
          />
        ),
        elements: [
          (() => {
            if (!property) {
              return null;
            }
            const adjustedField = getAdjustedField(
              f,
              data.comparables[0].adjustments
            );
            if (!adjustedField) {
              return null;
            }

            return (
              <TransparencyAdjustmentsCoeffs
                field={f}
                provider={adjustedField?.config_name}
                property={property}
                comparable={undefined}
              />
            );
          })(),

          ...data.comparables.map((c) => {
            if (!property) {
              return null;
            }
            const adjustedField = getAdjustedField(f, c.adjustments);
            if (!adjustedField) {
              return null;
            }

            return (
              <TransparencyAdjustmentsCoeffs
                field={f}
                provider={adjustedField?.config_name}
                property={property}
                comparable={c.comparable_transaction}
              />
            );
          }),
        ],
      },
    ],
  }));

  return (
    <ContentCard
      title={t("transparency.adjustmentsCorrection")}
      icon={
        data.updatedAt ? (
          <PropertyRow
            label={t("transparency.creationDate")}
            value={getLithuanianDateTimeString(data.updatedAt)}
          />
        ) : null
      }
    >
      <TableComponent
        rows={[firstRow, ...tableRows]}
        sx={{ width: "100%" }}
        isCollapsedDefault={true}
      />
    </ContentCard>
  );
};
