import React from "react";
import { Typography } from "common/components/Typography";
import colors from "common/styles/colors";

interface ChartWrapperProps {
  title: string;
  subtitle?: string;
  description?: string;
  children: React.ReactNode;
}

export const ChartWrapper: React.FC<ChartWrapperProps> = ({
  title,
  subtitle,
  description,
  children,
}) => {
  return (
    <div style={{ marginBottom: "40px" }}>
      <h3 style={{ fontWeight: 500 }}>{title}</h3>
      {subtitle && (
        <div style={{ marginBottom: "8px", marginTop: "-8px" }}>
          <Typography
            textStyle={{
              fontSize: 16,
            }}
          >
            {subtitle}
          </Typography>
        </div>
      )}
      {description && (
        <Typography
          variant="caption"
          textStyle={{
            color: colors.grey,
          }}
        >
          {description}
        </Typography>
      )}
      {children}
    </div>
  );
};
