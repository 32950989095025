import { MenuItem, Select } from "@mui/material";
import { useFormFieldErrors } from "common/hooks/form.hooks";
import { Controller, useFormContext } from "react-hook-form";
import { FormControlWrapper } from "./FormControlWrapper";
import { FormFieldWrapper } from "common/components/form/FormFieldWrapper";
import { useTranslation } from "react-i18next";

interface FormSelectProps {
  label?: string;
  name: string;
  values: string[];
  getIcon?: (value: string) => string;
  getTitle?: (value: string) => string;
  isRequired?: boolean;
  disabled?: boolean;
  height?: number;
  allowNull?: boolean;
  nullValueLabel?: string;
}

export function FormSelect({
  name,
  label,
  values,
  getIcon,
  getTitle,
  isRequired,
  disabled,
  height,
  allowNull = false,
  nullValueLabel,
}: FormSelectProps) {
  const { control } = useFormContext();
  const { t } = useTranslation();
  const { errorText } = useFormFieldErrors(name, label);

  const finalNullValueLabel = nullValueLabel || t("common.none");

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => {
        return (
          <FormFieldWrapper
            label={label}
            error={errorText}
            isRequired={isRequired}
          >
            <Select
              value={value ?? ""}
              onChange={(e) => {
                const val = e.target.value;
                onChange(val === "" ? null : val);
              }}
              style={{ zIndex: 100000, height: height || 56 }}
              renderValue={(value) => {
                if (!value) return finalNullValueLabel;
                return getTitle?.(value) ?? value;
              }}
              disabled={disabled}
            >
              {allowNull && (
                <MenuItem value="">
                  <div style={{ display: "flex", gap: "10px" }}>
                    <em>{finalNullValueLabel}</em>
                  </div>
                </MenuItem>
              )}
              {values.map((value) => {
                const Icon = getIcon?.(value);
                return (
                  <MenuItem key={value} value={value}>
                    <div style={{ display: "flex", gap: "10px" }}>
                      {!!Icon && <Icon />}
                      {getTitle?.(value) ?? value}
                    </div>
                  </MenuItem>
                );
              })}
            </Select>
          </FormFieldWrapper>
        );
      }}
    />
  );
}
