import React, { useState } from "react";
import { FormAutocomplete } from "../../../components/form/FormAutocomplete";
import { useQuery } from "react-query";
import { getCities } from "../../../api/geocode.api";
import { CityAutocompleteResult } from "../../../types/geocoding.types";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface CityFormFieldProps {
  onSelect?: (city: CityAutocompleteResult | undefined) => void;
  hideLabel?: boolean;
}

export const getCityLabel = (city: CityAutocompleteResult) => {
  return `${city.VARDAS} ${city.TIPO_SANTRUMPA} (${
    city.municipality.VARDAS_K
  } ${city.municipality.TIPO_SANTRUMPA})${
    city.eldership
      ? ` (${city.eldership.VARDAS_K} ${city.eldership.TIPO_SANTRUMPA})`
      : ""
  }`;
};

export const CityFormField: React.FC<CityFormFieldProps> = ({
  onSelect,
  hideLabel,
}) => {
  const { t } = useTranslation();
  const [query, setQuery] = useState("");

  const { data, isLoading } = useQuery(
    ["cities_autocomplete", query],
    () => getCities(query),
    {
      keepPreviousData: true,
    }
  );

  const options = data?.map(getCityLabel);

  const { setValue } = useFormContext();

  const label = t("propertyForm.address.city");

  return (
    <FormAutocomplete
      label={hideLabel ? undefined : label}
      placeholder={hideLabel ? label : undefined}
      name="city"
      isRequired
      options={options ?? []}
      onChange={(value) => {
        setQuery(value ?? "");
      }}
      isLoading={isLoading}
      onSelect={(value) => {
        const selectedCity = data?.find((city) => getCityLabel(city) === value);
        onSelect?.(selectedCity);

        setValue("cityId", selectedCity?.GYV_KODAS ?? null);
        setValue("street", null);
        setValue("streetId", null);
        setValue("streetNumber", null);
      }}
      disableFilterOptions
    />
  );
};
