import React, { useMemo } from "react";
import { PropertyValuationSummary } from "./PropertyValuationSummary";
import { ContentCard } from "../components/ContentCard";
import { getConfidenceMeasureForPricePrediction } from "../api/api";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { PredictionsConfidenceMeasure } from "./property/PredictionsConfidenceMeasure";
import { useProperty } from "../hooks/property.hooks";
import { AutoPricePrediction } from "../components/predictionOverview/AutoPricePrediction";
import { LinkForButton } from "../components/links/LinkForButton";
import { Button } from "@mui/material";
import { ComparablesSummaryTable } from "../components/comparablesSummary/ComparablesSummaryTable";
import {
  useComparablesSummary,
  usePricePredictions,
} from "../hooks/pricePredictions.hooks";
import { ValuationSummary } from "../components/comparablesSummary/ValuationSummary";
import {
  AdjustmentsJustification,
  AdjustmentsJustificationHandles,
} from "../components/comparablesSummary/AdjustmentsJustification";

interface ComparablesSummaryProps {}

export const ComparablesSummary: React.FC<ComparablesSummaryProps> = () => {
  const { propertyId } = useParams();

  const { data: property } = useProperty(propertyId ?? "");

  const { data: confidence } = useQuery(["confidence", propertyId], () =>
    getConfidenceMeasureForPricePrediction(propertyId ?? "")
  );

  const { comps } = useComparablesSummary(propertyId ?? "");

  const { getFinalPricePredictions } = usePricePredictions(propertyId ?? "");

  const finalPricePredictions = useMemo(
    () => getFinalPricePredictions(),
    [getFinalPricePredictions]
  );

  const adjustmentsJustificationRef =
    React.useRef<AdjustmentsJustificationHandles>(null);

  return (
    <PropertyValuationSummary
      adjustmentsJustificationRef={adjustmentsJustificationRef}
    >
      {confidence && (
        <ContentCard title="Pasitikėjimo indeksas" borderRadius={0}>
          <PredictionsConfidenceMeasure confidence={confidence} />
        </ContentCard>
      )}
      <ContentCard
        title="Turto vertinimo informacija"
        borderRadius={0}
        icon={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "20px",
            }}
          >
            {!!property && (
              <AutoPricePrediction
                property={property}
                skipNavigation
                buttonText="Įvertinti automatiškai iš naujo"
              />
            )}
            <LinkForButton to="../">
              <Button color="error">Redaguoti</Button>
            </LinkForButton>
          </div>
        }
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <ComparablesSummaryTable
            finalPricePredictions={finalPricePredictions}
            propertyId={propertyId ?? ""}
            comps={comps}
          />
        </div>
        <ValuationSummary
          finalPricePredictions={finalPricePredictions}
          propertyId={propertyId ?? ""}
        />
        <div
          style={{
            height: "30px",
          }}
        />
        <AdjustmentsJustification
          ref={adjustmentsJustificationRef}
          comps={comps}
        />
      </ContentCard>
    </PropertyValuationSummary>
  );
};
