import React from "react";
import { Box } from "@mui/material";
import { LanguageSwitcher } from "../LanguageSwitcher";

interface LoginLayoutProps {
  children: React.ReactNode;
}

export const LoginLayout: React.FC<LoginLayoutProps> = ({ children }) => {
  return (
    <>
      <Box
        sx={{
          position: "absolute",
          top: 20,
          right: 20,
        }}
      >
        <LanguageSwitcher />
      </Box>
      {children}
    </>
  );
};
