import React from "react";
import { useTranslation } from "react-i18next";
import { usePaginatedQuery } from "../../hooks/router.hooks";
import { useQuery } from "react-query";
import { getPricePredictionsFeed } from "../../api/api";
import { Button } from "@mui/material";
import { getLithuanianDateTimeString } from "common/helpers/dateStringHelpers";
import {
  ComparableTransactionFull,
  PricePredictionFeed,
} from "common/types/common.types";
import { formatPrice } from "common/helpers/text.helpers";
import { LinkForButton } from "../../components/links/LinkForButton";
import { CenteredContentWrapper } from "../../components/wrappers/CenteredContentWrapper";
import { PaginationComponent } from "../../components/pagination/PaginationComponent";
import { LoaderCentered } from "../../components/loaders/LoaderCentered";
import { ContentCard } from "../../components/ContentCard";
import { BasePropertyCard } from "../../components/cards/BasePropertyCard";
import { SimpleTableComponent } from "../../components/tables/simpleTable/SimpleTableComponent";
import { SimpleTableRow } from "../../components/tables/simpleTable/SimpleTableRow";
import { SimpleTableElement } from "../../components/tables/simpleTable/SimpleTableElement";
import { SimpleTableHeader } from "../../components/tables/simpleTable/SimpleTableHeader";
import { getPropertyListString } from "../../helpers/property.helpers";
import { ConfidenceTooltip } from "../../components/predictionOverview/ConfidenceTooltip";
import { TagComponent } from "../../components/tags/TagComponent";
import colors from "common/styles/colors";
import { getTranslatedFieldName } from "common/helpers/comparables.helpers";
import { Stack, Chip } from "@mui/material";
import { PercentageIndicator } from "../../components/indicators/PercentageIndicator";

const TABLE_CELL_PADDING = "10px";
const TABLE_CELL_WIDTH = "100px";

interface FeedTableProps {
  predictions: PricePredictionFeed[];
}

interface AdjustmentFieldInfo {
  field: keyof ComparableTransactionFull;
  name: string;
}

const AdjustmentSteps: React.FC<{ prediction: PricePredictionFeed }> = ({
  prediction,
}) => {
  const { t } = useTranslation();

  const adjustedFields = React.useMemo<AdjustmentFieldInfo[]>(() => {
    return (prediction.adjustedFields || []).map((field) => ({
      field,
      name: getTranslatedFieldName(field, t),
    }));
  }, [prediction, t]);

  const hasAdjustments = adjustedFields.length > 0;
  const hasComparableChanges =
    (prediction.comparableChanges?.changed.length || 0) > 0 ||
    (prediction.comparableChanges?.removed.length || 0) > 0;

  if (!hasAdjustments && !hasComparableChanges) {
    return <>-</>;
  }

  return (
    <div>
      {hasAdjustments && (
        <div style={{ marginBottom: "8px" }}>
          <div>{t("feed.predictions.adjustments.title")}</div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "4px",
              marginTop: "8px",
            }}
          >
            {adjustedFields.map(({ field, name }) => (
              <Chip key={field} label={name} size="small" variant="outlined" />
            ))}
          </div>
        </div>
      )}
      {hasComparableChanges && (
        <div>
          <div>{t("feed.predictions.adjustments.comparables")}</div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "4px",
              marginTop: "8px",
            }}
          >
            {prediction.comparableChanges?.changed.length ? (
              <div style={{ display: "inline-block" }}>
                <Chip
                  label={`${t(
                    "feed.predictions.adjustments.added"
                  )}: ${prediction.comparableChanges.changed.join("; ")};`}
                  size="small"
                  variant="outlined"
                />
              </div>
            ) : null}
            {prediction.comparableChanges?.removed.length ? (
              <div style={{ display: "inline-block" }}>
                <Chip
                  label={`${t(
                    "feed.predictions.adjustments.removed"
                  )}: ${prediction.comparableChanges.removed.join("; ")};`}
                  size="small"
                  variant="outlined"
                />
              </div>
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
};

const FeedTable: React.FC<FeedTableProps> = ({ predictions }) => {
  const { t } = useTranslation();

  return (
    <SimpleTableComponent>
      <SimpleTableRow>
        <SimpleTableHeader
          isFirstElement
          style={{ padding: TABLE_CELL_PADDING }}
        >
          {t("feed.predictions.table.property")}
        </SimpleTableHeader>
        <SimpleTableHeader
          width={TABLE_CELL_WIDTH}
          textAlign="left"
          style={{ padding: TABLE_CELL_PADDING }}
        >
          {t("feed.predictions.table.modelPrice")}
        </SimpleTableHeader>
        <SimpleTableHeader
          width={TABLE_CELL_WIDTH}
          textAlign="left"
          style={{ padding: TABLE_CELL_PADDING }}
        >
          {t("feed.predictions.table.adjustedPrice")}
        </SimpleTableHeader>
        <SimpleTableHeader
          width={TABLE_CELL_WIDTH}
          textAlign="left"
          style={{ padding: TABLE_CELL_PADDING }}
        >
          {t("feed.predictions.table.confidenceIndex")}
        </SimpleTableHeader>
        <SimpleTableHeader
          textAlign="left"
          style={{ padding: TABLE_CELL_PADDING }}
        >
          {t("feed.predictions.table.modelStep")}
        </SimpleTableHeader>
        <SimpleTableHeader
          style={{ padding: TABLE_CELL_PADDING }}
        ></SimpleTableHeader>
      </SimpleTableRow>
      {predictions.map((prediction) => {
        const property = prediction.property;
        const caption = getPropertyListString({
          area: property.area,
          rooms: property.rooms,
          floorNr: property.floorNr,
          floorCount: property.floorCount,
          year: property.year,
          renovationYear: property.renovationYear,
          energyClass: property.energyClass,
          finishing: property.finishing,
        });

        return (
          <SimpleTableRow key={property._id}>
            <SimpleTableElement isFirstElement>
              <BasePropertyCard
                images={property.images?.map((img) => img.url)}
                address={property.address}
                caption={caption}
                noBorder
              />
            </SimpleTableElement>
            <SimpleTableElement
              textAlign="center"
              style={{ padding: TABLE_CELL_PADDING }}
            >
              {prediction.predictedPriceOriginal
                ? formatPrice(
                    prediction.predictedPriceOriginal.average_price_area *
                      property.area
                  )
                : "-"}
            </SimpleTableElement>
            <SimpleTableElement
              textAlign="center"
              style={{ padding: TABLE_CELL_PADDING }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  gap: "4px",
                }}
              >
                <span>
                  {formatPrice(
                    prediction.predictedPrice.average_price_area * property.area
                  )}
                </span>
                {prediction.predictedPriceOriginal && (
                  <PercentageIndicator
                    originalValue={
                      prediction.predictedPriceOriginal.average_price_area
                    }
                    correctedValue={
                      prediction.predictedPrice.average_price_area
                    }
                  />
                )}
              </div>
            </SimpleTableElement>
            <SimpleTableElement
              textAlign="center"
              style={{ padding: TABLE_CELL_PADDING }}
            >
              {prediction.confidence ? (
                <ConfidenceTooltip
                  confidence={prediction.confidence}
                  fontSize="18px"
                />
              ) : (
                "-"
              )}
            </SimpleTableElement>
            <SimpleTableElement
              textAlign="left"
              style={{ padding: TABLE_CELL_PADDING }}
            >
              <AdjustmentSteps prediction={prediction} />
            </SimpleTableElement>
            <SimpleTableElement style={{ padding: TABLE_CELL_PADDING }}>
              <LinkForButton to={`/properties/${property._id}/transparency`}>
                <Button>{t("feed.predictions.table.more")}</Button>
              </LinkForButton>
            </SimpleTableElement>
          </SimpleTableRow>
        );
      })}
    </SimpleTableComponent>
  );
};

export const FeedPredictions: React.FC = () => {
  const { t } = useTranslation();
  const { page } = usePaginatedQuery();
  const { data, isLoading } = useQuery(["pricePredictionsFeed", page], () =>
    getPricePredictionsFeed(page)
  );

  if (isLoading) return <LoaderCentered />;
  if (!data) return null;

  return (
    <ContentCard title={t("feed.predictions.title")}>
      <FeedTable predictions={data.data} />
      <CenteredContentWrapper style={{ marginTop: "20px" }}>
        <PaginationComponent pagination={data.pagination} />
      </CenteredContentWrapper>
    </ContentCard>
  );
};
