import React, { useEffect, useMemo, useRef } from "react";
import { ContentCard } from "../components/ContentCard";
import { useNavigate, useParams } from "react-router-dom";
import {
  useComparablesSummary,
  usePricePredictions,
} from "../hooks/pricePredictions.hooks";
import { useMutation, useQuery } from "react-query";
import { FinalPricePredictions } from "common/types/common.types";
import {
  addPricePrediction,
  createConfirmation,
  getConfidenceMeasureForPricePrediction,
} from "../api/api";
import { MUILoadingButton } from "../components/buttons/MUILoadingButton";
import { ComparablesSummaryTable } from "../components/comparablesSummary/ComparablesSummaryTable";
import { ValuationSummary } from "../components/comparablesSummary/ValuationSummary";
import {
  AdjustmentsJustification,
  AdjustmentsJustificationHandles,
} from "../components/comparablesSummary/AdjustmentsJustification";
import { CompositeAssets } from "../components/comparablesSummary/CompositeAssets";
import { PredictionsConfirmButton } from "./PredictionsConfirmButton";
import {
  ConfirmationForm,
  ConfirmationFormHandles,
} from "../components/comparablesSummary/ConfirmationForm";
import { ConfirmationRequest } from "../types/api.types";
import { showToastError } from "common/toast/toast";
import { useProperty } from "../hooks/property.hooks";
import { getErrorMessage } from "common/helpers/error.helpers";
import { useDeletePropertyToken } from "common/hooks/propertyDetails.hooks";
import { PredictionsConfidenceMeasure } from "./property/PredictionsConfidenceMeasure";
import { LinkForButton } from "../components/links/LinkForButton";
import { Button } from "@mui/material";
import { AutoPricePrediction } from "../components/predictionOverview/AutoPricePrediction";

interface PropertyValuationSummaryProps {
  children?: React.ReactNode;
  adjustmentsJustificationRef?: React.MutableRefObject<AdjustmentsJustificationHandles | null>;
  ignoreComparables?: boolean;
}

export const PropertyValuationSummary: React.FC<
  PropertyValuationSummaryProps
> = ({ children, adjustmentsJustificationRef, ignoreComparables }) => {
  const { propertyId } = useParams();

  useDeletePropertyToken(propertyId ?? "");

  const { getFinalPricePredictions, pricePredictions, refetch } =
    usePricePredictions(propertyId ?? "");

  useEffect(() => {
    refetch();
  }, []);

  const navigate = useNavigate();
  const {
    mutateAsync: mutateAsyncPredictions,
    isLoading: isLoadingPredictions,
  } = useMutation(
    async (pricePrediction: FinalPricePredictions) => {
      await addPricePrediction<FinalPricePredictions>(
        propertyId ?? "",
        pricePrediction,
        true
      );
    },
    {
      onError: (error) => {
        showToastError(getErrorMessage(error));
      },
    }
  );

  const {
    mutateAsync: mutateAsyncConfirmation,
    isLoading: isLoadingConfirmation,
  } = useMutation(
    async (x: { data: ConfirmationRequest; propertyId: string }) =>
      createConfirmation(x.data, x.propertyId),
    {
      onError: (error) => {
        showToastError(getErrorMessage(error));
      },
    }
  );

  const [buttonPressed, setButtonPressed] = React.useState<"save" | "confirm">(
    "save"
  );

  const navigateToProperty = () => {
    navigate(`/properties/${propertyId}`);
  };

  const saveFinalPricePredictions = async (navigateOnSuccess: boolean) => {
    const final = getFinalPricePredictions(ignoreComparables);
    if (!final) return;
    final.descriptions =
      adjustmentsJustificationRef?.current?.getDescriptions();
    await mutateAsyncPredictions(final);

    if (navigateOnSuccess) {
      navigateToProperty();
    }
  };

  const confirmationFormRef = useRef<ConfirmationFormHandles>(null);

  const isLoading = isLoadingConfirmation || isLoadingPredictions;

  const { data: property } = useProperty(propertyId ?? "");

  if (!propertyId) return null;

  const handleConfirmationSubmit = async () => {
    const fromLead = !!property?.leadContact;

    if (property?.reportVerifiedAt) {
      await saveFinalPricePredictions(!fromLead);
      return true;
    }

    let error = false;
    await confirmationFormRef.current?.handleSubmit(
      async (data) => {
        setButtonPressed(fromLead ? "confirm" : "save");

        await mutateAsyncConfirmation({
          data,
          propertyId,
        });

        await saveFinalPricePredictions(!fromLead);
      },
      (data) => {
        if (data.confirmCheckbox) {
          showToastError("Patvirtinkite, kad sutinkate su sąlygomis");
        }
        error = true;
      }
    )();
    if (error) {
      return false;
    }
    return true;
  };

  return (
    <div style={{ padding: "15px 0" }}>
      {children}
      <ContentCard title="Priklausomas turtas" borderRadius={0}>
        <CompositeAssets
          propertyId={propertyId}
          pricePredictionId={pricePredictions?._id}
        />
      </ContentCard>
      <ContentCard title="Patvirtinimas">
        <ConfirmationForm
          ref={confirmationFormRef}
          disabled={!!property?.reportVerifiedAt}
          propertyId={propertyId}
        />
      </ContentCard>
      <div style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
        <MUILoadingButton
          loading={isLoading && buttonPressed === "save"}
          disabled={isLoading}
          onClick={
            property?.leadContact
              ? async () => {
                  setButtonPressed("save");
                  await saveFinalPricePredictions(true);
                }
              : handleConfirmationSubmit
          }
          style={{
            width: "300px",
          }}
          variant="outlined"
        >
          Išsaugoti ir uždaryti
        </MUILoadingButton>
        <PredictionsConfirmButton
          disabled={isLoading}
          loading={isLoading && buttonPressed === "confirm"}
          propertyId={propertyId}
          onClick={handleConfirmationSubmit}
          onConfirm={navigateToProperty}
        />
      </div>
    </div>
  );
};
