import React from "react";
import { Menu, Tooltip, Divider} from "@mui/material";
import VpnKeyOutlinedIcon from "@mui/icons-material/VpnKeyOutlined";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import LockResetIcon from "@mui/icons-material/LockReset";
import RefreshIcon from "@mui/icons-material/Refresh";
import LinkIcon from "@mui/icons-material/Link";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import LoginIcon from "@mui/icons-material/Login";
import { MenuItemWithIcon } from "../menu/MenuItemWithIcon";
import { Appraiser } from "../../types/appraiser.types";
import colors from "common/styles/colors";

interface AppraisersMenuProps {
  appraiser: Appraiser;
  currentAppraiser: Appraiser;
  anchorEl: HTMLElement | null;
  onMenuClose: () => void;
  onViewProfile: () => void;
  onRegeneratePasswordResetLink: () => void;
  onRegenerateRegistrationLink: () => void;
  onCopyLinkToClipboard: (link: string) => void;
  onLoginAs: () => void;
  onViewRCCredentials: () => void;
  onDeleteAppraiser: () => void;
  t: (key: string, options?: Record<string, any>) => string;
}

const AppraisersMenu: React.FC<AppraisersMenuProps> = ({
  appraiser,
  currentAppraiser,
  anchorEl,
  onMenuClose,
  onViewProfile,
  onRegeneratePasswordResetLink,
  onRegenerateRegistrationLink,
  onCopyLinkToClipboard,
  onLoginAs,
  onViewRCCredentials,
  onDeleteAppraiser,
  t
}) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onMenuClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Tooltip title={t("appraisers.tooltips.viewProfile", { email: appraiser.email })} placement="left">
        <span>
          <MenuItemWithIcon
            icon={<PermIdentityIcon />}
            label={t("appraisers.menu.viewProfile")}
            onClick={onViewProfile}
          />
        </span>
      </Tooltip>

      {appraiser.acceptedAt && (
        <Tooltip title={t("appraisers.tooltips.regeneratePasswordResetLink")} placement="left">
          <span>
            <MenuItemWithIcon
              icon={<LockResetIcon />}
              label={t("appraisers.menu.regeneratePasswordResetLink")}
              onClick={onRegeneratePasswordResetLink}
            />
          </span>
        </Tooltip>
      )}

      {!appraiser.acceptedAt && appraiser.decodedRegistrationToken?.error && (
        <Tooltip title={t("appraisers.tooltips.regenerateRegistrationLink")} placement="left">
          <span>
            <MenuItemWithIcon
              icon={<RefreshIcon />}
              label={t("appraisers.menu.regenerateRegistrationLink")}
              onClick={onRegenerateRegistrationLink}
            />
          </span>
        </Tooltip>
      )}

      {!appraiser.acceptedAt && !appraiser.decodedRegistrationToken?.error && (
        <Tooltip title={t("appraisers.tooltips.copyRegistrationLink")} placement="left">
          <span>
            <MenuItemWithIcon
              icon={<LinkIcon />}
              label={t("appraisers.menu.copyRegistrationLink")}
              onClick={() => onCopyLinkToClipboard(appraiser.registrationLink || "")}
            />
          </span>
        </Tooltip>
      )}

      <Tooltip title={t("appraisers.tooltips.loginAs", { email: appraiser.email })} placement="left">
        <span>
          <MenuItemWithIcon
            icon={<LoginIcon />}
            label={t("appraisers.menu.loginAs")}
            onClick={onLoginAs}
            disabled={appraiser._id === currentAppraiser._id}
          />
        </span>
      </Tooltip>

      <Tooltip title={t("appraisers.tooltips.rcCredentials")} placement="left">
        <span>
          <MenuItemWithIcon
            icon={<VpnKeyOutlinedIcon />}
            label={t("appraisers.menu.rcCredentials")}
            onClick={onViewRCCredentials}
          />
        </span>
      </Tooltip>

      <Divider />

      <Tooltip title={t("appraisers.tooltips.delete", { email: appraiser.email })} placement="left">
        <span>
          <MenuItemWithIcon
            icon={<DeleteIcon />}
            label={t("appraisers.menu.delete")}
            onClick={onDeleteAppraiser}
            color={colors.red}
            disabled={appraiser._id === currentAppraiser._id}
          />
        </span>
      </Tooltip>
    </Menu>
  );
};

export default AppraisersMenu;
