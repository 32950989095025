import React, { useContext } from "react";
import { useFinalPricePredictions } from "../../hooks/pricePredictions.hooks";
import { formatPrice } from "common/helpers/text.helpers";
import { AdjustmentsMap } from "../map/AdjustmentsMap";
import { AppraiserProperty } from "../../types/appraiser.types";
import { Typography } from "common/components/Typography";
import { getLithuanianDateString } from "common/helpers/dateStringHelpers";
import { Button } from "@mui/material";
import { Divider } from "common/components/listItems/Divider";
import { LinkForButton } from "../links/LinkForButton";
import { PredictionOverviewPrice } from "./PredictionOverviewPrice";
import { PredictionOverviewComparables } from "./PredictionOverviewComparables";
import { PredictionCompositeAssets } from "../PredictionCompositeAssets";
import { PredictionNotEvaluated } from "./PredictionNotEvaluated";
import { FinalPricePredictions } from "common/types/common.types";
import { useTranslation } from "react-i18next";

interface PredictionOverviewProps {
  property: AppraiserProperty;
  hasEditRights: boolean;
  data: FinalPricePredictions & {
    createdAt: string;
    updatedAt: string;
  };
}

export const PredictionOverview: React.FC<PredictionOverviewProps> = ({
  property,
  hasEditRights,
  data,
}) => {
  const { t } = useTranslation();
  const comparablesSelectionUri = `/properties/${property._id}/comparables`;
  const simpleValuationUri = `/properties/${property._id}/simple-valuation`;

  const editButton = hasEditRights ? (
    <LinkForButton
      to={
        data.comparables.length ? comparablesSelectionUri : simpleValuationUri
      }
    >
      <Button>{t("predictionOverview.edit")}</Button>
    </LinkForButton>
  ) : null;

  if (!data.predicted_price.average_price_area) {
    return (
      <PredictionNotEvaluated
        hasEditRights={hasEditRights}
        comparablesSelectionUri={comparablesSelectionUri}
        property={property}
      />
    );
  }

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ alignItems: "center", display: "flex" }}>
        <Typography style={{ fontSize: 16, marginRight: "8px" }}>
          {t("predictionOverview.evaluatedOn", {
            date: getLithuanianDateString(data.updatedAt),
          })}
        </Typography>
        {editButton}
      </div>
      <PredictionOverviewPrice
        predictedPrice={data.predicted_price}
        property={property}
      />
      <div
        style={{
          height: "20px",
        }}
      />
      {!!data._id && (
        <PredictionCompositeAssets
          propertyId={property._id}
          pricePredictionId={data._id}
        />
      )}
      {!!data.comparables.length && (
        <>
          <PredictionOverviewComparables
            editButton={editButton}
            comparables={data.comparables}
            property={property}
          />
          <div style={{ alignSelf: "flex-end", marginTop: "10px" }}>
            <LinkForButton to="valuation-summary">
              <Button>{t("predictionOverview.viewValuation")}</Button>
            </LinkForButton>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "20px",
              gap: "20px",
              justifyContent: "space-around",
            }}
          >
            <div>
              <Typography variant="h3">
                {t("predictionOverview.analyzeCalculations")}
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "50px",
                  margin: "20px 0",
                }}
              >
                <div>{t("predictionOverview.calculationsAnalysis")}</div>
                <LinkForButton to="transparency">
                  <Button>{t("common.view")}</Button>
                </LinkForButton>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
