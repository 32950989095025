import React from "react";
import { CompositeAssetsTable } from "./comparablesSummary/CompositeAssetsTable";
import { Divider } from "common/components/listItems/Divider";
import { CompositeAsset } from "../types/api.types";
import { useTranslation } from "react-i18next";

interface PredictionCompositeAssetsProps {
  propertyId: string;
  pricePredictionId?: string;
  compositeAssets?: CompositeAsset[];
}

export const PredictionCompositeAssets: React.FC<
  PredictionCompositeAssetsProps
> = ({ propertyId, pricePredictionId, compositeAssets }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div>{t("predictionCompositeAssets.assetUnits")}</div>
      <Divider
        style={{
          marginVertical: "10px",
        }}
      />
      <CompositeAssetsTable
        propertyId={propertyId}
        pricePredicitionId={pricePredictionId}
        readonly
        compositeAssets={compositeAssets}
      />
    </div>
  );
};
