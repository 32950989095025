export const MY_PROPERTY_SIZE_MULTIPLIER = 1.4;

export const mapStyle = [
    {
      stylers: [
        {
          saturation: -75,
        },
        {
          lightness: 30,
        },
      ],
    },
    {
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#414c54",
        },
        {
          visibility: "on",
        },
      ],
    },
    {
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#ffffff",
        },
      ],
    },
    {
      featureType: "landscape.natural",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#e0f1ea",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#ddeee7",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#ddeee7",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#ffffff",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "geometry.stroke",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#c8e3ea",
        },
      ],
    },
  ];