import {
  LeadContact,
  LeadContactWithPermissions,
} from "common/types/common.types";
import React from "react";
import { ContentCard } from "../ContentCard";
import { InfoRow } from "../../routes/propertiesCRUD/InfoRow";
import { formatPrice } from "common/helpers/text.helpers";
import { AddonsSummary } from "../../routes/propertiesCRUD/reportPayments/AddonsSummary";
import { useQuery } from "react-query";
import { getPropertyPurchasedAddons } from "../../api/api";
import {
  CompositeAsset,
  PropertyAddonWithPrice,
  PropertyDeliveryTimeType,
} from "../../types/api.types";
import { Box, Button, IconButton } from "@mui/material";
import { CompositeAssetsSummary } from "../../routes/propertiesCRUD/reportPayments/CompositeAssetsSummary";
import { DesignationType } from "common/types/comparableFields.types";
import { LinkForButton } from "../links/LinkForButton";
import { DeliveryTimeSummary } from "../../routes/propertiesCRUD/reportPayments/DeliveryTimeSummary";
import { OrderSummarySection } from "../../routes/propertiesCRUD/reportPayments/OrderSummarySection";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Typography } from "common/components/Typography";
import { getLithuanianDateTimeString } from "common/helpers/dateStringHelpers";
import { useTranslation } from "react-i18next";

interface PropertyLeadContactProps {
  leadContact: LeadContactWithPermissions;
  createdAt: string;
  updatedAt?: string;
  discountCode?: string | null;
  expectedValue?: number | null;
  addons?: PropertyAddonWithPrice[];
  compositeAssets?: CompositeAsset[];
  deliveryTimeLabel?: string;
  hidePdf?: boolean;
  timeLeftBadge?: React.ReactNode;
  depositPaid?: number;
  showLinkToMessages?: boolean;
}

export const PropertyLeadContact: React.FC<PropertyLeadContactProps> = ({
  leadContact,
  discountCode,
  expectedValue,
  addons,
  compositeAssets,
  deliveryTimeLabel,
  hidePdf,
  timeLeftBadge,
  depositPaid,
  showLinkToMessages,
  createdAt,
  updatedAt,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <ContentCard
        title={t("propertyLeadContact.requestData")}
        icon={
          <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
            {showLinkToMessages && (
              <LinkForButton to="messages">
                <IconButton>
                  <MailOutlineIcon />
                </IconButton>
              </LinkForButton>
            )}
            {timeLeftBadge}
          </div>
        }
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: "15px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "15px",
                flex: 1,
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <InfoRow
                  label={t("propertyLeadContact.requestTime")}
                  value={getLithuanianDateTimeString(createdAt)}
                />
                {!!updatedAt && (
                  <Typography
                    variant="caption"
                    textStyle={{
                      fontStyle: "italic",
                    }}
                  >
                    {t("propertyLeadContact.updated")}:{" "}
                    {getLithuanianDateTimeString(updatedAt)}
                  </Typography>
                )}
              </div>
              <InfoRow
                label={t("propertyLeadContact.fullName")}
                value={leadContact.name}
              />
              <InfoRow
                label={t("propertyLeadContact.email")}
                value={leadContact.email}
              />
              <InfoRow
                label={t("propertyLeadContact.phone")}
                value={leadContact.phone}
              />
              <InfoRow
                label={t("propertyLeadContact.discountCode")}
                value={discountCode ?? "-"}
              />
              <InfoRow
                label={t("propertyLeadContact.expectedValue")}
                value={!!expectedValue ? formatPrice(expectedValue) : "-"}
              />
            </div>
            <div
              style={{
                flex: 1,
              }}
            >
              <InfoRow
                label={t("propertyLeadContact.comment")}
                value={leadContact.additionalInfo ?? "-"}
              />
            </div>
          </Box>
          {!!addons && (
            <div
              style={{
                marginTop: "30px",
                display: "flex",
                flexDirection: "column",
                gap: "30px",
              }}
            >
              <AddonsSummary
                addons={addons}
                title={t("propertyLeadContact.orderedAdditionalReportParts")}
                isPurchased={true}
              />
              {!!compositeAssets && (
                <CompositeAssetsSummary
                  assets={compositeAssets.map((asset) => ({
                    designationTypeId: (asset.designationTypeId ??
                      "") as DesignationType,
                    _id: asset._id,
                  }))}
                  title={t(
                    "propertyLeadContact.orderedAdditionalCompositeAssets"
                  )}
                  isPurchased={true}
                />
              )}
              {!!deliveryTimeLabel && (
                <DeliveryTimeSummary
                  deliveryTimeLabel={deliveryTimeLabel}
                  deliveryTimePrice={undefined}
                  isPurchased={true}
                />
              )}
              {!!depositPaid && (
                <div style={{ marginBottom: "20px" }}>
                  <OrderSummarySection
                    title={t("propertyLeadContact.deposit")}
                    items={[
                      {
                        title: t(
                          "propertyLeadContact.depositPaidForFullValuation"
                        ),
                        price: depositPaid,
                      },
                    ]}
                    total={depositPaid}
                  />
                </div>
              )}
            </div>
          )}
          {!!leadContact.pdfUrl && !hidePdf && (
            <LinkForButton
              to={leadContact.pdfUrl}
              target="_blank"
              style={{ alignSelf: "end" }}
            >
              <Button variant="contained">
                {t("propertyLeadContact.openReportPdf")}
              </Button>
            </LinkForButton>
          )}
        </div>
      </ContentCard>
    </>
  );
};
