import React from "react";
import { SimpleDialog } from "../dialogs/SimpleDialog";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormInput } from "common/components/form/hookForms/FormInput";
import { FormSettingsContext } from "common/components/form/hookForms/FormSettingsContext";
import { useMutation, useQueryClient } from "react-query";
import { showToastError } from "common/toast/toast";
import { getErrorMessage } from "common/helpers/error.helpers";
import { Appraiser, RcCredentialsRequest } from "../../types/appraiser.types";
import { setRcCredentials, setOrganizationUseCredentials } from "../../api/api";
import { FormCheckboxInput } from "common/components/form/hookForms/FormCheckboxInput";
import { Switch, FormControlLabel } from "@mui/material";

interface RcCredentialsDialogProps {
  open: boolean;
  onClose: () => void;
  appraiser: Appraiser | undefined;
}

const validationSchema = Yup.object({
  RC_EndUserInfo: Yup.object({
    vv: Yup.string().required("Privalomas laukas"),
    vaidmuo: Yup.string().required("Privalomas laukas"),
    id: Yup.string().required("Privalomas laukas"),
  }),
  RC_IsProduction: Yup.boolean().required(),
});

export const RcCredentialsDialog: React.FC<RcCredentialsDialogProps> = ({
  open,
  onClose,
  appraiser,
}) => {
  const [useOrganizationCreds, setUseOrganizationCreds] = React.useState(
    appraiser?.useOrganizationCredentials ?? false
  );

  const methods = useForm<RcCredentialsRequest>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      RC_EndUserInfo: undefined,
      RC_IsProduction: true,
    },
  });

  const queryClient = useQueryClient();

  const { mutate: mutateRcCreds, isLoading: isLoadingRcCreds } = useMutation(
    (data: RcCredentialsRequest) => setRcCredentials(data, appraiser?._id || ""),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("appraisersInOrganization");
        onClose();
      },
      onError: (error) => {
        showToastError(getErrorMessage(error));
      },
    }
  );

  const { mutate: mutateOrgCreds, isLoading: isLoadingOrgCreds } = useMutation(
    (useOrganizationCredentials: boolean) =>
      setOrganizationUseCredentials(appraiser?._id || "", useOrganizationCredentials),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("appraisersInOrganization");
        onClose();
      },
      onError: (error) => {
        showToastError(getErrorMessage(error));
      },
    }
  );

  React.useEffect(() => {
    if (open) {
      setUseOrganizationCreds(appraiser?.useOrganizationCredentials ?? false);
      methods.reset({
        RC_EndUserInfo: appraiser?.RC_EndUserInfo,
        RC_IsProduction: appraiser?.RC_IsProduction ?? true,
      });
    }
  }, [open, appraiser]);

  const handleDialogSubmit = () => {
    if (useOrganizationCreds) {
      mutateOrgCreds(true);
    } else {
      methods.handleSubmit((data) => mutateRcCreds(data))();
    }
  };

  const formDisabledStyle = {
    opacity: useOrganizationCreds ? 0.5 : 1,
    pointerEvents: useOrganizationCreds ? "none" : "auto",
  } as const;

  return (
    <SimpleDialog
      title="RC Prisijungimo duomenys"
      open={open}
      onClose={onClose}
      onSubmit={handleDialogSubmit}
      isLoading={isLoadingRcCreds || isLoadingOrgCreds}
    >
      <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
        <FormControlLabel
          control={
            <Switch
              checked={useOrganizationCreds}
              onChange={(e) => setUseOrganizationCreds(e.target.checked)}
            />
          }
          label="Naudoti organizacijos prisijungimus"
        />

        <div style={formDisabledStyle}>
          <FormProvider {...methods}>
            <FormSettingsContext.Provider
              value={{ smallFont: true, maxInputWidth: 350, desktopColumns: true, disabled: useOrganizationCreds  }}
            >
              <form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 20,
                }}
              >
                <FormInput
                  name="RC_EndUserInfo.vv"
                  label="VV"
                  variant="text"
                  isRequired
                />
                <FormInput
                  name="RC_EndUserInfo.vaidmuo"
                  label="Vaidmuo"
                  variant="text"
                  isRequired
                />
                <FormInput
                  name="RC_EndUserInfo.id"
                  label="ID"
                  variant="text"
                  isRequired
                />
                  <FormCheckboxInput
                    name="RC_IsProduction"
                    label="Produkcijos aplinka"
                  />
              </form>
            </FormSettingsContext.Provider>
          </FormProvider>
        </div>
      </div>
    </SimpleDialog>
  );
}; 