import React from "react";
import { View, Platform, ViewStyle } from "react-native";
import colors from "common/styles/colors";
import { shadow5 } from "common/styles/shadows.styles";
import { Typography } from "common/components/Typography";

interface ClusterMarkerIconProps {
  propertyCount: number;
  color?: string;
  textColor?: string;
}

export const clusterRadius = 35;

export const ClusterMarkerIcon: React.FC<ClusterMarkerIconProps> = ({
  propertyCount,
  color,
  textColor,
}) => {
  const borderStyle: ViewStyle =
    Platform.OS === "android"
      ? {
          borderWidth: 0.5,
          borderColor: colors.white,
        }
      : {};

  return (
    <View
      style={[
        {
          width: clusterRadius,
          height: clusterRadius,
          borderRadius: clusterRadius / 2,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: color ?? colors.purple2,
        },
        shadow5,
        borderStyle,
      ]}
    >
      <Typography
        variant="badge"
        textStyle={{ fontSize: 12, color: textColor ?? colors.purple2 }}
      >
        {propertyCount}
      </Typography>
    </View>
  );
};
