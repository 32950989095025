import { Box } from "@mui/material";
import { Typography } from "common/components/Typography";
import { addAlpha } from "common/helpers/colors.helpers";
import colors from "common/styles/colors";
import React from "react";
import { Link, To, useLocation } from "react-router-dom";
import { useAppraiser } from "../../hooks/auth.hooks";
import { AppraiserRole } from "../../types/appraiser.types";

interface AdminListItemProps {
  to: To;
  label: string;
  allowedRoles?: AppraiserRole[];
}

export const AdminListItem: React.FC<AdminListItemProps> = ({
  label,
  to,
  allowedRoles,
}) => {
  const location = useLocation();

  const isCurrentPath = location.pathname === to;

  const { data: appraiser } = useAppraiser();

  if (!appraiser) {
    return null;
  }

  if (
    appraiser?.role !== AppraiserRole.ADMIN &&
    !allowedRoles?.includes(appraiser.role)
  ) {
    return null;
  }

  return (
    <Link to={to} style={{ textDecoration: "none" }}>
      <Box
        sx={{
          height: "50px",
          display: "flex",
          alignItems: "center",
          paddingLeft: "20px",
          backgroundColor: isCurrentPath
            ? addAlpha(colors.purple2, 0.2)
            : "white",
          color: isCurrentPath ? colors.purple2 : colors.black,
          "&:hover": {
            backgroundColor: addAlpha(colors.purple2, 0.2),
            color: colors.purple2,
          },
        }}
      >
        <Typography textStyle={{ fontSize: 14, color: "inherit" }}>
          {label}
        </Typography>
      </Box>
    </Link>
  );
};
