import { useQuery } from "react-query";
import {
  getAppraiserInfo,
  getDeliveryTimeConfig,
  getDepositPrices,
  getPropertyReportPrice,
  getReportPrices,
} from "../api/api";
import { useRouteQuery } from "./router.hooks";
import {
  PropertyDeliveryTimeType,
  PropertyType,
  TotalReportPrice,
} from "../types/api.types";
import { PropertyCreationContext } from "../routes/propertiesCRUD/PropertyCreationProvider";
import React, { useContext } from "react";

export const useDeliveryTimeConfigs = (noRefetch = false) => {
  return useQuery("deliveryTimeConfig", getDeliveryTimeConfig, {
    staleTime: noRefetch ? Infinity : 0,
    refetchOnWindowFocus: noRefetch ? false : undefined,
  });
};

export const useReportPrices = (enabled = true) => {
  const simpleType = useRouteQuery().get("simpleType") as PropertyType | null;
  const appraiserId = useRouteQuery().get("appraiserId");
  const discountCode = useRouteQuery().get("discountCode");
  const deliveryTime = useRouteQuery().get(
    "deliveryTime"
  ) as PropertyDeliveryTimeType | null;

  const { selectedAddons, cityId } = React.useContext(PropertyCreationContext);

  return useQuery(
    [
      "report_price_preliminary",
      appraiserId,
      simpleType,
      discountCode,
      deliveryTime,
      cityId,
      JSON.stringify(selectedAddons),
    ],
    () =>
      getReportPrices(
        appraiserId ?? "",
        simpleType ?? undefined,
        discountCode ?? undefined,
        selectedAddons,
        (deliveryTime ?? undefined) as PropertyDeliveryTimeType | undefined,
        cityId
      ),
    {
      enabled: !!appraiserId && enabled,
      keepPreviousData: true,
    }
  );
};

export const usePropertyReportPrice = (
  callback: ((data: TotalReportPrice) => Promise<void>) | undefined,
  enabled = true
) => {
  const createdPropertyId = useRouteQuery().get("createdPropertyId");
  const discountCode = useRouteQuery().get("discountCode");

  const deliveryTime = useRouteQuery().get(
    "deliveryTime"
  ) as PropertyDeliveryTimeType | null;

  const { depositChecked } = useContext(PropertyCreationContext);

  return useQuery(
    ["report_price", createdPropertyId, discountCode, depositChecked],
    async () => {
      const data = await getPropertyReportPrice(
        createdPropertyId ?? "",
        discountCode ?? undefined,
        deliveryTime ?? undefined,
        depositChecked
      );
      await callback?.(data);

      return data;
    },
    {
      enabled: !!createdPropertyId && enabled,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

export const useDepositPrices = () => {
  return useQuery("deposit_prices", getDepositPrices);
};

export const useAppraiserInfo = (appraiserId: string) => {
  return useQuery(
    ["appraiser_info", appraiserId],
    () => getAppraiserInfo(appraiserId),
    {
      enabled: !!appraiserId,
    }
  );
};