import { Box, IconButton, Stack, TextField, Typography } from "@mui/material";

import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import colors from "common/styles/colors";
import {
  PAGE_KEY,
  PROPERTY_TEXT_FILTER_KEY,
  usePropertyTextFilterQuery,
  useUpdateQueryParam,
} from "../../hooks/router.hooks";
import { useEffect, useRef, useState } from "react";
import LightTooltip from "../tooltips/StyledTooltip";
import { useTranslation } from "react-i18next";

interface TextFilterFieldProps {
  style?: React.CSSProperties;
  tooltipTitle?: React.ReactNode;
}

export const TextFilterField: React.FC<TextFilterFieldProps> = ({
  style,
  tooltipTitle,
}) => {
  const { t } = useTranslation();
  const textFilter = usePropertyTextFilterQuery();
  const { updateQueryParam } = useUpdateQueryParam();
  const updateTimerRef = useRef<NodeJS.Timeout>();
  const [isFlushing, setIsFlushing] = useState(false);
  const [value, setValue] = useState<string>(textFilter ?? "");

  useEffect(() => {
    // navigating via useEffect because navigate() does not work in setTimeout
    if (isFlushing) {
      setIsFlushing(false);
      updateQueryParam(
        PROPERTY_TEXT_FILTER_KEY,
        value,
        value === "" ? [PROPERTY_TEXT_FILTER_KEY, PAGE_KEY] : [PAGE_KEY], // remove params
        true // replace history
      );
    }
  }, [isFlushing, setIsFlushing, updateQueryParam, value]);

  const defaultTooltip = (
    <Box style={{ fontSize: "12px", color: colors.grey }}>
      <Typography fontSize="inherit">
        {t("propertyList.search.tooltipLine1")}
      </Typography>
      <Typography fontSize="inherit" fontStyle="italic">
        {t("propertyList.search.tooltipLine2")}
      </Typography>
      <Typography fontSize="inherit" fontStyle="italic">
        {t("propertyList.search.tooltipLine3")}
      </Typography>
    </Box>
  );

  return (
    <Stack direction={"row"} alignItems={"center"}>
      <TextField
        InputProps={{
          startAdornment: <SearchOutlinedIcon sx={{ color: colors.grey }} />,
          endAdornment: (
            <IconButton
              onClick={() => {
                clearTimeout(updateTimerRef.current);
                setValue("");
                setIsFlushing(true);
              }}
              size="small"
              sx={{ visibility: !!value ? undefined : "hidden" }}
            >
              <ClearOutlinedIcon />
            </IconButton>
          ),
        }}
        placeholder={t("propertyList.search.placeholder")}
        onChange={(e) => {
          setValue(e.target.value);
          clearTimeout(updateTimerRef.current);
          updateTimerRef.current = setTimeout(() => {
            // debounced to trigger fetch (not rerender) event only on slow typing / last keyup
            setIsFlushing(true);
          }, 200);
        }}
        value={value}
        style={style}
      />
      <LightTooltip
        title={tooltipTitle ?? defaultTooltip}
        arrow
        placement="bottom"
      >
        <InfoOutlinedIcon
          sx={{ color: colors.grey, marginLeft: "9px" }}
          fontSize="small"
        />
      </LightTooltip>
    </Stack>
  );
};
