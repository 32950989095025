import React from "react";
import { PropertyLocation } from "./PropertyLocation";
import { useQuery } from "react-query";
import { getPropertyLocationDetails } from "../../api/api";
import { useProperty } from "../../hooks/property.hooks";
import { PropertyDistances } from "./distances/PropertyDistances";
import { PropertySustainabilityReview } from "./PropertySustainabilityReview";

interface PropertyLocationReportProps {
  propertyId: string;
}

export const PropertyLocationReport: React.FC<PropertyLocationReportProps> = ({
  propertyId,
}) => {
  const { data: property } = useProperty(propertyId);

  const { data: propertyLocation, refetch } = useQuery(
    ["property_location", propertyId],
    () => getPropertyLocationDetails(propertyId),
    {
      refetchInterval: (data) =>
        data?.some((location) => !location.generatingCompleted) ? 5000 : false,
    }
  );

  return (
    <div>
      {!!property && <PropertyDistances property={property} />}
      {propertyLocation && (
        <>
          <PropertySustainabilityReview
            property={property}
            locations={propertyLocation}
            onRegenerate={() => refetch()}
          />
          <PropertyLocation
            locations={propertyLocation}
            onRegenerate={() => refetch()}
          />
        </>
      )}
    </div>
  );
};
